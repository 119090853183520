import { useEffect, useState } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { SourceSettings } from '@/components/AuralizerPresets/types';
import { AudioEngine } from '../AudioEngine';
import { ActionType, usePresetContext } from '../PresetContext';
import { Gain } from './Gain';
import { SourceSound } from './SourceSound';

import { DEFAULT_VALUE, MAX_VALUE, MIN_VALUE } from '../constants';

import { Source } from '@/types';

import '../style.scss';

export const SourcePointColumn = ({
  source,
  sourceIndex,
  sourceSettings,
}: {
  source: Source | null;
  sourceIndex: number;
  sourceSettings?: SourceSettings;
}) => {
  const [isMuted, setIsMuted] = useState(false);
  const [gainValue, setGainValue] = useState<number | undefined>(DEFAULT_VALUE);

  const { dispatch, selectedPresetEdited } = usePresetContext();

  const audioEngine = AudioEngine.getInstance();

  useEffect(() => {
    if (!selectedPresetEdited && sourceSettings && sourceSettings?.isMuted) {
      setIsMuted(true);
      if (gainValue !== undefined && source?.id) {
        audioEngine.muteSingleGain(source?.id);
      }
    } else if (
      !selectedPresetEdited &&
      sourceSettings &&
      !sourceSettings?.isMuted &&
      gainValue !== undefined &&
      source?.id
    ) {
      setIsMuted(false);
    } else if (sourceSettings === undefined && source) {
      setIsMuted(false);
      audioEngine.updateSingleGain(0, source.id);
    }
  }, [sourceSettings, selectedPresetEdited]);

  useEffect(() => {
    if (sourceSettings?.volume !== null && sourceSettings?.volume !== undefined) {
      setGainValue(sourceSettings?.volume);
    } else {
      setGainValue(DEFAULT_VALUE);
    }
  }, [sourceSettings, selectedPresetEdited]);

  const handleGainChange = (value: number) => {
    if (source?.id) {
      audioEngine.updateSingleGain(value, source.id);
      dispatch({
        type: ActionType.SET_SOURCE_VOLUME,
        sourceIndex,
        volume: value,
      });
    }
  };

  const handleIsMutedChange = (isMuted: boolean) => {
    if (source?.id) {
      dispatch({
        type: ActionType.SET_SOURCE_MUTE_SETTING,
        sourceIndex,
        isMuted,
      });
    }
  };

  const handleInputChange = (newValue: number | undefined) => {
    if (isMuted) {
      setIsMuted(false);
      handleIsMutedChange(false);
    }
    setGainValue(newValue === undefined ? undefined : Number(newValue));
    if (newValue !== undefined && newValue >= MIN_VALUE && newValue <= MAX_VALUE) {
      handleGainChange(Number(newValue));
    }
  };

  const handleSliderChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.target.value;
    if (isMuted) {
      setIsMuted(false);
      handleIsMutedChange(false);
    }
    setGainValue(parseInt(newValue));
    handleGainChange(Number(newValue));
  };

  const toggleMuteSourceSound = () => {
    const isSoundMute = !isMuted;
    setIsMuted(isSoundMute);
    handleIsMutedChange(isSoundMute);
    if (isSoundMute && source?.id) {
      audioEngine.muteSingleGain(source?.id);
    } else if (gainValue !== undefined && source?.id) {
      audioEngine.updateSingleGain(gainValue, source?.id);
    }
  };

  return (
    <div className="source-point-column">
      <SourceSound source={source} sourceIndex={sourceIndex} sourceSettings={sourceSettings} />
      <Gain inputValue={gainValue} onHandleInputChange={handleInputChange} onHandleSliderChange={handleSliderChange}>
        {source?.id && (
          <TrblTooltip title={`${isMuted ? 'Unmute' : 'Mute'}`} disableInteractive>
            <button className={`sound-btn ${isMuted ? 'selected' : ''}`} onClick={toggleMuteSourceSound}>
              <span> M</span>
            </button>
          </TrblTooltip>
        )}
      </Gain>
    </div>
  );
};
