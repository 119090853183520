import { IconProps } from '@/types';

export const TrblGASolverIcon = ({ fill = '#DADADA', width = '14', height = '14' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2205 1.31907C11.3349 1.38638 11.4178 1.49641 11.451 1.62491L12.0344 3.88241C12.1034 4.14977 11.9427 4.42252 11.6753 4.4916C11.408 4.56069 11.1352 4.39995 11.0662 4.13259L10.7872 3.05305L6.10811 11.1948L10.8508 8.01552L9.84993 7.86794C9.57674 7.82766 9.38793 7.57354 9.42821 7.30035C9.46849 7.02717 9.72261 6.83836 9.9958 6.87864L12.1716 7.19947C12.305 7.21914 12.4248 7.29182 12.5038 7.40103C12.5828 7.51024 12.6144 7.64674 12.5913 7.77956L12.2413 9.79789C12.1942 10.07 11.9353 10.2523 11.6633 10.2051C11.3912 10.1579 11.2089 9.89911 11.256 9.62703L11.3893 8.85841L5.37096 12.8928C5.18806 13.0154 4.94649 13.004 4.77604 12.8646L1.22354 9.95957C1.00977 9.78476 0.97818 9.46976 1.15299 9.25599C1.32779 9.04222 1.6428 9.01063 1.85657 9.18544L3.77254 10.7522L1.11301 6.38515C0.969377 6.1493 1.04413 5.84167 1.27998 5.69804C1.51583 5.55441 1.82346 5.62917 1.96709 5.86502L5.08008 10.9767L9.93115 2.5357L8.71265 2.85227C8.44538 2.92171 8.17243 2.76133 8.10299 2.49406C8.03355 2.22679 8.19393 1.95384 8.4612 1.8844L10.8412 1.26607C10.9697 1.23269 11.1061 1.25176 11.2205 1.31907Z"
      fill={fill}
    />
  </svg>
);
