import { forwardRef } from 'react';

import { TrblTooltip } from '@/components/Shared';

import styles from './styles.module.scss';

type ProjectRowProps = {
  projectName: string;
  isSelected: boolean;
  onClick: () => void;
};

export const ProjectRow = forwardRef<HTMLButtonElement, ProjectRowProps>(
  ({ projectName, isSelected, onClick }, ref) => {
    return (
      <button
        ref={ref}
        className={`${styles['project-row']} ${isSelected ? styles['selected'] : ''}`}
        onClick={onClick}
        type="button">
        <TrblTooltip title={projectName.length > 32 ? projectName : ''}>
          <span className={styles['project-name']}>{projectName}</span>
        </TrblTooltip>
      </button>
    );
  }
);

ProjectRow.displayName = 'ProjectRow';
