import { useQuery } from '@tanstack/react-query';

import axios from '@/axios';

export type ConcurrencyOverviewDtoConcurrencyOverviewDto = {
  maxConcurrency: number;
  currentConcurrencyInUse: number;
  queuedSourcesCount: number;
  queuedSourcesEstimatedRuntime: number;
  estimatedTimeUntilNextRunningSourceFinishes: number;
};

const getConcurrencyOverview = async (): Promise<ConcurrencyOverviewDtoConcurrencyOverviewDto> => {
  const { data } = await axios.get(`/api/Organization/GetConcurrencyOverview`);

  return data;
};

export const useGetConcurrencyOverview = () => {
  return useQuery<ConcurrencyOverviewDtoConcurrencyOverviewDto>(
    ['organization_overview'],
    () => getConcurrencyOverview(),
    {
      refetchOnWindowFocus: false,
    }
  );
};
