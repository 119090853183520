import { FC } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { IconButton } from '@mui/material';

import { PageLayout } from '@/components';
import { TrblChevronLeft } from '@/components/Icons';
import { useGetOrganizationById } from '@/components/ManagementPortal/hooks';
import { SDKUsage } from '@/components/ManagementPortal/SDKUsage/SDKUsage';

export const OrganizationUsagePage: FC = () => {
  const location = useLocation();
  const { id: organizationId } = useParams();
  const { data: organization } = useGetOrganizationById(organizationId || '');

  return (
    <PageLayout
      extraHeader={
        <div className="breadcrumbs-div">
          <Link to={location.state ?? `/organizations/${organizationId}`}>
            <IconButton className="back-btn">
              <TrblChevronLeft />
            </IconButton>
          </Link>
          <div className="top-info-text">
            <Link to={`/organizations/${organizationId}`}>{organization?.organization.name}</Link>
            <span className="arrow"> &rsaquo; </span>
            <span className="active">SDK token usage</span>
          </div>
        </div>
      }>
      <SDKUsage organizationId={organizationId ?? ''} />
    </PageLayout>
  );
};
