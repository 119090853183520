import { Link } from 'react-router-dom';

export const INFO_TEXT = {
  firstColumn: {
    header: 'Welcome to Treble!',
    firstSentence: (
      <>
        Go to{' '}
        <Link to="/tutorials" style={{ fontWeight: '600' }}>
          Tutorials
        </Link>{' '}
        to start exploring the possibilities of Treble
      </>
    ),
    secondSentence: (
      <>
        To import your own geometries press <span style={{ color: '#00f5ba', fontWeight: '600' }}>+ New</span> in the
        navbar, or import your geometries from SketchUp using our{' '}
        <a
          style={{ color: '#00f5ba', textDecoration: 'underline' }}
          target="_blank"
          href={'https://docs.treble.tech/user-guide/importing-models/treble-in-sketchup/treble-extension'}>
          SketchUp plugin
        </a>
        *
      </>
    ),
    smallText: (
      <>
        * Note that you will need a{' '}
        <a
          target="_blank"
          style={{ textDecoration: 'underline' }}
          href={'https://docs.treble.tech/user-guide/importing-models/treble-in-sketchup/sketchup'}>
          SketchUp Pro
        </a>{' '}
        license
      </>
    ),
  },
  secondColumn: {
    linkButtonText1: 'Quick start guide',
    linkButtonText2: 'Documentation',
    linkButtonText3: 'FAQ',
  },
  thirdColumn: {
    firstSentenceStandard: 'A support button is on the bottom left side of the screen',
    firstSentence: 'Our live support chat is on the bottom left side of the screen',
    secondSentence: 'Find documentation under the menu icon in the top right corner',
  },
  fourthColumn: {
    imgSrc: 'https://images.prismic.io/treble-tech/Z36PjJbqstJ99LSZ_header1.png?auto=format,compress',
    label: 'Documentation',
    title: (
      <>
        Our biggest <br /> update ever
      </>
    ),
    button: 'Learn more',
  },
};

export const EXTERNAL_LINKS = {
  docs: `https://docs.treble.tech/user-guide/getting-started/quick_start_guide`,
  userGuide: 'https://docs.treble.tech/user-guide',
  faq: 'https://www.treble.tech/faq',
};

export const INFO_PANEL_KEY = 'treble_info_panel_state';
