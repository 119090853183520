import { FC, useMemo } from 'react';
import { Sphere } from '@react-three/drei';
import { DynamicDrawUsage, Texture, TextureLoader, Vector3 } from 'three';

import { EMPTY_GUID, ZEROS_ONE_GUID } from '@/components/Shared/constants';
import { useCreatePointLabel, useObjectClickHandler } from '@/components/Editor/hooks';
import { Arrow } from '../Arrow';
import roundImageUrl from '../images/round.png';
import roundSelectedImageUrl from '../images/round_selected.png';

import { ResultsView, ValidationError } from '@/context/EditorContext/types';

const DEFAULT_GEOMETRY_ARRAY = new Float32Array([0, 0, 0]);

const pointTexture = new TextureLoader().load(roundImageUrl);
const pointSelectedTexture = new TextureLoader().load(roundSelectedImageUrl);

const getPointColor = (validationError: ValidationError | undefined, type: 'SourcePoint' | 'ReceiverPoint') => {
  if (validationError) return '#f84400';
  return type === 'SourcePoint' ? '#65f6b0' : '#00A3FE';
};

const getRenderOrder = (isSelected: boolean, inEditor: boolean) => {
  if (isSelected) return inEditor ? 0 : 10;
  return 0;
};

type PointProps = {
  id: string;
  index: number;
  type: 'SourcePoint' | 'ReceiverPoint';
  resultView: ResultsView;
  x: number;
  y: number;
  z: number;
  defaultSize?: number;
  defaultTexture?: Texture;
  directivityPattern?: string;
  azimuth?: number;
  elevation?: number;
  isSelected?: boolean;
  inEditor?: boolean;
  validationError?: ValidationError;
  sphereMargin?: number;
  onSelect?: (id: string, type: 'SourcePoint' | 'ReceiverPoint') => void;
};

export const Point: FC<PointProps> = ({
  id,
  index,
  type,
  resultView,
  x,
  y,
  z,
  defaultSize = 12,
  defaultTexture = pointTexture,
  directivityPattern,
  azimuth,
  elevation,
  isSelected = false,
  inEditor = false,
  validationError,
  sphereMargin,
  onSelect,
}) => {
  const handleClick = onSelect ? () => onSelect(id, type) : undefined;

  const clickHandlerProps = useObjectClickHandler(handleClick, onSelect && !isSelected && inEditor);

  const pointColor = getPointColor(validationError, type);
  const pointSize = isSelected ? 17.5 : defaultSize;
  const renderOrder = getRenderOrder(isSelected, inEditor);

  const pointLabel = useCreatePointLabel(
    (index + 1).toString(),
    pointColor,
    defaultSize > 12 ? 17 : undefined,
    defaultSize > 12 ? -1.7 : undefined
  );

  const showArrow = useMemo(
    () =>
      azimuth !== undefined &&
      elevation !== undefined &&
      isSelected &&
      ((directivityPattern && ![EMPTY_GUID, ZEROS_ONE_GUID].includes(directivityPattern)) ||
        (resultView === ResultsView.ResultsReflectogramView && type === 'ReceiverPoint')),
    [azimuth, elevation, isSelected, directivityPattern, resultView, type]
  );

  return (
    <points uuid={id} name={type} position={new Vector3(x, y, z)} {...clickHandlerProps} renderOrder={renderOrder}>
      <primitive object={pointLabel} renderOrder={renderOrder} />

      <bufferGeometry attach="geometry">
        <bufferAttribute
          attach="attributes-position"
          count={1}
          array={DEFAULT_GEOMETRY_ARRAY}
          itemSize={3}
          usage={DynamicDrawUsage}
        />
      </bufferGeometry>

      <pointsMaterial
        attach="material"
        map={isSelected ? pointSelectedTexture : defaultTexture}
        color={pointColor}
        size={pointSize}
        sizeAttenuation={false}
        alphaTest={0.2}
        transparent={true}
      />

      {validationError === ValidationError.CloseToSurface && (
        <Sphere
          args={[
            type === 'SourcePoint' ? sphereMargin : 0.1,
            type === 'SourcePoint' ? 16 : 12,
            type === 'SourcePoint' ? 16 : 12,
          ]}>
          <meshStandardMaterial color={0xf84400} opacity={0.3} depthTest={false} transparent={true} />
        </Sphere>
      )}
      {validationError === ValidationError.CloseToSource && (
        <Sphere args={[0.5, 12, 12]}>
          <meshStandardMaterial color={0xf84400} depthTest={false} opacity={0.3} transparent={true} />
        </Sphere>
      )}

      {showArrow && (
        <Arrow azimuth={azimuth!} elevation={elevation!} color={type === 'ReceiverPoint' ? '#00A3FE' : '#65f6b0'} />
      )}
    </points>
  );
};
