import { FC, SyntheticEvent, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';

import { ChatIcon, TrblCloseIcon, TrblFileIcon, TrblInfoIcon, TrblQuickGuideIcon } from '../Icons';
import { TrblQuestionMarkIcon } from '../Icons/TrblQuestionMarkIcon';

import './style.scss';

export const SupportStandard: FC = () => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setIsOpen(false);
  };

  const openQuickStartGuideWindow = (e: SyntheticEvent) => {
    e.preventDefault();
    window.open(
      'https://docs.treble.tech/user-guide/getting-started/quick_start_guide',
      'mywindow',
      'width=600,height=' + window.screen.height
    );
  };

  return (
    <>
      <Button
        ref={anchorRef}
        className={`support-button standard ${location.pathname === '/results' ? 'pushed-up' : 'normal'} ${
          location.pathname === '/auralizer' ? 'pushed-up auralizer' : 'normal'
        } ${isOpen ? 'open' : ''} `}
        variant="text"
        startIcon={isOpen ? <TrblCloseIcon /> : <ChatIcon />}
        onClick={handleToggle}>
        Support
      </Button>

      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="right-end"
        transition
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        style={{ zIndex: 1 }}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className="create-button-paper" style={{ marginLeft: '8px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className="create-button-list support-list">
                  <MenuItem>
                    <a
                      href="https://docs.treble.tech/user-guide/getting-started/quick_start_guide"
                      onClick={openQuickStartGuideWindow}>
                      <ListItemIcon>
                        <TrblQuickGuideIcon width="14" height="14" />
                      </ListItemIcon>
                      <ListItemText>Quick start guide</ListItemText>
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a href="https://docs.treble.tech/user-guide/" target="_blank">
                      <ListItemIcon>
                        <TrblFileIcon width="14" height="14" />
                      </ListItemIcon>
                      <ListItemText>Documentation</ListItemText>
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a href="https://www.treble.tech/faq" target="_blank">
                      <ListItemIcon>
                        <TrblInfoIcon width="14" height="14" />
                      </ListItemIcon>
                      <ListItemText>FAQ</ListItemText>
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a href="https://docs.treble.tech/user-guide/support/contact" target="_blank">
                      <ListItemIcon>
                        <TrblQuestionMarkIcon width="14" height="14" />
                      </ListItemIcon>
                      <ListItemText>Contact us</ListItemText>
                    </a>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
