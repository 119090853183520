import { useEffect, useState } from 'react';

import { ActionType, useAppContext } from '@/context/AppContext';
import { useModelContext } from '@/context/ModelContext';

import { SortType } from '@/components/SimulationSidePanel/constants';
import { GeometryCard } from './GeometryCard';

import { useGetSpaceById, useGetThumbnailsForModelBases } from '@/hooks';

import { toSorted } from '@/utils/trebleFunctions';

import { ModelBaseDto } from '@/types';

import classes from './styles.module.scss';

type GeometryPicker = {
  sortListBy: SortType;
  searchValue: string;
};

export const GeometryPicker = ({ sortListBy, searchValue }: GeometryPicker) => {
  const { dispatch } = useAppContext();

  const { modelInformation } = useModelContext();
  const { data: space } = useGetSpaceById(modelInformation?.spaceId || '');
  const [modelBases, setModelBases] = useState(space?.modelBases);
  const { data: thumbnails, isFetching: isFetchingThumbnails } = useGetThumbnailsForModelBases(
    modelBases?.map((modelBase) => modelBase.id) || []
  );

  const [filteredModels, setFilteredModels] = useState<ModelBaseDto[]>([]);

  useEffect(() => {
    if (space?.modelBases) {
      setModelBases(
        [...space.modelBases].sort((a: ModelBaseDto, b: ModelBaseDto) =>
          new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1
        )
      );
      dispatch({
        type: ActionType.SET_MODELS_IN_SPACE,
        payload: space?.modelBases,
      });
    }
  }, [space, isFetchingThumbnails]);

  useEffect(() => {
    if (searchValue && modelBases) {
      const filteredModels = [...modelBases].filter((modelBase) => {
        return [modelBase.name].some((prop) => prop?.toLowerCase().includes(searchValue.toLowerCase()));
      });
      setFilteredModels(filteredModels);
    } else if (modelBases) {
      setFilteredModels(modelBases);
    }
  }, [searchValue, modelBases]);

  const sortModels = (modelBases: ModelBaseDto[] | undefined, sortListBy: SortType) => {
    if (modelBases) {
      switch (sortListBy) {
        case SortType.NAME: {
          return toSorted(modelBases, (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        }

        case SortType.CREATED: {
          return toSorted(modelBases, (a, b) => (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1));
        }
      }
      return modelBases;
    }
    return [];
  };

  return (
    <div className={`${classes.models_container}  `}>
      {!isFetchingThumbnails &&
        filteredModels.length > 0 &&
        sortModels(filteredModels, sortListBy)?.map(
          ({ id, name, revisions, model }) =>
            revisions > 0 && (
              <GeometryCard
                key={id}
                id={id}
                geometry={model}
                thumbnail={thumbnails?.find((t) => t.key === id)?.value as string}
                name={name}
                selectedGeometryId={modelInformation?.id}
                searchValue={searchValue}
              />
            )
        )}
    </div>
  );
};
