import { useMutation } from '@tanstack/react-query';

import { useRemoveSourceSummingTask } from './useRemoveSourceSummingTask';

type RemoveMultipleSourceSummingTasksRequest = {
  summedSourceId: string;
  taskIds: string[];
};

export const useRemoveMultipleSourceSummingTasks = () => {
  const { mutateAsync: removeTask } = useRemoveSourceSummingTask();

  return useMutation(async (request: RemoveMultipleSourceSummingTasksRequest) => {
    const { summedSourceId, taskIds } = request;

    // Execute all mutations in parallel and wait for all to complete
    const results = await Promise.all(taskIds.map((taskId) => removeTask({ summedSourceId, taskId })));

    return results;
  });
};
