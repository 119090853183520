import { useEffect, useState } from 'react';

import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';

import { TrblTooltip } from '@/components/Shared';
import { PrimaryButton } from '@/components/Shared/Buttons';

import { usePerformLambdaMaterialFitting } from '@/hooks';

import { FREQUENCY_RANGE_THIRD_OCTAVE_NUM, MaterialInputType } from '../constants';

import { getMissingKeys } from '../utils/getMissingKeys';
import { getValuesByPropName } from '../utils/getValuesByPropName';

import { ComplexData } from '../types';

export const LambdaMaterialFit = ({ data }: { data: ComplexData }) => {
  const { inputType, formDisabled, formValues, dispatch } = useCreateMaterialContext();

  const { mutate: fitMaterial } = usePerformLambdaMaterialFitting();

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const missingKeys = getMissingKeys(data, FREQUENCY_RANGE_THIRD_OCTAVE_NUM);
    setDisabled(missingKeys.length > 0);
  }, [data]);

  const calculateAbsorption = () => {
    dispatch({
      type: ActionType.SET_DISABLED_FORM,
      formDisabled: true,
    });
    const realValues = getValuesByPropName('real', data);
    const imagValues = getValuesByPropName('imag', data);

    if (!inputType) return;

    fitMaterial(
      {
        inputType,
        coefficientsReal: realValues,
        coefficientsImaginary: imagValues,
        ...(formValues.isSpecific && { isSpecific: formValues.isSpecific }),
      },
      {
        onSuccess: (fitResponse) => {
          if (inputType === MaterialInputType.SurfaceImpedance) {
            dispatch({
              type: ActionType.SET_IMPEDANCE_FITTED_DATA,
              impedanceFittedData: fitResponse,
            });
          } else {
            dispatch({
              type: ActionType.SET_REFLECTION_FITTED_DATA,
              reflectionFittedData: fitResponse,
            });
          }
        },
        onError: () => {
          dispatch({
            type: ActionType.SET_DISABLED_FORM,
            formDisabled: false,
          });
        },
      }
    );
  };

  return (
    <TrblTooltip
      title={
        disabled
          ? 'You need to provide all 24 frequencies'
          : formDisabled
          ? 'Calculating absorption'
          : 'Calculate absorption'
      }>
      <span>
        <PrimaryButton
          width="fit-content"
          label="Calculate absorption"
          disabled={disabled || formDisabled}
          onClick={calculateAbsorption}
        />
      </span>
    </TrblTooltip>
  );
};
