import { useQuery } from '@tanstack/react-query';

import { TutorialDto } from '../types';

import axios from '@/axios';

const getTutorials = async () => {
  const { data } = await axios.get<TutorialDto[]>(`/api/Space/GetTutorials`);

  return data.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

export const useGetTutorials = () => {
  const query = useQuery<TutorialDto[], boolean>(['tutorials'], getTutorials, {
    refetchOnWindowFocus: false,
  });

  return query;
};
