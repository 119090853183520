import { SubscriptionInfo } from '@/context/BaseContext';

import { ChatIcon, ChatWidgetIcon, TrblArrowThinRight, TrblCloseIcon, TrblUserIcon } from '@/components/Icons';
import { LinkButton } from './LinkButton';
import infoVideoSrc from './videos/tvi.mp4';

import { EXTERNAL_LINKS, INFO_TEXT } from './constants';

import classes from './styles.module.scss';

export const InfoPanel = ({
  show,
  subscriptionInfo,
  closeInfoPanel,
}: {
  show: string;
  subscriptionInfo: SubscriptionInfo;
  closeInfoPanel: () => void;
}) => {
  const openQuickStartGuideWindow = (url: string) => {
    window.open(url, 'mywindow', 'width=600,height=' + window.screen.height);
  };

  return show == 'open' ? (
    <div className={classes.info_panel_container}>
      <div
        className={`${classes.info_column} 
      ${classes.first}`}>
        <p className={classes.info_header}> {INFO_TEXT.firstColumn.header}</p>
        <p className={classes.column_text}>{INFO_TEXT.firstColumn.firstSentence}</p>
        <p className={classes.column_text}>{INFO_TEXT.firstColumn.secondSentence}</p>
        <p className={classes.column_small_text}> {INFO_TEXT.firstColumn.smallText}</p>
      </div>
      <div className={`${classes.info_column} ${classes.second}`}>
        <LinkButton
          text={INFO_TEXT.secondColumn.linkButtonText1}
          icon={'quickGuide'}
          url={EXTERNAL_LINKS.docs}
          onClick={openQuickStartGuideWindow}
        />
        <LinkButton text={INFO_TEXT.secondColumn.linkButtonText2} icon={'file'} url={EXTERNAL_LINKS.userGuide} />
        <LinkButton text={INFO_TEXT.secondColumn.linkButtonText3} icon={'info'} url={EXTERNAL_LINKS.faq} />
      </div>
      <div className={`${classes.info_column} ${classes.third}`}>
        {subscriptionInfo.type == 'SingleStandard' || subscriptionInfo.type == 'TeamStandard' ? (
          <div className={classes.info_section}>
            <ChatIcon width="18" height="18" />
            <span className={classes.info_text}>{INFO_TEXT.thirdColumn.firstSentenceStandard}</span>
          </div>
        ) : (
          <div className={classes.info_section}>
            <ChatWidgetIcon />
            <span className={classes.info_text}>{INFO_TEXT.thirdColumn.firstSentence}</span>
          </div>
        )}

        <div className={classes.info_section}>
          <TrblUserIcon />
          <span className={classes.info_text}>{INFO_TEXT.thirdColumn.secondSentence}</span>
        </div>
      </div>

      <div className={`${classes.info_column} ${classes.fourth}`}>
        <div className={classes.info_image}>
          <video autoPlay muted playsInline loop src={infoVideoSrc} />
          <a
            target="_blank"
            href="https://docs.treble.tech/release-notes#treble-web-application---06022025---our-biggest-update-ever"
            className={classes.info_image_text_container}>
            <span>{INFO_TEXT.fourthColumn.label}</span>
            <h5>{INFO_TEXT.fourthColumn.title}</h5>
            <div>
              {INFO_TEXT.fourthColumn.button} <TrblArrowThinRight />
            </div>
          </a>
        </div>
      </div>
      <button className={classes.dismiss_button} onClick={closeInfoPanel}>
        <TrblCloseIcon stroke="#999999" />
      </button>
    </div>
  ) : null;
};
