import { useEffect, useState } from 'react';

import { useCreateMaterialContext } from '../context/CreateMaterialContext';

import { Box } from '@mui/material';

import { BufferLoadingScreen } from '@/components/Shared/BufferLoadingScreen';
import { DarkBox } from '@/components/Shared/Popup';
import { ABS_PLOT_TITLE, ABS_RANGE, TICK_VALS } from '@/components/MaterialDetailsPopup/constants';
import { ImpedanceAndReflectionPlots } from '@/components/MaterialDetailsPopup/ImpedanceAndReflectionPlots';
import { MaterialDetailsPlot } from '@/components/MaterialDetailsPopup/MaterialDetailsPlot';
import { AirCavityForm } from './AirCavityForm';
import { BuilderCanvas } from './BuilderCanvas';
import { PorousAbsorberForm } from './PorousAbsorberForm';
import { SimpleInputTable } from './SimpleInputTable';

import { FREQUENCY_RANGE_FULL_OCTAVE, GREEN_PLOT_COLOR, ORANGE_PLOT_COLOR, PLOT_WIDTH_SMALL } from '../constants';

import { MaterialInfoJsonDto } from '../types';
import { MaterialMetadata } from '@/types';

import styles from '../styles.module.scss';

export const MaterialBuilder = ({ plotHeight }: { plotHeight: number }) => {
  const { formDisabled, builderFittedData } = useCreateMaterialContext();

  const [disableEdit, setDisableEdit] = useState<boolean>(false);

  const [parsedMaterialJson, setParsedMaterialJson] = useState<MaterialInfoJsonDto | null>();
  const [parsedMaterialMetadata, setParsedMaterialMetadata] = useState<MaterialMetadata | null>();

  useEffect(() => {
    setDisableEdit(formDisabled || builderFittedData !== null);
  }, [formDisabled, builderFittedData]);

  useEffect(() => {
    if (builderFittedData) {
      const parsedMaterialMetadata = JSON.parse(builderFittedData.materialMetadataJson);
      const parsedMaterialJson = JSON.parse(builderFittedData.materialJson);
      setParsedMaterialJson(parsedMaterialJson);
      setParsedMaterialMetadata(parsedMaterialMetadata);
    } else {
      setParsedMaterialJson(null);
      setParsedMaterialMetadata(null);
    }
  }, [builderFittedData]);

  return (
    <>
      <Box component={'div'} display="flex" gap="60px" mt="24px" mb="24px">
        <AirCavityForm disabled={disableEdit} />
        <PorousAbsorberForm disabled={disableEdit} />
      </Box>
      <div style={{ width: '82%', margin: '-8px 0 8px' }}>
        <SimpleInputTable
          editable={false}
          onlyResults={true}
          fittedAbsorptionCoefficients={parsedMaterialJson?.FittedAbsorptionCoefficients}
          resultTitle="Absorption coefficient (Random incidence)"
        />
      </div>
      <DarkBox>
        <div className={`material-builder-plots ${styles.plot_dark_box}`}>
          {formDisabled && <BufferLoadingScreen />}
          <MaterialDetailsPlot
            range={ABS_RANGE}
            ticktext={FREQUENCY_RANGE_FULL_OCTAVE}
            tickvals={TICK_VALS}
            xData={TICK_VALS}
            plots={[
              parsedMaterialJson?.InputAbsorptionCoefficients ?? [],
              parsedMaterialJson?.FittedAbsorptionCoefficients ?? [],
            ]}
            plotColors={[ORANGE_PLOT_COLOR, GREEN_PLOT_COLOR]}
            plotTitles={['Target', 'Result']}
            plotDashed={[false, true]}
            yAxesTitle={ABS_PLOT_TITLE}
            plotHeight={plotHeight}
            plotWidth={PLOT_WIDTH_SMALL}
          />
          <ImpedanceAndReflectionPlots
            impedanceFirst
            showTabs
            resultRealReflection={parsedMaterialMetadata?.RealReflectionCoefficient ?? []}
            resultImagReflection={parsedMaterialMetadata?.ImagReflectionCoefficient ?? []}
            resultRealImpedance={parsedMaterialMetadata?.RealSurfaceImpedance ?? []}
            resultImagImpedance={parsedMaterialMetadata?.ImagSurfaceImpedance ?? []}
            targetRealReflection={parsedMaterialMetadata?.InputRealReflectionCoefficient ?? []}
            targetImagReflection={parsedMaterialMetadata?.InputImagReflectionCoefficient ?? []}
            targetRealImpedance={parsedMaterialMetadata?.InputRealSurfaceImpedance ?? []}
            targetImagImpedance={parsedMaterialMetadata?.InputImagSurfaceImpedance ?? []}
            plotHeight={plotHeight}
            plotWidth={PLOT_WIDTH_SMALL}
          />
          <BuilderCanvas height={plotHeight} />
        </div>
      </DarkBox>
    </>
  );
};
