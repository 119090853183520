/* eslint-disable @typescript-eslint/no-explicit-any */
import { Data, Layout } from 'plotly.js';

import { SourceDefinitionJson } from './types';

function getMinMaxForSourceDefinitionPlot(plotlyData: any[]) {
  const numberArray: any = [];

  plotlyData.forEach((plot: any) => {
    if (plot.type == 'scatterpolar') {
      plot.r.forEach((number: number) => {
        if (!isNaN(number) && number !== null) numberArray.push(number);
      });
    }
  });

  return [Math.min(...numberArray), Math.max(...numberArray)];
}

export const getSourceDefinitionPlotLayoutConfig = (plotlyData: any, view?: string, type?: string) => {
  let min: number = 0;
  let max: number = 0;
  if (plotlyData.length && plotlyData[0]?.r) {
    [min, max] = getMinMaxForSourceDefinitionPlot(plotlyData);
    // set min and max for Omni
    if (min === 0 && max === 0) [min, max] = [-25, 0];
  }

  let tickText = ['0°', '45°', '90°', '135°', '180°', '225°', '270°', '315°'];
  if (view && view === 'Side') tickText = ['0°', '45°', '90°', '135°', '180°', '-135°', '-90°', '-45°'];

  const layoutConfig = type === 'small' ? plotLayoutConfigSmall : plotLayoutConfig;
  const returnLayoutConfig = {
    ...layoutConfig,
    polar: {
      ...layoutConfig.polar,
      radialaxis: {
        ...layoutConfig.polar?.radialaxis,
        range: [min - 5, max + 5],
      },
      angularaxis: {
        ...layoutConfig.polar?.angularaxis,
        tickvals: [0, 45, 90, 135, 180, 225, 270, 315],
        ticktext: tickText,
      },
    },
  };
  return returnLayoutConfig;
};

const plotLayoutConfig: Partial<Layout> = {
  xaxis: {
    color: 'black',
  },
  yaxis: {
    color: 'black',
  },
  polar: {
    bgcolor: '#1a1b1c',
    angularaxis: {
      tickwidth: 2,
      linewidth: 2,
      griddash: 'dot',
      linecolor: '#171717',
      gridcolor: '#444444',
      tickcolor: '#444444',
    },
    radialaxis: {
      range: [-30, 5],
      nticks: 5,
      tickmode: 'auto',
      color: '#dadada',
      gridcolor: '#444444',
      linecolor: '#dadada',
    },
  },
  legend: {
    xanchor: 'left',
    font: {
      size: 10,
      color: '#DADADA',
    },
  },

  font: {
    size: 9,
    color: '#DADADA',
  },
  showlegend: true,
  paper_bgcolor: 'transparent',
  margin: { t: 24, b: 20, l: 10, r: 0 },
  width: 380,
  height: 260,
};

const plotLayoutConfigSmall: Partial<Layout> = {
  xaxis: {
    color: 'black',
  },
  yaxis: {
    color: 'black',
  },
  polar: {
    bgcolor: '#1a1b1c',
    angularaxis: {
      tickwidth: 2,
      linewidth: 2,
      griddash: 'dot',
      linecolor: '#171717',
      gridcolor: '#444444',
      tickcolor: '#444444',
      ticklen: 3,
    },
    radialaxis: {
      range: [-30, 5],
      nticks: 5,
      tickmode: 'auto',
      color: '#dadada',
      gridcolor: '#444444',
      linecolor: '#dadada',
    },
  },

  legend: {
    xanchor: 'right',
    orientation: 'v',
    font: {
      color: '#DADADA',
      size: 10,
    },
  },
  font: {
    size: 9,
    color: '#DADADA',
  },
  showlegend: true,
  paper_bgcolor: 'transparent',
  grid: {
    rows: 1,
    columns: 2,
    pattern: 'coupled',
  },
  width: 370,
  height: 170,
  margin: { t: 0, b: 0, l: 90, r: 0, pad: 0 },

  autosize: true,
};

export const getSoundPowerValues = () => {
  return [105, 105, 105, 105, 105, 105, 105, 105];
};

export const getSPLValues = (sourceDefinitionJson: SourceDefinitionJson): number[] => {
  const spl = sourceDefinitionJson.spl_on_axis;

  if (!spl) {
    throw new Error('spl_on_axis is missing from sourceDefinitionJson');
  }

  return [
    parseFloat(spl['63'].toFixed(1)),
    parseFloat(spl['125'].toFixed(1)),
    parseFloat(spl['250'].toFixed(1)),
    parseFloat(spl['500'].toFixed(1)),
    parseFloat(spl['1000'].toFixed(1)),
    parseFloat(spl['2000'].toFixed(1)),
    parseFloat(spl['4000'].toFixed(1)),
    parseFloat(spl['8000'].toFixed(1)),
  ];
};

export const getSPLOnAxisValues = (sourceDefinitionJson: SourceDefinitionJson): number[] => {
  const spl = sourceDefinitionJson.SplOnAxis1mByFrequency;

  if (!spl) {
    throw new Error('SplOnAxis1mByFrequency is missing from sourceDefinitionJson');
  }

  return Object.values(spl);
};

export const getPlotData = (angles: any, directivity: any): Array<Data> => {
  const traces: Array<Data> = [];

  if (directivity) {
    const y = angles;
    const x1 = directivity['63'];
    const x2 = directivity['125'];
    const x3 = directivity['250'];
    const x4 = directivity['500'];
    const x5 = directivity['1000'];
    const x6 = directivity['2000'];
    const x7 = directivity['4000'];
    const x8 = directivity['8000'];

    traces.push({
      r: x1,
      theta: y,
      mode: 'lines',
      name: ' 63 Hz',
      line: { color: 'peru' },
      type: 'scatterpolar',
    });

    traces.push({
      r: x2,
      theta: y,
      mode: 'lines',
      name: '125 Hz',
      line: { color: 'darkviolet' },
      type: 'scatterpolar',
    });

    traces.push({
      r: x3,
      theta: y,
      mode: 'lines',
      name: '250 Hz',
      line: { color: 'deepskyblue' },
      type: 'scatterpolar',
    });

    traces.push({
      r: x4,
      theta: y,
      mode: 'lines',
      name: '500 Hz',
      line: { color: '#f6845b' },
      type: 'scatterpolar',
    });

    traces.push({
      r: x5,
      theta: y,
      mode: 'lines',
      name: ' 1k Hz',
      line: { color: '#d15bf6' },
      type: 'scatterpolar',
    });

    traces.push({
      r: x6,
      theta: y,
      mode: 'lines',
      name: ' 2k Hz',
      type: 'scatterpolar',
      line: { color: '#f65b80' },
    });

    traces.push({
      r: x7,
      theta: y,
      mode: 'lines',
      name: ' 4k Hz',
      type: 'scatterpolar',
      line: { color: '#f6d15b' },
    });

    traces.push({
      r: x8,
      theta: y,
      mode: 'lines',
      name: ' 8k Hz',
      type: 'scatterpolar',
      line: { color: '#5bcef6' },
    });
  }

  return traces;
};
