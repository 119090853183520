import { ReactElement, useEffect, useRef, useState } from 'react';

import { CategoryFilter } from './components/CategoryFilter';
import { LibraryPanelHeader } from './components/LibraryPanelHeader';
import { LibrarySearch } from './components/LibrarySearch';

import { useHandleKeyDown } from './hooks/useHandleKeyDown';

import './style.scss';

export const LibraryPanel = ({
  label,
  icon,
  onSearchLibrary,
  categories,
  onSelectCategories,
  children,
  inPopup,
}: {
  label: string;
  icon: ReactElement;
  categories: string[];
  onSearchLibrary: (key: string, inputValue: string) => void;
  onSelectCategories: (categories: string[]) => void;
  children: JSX.Element | JSX.Element[];
  inPopup?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const libraryPanelRef = useRef<HTMLDivElement>(null);
  useHandleKeyDown(inputRef, libraryPanelRef);

  const [trialBannerHeight, setTrialBannerHeight] = useState<number>(0);

  useEffect(() => {
    const trialBanner = document.querySelector<HTMLElement>('.trial-expiring-header');
    if (trialBanner) {
      setTrialBannerHeight(trialBanner.offsetHeight);
    }
  }, []);

  return (
    <div ref={libraryPanelRef} className={inPopup ? 'popup-library-panel' : 'treble-library-panel'}>
      {!inPopup && <LibraryPanelHeader title={label} icon={icon} />}
      <div
        className="treble-library-panel-content"
        style={{ height: `calc(100vh - ${60 + 54 + trialBannerHeight}px)` }}>
        <div style={{ margin: '0 20px' }}>
          <LibrarySearch inputRef={inputRef} label={label} searchLibrary={onSearchLibrary} />
        </div>
        <CategoryFilter categories={categories} onSelectCategories={onSelectCategories} />
        {children}
      </div>
    </div>
  );
};
