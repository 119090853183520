import { FC } from 'react';

import { Checkbox } from '@/components/Shared/Checkbox';
import { SearchInput } from '@/components/Shared/SearchInput';
import { Text } from '@/components/Shared/Text';
import { Marker } from '@/components/SourceRecieverSettings/Marker';

import { BaseType } from '@/types';

import styles from './styles.module.scss';

interface SourceListProps {
  sources: BaseType[];
  selectedSourceIds: string[];
  filteredSourceItemIds: string[];
  sourceSearchQuery: string;
  onSearchChange: (value: string) => void;
  onSourceToggle: (id: string) => void;
  onSelectAll: (isChecked: boolean) => void;
}

export const SourceList: FC<SourceListProps> = ({
  sources,
  selectedSourceIds,
  filteredSourceItemIds,
  sourceSearchQuery,
  onSearchChange,
  onSourceToggle,
  onSelectAll,
}) => (
  <div className={styles.listContainer}>
    <div className={styles.listHeader}>
      <Checkbox
        id={'select-all-sources'}
        label={<Text type="semibold-12px">Sources</Text>}
        spaceBetween={true}
        labelAlignment={'left'}
        isChecked={selectedSourceIds.length > 0 && selectedSourceIds.length === sources.length}
        onChange={onSelectAll}
      />
    </div>

    <div className={styles.searchContainer}>
      <SearchInput value={sourceSearchQuery} onChange={onSearchChange} placeholder="Search in sources" />
    </div>

    <div className={styles.listContent}>
      {sources.map((item, index) =>
        filteredSourceItemIds.includes(item.id) ? (
          <div key={item.id} className={styles.listItem}>
            <Checkbox
              id={item.id}
              label={
                <div style={{ flex: 1 }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Marker label={`${index + 1}`} color="green" small />
                    <Text type="regular-11px">{item.name}</Text>
                  </div>
                </div>
              }
              spaceBetween={true}
              labelAlignment={'left'}
              isChecked={selectedSourceIds.includes(item.id)}
              onChange={() => onSourceToggle(item.id)}
            />
          </div>
        ) : null
      )}
    </div>
  </div>
);
