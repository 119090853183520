import React, { FC, ReactElement } from 'react';
import Draggable from 'react-draggable';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Paper,
  PaperProps,
  styled,
} from '@mui/material';

import { TrblCloseIcon } from '@/components/Icons';

import styles from './styles.module.scss';

interface TrblPopupProps extends DialogProps {
  width?: number | string;
  height?: number | string;
  minheight?: number | string;
  maxheight?: number | string;
  minwidth?: number | string;
  maxwidth?: number | string;
  boxShadow?: string;
  position?: string;
  right?: string;
  top?: string;
  draggable?: boolean;
}

const StyledDialog = styled(Dialog)<TrblPopupProps>(
  ({ width, height, minheight, maxheight, minwidth, maxwidth, boxShadow, position, right, top }) => ({
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      backgroundImage: 'none',
      fontSize: '12px',
      width: width || '400px',
      height: height || 'initial',
      minHeight: minheight || 'initial',
      maxHeight: maxheight || 'initial',
      minWidth: minwidth || 'initial',
      maxWidth: maxwidth || 'none',
      borderRadius: '4px',
      boxShadow: boxShadow || '0px 6px 20px rgb(0 0 0 / 50%)',
      position: position,
      right: right,
      top: top,
    },
    '.MuiIconButton-root.Mui-disabled': {
      opacity: '0.5',
    },
  })
);

const DraggablePaperComponent: FC<PaperProps> = (props) => {
  const nodeRef = React.useRef(null);
  const [position, setPosition] = React.useState({
    x: 0,
    y: 0,
  });

  return (
    <Draggable
      nodeRef={nodeRef}
      position={position}
      onDrag={(e, data) => setPosition({ x: data.x, y: data.y })}
      handle=".draggable-popup-title"
      bounds={'parent'}>
      <Paper {...props} ref={nodeRef} />
    </Draggable>
  );
};

const PopupContext = React.createContext<{ draggable?: boolean }>({ draggable: false });

export const TrblPopup = ({ draggable, style, ...props }: TrblPopupProps) => {
  const contextValue = React.useMemo(() => ({ draggable }), [draggable]);

  return (
    <PopupContext.Provider value={contextValue}>
      <StyledDialog
        {...props}
        style={{
          ...style,
          position: draggable ? 'fixed' : undefined,
        }}
        PaperComponent={draggable ? DraggablePaperComponent : undefined}
        hideBackdrop={draggable ? true : props.hideBackdrop}
        disableEnforceFocus={draggable}
        disableAutoFocus={draggable}
      />
    </PopupContext.Provider>
  );
};

type TrblPopupTitleProps = {
  children?: React.ReactNode;
  onClose?: () => void;
  disabled?: boolean | false;
  backgroundColor?: string;
  closeIcon?: ReactElement | null;
  small?: boolean;
};

export const TrblPopupTitle = ({
  children,
  onClose,
  disabled,
  backgroundColor,
  closeIcon = <TrblCloseIcon />,
  small,
}: TrblPopupTitleProps) => {
  const { draggable } = React.useContext(PopupContext);

  return (
    <DialogTitle
      className={`${styles['popup-title']} ${draggable ? 'draggable-popup-title' : ''} ${small ? styles['small'] : ''}`}
      style={{ backgroundColor, cursor: draggable ? 'move' : 'default' }}>
      {children}
      {onClose ? (
        <IconButton
          disabled={disabled}
          className={styles['popup-close']}
          aria-label="close"
          onClick={(e: { stopPropagation: () => void }) => {
            e.stopPropagation();
            onClose();
          }}>
          {closeIcon ?? <TrblCloseIcon />}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

type TrblPopupContentProps = {
  children?: React.ReactNode;
  minimal?: boolean;
  style?: React.CSSProperties;
};

export const TrblPopupContent = (props: TrblPopupContentProps) => {
  return (
    <DialogContent
      className={`${props.minimal ? styles['popup-content-minimal'] : styles['popup-content']}  `}
      style={props.style}>
      {props.children}
    </DialogContent>
  );
};

type TrblPopupActionsProps = {
  children?: React.ReactNode;
  framed?: boolean;
};

export const TrblPopupActions = (props: TrblPopupActionsProps) => {
  return (
    <DialogActions className={`${styles['popup-actions']} ${props.framed ? styles['framed'] : ''}`}>
      {props.children}
    </DialogActions>
  );
};
