import { useEffect, useState } from 'react';

import { ActionType, useResultsContext } from '@/components/Results/context/ResultsContext';

import { TrblTooltip } from '@/components/Shared';
import { AcousticalParametersHeader } from '../AcousticalParametersHeader';
import { ModalAnalysisHeader } from '../ModalAnalysisHeader';

import styles from './styles.module.scss';

export const GridResultsHeader = () => {
  const { gridReceiverSubView, dispatch, availableComparisons } = useResultsContext();
  const [disableModalAnalysis, setDisableModalAnalysis] = useState(true);

  const selectSubHeader = (subHeader: 'grid' | 'modal') => {
    dispatch({
      type: ActionType.SET_GRID_RECEIVER_SUB_VIEW,
      gridReceiverSubView: subHeader,
    });
    if (subHeader === 'grid') {
      dispatch({
        type: ActionType.SET_IS_PLAYING_MODAL_ANALYSIS,
        isPlayingModalAnalysis: false,
      });
    }
  };

  useEffect(() => {
    const availableResultTypes = availableComparisons.flatMap(
      (comparison) => comparison.formState?.availableResultTypes
    );
    const allowModalAnalysis = availableResultTypes.some(
      (resultType) => resultType?.id === 'Hybrid' || resultType?.id === 'DG'
    );
    setDisableModalAnalysis(!allowModalAnalysis);
  }, [availableComparisons]);

  return (
    <div className={styles['tab-container']}>
      <div className={styles['tab-options']}>
        <button
          className={`${styles['tab-button']} ${gridReceiverSubView === 'grid' ? styles['selected'] : ''}`}
          onClick={() => selectSubHeader('grid')}>
          Acoustical parameters
        </button>
        <TrblTooltip
          title={disableModalAnalysis ? 'Forced modal analysis is only applicable for wave based simulations' : ''}>
          <button
            disabled={disableModalAnalysis}
            className={`${styles['tab-button']} ${gridReceiverSubView === 'modal' ? styles['selected'] : ''}  ${
              disableModalAnalysis ? styles['disabled'] : ''
            }`}
            onClick={() => selectSubHeader('modal')}>
            Forced modal analysis
          </button>
        </TrblTooltip>
      </div>
      <div className={styles['tab-content']}>
        {gridReceiverSubView === 'grid' ? <AcousticalParametersHeader /> : <ModalAnalysisHeader />}
      </div>
    </div>
  );
};
