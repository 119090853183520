import { useEffect, useState } from 'react';

import { ActionType, useResultsContext } from '@/components/Results/context/ResultsContext';

import { TrblTooltip } from '@/components/Shared';
import { TrblActionButton } from '@/components/Shared/Buttons/TrblActionButton';
import { TrblInfoIcon, TrblPauseIcon, TrblPlayIcon, TrblReferenceCurveIcon } from '@/components/Icons';
import { InterpolatedToggle } from '../GridResultsHeader/InterpolatedToggle';
import { FrequencyInputField } from './FrequencyInputField';
import { FrequencySliderSelector } from './FrequencySliderSelector';
import { useModalAnalysisKeyPressHandlers } from './useModalAnalysisKeyPressHandlers';

import { ParameterKeys } from '../../ParameterResults/constants';

import styles from './styles.module.scss';

const MIN_VALUE = 0;
const MAX_VALUE = 0;
const MIN_FREQUENCY_WITH_MATERIAL_DATA = 50;

export const ModalAnalysisHeader = () => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [maxValue, setMaxValue] = useState<number>(MAX_VALUE);
  const [minValue, setMinValue] = useState<number>(MIN_VALUE);

  const {
    dispatch,
    surfaceReceiversIsInterpolated,
    showFreqResponsePopup,
    modalFrequencySelected,
    isPlayingModalAnalysis,
    availableComparisons,
    selectedComparisonIndex,
    modalAnalysisFrequencyValues,
  } = useResultsContext();

  useModalAnalysisKeyPressHandlers(modalAnalysisFrequencyValues, minValue, maxValue);

  const selectedComparison = availableComparisons.length ? availableComparisons[selectedComparisonIndex] : null;
  const gridReceiverResultsToDisplay = selectedComparison?.formState?.simulationData?.selectedGridReceiverObjects || [];

  useEffect(() => {
    if (gridReceiverResultsToDisplay.length === 0 && isPlayingModalAnalysis) {
      stopPlaying();
    }
  }, [gridReceiverResultsToDisplay]);

  useEffect(() => {
    if (modalFrequencySelected === maxValue) {
      stopPlaying();
    }
  }, [modalFrequencySelected]);

  useEffect(() => {
    if (modalAnalysisFrequencyValues && gridReceiverResultsToDisplay.length > 0 && !isPlayingModalAnalysis) {
      const simulationKeys = Object.keys(modalAnalysisFrequencyValues);
      let minVal = Infinity;
      let maxVal = -Infinity;

      // Filter the simulation keys to only those that match the pointId prop in gridReceiverResultsToDisplay
      const filteredSimulationKeys = simulationKeys.filter((simulationKey) =>
        gridReceiverResultsToDisplay.some((result) => result.pointId === simulationKey)
      );

      // If only one simulation key is found, use its min and max values as defaults
      if (filteredSimulationKeys.length === 1) {
        const firstSimulationKey = filteredSimulationKeys[0];
        const frequencyValues = Object.values(modalAnalysisFrequencyValues[firstSimulationKey]);
        minVal = frequencyValues[0];
        maxVal = frequencyValues[frequencyValues.length - 1];
      } else {
        // Loop over the filtered keys and find the min and max frequencies
        filteredSimulationKeys.forEach((simulationKey) => {
          const frequencyValues = Object.values(modalAnalysisFrequencyValues[simulationKey]);
          const minFrequency = frequencyValues[0];
          const maxFrequency = frequencyValues[frequencyValues.length - 1];

          if (minFrequency < minVal) {
            minVal = minFrequency;
          }
          if (maxFrequency > maxVal) {
            maxVal = maxFrequency;
          }
        });
      }

      setMinValue(minVal);
      setMaxValue(maxVal);
    }
  }, [modalAnalysisFrequencyValues, gridReceiverResultsToDisplay]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_MODAL_ANALYSIS_PARAMETER,
      parameter: ParameterKeys.SPL,
    });
  }, []);

  const toggleIsInterpolated = (isInterpolated: boolean) => {
    dispatch({
      type: ActionType.SET_SURFACE_RECEIVERS_IS_INTERPOLATED,
      isInterpolated,
    });
  };

  const openFreqPopup = () => {
    dispatch({
      type: ActionType.SHOW_FREQ_RESPONSE_POPUP,
      showFreqResponsePopup: !showFreqResponsePopup,
    });
  };

  const setFreqValue = (freqValue: number) => {
    stopPlaying();
    if (freqValue >= minValue || freqValue <= maxValue) {
      dispatch({
        type: ActionType.SET_MODAL_FREQUENCY_SELECTED,
        modalFrequencySelected: freqValue,
      });
    }
  };

  const togglePlayButton = () => {
    if (!isPlayingModalAnalysis) {
      playThroughFrequencies();
    } else {
      stopPlaying();
    }
  };

  const playThroughFrequencies = () => {
    dispatch({
      type: ActionType.SET_IS_PLAYING_MODAL_ANALYSIS,
      isPlayingModalAnalysis: true,
    });
  };

  const stopPlaying = () => {
    dispatch({
      type: ActionType.SET_IS_PLAYING_MODAL_ANALYSIS,
      isPlayingModalAnalysis: false,
    });
  };

  return (
    <div className={styles['grid-results-header']}>
      <div className={styles['grid-results-header-child']}>
        <div className={styles['item']}>
          <TrblActionButton
            disabled={
              !modalAnalysisFrequencyValues ||
              gridReceiverResultsToDisplay.length === 0 ||
              modalFrequencySelected === maxValue
            }
            name="Play"
            round
            onClick={togglePlayButton}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}>
            {isPlayingModalAnalysis ? (
              <TrblPauseIcon height="14" width="14" fill={isHovering ? '#F5F5F5' : '#DADADA'} />
            ) : (
              <TrblPlayIcon height="14" width="14" fill={isHovering ? '#F5F5F5' : '#DADADA'} />
            )}
          </TrblActionButton>
        </div>

        <div className={styles['item']}>
          <FrequencyInputField
            disabled={!modalAnalysisFrequencyValues || gridReceiverResultsToDisplay.length === 0}
            freqValue={modalFrequencySelected}
            setFreqValue={setFreqValue}
            min={minValue}
            max={maxValue}
          />
          {modalFrequencySelected < MIN_FREQUENCY_WITH_MATERIAL_DATA && (
            <div className={styles['warning-icon']}>
              <TrblTooltip title="Results below 50 Hz are based on extrapolated material data.">
                <span>
                  <TrblInfoIcon />
                </span>
              </TrblTooltip>
            </div>
          )}
        </div>
        <div className={styles['item']}>
          <FrequencySliderSelector
            disabled={!modalAnalysisFrequencyValues || gridReceiverResultsToDisplay.length === 0}
            freqValue={modalFrequencySelected}
            setFreqValue={setFreqValue}
            min={minValue}
            max={maxValue}
          />
        </div>
        <div className={styles['item']} style={{ marginLeft: '5px' }}>
          <TrblActionButton
            disabled={!modalAnalysisFrequencyValues || gridReceiverResultsToDisplay.length === 0}
            name="Open Frequency response"
            onClick={() => openFreqPopup()}
            stayActive={showFreqResponsePopup}>
            <TrblReferenceCurveIcon fill={'#00F5BA'} />
          </TrblActionButton>
        </div>
      </div>
      <div className={styles['grid-results-header-child']}>
        <InterpolatedToggle isInterpolated={surfaceReceiversIsInterpolated} setIsInterpolated={toggleIsInterpolated} />
      </div>
    </div>
  );
};
