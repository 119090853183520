export const FREQ_LABELS = ['63', '125', '250', '500', '1k', '2k', '4k', '8k'];
export const TICK_VALS = [63, 125, 250, 500, 1000, 2000, 4000, 8000];
export const REF_X_VALS = [
  50, 63, 80, 100, 125, 160, 200, 250, 315, 400, 500, 630, 800, 1000, 1250, 1600, 2000, 2500, 3150, 4000, 5000, 6300,
  8000, 10000,
];
export const ABS_TITLE = 'Absorption Coefficient (Random Incidence)';
export const REF_TITLE = 'Reflection Coefficient (Normal Incidence)';
export const SURFACE_TITLE = 'Surface impedance';
export const SURFACE_TITLE_SPECIFIC = 'Surface impedance (Specific)';
export const ABS_PLOT_TITLE = 'ABSORPTION COEFFICIENT';
export const REF_PLOT_TITLE = 'REFLECTION COEFFICIENT';
export const SPECIFIC_IMPEDANCE_PLOT_TITLE = 'SPECIFIC SURFACE IMPEDANCE';
export const IMPEDANCE_PLOT_TITLE = 'SURFACE IMPEDANCE Pa*s/m^3';
export const IMPEDANCE_PLOT_TITLE_SIMPLE = 'SURFACE IMPEDANCE';
// set min as -0.006 so that dotted zero line is visible
export const ABS_RANGE = [-0.006, 1.1];
export const REF_RANGE = [-1, 1.1];

export const MIN_VALUE = 0.01;
export const MAX_VALUE = 0.95;

export const TOGGLE_MATERIAL_REFLECTION_PLOT = ['Reflection Coefficient (Normal Incidence)', 'Surface impedance'];
