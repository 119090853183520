import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

type CardsHeaderProps = {
  background?: string;
  height?: string;
  children: ReactNode;
};

export const CardsHeader: FC<CardsHeaderProps> = ({ background, height, children }) => {
  return (
    <div className={styles['cards-header']} style={{ background, height }}>
      {children}
    </div>
  );
};
