import { FC, useEffect, useState } from 'react';

import { Box, ListSubheader } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { TertiaryButton } from '@/components/Shared/Buttons';
import { Text } from '@/components/Shared/Text';
import { TextField } from '@/components/Shared/TextField';
import { TrblAddIconCircle } from '@/components/Icons';

import { SummedSourceOption } from '../../../types';
import { BaseType } from '@/types';

import classes from './styles.module.scss';

type SumSourcesItemProps = {
  availableSources: BaseType[];
  availableSummedSources: SummedSourceOption[];
  sourceSummingSelectionEnabled: boolean;
  selectedSourcesForSumming: string[];
  sourceSummingDisabled: boolean;
  resultType: string;
  onEnableSourceSummingSelection: () => void;
  onDisableSourceSummingSelection: () => void;
  onStartSummingProcess: (name: string, sources: string[]) => void;
};

export const SumMultipleSourcesMenuItem: FC<SumSourcesItemProps> = ({
  availableSources,
  availableSummedSources,
  sourceSummingDisabled,
  sourceSummingSelectionEnabled,
  selectedSourcesForSumming,
  resultType,
  onEnableSourceSummingSelection,
  onDisableSourceSummingSelection,
  onStartSummingProcess,
}) => {
  const isValidLength = selectedSourcesForSumming.length >= 2;
  const alreadyExists = availableSummedSources.some(
    (source) =>
      (source.completedTasks?.some((t) => t.resultType === resultType) ||
        source.inProgressTasks.some((t) => t.resultType === resultType)) &&
      selectedSourcesForSumming.length === source.sourceIds.length &&
      selectedSourcesForSumming.every((selectedSourceId) => source.sourceIds.includes(selectedSourceId))
  );
  const isValid = isValidLength && !alreadyExists;

  const getStartButtonTooltipTitle = () => {
    if (!isValidLength) {
      return 'At least 2 sources need to be selected';
    } else if (alreadyExists) {
      return 'This sum result is already available or being processed';
    }
    return 'Start sum process';
  };

  const getHeaderButtonTooltipTitle = () => {
    if (sourceSummingDisabled) {
      return 'Summing of sources is not available for simulations run before 6th of February 2025. Please re-run the simulation to enable this feature.';
    } else if (!sourceSummingSelectionEnabled) {
      return null;
    }
    return 'Close';
  };

  const defaultTitle = availableSources
    .filter((s) => selectedSourcesForSumming.includes(s.id))
    .map((opt) => opt.name)
    .join(', ');

  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    if (title && !sourceSummingSelectionEnabled) {
      setTitle('');
    }
  }, [sourceSummingSelectionEnabled]);

  const handleStartSummingProcess = () => {
    if (isValid) {
      onStartSummingProcess(title || defaultTitle, selectedSourcesForSumming);
    }
  };

  return (
    <ListSubheader style={{ padding: 0 }}>
      <Box
        className={`${classes.sum_sources_menu_item} ${
          sourceSummingSelectionEnabled ? classes.selection_enabled : ''
        } ${sourceSummingDisabled ? classes.disabled : ''}`}
        onClick={!sourceSummingSelectionEnabled && !sourceSummingDisabled ? onEnableSourceSummingSelection : undefined}
        component="div">
        <TrblTooltip enterDelay={500} title={getHeaderButtonTooltipTitle()}>
          <button
            style={{ display: 'flex', alignItems: 'center', gap: '7px' }}
            onClick={onDisableSourceSummingSelection}>
            <TrblAddIconCircle width="16" height="16" fill="#F5F5F5" />
            {!sourceSummingSelectionEnabled && (
              <Text type="bold-11px" color="#F5F5F5">
                Sum multiple sources
              </Text>
            )}
          </button>
        </TrblTooltip>
        {sourceSummingSelectionEnabled && (
          <>
            <TextField
              value={title}
              onChange={setTitle}
              placeholder={selectedSourcesForSumming.length ? defaultTitle : 'Summed source name'}
              underlined
              className={classes['summed-source-text-field']}
              autoFocus
            />
            <TrblTooltip title={getStartButtonTooltipTitle()}>
              <span>
                <TertiaryButton
                  style={{ padding: '0px 6px', minHeight: '22px', height: '22px' }}
                  label="Sum"
                  disabled={!isValid}
                  onClick={handleStartSummingProcess}
                />
              </span>
            </TrblTooltip>
          </>
        )}
      </Box>
    </ListSubheader>
  );
};
