import React from 'react';
import Plot from 'react-plotly.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import { DataTitle } from 'plotly.js';

import { useImpulseResponsePlotData } from './hooks';

import { LAYOUT_CONFIG } from './constants';

import { IRType } from '../SPLPerBandPlot/types';
import { ReceiverResults } from '@/types';

type ReflectionsPlotProps = {
  selectedIrType: IRType;
  receiverIrResult: ReceiverResults | null;
  irNormalization: number | null;
  selectedReflectionTimeOfArrival: number | null;
  lastReflectionTimeOfArrival: number | null;
};

export const ImpulseResponsePlot: React.FC<ReflectionsPlotProps> = ({
  selectedIrType,
  receiverIrResult,
  irNormalization,
  selectedReflectionTimeOfArrival,
  lastReflectionTimeOfArrival,
}) => {
  const plotData = useImpulseResponsePlotData({
    selectedIrType,
    receiverIrResult,
    irNormalization,
  });

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Plot
          data={plotData}
          layout={{
            ...LAYOUT_CONFIG,
            xaxis: {
              ...LAYOUT_CONFIG.xaxis,
              // Set initial range from 0 to lastReflectionTimeOfArrival + margin
              range: lastReflectionTimeOfArrival
                ? [0, lastReflectionTimeOfArrival + lastReflectionTimeOfArrival * 0.1]
                : undefined,
              autorange: !lastReflectionTimeOfArrival,
            },
            yaxis: {
              ...LAYOUT_CONFIG.yaxis,
              title: {
                ...(LAYOUT_CONFIG.yaxis?.title as Partial<DataTitle>),
                text: selectedIrType === 'db' ? 'dB' : 'PRESSURE',
              },
            },
            // Add vertical line to indicate selected reflection time of arrival
            shapes: selectedReflectionTimeOfArrival
              ? [
                  {
                    type: 'line',
                    x0: selectedReflectionTimeOfArrival,
                    y0: 0,
                    x1: selectedReflectionTimeOfArrival,
                    y1: 1,
                    xref: 'x',
                    yref: 'paper',
                    line: {
                      color: '#B8C7FF',
                      width: 2,
                    },
                  },
                ]
              : undefined,
          }}
          style={{ width, height }}
          config={{ displaylogo: false, responsive: true, displayModeBar: false }}
          useResizeHandler={true}
        />
      )}
    </AutoSizer>
  );
};
