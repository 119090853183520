import { GpuAllocationStrategy } from '@/hooks/SolveTask/useCalculateSimulationRunEstimatedTime';

export const MESH_ERROR_TEXT = `Failed to prepare geometry for the wave solver. Select 'Geometrical acoustics solver only' in
Advanced settings to run a simulation, or contact support.`;

export const OVERWRITE_TEXT = `
Are you sure you want to run this simulation and overwrite previous results?
`;

export const TOKEN_EXCEEDS_TEXT = `This simulation exceeds the total remaining tokens for your subscription. Try lowering the transition frequency or reducing the number of sources. Alternatively, you can upgrade your subscription or buy more tokens.`;

export const TOKEN_EXCEEDS_TRIAL_TEXT = `This simulation exceeds the cloud computing limit for the free trial. Try lowering the transition frequency, reducing the number of sources or simulating another model.`;

export const MAX_TOKENS_FOR_TRIAL = 2;

// 1. GPUCountOptimized && estimatedGpusAllocated > 1 = scale up
// 2. UtilizationOptimized && estimatedGpusAllocated > 1 = speed up
export const getGpuAllocationStrategyText = (gpuAllocationStrategy: GpuAllocationStrategy | null) => {
  return gpuAllocationStrategy === 'GPUCountOptimized'
    ? `Scale up - This simulation can be run at the selected transition frequency due to the premium multi GPU feature.`
    : `Speed up - This simulation will be run faster by using the premium multi GPU feature.`;
};

export const SUBSCRIPTION_TYPES_USING_TOKENS = ['Trial', 'Flexible'];
