import { FC } from 'react';

import { Box } from '@mui/material';

import { SpaceExtractionTask } from '@/components/MultiSpaceImport/types';
import { Text } from '../../Shared/Text';
import { RecentSimulationStatus } from './RecentSimulationStatus';
import { RecentUploadStatus } from './RecentUploadStatus ';

import { SimulationRunStatusDto } from '@/types';

type RecentTasksListProps = {
  recentTasks: (SimulationRunStatusDto | SpaceExtractionTask)[];
  queuedTasks: { simulationRunId: string; estimatedQueueTimeSeconds: number }[];
  onGeometryImport: (id: string, filename: string) => void;
};

export const RecentTasksList: FC<RecentTasksListProps> = ({ recentTasks, queuedTasks, onGeometryImport }) => (
  <>
    {recentTasks.length > 0 ? (
      recentTasks.map((recentTask) =>
        'sources' in recentTask ? (
          <RecentSimulationStatus
            key={recentTask.id}
            simulationRunStatus={recentTask}
            queueTime={
              queuedTasks.find((task) => task.simulationRunId === recentTask.id)?.estimatedQueueTimeSeconds ?? null
            }
          />
        ) : (
          <RecentUploadStatus key={recentTask.id} spaceExtractionTask={recentTask} onClick={onGeometryImport} />
        )
      )
    ) : (
      <Box component="div" padding={'16px 20px 20px 20px'}>
        <Text type="semibold-14px">There are no tasks yet</Text>
      </Box>
    )}
  </>
);
