import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblProjectIcon: FC<IconProps> = ({ height = '14', width = '14', fill = '#DADADA' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.824 3.00001H15C15.5523 3.00001 16 3.44773 16 4.00001V14C16 14.5523 15.5523 15 15 15H1C0.447715 15 0 14.5523 0 14V2.00001C0 1.44773 0.447715 1.00001 1 1.00001H6C6.37835 0.998325 6.72499 1.21116 6.89467 1.54934L7.526 2.81601C7.58251 2.92879 7.69785 3.00001 7.824 3.00001ZM4.78072 5C4.77915 5.50285 4.76863 5.99296 4.72075 6.4234C4.66839 6.89418 4.5692 7.31838 4.3686 7.61186C4.13661 7.95086 3.58776 8.2456 2 8.2686V9.91788C3.58898 9.91788 4.87314 9.49882 5.57521 8.47289C6.18271 7.58516 6.3651 6.42116 6.36971 5H4.78072ZM6.36971 13.1715C6.37128 12.6686 6.3818 12.1935 6.42968 11.7631C6.48204 11.2923 6.58123 10.8681 6.78183 10.5746C7.01382 10.2356 7.56267 9.94086 9.15043 9.91786L9.15043 8.26858C7.56145 8.26858 6.27729 8.67268 5.57521 9.69861C4.96772 10.5863 4.78533 11.7503 4.78072 13.1715L6.36971 13.1715Z"
      fill={fill}
    />
  </svg>
);
