import { FC, useRef } from 'react';

import styles from './styles.module.scss';

export type TextFieldProps = {
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>, value?: number) => void;
  label?: string;
  placeholder?: string;
  title?: string;
  value: string;
  maxLength?: number;
  blurOnEnter?: boolean;
  className?: string;
  underlined?: boolean;
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  onInteractionStart?: () => void;
  onInteractionEnd?: () => void;
};

export const TextField: FC<TextFieldProps> = ({
  placeholder,
  value,
  label,
  title,
  onChange,
  onBlur,
  onKeyDown,
  autoFocus,
  maxLength,
  blurOnEnter,
  className,
  underlined,
  disabled = false,
  endAdornment,
  onInteractionStart,
  onInteractionEnd,
}) => {
  const internalInputElement = useRef<HTMLInputElement>(null);
  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    event.stopPropagation();
    if (blurOnEnter && event.key === 'Enter') {
      internalInputElement.current?.blur();
      internalInputElement.current?.focus();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };
  return (
    <div
      className={styles['input-div']}
      role="button"
      tabIndex={0}
      onMouseEnter={onInteractionStart}
      onMouseLeave={onInteractionEnd}
      onFocus={onInteractionStart}
      onBlur={onInteractionEnd}>
      {label && <span className={styles['label-top']}>{label}</span>}
      <div className={styles['input-container']}>
        <input
          ref={internalInputElement}
          autoFocus={autoFocus}
          type="text"
          className={`${styles['text-field']} ${label ? styles['has-label-top'] : ''} ${
            underlined ? styles['underlined'] : ''
          } ${endAdornment ? styles['has-adornment'] : ''} ${className ?? ''}`}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          maxLength={maxLength}
          title={title}
          onChange={onChange ? (event) => onChange(event.target.value) : undefined}
          onBlur={onBlur ? (event) => onBlur(event.target.value) : undefined}
          onKeyDown={handleKeyPress}
        />
        {endAdornment && <div className={styles['end-adornment']}>{endAdornment}</div>}
      </div>
    </div>
  );
};
