import { Layout } from 'plotly.js';

export const LAYOUT_CONFIG: Partial<Layout> = {
  autosize: true,
  uirevision: 'true', // Needed so that the zoom persists after a re-render (changing bar selection)
  xaxis: {
    gridcolor: '#555555',
    hoverformat: '.4f',
    title: {
      standoff: 20,
      text: 'TIME [s]',
      font: {
        size: 10,
        color: '#ADADAD',
      },
    },
  },
  yaxis: {
    gridcolor: '#555555',
    hoverformat: '.6f',
    autorange: true,
    title: {
      font: {
        size: 10,
        color: '#ADADAD',
      },
      standoff: 10,
    },
  },
  paper_bgcolor: '#272727',
  plot_bgcolor: 'transparent',
  font: {
    family: 'Inter, Helvetica, Arial, sans-serif',
    color: '#f5f5f5',
    size: 10,
  },
  margin: {
    l: 40,
    r: 5,
    b: 50,
    t: 15,
    pad: 5,
  },
};
