import { ChangeEventHandler, FC } from 'react';

import { useResultComparisonContext } from './context/ResultComparisonContext';

import { SelectChangeEvent } from '@mui/material';

import { SimulationFormSelect } from '@/components';
import { SelectedSimulationField } from '../SelectedSimulationField';
import { SimulationFormMultiSelect } from '../SimulationFormMultiSelect';
import { SimulationFormSourceSelect } from '../SimulationFormSourceSelect';
import { SimulationFormTextField } from '../SimulationFormTextField.tsx';

import { ActionType, ResultComparisonLabels } from './constants';

import { ResultType } from '@/types';

import classes from './styles.module.scss';

interface SimulationFormGridReceivers {
  color: string;
  spaceName: string;
  modelName: string;
  isModalAnalysisView: boolean;
  onFormChange: () => void;
  onStartSourceSumming: (name: string, sources: string[]) => void;
  onRestartSourceSumming: (summedSourceId: string, sources: string[]) => void;
  onCancelSummingProcess: (summedSourceId: string, taskId: string) => void;
  onDeleteSummedSource: (summedSourceId: string, taskId: string[]) => void;
  onUpdateSourceSummingLabel: (summedSourceId: string, label: string) => void;
}

export const SimulationFormGridReceivers: FC<SimulationFormGridReceivers> = ({
  color,
  spaceName,
  modelName,
  isModalAnalysisView,
  onFormChange,
  onStartSourceSumming,
  onRestartSourceSumming,
  onCancelSummingProcess,
  onDeleteSummedSource,
  onUpdateSourceSummingLabel,
}) => {
  const {
    dispatch,
    state: {
      availableResultTypes,
      simulationId,
      availableSources,
      availableSummedSources,
      selectedSourcesForSumming,
      sourceSummingSelectionEnabled,
      sourcePointIds,
      availableGridReceivers,
      gridReceiverPointIds,
      resultType,
      selectedSimulation,
      title,
    },
  } = useResultComparisonContext();

  const handleSelectFormControlChange = (event: SelectChangeEvent<string>) => {
    event.stopPropagation();

    const { value, name } = event?.target ?? {};
    if (value) {
      onFormChange();
      switch (name) {
        case ResultComparisonLabels.SOURCE:
          dispatch({
            type: ActionType.SELECT_GRID_SOURCE,
            payload: [value],
          });
          return;
        case ResultComparisonLabels.RESULT_TYPE:
          dispatch({
            type: ActionType.SELECT_RESULT_TYPE,
            resultType: value,
            isUserSelected: true,
          });
          return;
      }
    }
  };

  const handleSourceChanged = (value: string[]) => {
    onFormChange();
    dispatch({
      type: ActionType.SELECT_GRID_SOURCE,
      payload: value,
    });
  };

  const handleReceiversChanged = (value: string[]) => {
    onFormChange();

    dispatch({
      type: ActionType.SELECT_GRID_RECEIVERS,
      payload: value,
    });
  };

  const handleTextFieldFormControlChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value, name } = event?.target ?? {};
    onFormChange();
    switch (name) {
      case ResultComparisonLabels.LABEL:
        dispatch({ type: ActionType.UPDATE_LABEL, payload: value });
        return;
    }
  };

  const handleSetSourceSummingSelectionEnabled = (value: boolean) => {
    onFormChange();
    dispatch({ type: ActionType.SET_SOURCE_SUMMING_SELECTION_ENABLED, payload: value });
  };

  const handleSelectedSourcesForSummingChanged = (sources: string[]) => {
    onFormChange();
    dispatch({ type: ActionType.SET_SELECTED_SOURCES_FOR_SUMMING, payload: sources });
  };

  const noSurfaceLayersAvailable = availableGridReceivers === undefined || availableGridReceivers.length === 0;

  return (
    <form>
      <SelectedSimulationField spaceName={spaceName} modelName={modelName} selectedSimulation={selectedSimulation} />
      <SimulationFormTextField
        label={ResultComparisonLabels.LABEL}
        value={title}
        color={color}
        formControlChangeEvent={handleTextFieldFormControlChange}
      />
      <div className={classes.comparison_form_fields}>
        <SimulationFormSourceSelect
          disabled={!simulationId || noSurfaceLayersAvailable}
          values={!noSurfaceLayersAvailable && sourcePointIds?.length ? sourcePointIds : []}
          availableSources={availableSources ?? []}
          availableSummedSources={availableSummedSources ?? []}
          resultType={resultType}
          // Disable source summing if the last simulation run was created before the source summing feature was added
          sourceSummingDisabled={
            new Date(selectedSimulation?.lastSimulationRun?.createdAt ?? 0) < new Date('2025-02-06')
          }
          sourceSummingSelectionEnabled={sourceSummingSelectionEnabled}
          selectedSourcesForSumming={selectedSourcesForSumming}
          onChange={handleSourceChanged}
          onChangeSelectedSourceForSumming={handleSelectedSourcesForSummingChanged}
          setSourceSummingSelectionEnabled={handleSetSourceSummingSelectionEnabled}
          onStartSummingProcess={onStartSourceSumming}
          onRestartSummingProcess={onRestartSourceSumming}
          onCancelSummingProcess={onCancelSummingProcess}
          onDeleteSummedSource={onDeleteSummedSource}
          onUpdateSourceSummingLabel={onUpdateSourceSummingLabel}
        />
        <SimulationFormMultiSelect
          disabled={!sourcePointIds?.length || noSurfaceLayersAvailable}
          values={gridReceiverPointIds || []}
          label={ResultComparisonLabels.SURFACE_RECEIVERS}
          options={availableGridReceivers || []}
          onChange={handleReceiversChanged}
          placeholder={
            noSurfaceLayersAvailable
              ? `No ${ResultComparisonLabels.SURFACE_RECEIVERS.toLowerCase()} available`
              : `Select ${ResultComparisonLabels.SURFACE_RECEIVERS.toLowerCase()}`
          }
        />

        <SimulationFormSelect
          disabled={true}
          value={isModalAnalysisView ? ResultType.DG : resultType}
          label={ResultComparisonLabels.RESULT_TYPE}
          options={availableResultTypes || []}
          formControlChangeEvent={handleSelectFormControlChange}
          placeholder={'Select ' + ResultComparisonLabels.RESULT_TYPE}
        />
      </div>
    </form>
  );
};
