import { FC, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionType, useResultsContext } from '@/components/Results/context/ResultsContext';

import { Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { EMPTY_GUID } from '@/components/Shared/constants';
import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { Text } from '@/components/Shared/Text';
import { TrblSelect } from '@/components/Shared/TrblSelect';
import { TrblCloseEyeIcon, TrblEyeIcon } from '@/components/Icons';
import {
  FREQUENCY_OPTIONS,
  FREQUENCY_OPTIONS_FOR_SPL,
  NC_CURVE_OPTIONS,
  TARGET_TYPE_OPTIONS,
} from '@/components/Results/components/ResponsePlot/constants';
import { InterpolatedToggle } from '../GridResultsHeader/InterpolatedToggle';

import {
  ParameterKeys,
  SPEECH_LABEL,
  STI_DISABLED_TEXT,
  STI_DISABLED_TEXT_OMNI,
} from '../../ParameterResults/constants';

import { getParameterDecimals } from '@/utils/trebleFunctions';

import styles from './styles.module.scss';

export const AcousticalParametersHeader: FC = () => {
  const targetInputRef = useRef<HTMLInputElement>(null);
  const targetMaxInputRef = useRef<HTMLInputElement>(null);

  const {
    dispatch,
    surfaceReceiversSelectedFrequency,
    surfaceReceiversSelectedParameter,
    surfaceReceiversSelectedNcCurve,
    surfaceReceiversIsInterpolated,
    selectedComparisonIndex,
    availableComparisons,
    targetPercentage,
  } = useResultsContext();

  const selectedSim = availableComparisons.length ? availableComparisons[selectedComparisonIndex] : null;
  const isSummedSourceSelected = !!selectedSim?.formState?.simulationData?.selectedSourceSummingObject;
  const sourcePointId =
    selectedSim?.formState?.availableSources?.find(
      (source) => source.id === selectedSim?.formState?.simulationData?.selectedSourceObject?.sourcePointId
    )?.sourceDefinitionId ?? '';
  const selectedSimGridReceiverResults = selectedSim?.formState?.simulationData?.gridReceiverParameterValues;
  const isWaveBased = selectedSim?.formState?.resultType === 'DG';

  const [availableResultParameters, setAvailableResultParameters] = useState<ParameterKeys[]>([]);
  const [availableFrequencies, setAvailableFrequencies] = useState<{ [id: string]: string[] }>({});
  const [targetValue, setTargetValue] = useState<number | undefined>(undefined);
  const [targetValueMax, setTargetValueMax] = useState<number | undefined>(undefined);
  const [targetValueSelected, setTargetValueSelected] = useState(true);
  const [targetTypeSelected, setTargetTypeSelected] = useState('');
  const [inputDecimals, setInputDecimals] = useState(2);
  const [showTooltip, setShowTooltip] = useState(true);
  const [selectedSTIParameter, setSelectedSTIParameter] = useState<ParameterKeys.STI | ParameterKeys.STI_USING_SPL>(
    ParameterKeys.STI
  );

  useEffect(() => {
    const isOmniSource = sourcePointId === EMPTY_GUID;

    if (isSummedSourceSelected || !isOmniSource) {
      setSelectedSTIParameter(ParameterKeys.STI_USING_SPL);
    }
  }, [sourcePointId, isSummedSourceSelected]);

  // lil logic to switch between STI and STI_USING_SPL data when changing the
  // selected parameter because they bot live under the STI option in the UI
  useEffect(() => {
    if (
      surfaceReceiversSelectedParameter === ParameterKeys.STI &&
      selectedSTIParameter === ParameterKeys.STI_USING_SPL
    ) {
      clickParameter(ParameterKeys.STI_USING_SPL);
    } else if (
      surfaceReceiversSelectedParameter === ParameterKeys.STI_USING_SPL &&
      selectedSTIParameter === ParameterKeys.STI
    ) {
      clickParameter(ParameterKeys.STI);
    }
  }, [surfaceReceiversSelectedParameter, selectedSTIParameter]);

  useEffect(() => {
    if (surfaceReceiversSelectedParameter === ParameterKeys.STI && isWaveBased) {
      toast.error(
        'Hybrid or geometrical acoustic simulations are required to get STI results as the parameter requires simulation data up to 8000 Hz.'
      );
    }
  }, [surfaceReceiversSelectedParameter]);

  // Executed when changing the selected simulation
  useEffect(() => {
    const selectedSimGridReceiverResultsKeys = selectedSimGridReceiverResults
      ? Object.keys(selectedSimGridReceiverResults)
      : [];
    if (selectedSimGridReceiverResults && selectedSimGridReceiverResultsKeys.length) {
      // We just take the first source as we assume the result parameters are the same
      const firstSourceResult = selectedSimGridReceiverResults[selectedSimGridReceiverResultsKeys[0]];
      const firstSourceResultKeys = firstSourceResult && Object.keys(firstSourceResult);
      if (firstSourceResult && firstSourceResultKeys.length) {
        // We just take the first grid receiver as we assume the result parameters are the same
        const firstGridReceiverResult = firstSourceResult[firstSourceResultKeys[0]];
        let allParams: ParameterKeys[] = Object.keys(firstGridReceiverResult) as ParameterKeys[];

        // STI_USING_SPL should not be shown in the first dropdown
        allParams = allParams.filter((x) => x !== ParameterKeys.STI_USING_SPL);

        setAvailableResultParameters(allParams);

        if (allParams.length) {
          // Iterate through all the parameters and obtain the available frequencies
          const availableFrequencies: { [id: string]: string[] } = {};
          allParams.forEach((param) => {
            const frequencies = Object.keys(firstGridReceiverResult[param]);
            availableFrequencies[param] = frequencies;
          });
          setAvailableFrequencies(availableFrequencies);
        }
      }
    }
  }, [selectedSimGridReceiverResults, isSummedSourceSelected]);

  // Change the selected parameter based on the available ones
  useEffect(() => {
    if (availableResultParameters.length) {
      let defaultParameter = surfaceReceiversSelectedParameter || ParameterKeys.T20;

      // If the default parameter is not in the available parameters, we should default to the first one
      // We also need to check for STI_USING_SPL because it's not in the available parameters
      if (![...availableResultParameters, ParameterKeys.STI_USING_SPL].includes(defaultParameter)) {
        defaultParameter = availableResultParameters[0];
      }

      dispatch({
        type: ActionType.SET_SURFACE_RECEIVERS_PARAMETER,
        parameter: defaultParameter,
      });
    }
  }, [availableResultParameters]);

  // Change the selected frequency based on the available ones
  useEffect(() => {
    if (surfaceReceiversSelectedParameter !== null && availableFrequencies[surfaceReceiversSelectedParameter]) {
      const frequencies = availableFrequencies[surfaceReceiversSelectedParameter];
      let defaultFrequency = surfaceReceiversSelectedFrequency || '250';
      if (!frequencies.includes(defaultFrequency)) {
        defaultFrequency = frequencies[0];
      }

      dispatch({
        type: ActionType.SET_SURFACE_RECEIVERS_FREQUENCY,
        frequency: defaultFrequency,
      });
    }
  }, [availableFrequencies, surfaceReceiversSelectedParameter]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_SURFACE_RECEIVERS_NC_CURVE,
      payload: NC_CURVE_OPTIONS[4],
    });

    return () => {
      dispatch({
        type: ActionType.SET_TARGET_VALUE,
        payload: undefined,
      });
      dispatch({
        type: ActionType.SET_TARGET_VALUE_MAX,
        payload: undefined,
      });
      dispatch({
        type: ActionType.SET_TARGET_PERCENTAGE,
        payload: 0,
      });
    };
  }, []);

  const clickFrequency = (newFrequency: string) => {
    dispatch({
      type: ActionType.SET_SURFACE_RECEIVERS_FREQUENCY,
      frequency: newFrequency,
    });
  };
  const clickParameter = (newParameter: string) => {
    dispatch({
      type: ActionType.SET_SURFACE_RECEIVERS_PARAMETER,
      parameter: newParameter as ParameterKeys,
    });
    // set the decimals for target value input to parameter decimals +1
    setInputDecimals(getParameterDecimals(newParameter) + 1);
    // hide the target value to show the normal heatmap instead
    if (targetValueSelected) setTargetValueSelected(false);
  };
  const clickNcCurve = (newNcCurve: string) => {
    dispatch({
      type: ActionType.SET_SURFACE_RECEIVERS_NC_CURVE,
      payload: newNcCurve,
    });
  };

  const toggleIsInterpolated = (isInterpolated: boolean) => {
    dispatch({
      type: ActionType.SET_SURFACE_RECEIVERS_IS_INTERPOLATED,
      isInterpolated,
    });
  };

  useEffect(() => {
    if (targetValueSelected && targetValue !== undefined) {
      dispatch({
        type: ActionType.SET_TARGET_VALUE,
        payload: targetValue,
      });
      if (targetValueMax && targetValueMax < targetValue) {
        setTargetValueMax(undefined);
        dispatch({
          type: ActionType.SET_TARGET_VALUE_MAX,
          payload: undefined,
        });
      }
    } else {
      dispatch({
        type: ActionType.SET_TARGET_VALUE,
        payload: undefined,
      });
      if (targetValue == undefined) {
        dispatch({
          type: ActionType.SET_TARGET_PERCENTAGE,
          payload: 0,
        });
      }
    }
  }, [targetValueSelected, targetValue]);

  useEffect(() => {
    if (targetValueSelected && targetValue !== undefined) {
      if (targetValueMax !== undefined && targetTypeSelected == 'Range') {
        let newTargetValueMax = targetValueMax;
        if (targetValueMax < targetValue) {
          return;
        } else if (targetValueMax === targetValue) {
          newTargetValueMax = targetValue + 0.000001;
        }
        dispatch({
          type: ActionType.SET_TARGET_VALUE_MAX,
          payload: newTargetValueMax,
        });
      } else {
        dispatch({
          type: ActionType.SET_TARGET_VALUE_MAX,
          payload: undefined,
        });
      }
    }
  }, [targetValueMax, targetTypeSelected]);

  const onTargetValueMaxBlur = (value: number | undefined) => {
    if (value !== undefined && targetValue !== undefined) {
      if (value < targetValue) setTargetValueMax(targetValue);
    }
  };

  const toggleTargetType = (value: string) => {
    setTargetValueSelected(true);
    setTargetTypeSelected(value);
    dispatch({
      type: ActionType.SET_TARGET_TYPE,
      payload: value,
    });

    // hack to focus on the targetValue inputs if no value has been inputted
    if (targetValue == undefined) setTimeout(() => targetInputRef?.current?.focus(), 100);
    else if (targetValueMax == undefined) setTimeout(() => targetMaxInputRef?.current?.focus(), 100);
  };

  const toggleShowTargetValue = () => {
    setTargetValueSelected(!targetValueSelected);
  };

  return (
    <div className={styles['grid-results-header']}>
      <div className={styles['grid-results-header-child']}>
        <div className={styles['item']}>
          <TrblSelect
            menuItems={availableResultParameters.map((option) => ({
              id: option,
              name: option.toUpperCase(),
            }))}
            value={
              surfaceReceiversSelectedParameter === ParameterKeys.STI_USING_SPL
                ? ParameterKeys.STI
                : surfaceReceiversSelectedParameter || ''
            }
            setValue={clickParameter}
            className={styles['custom-select']}
          />
        </div>

        {(surfaceReceiversSelectedParameter === ParameterKeys.STI ||
          surfaceReceiversSelectedParameter === ParameterKeys.STI_USING_SPL) && (
          <div className={styles['item']}>
            <TrblSelect
              menuItems={[
                {
                  id: ParameterKeys.STI,
                  name: SPEECH_LABEL,
                  // Disable Speech source if there are summed sources in the comparison panel
                  // or if any of the sources are NOT omni source
                  disabled: sourcePointId !== EMPTY_GUID || isSummedSourceSelected || isWaveBased,
                  tooltipText: isSummedSourceSelected
                    ? STI_DISABLED_TEXT
                    : sourcePointId !== EMPTY_GUID
                    ? STI_DISABLED_TEXT_OMNI
                    : '',
                },
                {
                  id: ParameterKeys.STI_USING_SPL,
                  name: 'Source SPL level',
                  disabled: isWaveBased,
                },
              ]}
              value={selectedSTIParameter}
              setValue={(value) => setSelectedSTIParameter(value as ParameterKeys.STI | ParameterKeys.STI_USING_SPL)}
              className={`${styles['custom-select']} ${styles['extra-width']}`}
            />
          </div>
        )}

        <div className={styles['item']}>
          {surfaceReceiversSelectedParameter !== null &&
            surfaceReceiversSelectedParameter !== ParameterKeys.STI &&
            surfaceReceiversSelectedParameter !== ParameterKeys.STI_USING_SPL && (
              <TrblSelect
                menuItems={(surfaceReceiversSelectedParameter === ParameterKeys.SPL
                  ? FREQUENCY_OPTIONS_FOR_SPL
                  : FREQUENCY_OPTIONS
                )
                  .filter((x) => availableFrequencies[surfaceReceiversSelectedParameter]?.includes(x.value))
                  .map((option) => ({
                    id: option.value,
                    name: option.label,
                  }))}
                value={surfaceReceiversSelectedFrequency ?? ''}
                setValue={clickFrequency}
                className={styles['custom-select']}
              />
            )}
          {surfaceReceiversSelectedParameter !== null &&
            (surfaceReceiversSelectedParameter === ParameterKeys.STI ||
              surfaceReceiversSelectedParameter === ParameterKeys.STI_USING_SPL) && (
              <TrblSelect
                menuItems={NC_CURVE_OPTIONS.map((x) => ({ id: x, name: x }))}
                value={surfaceReceiversSelectedNcCurve ?? ''}
                setValue={clickNcCurve}
                className={styles['custom-select']}
              />
            )}
        </div>

        <div className={styles['item']}>
          <Text
            type="regular-12px"
            style={{
              lineHeight: 2.1,
              marginLeft: '4px',
              marginTop: '1px',
              color: '#ADADAD',
              fontWeight: '500',
              marginRight: '12px',
            }}>
            Target:
          </Text>
          <TrblSelect
            menuItems={TARGET_TYPE_OPTIONS}
            placeholder="Value"
            value={targetTypeSelected}
            setValue={toggleTargetType}
            className={styles['custom-select']}
            style={{
              width: targetTypeSelected == '' ? '100px' : targetTypeSelected == 'Range' ? '236px' : '184px',
              opacity: !targetValueSelected ? 0.5 : 1,
            }}
          />
          {targetTypeSelected !== '' && (
            <TrblTooltip title={targetValueSelected ? 'Hide target area' : 'Show target area'} hidden={!showTooltip}>
              <button
                onClick={toggleShowTargetValue}
                onMouseDown={() => setShowTooltip(false)}
                onMouseLeave={() => setTimeout(() => setShowTooltip(true), 100)}
                className={styles['show-toggle']}>
                {targetValueSelected ? <TrblEyeIcon fill="#adadad" /> : <TrblCloseEyeIcon fill="#dadada" />}
              </button>
            </TrblTooltip>
          )}

          {targetTypeSelected !== '' && (
            <Stack
              onClick={() => setTargetValueSelected(true)}
              className={styles['target-value-box']}
              left={targetTypeSelected == 'Above' ? '104px' : '88px'}>
              <TrblNumberInput
                ref={targetInputRef}
                style={{
                  width: 52,
                  height: 24,
                  fontSize: '12px',
                  opacity: !targetValueSelected ? 0.5 : 1,
                }}
                value={targetValue}
                onChange={setTargetValue}
                decimals={inputDecimals}
                step={1 / Math.pow(10, inputDecimals - 1)}
                allowEmpty
                selectOnClick
                underlined
                alignment={targetTypeSelected == 'Range' ? 'center' : 'left'}
              />
              {targetTypeSelected == 'Range' && (
                <>
                  <Text type="regular-10px" style={{ marginRight: '4px' }}>
                    –
                  </Text>
                  <TrblNumberInput
                    ref={targetMaxInputRef}
                    style={{
                      width: 52,
                      height: 24,
                      fontSize: '12px',
                      opacity: !targetValueSelected ? 0.5 : 1,
                    }}
                    value={targetValueMax}
                    onChange={setTargetValueMax}
                    onBlur={onTargetValueMaxBlur}
                    decimals={inputDecimals}
                    step={1 / Math.pow(10, inputDecimals - 1)}
                    allowEmpty
                    selectOnClick
                    underlined
                    alignment="center"
                  />
                </>
              )}
            </Stack>
          )}
        </div>
        {targetTypeSelected !== '' && (
          <TrblTooltip title="Percentage of the surface receiver area that meets the criteria defined by the target settings, based on non-interpolated data">
            <div className={styles['item']}>
              <Text
                type="medium-12px"
                style={{
                  marginLeft: '4px',
                  opacity: !targetValueSelected ? 0.5 : 1,
                  marginTop: '1px',
                  color: '#ADADAD',
                }}>
                Target area: <span style={{ color: '#dadada' }}>{targetPercentage + '%'}</span>
              </Text>
            </div>
          </TrblTooltip>
        )}
      </div>
      <div className={styles['grid-results-header-child']}>
        <InterpolatedToggle isInterpolated={surfaceReceiversIsInterpolated} setIsInterpolated={toggleIsInterpolated} />
      </div>
    </div>
  );
};
