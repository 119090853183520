import { useMemo } from 'react';

import { SavedResultsRowData } from '../types';

export const useFilterData = (data: SavedResultsRowData[], searchValue: string): SavedResultsRowData[] => {
  return useMemo(() => {
    if (!searchValue.trim()) return data;

    const searchTerm = searchValue.toLowerCase();
    return data.filter((row) => {
      return [row.name, row.allSimulations, row.allProjects, row.allSpaces, row.createdByUserEmail].some((field) =>
        field?.toLowerCase().includes(searchTerm)
      );
    });
  }, [data, searchValue]);
};
