import { useQueries } from '@tanstack/react-query';

import { ActionType as MeshActionType, useMeshContext } from '@/context/MeshContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { useEstimatedImpulseLength } from '@/components/SolverSettings/hooks/useEstimatedImpulseLength';
import { getMeshTask } from './useGetMeshTask';
import { startMeshTask } from './useStartMeshTask';
import { useUpdateSolverSettings } from './useUpdateSolverSettings';

import { MeshTaskDto, TaskStatusDto } from '../types';

export const useSolverSettings = () => {
  const { activeMeshTasks, completedMeshTasks, dispatch: meshDispatch } = useMeshContext();
  const estimateImpulseLength = useEstimatedImpulseLength();

  const {
    simulationState: { selectedSimulation },
    dispatch,
  } = useSimulationContext();

  const updateSolverSettings = useUpdateSolverSettings();

  useQueries({
    queries: activeMeshTasks.map((meshTask) => {
      return {
        queryKey: ['activeMeshTasks', meshTask.id],
        queryFn: () => getMeshTask(meshTask.id),
        onSuccess: (mt: MeshTaskDto) => {
          if (mt.task.status === TaskStatusDto.Completed) {
            const newActiveMeshTasks = activeMeshTasks.filter((x) => x.id !== mt.id);
            meshDispatch({
              type: MeshActionType.SET_ACTIVE_MESH_TASKS,
              activeMeshTasks: newActiveMeshTasks,
            });
            const newCompletedMeshTasks = [
              ...completedMeshTasks,
              {
                meshTaskId: mt.id,
                crossoverFrequency: meshTask.transitionFrequency,
              },
            ];
            meshDispatch({
              type: MeshActionType.SET_COMPLETED_MESH_TASKS,
              completedMeshTasks: newCompletedMeshTasks,
            });
            dispatch({
              type: ActionType.UPDATE_MESH_TASK_ID,
              meshTaskId: meshTask.id,
            });
          } else if (mt.task.status === TaskStatusDto.Error) {
            const newActiveMeshTasks = activeMeshTasks.filter((x) => x.id !== mt.id);
            meshDispatch({
              type: MeshActionType.SET_ACTIVE_MESH_TASKS,
              activeMeshTasks: newActiveMeshTasks,
            });
            meshDispatch({
              type: MeshActionType.SET_MESH_ERROR,
              meshHasError: true,
            });
            dispatch({
              type: ActionType.UPDATE_MESH_TASK_ID,
              meshTaskId: meshTask.id,
            });
          }
        },
        refetchInterval: 3000,
      };
    }),
  });

  const saveTransitionFrequency = (transitionFrequency?: number) => {
    if (transitionFrequency !== undefined && selectedSimulation) {
      const updatedSimulation = {
        ...selectedSimulation,
        solverSettings: {
          ...selectedSimulation.solverSettings,
          gaSettings: {
            ...selectedSimulation.solverSettings.gaSettings,
            crossoverFrequency: transitionFrequency
              ? transitionFrequency
              : selectedSimulation.solverSettings.gaSettings.crossoverFrequency,
          },
          dgSettings: {
            ...selectedSimulation.solverSettings.dgSettings,
            crossoverFrequency: transitionFrequency
              ? transitionFrequency
              : selectedSimulation.solverSettings.dgSettings.crossoverFrequency,
          },
        },
      };

      updateSolverSettings(updatedSimulation);
    }
  };

  const saveImpulseResponseLength = (impulseResponseLength?: number) => {
    if (impulseResponseLength !== undefined && selectedSimulation) {
      const updatedSimulation = {
        ...selectedSimulation,
        solverSettings: {
          ...selectedSimulation.solverSettings,
          gaSettings: {
            ...selectedSimulation.solverSettings.gaSettings,
            impulseLengthSeconds: impulseResponseLength
              ? impulseResponseLength
              : selectedSimulation.solverSettings.gaSettings.impulseLengthSeconds,
          },
          dgSettings: {
            ...selectedSimulation.solverSettings.dgSettings,
            impulseLengthSeconds: impulseResponseLength
              ? impulseResponseLength
              : selectedSimulation.solverSettings.dgSettings.impulseLengthSeconds,
          },
        },
      };
      updateSolverSettings(updatedSimulation);
    }
  };

  const saveEnergyDecayThreshold = (energyDecayThreshold?: number | null) => {
    if (energyDecayThreshold !== undefined && selectedSimulation) {
      let estImpulseLength = selectedSimulation.solverSettings.dgSettings.impulseLengthSeconds;

      // calculate estimated IRL for estImpulseLength, but if it returns null/undefined, then use the dgSettings.impulseLengthSeconds
      estImpulseLength =
        estimateImpulseLength(selectedSimulation.modelSettings?.materialIdByObjectId || {}, energyDecayThreshold) ??
        selectedSimulation.solverSettings.dgSettings.impulseLengthSeconds;

      const updatedSimulation = {
        ...selectedSimulation,
        solverSettings: {
          ...selectedSimulation.solverSettings,
          gaSettings: {
            ...selectedSimulation.solverSettings.gaSettings,
            energyDecayThreshold: energyDecayThreshold,
            impulseLengthSeconds: estImpulseLength,
          },
          dgSettings: {
            ...selectedSimulation.solverSettings.dgSettings,
            energyDecayThreshold: energyDecayThreshold,
            impulseLengthSeconds: estImpulseLength,
          },
        },
      };
      updateSolverSettings(updatedSimulation);
    }
  };

  const saveNumberOfRays = (numberOfRays?: number) => {
    if (numberOfRays !== undefined && selectedSimulation) {
      const updatedSimulation = {
        ...selectedSimulation,
        solverSettings: {
          ...selectedSimulation.solverSettings,
          gaSettings: {
            ...selectedSimulation.solverSettings.gaSettings,
            rayCount: numberOfRays,
          },
        },
      };
      updateSolverSettings(updatedSimulation);
    }
  };

  const saveImageSourceOrder = (imageSourceOrder?: number) => {
    if (imageSourceOrder !== undefined && selectedSimulation) {
      const updatedSimulation = {
        ...selectedSimulation,
        solverSettings: {
          ...selectedSimulation.solverSettings,
          gaSettings: {
            ...selectedSimulation.solverSettings.gaSettings,
            reflectionOrder: imageSourceOrder,
          },
        },
      };
      updateSolverSettings(updatedSimulation);
    }
  };

  const saveTaskType = (taskType: string) => {
    if (selectedSimulation) {
      const newSim = {
        ...selectedSimulation,
        taskType: taskType,
      };
      updateSolverSettings(newSim);
    }
  };

  const startNewMeshTask = async (transitionFrequency: number, modelId: string) => {
    const meshTask = await startMeshTask(transitionFrequency, modelId);
    const newActiveMeshTasks = [...activeMeshTasks, { id: meshTask.id, transitionFrequency }];
    meshDispatch({
      type: MeshActionType.SET_ACTIVE_MESH_TASKS,
      activeMeshTasks: newActiveMeshTasks,
    });
    return newActiveMeshTasks;
  };

  return {
    saveTransitionFrequency,
    saveImpulseResponseLength,
    saveEnergyDecayThreshold,
    saveImageSourceOrder,
    saveNumberOfRays,
    saveTaskType,
    startNewMeshTask,
    activeMeshTasks,
  };
};
