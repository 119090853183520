import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import axios from '@/axios';

type AddUserToOrganizationRequest = {
  userId: string;
  organizationId: string;
  products: string[];
};

const addUserToOrganization = async (organizationId: string, userId: string, products: string[]) => {
  await axios.patch(`/api/Organization/AddUserToOrganization`, products, {
    params: {
      organizationId,
      userId,
    },
  });
};

export const useAddUserToOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: AddUserToOrganizationRequest) =>
      await addUserToOrganization(data.organizationId, data.userId, data.products),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['subscription', variables.organizationId]);
        queryClient.invalidateQueries(['product-accesses', variables.organizationId]);
      },
      onError: () => {
        toast.error('An error occurred while adding the user to the organization!');
      },
    }
  );
};
