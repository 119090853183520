import { FC, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { Box } from '@mui/material';

import { Text } from '@/components/Shared/Text';

import { SimulationUsageInfoDto } from './hooks/useGetTokenUsageReport';

import { getProjectUsageColumns, getSimulationUsageColumns } from './utils';

import styles from './styles.module.scss';

type SDKUsageGridProps = {
  tokenUsage: SimulationUsageInfoDto[];
};

const NoRowsOverlay = () => (
  <Box component="div" display="flex" alignItems="center" justifyContent="center" height="100%">
    <Text type="medium-14px">No token usage data found for the selected time period</Text>
  </Box>
);

export const SDKUsageGrid: FC<SDKUsageGridProps> = ({ tokenUsage }) => {
  const [selectedProject, setSelectedProject] = useState<string | null>(null);

  useEffect(() => {
    if (selectedProject && !tokenUsage.find((p) => p.projectId === selectedProject)) {
      setSelectedProject(null);
    }
  }, [tokenUsage]);

  const selectedProjectData = selectedProject ? tokenUsage.find((p) => p.projectId === selectedProject) : null;

  return (
    <div style={{ height: '100%', display: 'flex', gap: '20px' }}>
      <div style={{ flex: '1' }}>
        <DataGrid
          rows={tokenUsage}
          columns={getProjectUsageColumns()}
          getRowId={(row) => row.projectId}
          autoPageSize
          disableColumnSelector
          onRowClick={(params) => setSelectedProject(params.row.projectId)}
          selectionModel={selectedProject ? [selectedProject] : []}
          initialState={{
            sorting: {
              sortModel: [{ field: 'lastCompleted', sort: 'desc' }],
            },
          }}
          components={{
            NoRowsOverlay: NoRowsOverlay,
          }}
          componentsProps={{
            row: { className: styles['data-grid-row'] },
            filterPanel: {
              filterFormProps: {
                deleteIconProps: {
                  sx: {
                    width: 'initial', // Weird fix needed for the filter panel
                  },
                },
              },
            },
          }}
          rowHeight={42}
          headerHeight={42}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: '#2b8f77',
              '&:hover': {
                backgroundColor: '#2b8f77',
              },
            },
          }}
        />
      </div>
      {tokenUsage.length > 0 && (
        <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
          {selectedProject ? (
            <Box component="div" sx={{ flex: 1 }}>
              <DataGrid
                rows={selectedProjectData?.simulationUsages || []}
                columns={getSimulationUsageColumns()}
                getRowId={(row) => row.simulationId}
                autoPageSize
                disableSelectionOnClick
                disableColumnSelector
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'completedAt', sort: 'desc' }],
                  },
                }}
                componentsProps={{
                  row: { className: styles['data-grid-row'] },
                  filterPanel: {
                    filterFormProps: {
                      deleteIconProps: {
                        sx: {
                          width: 'initial',
                        },
                      },
                    },
                  },
                }}
                rowHeight={42}
                headerHeight={42}
                sx={{
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
          ) : (
            <Box
              component="div"
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid rgba(81, 81, 81, 1)',
                borderRadius: 1,
                bgcolor: 'background.paper',
              }}>
              <Text type="medium-14px">Select a project to view token usage per simulation</Text>
            </Box>
          )}
        </div>
      )}
    </div>
  );
};
