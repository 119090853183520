/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { ActionType, useResultsContext } from '@/components/Results/context/ResultsContext';

import { TrblProjectIcon, TrblSimulationIcon, TrblSpaceIcon } from '@/components/Icons';
import { NewComparison, SimulationLightDto } from '@/components/Results/types';
import { SearchInput } from '../SearchInput';
import { ProjectRow } from './ProjectRow';
import { SimulationsList } from './SimulationsList';

import { useFilteredProject } from './hooks/useFilteredProject';

import { extractProjectAndSimulationData } from './utils';

import { Simulation } from '@/types';

import styles from './styles.module.scss';

type AddComparisonContentProps = {
  setShowPopup: (showPopup: boolean) => void;
};

export const AddComparisonContent: FC<AddComparisonContentProps> = ({ setShowPopup }) => {
  const { allSpacesWithSims, selectedPreset, availableComparisons, dispatch } = useResultsContext();

  const lastComparison = useMemo(() => {
    if (availableComparisons.length && availableComparisons[availableComparisons.length - 1].formState) {
      return availableComparisons[availableComparisons.length - 1];
    }
    return null;
  }, [availableComparisons]);
  const defaultProjectId = lastComparison?.formState?.projectId ?? null;
  const defaultSimulationId = lastComparison?.formState?.simulationId ?? null;

  const projectAndSimulationData = useMemo(
    () => extractProjectAndSimulationData(allSpacesWithSims),
    [allSpacesWithSims]
  );

  const [searchTerm, setSearchTerm] = useState('');

  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(defaultProjectId);
  const selectedProjectFiltered = useFilteredProject(projectAndSimulationData, selectedProjectId, searchTerm);

  const addComparison = (simulation: SimulationLightDto, modelName: string, spaceName: string) => {
    const newComparison: NewComparison = {
      // TODO: We should check if this is ok. Some properties that are missing from SimulationLightDto are required for NewComparison.
      // This is the same way as was implemented in earlier version. Only with better type checking now.
      selectedSimulation: simulation as unknown as Simulation,
      modelName,
      spaceName,
      title: simulation.name ?? '',
    };
    setShowPopup(false);
    dispatch({ type: ActionType.ADD_COMPARISON, newComparison, select: true });
    if (selectedPreset) {
      dispatch({ type: ActionType.SET_PRESET_EDITED, presetEdited: true });
    }
  };

  const simulationsContainerRef = useRef<HTMLDivElement>(null);

  const handleProjectClick = (projectId: string) => {
    setSelectedProjectId(projectId);
    simulationsContainerRef.current?.scrollTo({ top: 0 });
  };

  const defaultProjectRef = useRef<HTMLButtonElement>(null);
  const defaultSimulationRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // First scroll to the default project
    if (defaultProjectId && defaultProjectRef.current) {
      defaultProjectRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    // Then scroll to the default simulation with a small delay to ensure proper scrolling
    if (defaultSimulationId && defaultSimulationRef.current) {
      setTimeout(() => {
        defaultSimulationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    }
  }, [defaultProjectId, defaultSimulationId]);

  return (
    <div className={styles['main-content']}>
      <div className={styles['project-list-container']}>
        <div className={styles['list-header']}>
          <TrblProjectIcon width="14" height="14" />
          Projects
        </div>
        <div className={styles['item-list']}>
          {projectAndSimulationData.map((project) => (
            <ProjectRow
              key={project.projectId}
              ref={project.projectId === defaultProjectId ? defaultProjectRef : undefined}
              projectName={project.projectName}
              isSelected={selectedProjectId === project.projectId}
              onClick={() => handleProjectClick(project.projectId)}
            />
          ))}
        </div>
      </div>
      <div ref={simulationsContainerRef} className={styles['project-simulations-container']}>
        <div className={styles['list-header']}>
          <TrblSimulationIcon width="14" height="14" />
          <span style={{ flex: '1 1 0' }}> Simulations</span>

          <div className={styles['search-container']}>
            <SearchInput value={searchTerm} onChange={setSearchTerm} placeholder="Search" />
          </div>
        </div>
        <div className={styles['item-list']}>
          {selectedProjectFiltered?.spaces.length === 0 && searchTerm.trim() ? (
            <div className={styles['no-results']}>
              <p>No simulations found matching "{searchTerm}"</p>
            </div>
          ) : (
            selectedProjectFiltered?.spaces.map((space) => (
              <div
                key={`${selectedProjectFiltered.projectId}-${space.spaceId}`}
                className={styles['space-simulations-container']}>
                <div className={styles['space-header']}>
                  <span className={styles['space-icon']}>
                    <TrblSpaceIcon fill="#adadad" width="13" height="13" />
                  </span>
                  <span className={styles['space-name']}>{space.spaceName}</span>
                </div>
                <SimulationsList
                  space={space}
                  searchTerm={searchTerm}
                  defaultSimulationId={defaultSimulationId}
                  defaultSimulationRef={defaultSimulationRef}
                  onSimulationClick={addComparison}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
