import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblAuralizeIcon: FC<IconProps> = ({ fill = '#00F5BA', width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4077 6.82792C12.3543 6.80573 12.3192 6.75189 12.3192 6.692V6.63367C12.3192 3.73417 10.0627 1.38367 7.27918 1.38367C4.49567 1.38367 2.23918 3.73417 2.23918 6.63367V6.692C2.23915 6.75189 2.20411 6.80573 2.1507 6.82792C1.08866 7.26611 0.44242 8.39363 0.575988 9.57538C0.709556 10.7571 1.58953 11.6975 2.72022 11.8668C2.88099 11.8906 3.04379 11.8406 3.1664 11.7297C3.289 11.6187 3.35934 11.4578 3.35918 11.2887V6.63542C3.35918 4.38025 5.11423 2.55208 7.27918 2.55208C9.44414 2.55208 11.1992 4.38025 11.1992 6.63542V11.4112C11.1995 11.5437 11.253 11.6701 11.3467 11.7598C11.4403 11.8495 11.5657 11.8944 11.6925 11.8837C12.8577 11.7804 13.8015 10.8558 13.972 9.65076C14.1424 8.44572 13.4947 7.27685 12.4077 6.82792Z"
      fill={fill}
    />
    <path
      d="M10.4989 9.70555C10.4989 9.38339 10.2482 9.12222 9.93889 9.12222H8.95889C8.74708 9.12212 8.55335 9.2465 8.45825 9.44364L8.18497 10.0118C8.15866 10.0663 8.10256 10.098 8.04435 10.0912C7.98614 10.0845 7.93813 10.0407 7.92401 9.98147L7.26209 7.22347C7.20466 6.98683 7.01186 6.81245 6.77887 6.78642C6.54589 6.7604 6.32198 6.88823 6.21769 7.1068L5.28977 9.03764C5.26636 9.08661 5.21852 9.11772 5.16601 9.11814L4.61441 9.12222C4.30513 9.12351 4.05541 9.38572 4.05665 9.70789C4.05788 10.0301 4.30961 10.2902 4.61889 10.2889H4.62337L5.60337 10.2813C5.814 10.2796 6.00593 10.155 6.10009 9.95872L6.37337 9.3888C6.39958 9.33428 6.45559 9.3025 6.51378 9.30913C6.57197 9.31577 6.62006 9.35942 6.63433 9.41855L7.29569 12.1725C7.35246 12.4092 7.54466 12.5841 7.77747 12.6109C8.01027 12.6377 8.2345 12.5107 8.33953 12.2926L9.26577 10.3676C9.28936 10.3183 9.33773 10.2871 9.39065 10.2871H9.93889C10.2475 10.2871 10.498 10.027 10.4989 9.70555Z"
      fill={fill}
    />
  </svg>
);
