import { ChangeEventHandler, FC, useEffect } from 'react';

import { useResultComparisonContext } from './context/ResultComparisonContext';

import { SelectChangeEvent } from '@mui/material';

import { SimulationFormSelect } from '@/components';
import { SelectedSimulationField } from '../SelectedSimulationField';
import { SimulationFormMultiSelect } from '../SimulationFormMultiSelect';
import { SimulationFormSourceSelect } from '../SimulationFormSourceSelect';
import { SimulationFormTextField } from '../SimulationFormTextField.tsx';

import { ActionType } from './constants';
import { ResultComparisonLabels } from './constants';

import { ResultType } from '@/types';

import classes from './styles.module.scss';

interface SimulationForm {
  color: string;
  spaceName: string;
  modelName: string;
  isReflectionResults: boolean;
  onFormChange: () => void;
  onStartSourceSumming: (name: string, sources: string[]) => void;
  onRestartSourceSumming: (summedSourceId: string, sources: string[]) => void;
  onCancelSummingProcess: (summedSourceId: string, taskId: string) => void;
  onDeleteSummedSource: (summedSourceId: string, taskIds: string[]) => void;
  onUpdateSourceSummingLabel: (summedSourceId: string, label: string) => void;
}

export const SimulationForm: FC<SimulationForm> = ({
  color,
  spaceName,
  modelName,
  isReflectionResults,
  onFormChange,
  onStartSourceSumming,
  onRestartSourceSumming,
  onCancelSummingProcess,
  onDeleteSummedSource,
  onUpdateSourceSummingLabel,
}) => {
  const { dispatch, state } = useResultComparisonContext();

  const {
    title,
    availableResultTypes,
    simulationId,
    availableSources,
    availableSummedSources,
    sourcePointIds,
    availableReceivers,
    receiverPointIds,
    resultType,
    lastSelectedResultType,
    selectedSimulation,
    lastSolveResults,
    sourceSummingSelectionEnabled,
    selectedSourcesForSumming,
  } = state;

  const controlsDisabled =
    isReflectionResults &&
    !lastSolveResults?.sourceResults
      .find((sr) => sr.resultType === 'GA')
      ?.taskResultForReceiver[0].receiverResults?.some((x) => x.resultType === 'reflection');

  // When switching to/from the reflection results tab or if the user selects a different simulation (receiverPointIds)
  useEffect(() => {
    if (lastSolveResults) {
      if (isReflectionResults && receiverPointIds) {
        // If we are switching to the reflection results tab and have a summed source result selected, select the first available source
        if (sourcePointIds && sourcePointIds.length > 1) {
          dispatch({
            type: ActionType.SELECT_SOURCE,
            payload: [],
          });
        }

        // If we are switching to the reflection results tab and have multiple receivers selected, select the first one
        if (receiverPointIds.length > 1) {
          dispatch({
            type: ActionType.SELECT_RECEIVERS,
            payload: [receiverPointIds[0]],
          });
        }

        // If switching to the reflection results tab, and we are not already on the GA result type, switch to it
        if (resultType !== ResultType.GA) {
          dispatch({
            type: ActionType.SELECT_RESULT_TYPE,
            resultType: ResultType.GA,
            isUserSelected: false,
          });
        }
      } else if (lastSelectedResultType !== resultType) {
        // If switching from the reflection results tab, and the last user selected result type is different, switch
        dispatch({
          type: ActionType.SELECT_RESULT_TYPE,
          resultType: lastSelectedResultType,
          isUserSelected: false,
        });
      }
    }
  }, [isReflectionResults, receiverPointIds]);

  const handleSelectFormControlChange = (event: SelectChangeEvent<string>) => {
    event.stopPropagation();

    const { value, name } = event?.target ?? {};

    if (value) {
      onFormChange();
      switch (name) {
        case ResultComparisonLabels.SOURCE:
          dispatch({
            type: ActionType.SELECT_SOURCE,
            payload: [value],
          });
          return;
        case ResultComparisonLabels.RECEIVER:
          dispatch({
            type: ActionType.SELECT_RECEIVERS,
            payload: [value],
          });
          return;
        case ResultComparisonLabels.RESULT_TYPE:
          dispatch({
            type: ActionType.SELECT_RESULT_TYPE,
            resultType: value,
            isUserSelected: true,
          });
          return;
      }
    }
  };

  const handleSourceChanged = (value: string[]) => {
    onFormChange();
    dispatch({
      type: ActionType.SELECT_SOURCE,
      payload: value,
    });
  };

  const handleReceiversChanged = (value: string[]) => {
    onFormChange();
    dispatch({
      type: ActionType.SELECT_RECEIVERS,
      payload: value,
    });
  };

  const handleTextFieldFormControlChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const { value, name } = event?.target ?? {};
    onFormChange();
    switch (name) {
      case ResultComparisonLabels.LABEL:
        dispatch({ type: ActionType.UPDATE_LABEL, payload: value });
        return;
    }
  };

  const handleSetSourceSummingSelectionEnabled = (value: boolean) => {
    dispatch({ type: ActionType.SET_SOURCE_SUMMING_SELECTION_ENABLED, payload: value });
  };

  const handleSelectedSourcesForSummingChanged = (sources: string[]) => {
    dispatch({ type: ActionType.SET_SELECTED_SOURCES_FOR_SUMMING, payload: sources });
  };

  return (
    <form>
      <SelectedSimulationField
        spaceName={spaceName}
        modelName={modelName}
        selectedSimulation={selectedSimulation}></SelectedSimulationField>
      <SimulationFormTextField
        label={ResultComparisonLabels.LABEL}
        value={title}
        color={color}
        formControlChangeEvent={handleTextFieldFormControlChange}
      />
      <div className={classes.comparison_form_fields}>
        <SimulationFormSourceSelect
          disabled={!simulationId || controlsDisabled}
          values={!controlsDisabled && sourcePointIds?.length ? sourcePointIds : []}
          availableSources={availableSources ?? []}
          availableSummedSources={!isReflectionResults ? availableSummedSources ?? [] : []}
          sourceSummingAvailable={!isReflectionResults}
          // Disable source summing if the last simulation run was created before the source summing feature was added
          sourceSummingDisabled={
            new Date(selectedSimulation?.lastSimulationRun?.createdAt ?? 0) < new Date('2025-02-06')
          }
          resultType={resultType}
          sourceSummingSelectionEnabled={sourceSummingSelectionEnabled}
          selectedSourcesForSumming={selectedSourcesForSumming}
          onChange={handleSourceChanged}
          onChangeSelectedSourceForSumming={handleSelectedSourcesForSummingChanged}
          setSourceSummingSelectionEnabled={handleSetSourceSummingSelectionEnabled}
          onStartSummingProcess={onStartSourceSumming}
          onRestartSummingProcess={onRestartSourceSumming}
          onCancelSummingProcess={onCancelSummingProcess}
          onDeleteSummedSource={onDeleteSummedSource}
          onUpdateSourceSummingLabel={onUpdateSourceSummingLabel}
        />
        {!isReflectionResults ? (
          <SimulationFormMultiSelect
            disabled={!sourcePointIds?.length}
            values={receiverPointIds || []}
            label={ResultComparisonLabels.RECEIVERS}
            options={availableReceivers || []}
            onChange={handleReceiversChanged}
            placeholder={'Select ' + ResultComparisonLabels.RECEIVERS}
            allowSingleSelect={true}
          />
        ) : (
          <SimulationFormSelect
            disabled={!sourcePointIds?.length || controlsDisabled}
            value={!controlsDisabled && receiverPointIds?.length ? receiverPointIds[0] : ''}
            label={ResultComparisonLabels.RECEIVER}
            options={availableReceivers || []}
            formControlChangeEvent={handleSelectFormControlChange}
            placeholder={
              !controlsDisabled ? 'Select ' + ResultComparisonLabels.RECEIVER : 'No reflection results available'
            }
          />
        )}
        <SimulationFormSelect
          disabled={!simulationId || isReflectionResults}
          value={resultType}
          label={ResultComparisonLabels.RESULT_TYPE}
          options={availableResultTypes || []}
          formControlChangeEvent={handleSelectFormControlChange}
          placeholder={'Select ' + ResultComparisonLabels.RESULT_TYPE}
        />
      </div>
    </form>
  );
};
