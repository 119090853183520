import { FC, SyntheticEvent } from 'react';

import { useBaseContext } from '@/context/BaseContext';

import { TrblTooltip } from '@/components/Shared';
import { ShareDisabledInformation } from '@/components/Shared/ShareDisabledInformation';
import { TrblAddMemberIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type ShareProjectButtonProps = {
  size?: string;
  label?: string;
  tooltip?: string;
  onClick: (e: SyntheticEvent) => void;
};

export const ShareProjectButton: FC<ShareProjectButtonProps> = ({ size = '20px', label, tooltip, onClick }) => {
  const {
    state: { subscriptionInfo },
  } = useBaseContext();
  return (
    <TrblTooltip
      title={
        !subscriptionInfo?.hasAccessToShare ? <ShareDisabledInformation type="projects" /> : tooltip ?? 'Manage members'
      }
      disableInteractive={false}>
      <button
        className={`${styles['members-button']} ${!subscriptionInfo?.hasAccessToShare ? styles['disabled'] : ''}`}
        onClick={subscriptionInfo?.hasAccessToShare ? onClick : undefined}>
        <span className={styles['members-icon']} style={{ width: size, height: size }}>
          <TrblAddMemberIcon />
        </span>
        {label}
      </button>
    </TrblTooltip>
  );
};
