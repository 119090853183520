import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ActionType as EdActionType, useEditorContext } from '@/context/EditorContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { Simulation } from '@/types';

/** Hook to share logic to get simulation based on route params */
export const useGetSimulationFromRouteParam = () => {
  const {
    dispatch,
    simulationState: { selectedSimulation, availableSimulations },
  } = useSimulationContext();
  const { dispatch: edDispatch } = useEditorContext();

  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentModelId = searchParams.get('mid') || '';
  const currentSimId = searchParams.get('sid') || '';

  useEffect(() => {
    let newSelectedSim: Simulation | null = null;
    // available simulations always updates whenever a simulation prop change
    const hasModel = availableSimulations?.find((sim) => sim.modelId === currentModelId);

    if (availableSimulations && availableSimulations.length > 0 && hasModel) {
      if (currentSimId !== '' && selectedSimulation?.id !== currentSimId) {
        newSelectedSim = availableSimulations.find((simulation) => simulation.id === currentSimId) || null;
        if (!newSelectedSim) {
          // when the simulation doesn't exist anymore... maybe they shouldn't be returned even?
          // this should only happen when users are clicking on recent simulation runs
          toast.warning(`Simulation with id \n${currentSimId} could not be found`, { className: 'editor-toast' });
          newSelectedSim = availableSimulations[availableSimulations.length - 1];
          if (searchParams.get('mid')) {
            setSearchParams({ mid: newSelectedSim.modelId, sid: newSelectedSim.id }, { replace: true });
          } else {
            setSearchParams({ sid: newSelectedSim.id }, { replace: true });
          }
        }
      } else if (currentSimId === '') {
        newSelectedSim = availableSimulations.find((simualtion) => simualtion.modelId == currentModelId) ?? null;

        if (currentModelId && newSelectedSim) {
          setSearchParams({ mid: currentModelId, sid: newSelectedSim.id }, { replace: true });
        }
      }

      // only go in here if newSelectedSim has value, and it only has value if the selected
      // simulation changes because otherwise we are always setting the selected simulation
      // every time availableSimulations changes - and that variable always changes when the
      // selected simulation changes because we need to maintain an updated simulation in
      // that array for run statuses and  to prevent a refetch from the backend
      if (newSelectedSim) {
        dispatch({
          type: ActionType.SET_SELECTED_SIMULATION,
          simulation: newSelectedSim,
        });
        edDispatch({
          type: EdActionType.CLEAR_SELECTED,
        });
        edDispatch({
          type: EdActionType.SET_SELECTED_SIMULATION_LOADED,
          payload: true,
        });
      }

      if (pathname === '/auralizer') {
        newSelectedSim = availableSimulations.find((simulation) => simulation.id === currentSimId) || null;
        dispatch({
          type: ActionType.SET_ORIGINAL_SIMULATION,
          simulation: newSelectedSim ? { ...newSelectedSim } : null,
        });
      }
    } else if (availableSimulations && pathname === '/editor') {
      dispatch({
        type: ActionType.SET_SELECTED_SIMULATION,
        simulation: null,
      });
      edDispatch({
        type: EdActionType.CLEAR_SELECTED,
      });
      edDispatch({
        type: EdActionType.SET_SELECTED_SIMULATION_LOADED,
        payload: true,
      });
    }
  }, [availableSimulations, currentModelId, currentSimId, pathname]);
};
