export const HighlightedText = ({
  text,
  highlight,
  highlightColor = '#00f5ba', // Treble neon green
}: {
  text: string;
  highlight: string;
  highlightColor?: string;
}) => {
  if (highlight.length > 0) {
    const parts = text.split(new RegExp(`(${highlight.replace(/([[\]\\(){}|-])/g, '\\$1')})`, 'i'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <b key={`${part}-${index}`} style={{ color: highlightColor }}>
              {part}
            </b>
          ) : (
            part
          )
        )}
      </span>
    );
  } else {
    return <span>{text}</span>;
  }
};
