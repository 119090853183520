import { TrblNumberInput } from '@/components/Shared/NumberInput';

interface FrequencyInputFieldProps {
  freqValue: number;
  setFreqValue: (value: number) => void;
  min: number;
  max: number;
  disabled: boolean;
}

export const FrequencyInputField = ({ freqValue, setFreqValue, min, max, disabled }: FrequencyInputFieldProps) => {
  const handleChange = (value: number | undefined) => {
    setFreqValue(value as number);
  };

  return (
    <TrblNumberInput
      disabled={disabled}
      value={freqValue}
      onChange={handleChange}
      step={1}
      endAdornment={'Hz'}
      alignment="center"
      min={min}
      max={max}
      style={{ height: '26px' }}
    />
  );
};
