import { IconButton } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { Text } from '@/components/Shared/Text';
import { TrblChevronRight, TrblUploadIcon } from '../../../Icons';

import styles from '../../styles.module.scss';

export const StatusNav = ({
  isSidebarActive,
  setIsSidebarActive,
}: {
  isSidebarActive: boolean;
  setIsSidebarActive: (value: boolean) => void;
}) => {
  return (
    <div className={`${styles['recent-uploads-nav']} ${isSidebarActive ? styles['active'] : ''} `}>
      <Text type="regular-11px" className={styles['status-text']}>
        <button className={styles['status-text-btn']} onClick={() => setIsSidebarActive(!isSidebarActive)}>
          <TrblUploadIcon />

          <span>Recent IFC imports</span>
        </button>
      </Text>
      <TrblTooltip title="Collapse sidepanel">
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsSidebarActive(false);
          }}>
          <TrblChevronRight width="8" height="8" fill="#c0c0c0" />
        </IconButton>
      </TrblTooltip>
    </div>
  );
};
