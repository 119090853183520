import { Vector3 } from 'three';

import { useModelContext } from '@/context/ModelContext';

import {
  getSourceProximityError,
  getSourceProximityWarning,
  isPointCloseToSource,
  isPointInsideInternalVolume,
  isPointInsideModel,
  isPointInValidProximity,
} from '../utils';

import { Source, ValidationError, ValidationWarning } from '@/context/EditorContext/types';

export const useGetPointValidity = () => {
  const { innerMeshes, outerMeshes, meshes, bypassPointValidity } = useModelContext();

  const getPointValidity = (
    point3D: Vector3,
    type: 'SourcePoint' | 'ReceiverPoint',
    sources?: Source[],
    taskType?: string | null,
    transitionFrequency?: number | null
  ): Promise<null | ValidationError> => {
    return new Promise((resolve) => {
      if (bypassPointValidity) resolve(null);

      let sourceProximity = 0.5;

      // if taskType is GA, then disable source proximity margin by setting it to 0.01 (1cm)
      if (taskType == 'GA') {
        sourceProximity = 0.01;
      }

      // if taskType is DG or Hybrid, then adjust source proximity to the transition frequency of the simulation
      if (taskType !== 'GA' && transitionFrequency) {
        sourceProximity = getSourceProximityError(transitionFrequency);
      }

      if (outerMeshes?.length && !isPointInsideModel(point3D, outerMeshes)) {
        resolve(ValidationError.NotInsideModel);
      } else if (type === 'ReceiverPoint' && sources && isPointCloseToSource(point3D, sources)) {
        resolve(ValidationError.CloseToSource);
      } else if (innerMeshes?.length && isPointInsideInternalVolume(point3D, innerMeshes)) {
        resolve(ValidationError.InsideInnerVolume);
      } else if (
        (type == 'SourcePoint' || type == 'ReceiverPoint') &&
        meshes?.length &&
        !isPointInValidProximity(point3D, type == 'SourcePoint' ? sourceProximity : 0.1, meshes)
      ) {
        resolve(ValidationError.CloseToSurface);
      }

      resolve(null);
    });
  };

  const getPointWarning = (
    point3D: Vector3,
    type: 'SourcePoint' | 'ReceiverPoint',
    taskType?: string | null,
    transitionFrequency?: number | null
  ): Promise<null | ValidationWarning> => {
    return new Promise((resolve) => {
      if (bypassPointValidity) resolve(null);

      if (type == 'SourcePoint') {
        let sourceProximityWarning = 1.3;
        if (taskType == 'GA') {
          resolve(null);
        } else if (transitionFrequency) {
          sourceProximityWarning = getSourceProximityWarning(transitionFrequency);
        }

        if (meshes?.length && !isPointInValidProximity(point3D, sourceProximityWarning, meshes)) {
          resolve(ValidationWarning.CloseToSurface);
        }
      }

      resolve(null);
    });
  };

  return { getPointValidity, getPointWarning };
};
