import { useMemo } from 'react';

import { AuralizationPresetInfoDtoAuralizationPresetInfoDto } from '@/components/ResultPresets/hooks/useGetAuralizationPresets';

export const useTransformData = (
  allAuralizationPresets: AuralizationPresetInfoDtoAuralizationPresetInfoDto[],
  isLoadingPresets: boolean
) => {
  return useMemo(() => {
    if (allAuralizationPresets.length === 0 || isLoadingPresets) {
      return [];
    }

    return allAuralizationPresets.map((row) => {
      const allSimulations = row.simulationNames.join(', ');
      return {
        ...row,
        allSimulations,
      };
    });
  }, [allAuralizationPresets, isLoadingPresets]);
};
