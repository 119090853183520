import { FC } from 'react';

import { ProjectCard } from '../ProjectCard';

import { ProjectAndSpacesDto } from '@/types';

import styles from './styles.module.scss';

type ProjectsCardViewProps = {
  projects: ProjectAndSpacesDto[];
  loggedInUserId: string;
  projectSearchString: string;
};

export const ProjectsCardView: FC<ProjectsCardViewProps> = ({ projects, loggedInUserId, projectSearchString }) => {
  return (
    <div className={styles['projects_cards_container']}>
      {projects.map((project) => (
        <ProjectCard
          key={project.id}
          project={project}
          loggedInUserId={loggedInUserId}
          projectSearchString={projectSearchString}
        />
      ))}
    </div>
  );
};
