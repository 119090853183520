import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblResultsIcon: FC<IconProps> = ({ fill = '#00F5BA', width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.84611 6.70831C1.63086 6.70831 1.45703 6.83898 1.45703 6.99998V11.6666C1.45703 11.8277 1.58761 11.9583 1.7487 11.9583H3.4987C3.65978 11.9583 3.79036 11.8277 3.79036 11.6666V6.99998C3.79036 6.83898 3.61536 6.70831 3.40128 6.70831H1.84611Z"
      fill={fill}
    />
    <path
      d="M6.51408 8.45831C6.29883 8.45831 6.125 8.58898 6.125 8.74998V11.6666C6.125 11.8277 6.25558 11.9583 6.41667 11.9583H8.16667C8.32775 11.9583 8.45833 11.8277 8.45833 11.6666V8.74998C8.45833 8.58898 8.28333 8.45831 8.06925 8.45831H6.51408Z"
      fill={fill}
    />
    <path
      d="M10.7917 11.9583H12.5417C12.6969 11.9639 12.8273 11.8429 12.8333 11.6877V4.64625C12.8333 4.49633 12.6583 4.375 12.4443 4.375H10.8891C10.6738 4.375 10.5 4.49633 10.5 4.64625V11.6877C10.506 11.8429 10.6365 11.9639 10.7917 11.9583Z"
      fill={fill}
    />
    <path
      d="M13.4167 12.8333H0.583333C0.261167 12.8333 0 13.0945 0 13.4166C0 13.7388 0.261167 14 0.583333 14H13.4167C13.7388 14 14 13.7388 14 13.4166C14 13.0945 13.7388 12.8333 13.4167 12.8333Z"
      fill={fill}
    />
    <path
      d="M11.1139 2.29892C11.1991 2.32136 11.2867 2.33293 11.3747 2.33333C12.019 2.33333 12.5414 1.811 12.5414 1.16667C12.5414 0.522334 12.019 0 11.3747 0C10.7304 0 10.208 0.522334 10.208 1.16667C10.2084 1.22884 10.2139 1.29088 10.2244 1.35217C10.233 1.40062 10.2162 1.45011 10.18 1.48342L7.39519 4.08333C7.35905 4.11697 7.30838 4.13013 7.26044 4.11833C7.17529 4.09597 7.08772 4.08422 6.99969 4.08333C6.79723 4.08335 6.59831 4.13645 6.42277 4.23733C6.38037 4.26134 6.32885 4.26286 6.2851 4.24142L3.56444 2.8805C3.52132 2.85833 3.49172 2.81653 3.4851 2.7685C3.41806 2.2613 3.02732 1.85753 2.52259 1.7739C2.01785 1.69027 1.51777 1.94643 1.29072 2.40491C1.06367 2.86338 1.16303 3.4164 1.53546 3.76717C1.9079 4.11794 2.46588 4.18401 2.90994 3.92992C2.95234 3.90591 3.00386 3.90439 3.0476 3.92583L5.76827 5.28617C5.81148 5.30853 5.84108 5.35054 5.8476 5.39875C5.94117 5.99436 6.47315 6.42056 7.07483 6.38196C7.6765 6.34336 8.14966 5.85268 8.16636 5.25C8.16597 5.18802 8.16051 5.12617 8.15002 5.06508C8.14143 5.01646 8.15813 4.9668 8.19436 4.93325L10.9792 2.33333C11.0154 2.2999 11.0661 2.28696 11.1139 2.29892Z"
      fill={fill}
    />
  </svg>
);
