import { useQuery } from '@tanstack/react-query';

import { ProjectAndSpacesDto } from '@/types';

import axios from '@/axios';

const getRecentProjects = async (ownershipType: string, limit: number) => {
  const { data } = await axios.get<ProjectAndSpacesDto[]>(
    `/api/v2/Project/GetRecentProjects?limit=${limit}&ownershipType=${ownershipType}`
  );

  return data;
};

export const useGetRecentProjects = (ownershipType: string = 'Accessible', limit: number = 4) => {
  return useQuery<ProjectAndSpacesDto[]>(
    ['recent_projects', ownershipType],
    () => getRecentProjects(ownershipType, limit),
    {
      refetchOnWindowFocus: false,
    }
  );
};
