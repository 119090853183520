import { useReflectogramResultsContext } from '@/components/Results/context/ReflectogramResultsContext/ReflectogramResultsContext';
import { useResultsContext } from '@/components/Results/context/ResultsContext';
import { ActionType, useEditorContext } from '@/context/EditorContext';

import { getSourceProximityError } from '@/components/SourceRecieverSettings/utils';
import { Point } from './Point';

import { ResultsView, Source, ValidationError } from '@/context/EditorContext/types';

export const Points = () => {
  const {
    selected,
    receivers,
    sources,
    isInResultsMode,
    isAuralizerOpen,
    resultsView,
    selectedAurSource,
    transitionFrequency,
    dispatch,
  } = useEditorContext();

  const { selectedComparisonIndex, availableComparisons } = useResultsContext();
  const {
    state: { selectedReceiverDirection },
  } = useReflectogramResultsContext();

  let sourcesSelectedInResults: string[] | undefined, receiversSelectedInResults: string[] | undefined;

  if (isInResultsMode && availableComparisons.length > 0) {
    const selectedComparisonState = availableComparisons?.[selectedComparisonIndex]?.formState;
    sourcesSelectedInResults = selectedComparisonState?.sourceSummingSelectionEnabled
      ? selectedComparisonState.selectedSourcesForSumming
      : selectedComparisonState?.sourcePointIds;
    receiversSelectedInResults = availableComparisons?.[selectedComparisonIndex]?.formState?.receiverPointIds;
  }

  if (isAuralizerOpen && selectedAurSource) {
    receiversSelectedInResults = [selectedAurSource.source.id];
  }

  const handlePointSelect = (pointId: string, type: 'SourcePoint' | 'ReceiverPoint') => {
    if (selected?.id !== pointId) {
      dispatch({
        type: ActionType.SET_SELECTED,
        selected: {
          type: type,
          id: pointId,
        },
      });
    } else {
      dispatch({ type: ActionType.CLEAR_SELECTED });
    }
  };

  const getSphereMargin = (source: Source) => {
    if (transitionFrequency && source.validationError == ValidationError.CloseToSurface) {
      return getSourceProximityError(transitionFrequency);
    }

    return undefined;
  };

  return (
    <>
      {sources.map((s, index) =>
        s.x !== undefined && s.y !== undefined && s.z !== undefined ? (
          <Point
            key={s.id}
            id={s.id}
            index={index}
            type="SourcePoint"
            resultView={resultsView}
            x={s.x}
            y={s.y}
            z={s.z}
            directivityPattern={s.params.directivityPattern}
            azimuth={s.params.azimuth}
            elevation={s.params.elevation}
            onSelect={handlePointSelect}
            inEditor={!isInResultsMode && !isAuralizerOpen}
            isSelected={
              !isInResultsMode && !isAuralizerOpen
                ? selected?.type === 'SourcePoint' && selected.id === s.id
                : sourcesSelectedInResults?.includes(s.id) ?? false
            }
            validationError={s.validationError}
            sphereMargin={getSphereMargin(s)}
          />
        ) : null
      )}
      {!(isInResultsMode && resultsView === ResultsView.ResultsGridReceiversView) &&
        receivers.map((r, index) =>
          r.x !== undefined && r.y !== undefined && r.z !== undefined ? (
            <Point
              key={r.id}
              id={r.id}
              index={index}
              type="ReceiverPoint"
              resultView={resultsView}
              x={r.x}
              y={r.y}
              z={r.z}
              azimuth={selectedReceiverDirection?.receiverId === r.id ? selectedReceiverDirection.azimuth : undefined}
              elevation={
                selectedReceiverDirection?.receiverId === r.id ? selectedReceiverDirection.elevation : undefined
              }
              onSelect={handlePointSelect}
              inEditor={!isInResultsMode && !isAuralizerOpen}
              isSelected={
                !isInResultsMode
                  ? selected?.type === 'ReceiverPoint' && selected.id === r.id
                  : receiversSelectedInResults?.includes(r.id) ?? false
              }
              validationError={r.validationError}
            />
          ) : null
        )}
    </>
  );
};
