import { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';

import { DarkBox } from '@/components/Shared/Popup';
import { FrequencyTable } from '../StatisticalEstimates/components/FrequencyTable';
import { ImpedanceAndReflectionPlots } from './ImpedanceAndReflectionPlots';
import { MaterialDetailsPlot } from './MaterialDetailsPlot';
import { MaterialInfo } from './MaterialInfo';

import { GREEN_PLOT_COLOR } from '../CreateMaterial/constants';
import {
  ABS_PLOT_TITLE,
  ABS_RANGE,
  ABS_TITLE,
  FREQ_LABELS,
  IMPEDANCE_PLOT_TITLE,
  SPECIFIC_IMPEDANCE_PLOT_TITLE,
  TICK_VALS,
} from './constants';

import { Material } from '@/types';

import styles from './styles.module.scss';

export const MaterialDetailsContent = ({ material }: { material: Material }) => {
  const [realReflectionCoefficient, setRealReflectionCoefficient] = useState<number[]>([]);
  const [imagReflectionCoefficient, setImagReflectionCoefficient] = useState<number[]>([]);
  const [imagSurfaceImpedance, setImagSurfaceImpedance] = useState<number[]>([]);
  const [realSurfaceImpedance, setRealSurfaceImpedance] = useState<number[]>([]);

  useEffect(() => {
    if (material?.materialMetadataJson) {
      const parsedMaterialMetadata = JSON.parse(material.materialMetadataJson);
      setRealReflectionCoefficient(parsedMaterialMetadata.RealReflectionCoefficient || []);
      setImagReflectionCoefficient(parsedMaterialMetadata.ImagReflectionCoefficient || []);
      // When we get the impedance data we will populate it here
      setImagSurfaceImpedance(parsedMaterialMetadata.ImagSurfaceImpedance || []);
      setRealSurfaceImpedance(parsedMaterialMetadata.RealSurfaceImpedance || []);
    }
  }, [material]);

  return (
    <Box component="div">
      <Grid container spacing={2} alignItems="stretch" mb={2}>
        <MaterialInfo material={material} />
      </Grid>
      <DarkBox>
        <Grid container>
          <Grid item xs={6}>
            <p className={styles['plot-title']}> {ABS_TITLE} </p>
            <div style={{ margin: `0 0 20px -12px` }}>
              <FrequencyTable
                data={material.absorptionCoefficients ?? []}
                showData={material.absorptionCoefficients ? material.absorptionCoefficients.length > 0 : false}
                fontSize="11px"
              />
            </div>
            <MaterialDetailsPlot
              range={ABS_RANGE}
              ticktext={FREQ_LABELS}
              tickvals={TICK_VALS}
              xData={TICK_VALS}
              plots={[material?.absorptionCoefficients ?? []]}
              plotColors={[GREEN_PLOT_COLOR]}
              plotTitles={['Real']}
              yAxesTitle={ABS_PLOT_TITLE}
              plotHeight={280}
            />
          </Grid>
          <Grid item xs={6} className={styles['right-plot-container']}>
            <ImpedanceAndReflectionPlots
              showTitle={true}
              isSpecific={material.isSpecific}
              impedanceLabel={material.isSpecific ? SPECIFIC_IMPEDANCE_PLOT_TITLE : IMPEDANCE_PLOT_TITLE}
              showTabs={realSurfaceImpedance && realSurfaceImpedance.length > 0}
              resultRealReflection={realReflectionCoefficient}
              resultImagReflection={imagReflectionCoefficient}
              resultRealImpedance={realSurfaceImpedance}
              resultImagImpedance={imagSurfaceImpedance}
              plotTitles={['Real', 'Imag', 'Real', 'Imag']}
            />
          </Grid>
        </Grid>
      </DarkBox>
    </Box>
  );
};
