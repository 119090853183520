export type SimulationRunSourceDetails = {
  label: string;
  statusText: string;
  inProgress: boolean;
  progressBarColor: 'secondary' | 'warning' | 'error';
  percentage: number | null;
  orderNumber: number;
};

export enum SimulationStatusText {
  Starting = 'Starting',
  Queued = 'Queued',
  Running = 'Running',
  Completed = 'Completed',
  ProcessingResults = 'Processing results',
  Cancelled = 'Cancelled',
  InsufficientTokens = 'Insufficient tokens',
  InitializingSolver = 'Initializing solver',
  Error = 'Error',
}

export type SimulationRunDetails = {
  id: string | null;
  inProgress: boolean;
  progressBarColor: 'primary' | 'error' | 'warning';
  statusText: SimulationStatusText;
  showEllipsis: boolean;
  percentage: number | null;
  timeRemainingText: string | null;
  showRemaining: boolean;
  createdAt: string | null;
  completedAt: string | null;
  sourceDetails: Array<SimulationRunSourceDetails>;
};
