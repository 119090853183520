import { useState } from 'react';

import { Stack } from '@mui/material';

import { TabButtonsOutlined } from '../TabButtonsOutlined/TabButtonsOutlined';
import { FilterDropdown } from './FilterDropdown';
import { RecentSimulationsContent } from './RecentSimulationsContent';

import { FilterType } from './utils';

import classes from './styles.module.scss';

export const RecentActivity = ({ color, hasAccessToShare }: { color?: string; hasAccessToShare: boolean }) => {
  const [activitySelected, setActivitySelected] = useState('Recent');
  const [showNumber, setShowNumber] = useState(8);
  const [filterType, setFilterType] = useState<FilterType>(FilterType.All);

  const activityTabOptions = hasAccessToShare
    ? [
        { key: 'Recent', label: 'My recent tasks' },
        { key: 'Shared', label: 'Shared recent tasks' },
      ]
    : [{ key: 'Recent', label: 'My recent tasks' }];
  return (
    <div className={classes.recent_activity_container}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <TabButtonsOutlined
          options={activityTabOptions}
          selectedValue={activitySelected}
          onChange={setActivitySelected}
          color={color}
        />
        <FilterDropdown
          disabled={activitySelected == 'Shared'}
          showNumber={showNumber}
          setShowNumber={setShowNumber}
          type={filterType}
          setType={setFilterType}
        />
      </Stack>
      <RecentSimulationsContent shared={activitySelected == 'Shared'} showNumber={showNumber} filterType={filterType} />
    </div>
  );
};
