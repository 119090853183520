import { useEffect, useState } from 'react';

import { useResultsContext } from '@/components/Results/context/ResultsContext';
import { useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { ResultsContainer } from '../Results/ResultsContainer';
import { ResultsComparisonsPanel } from '../ResultsComparisonPanel';
import { ResultsSubHeader } from '../ResultsSubHeader';
import { Viewport } from '../Viewport/Viewport';
import { FrequencyResponsePopup } from './FrequencyResponsePopup';
import { ModelViewOptions } from './ModelViewOptions';
import { ReceiverDirectionOptions } from './ReceiverDirectionOptions';
import { ViewportInfo } from './ViewportInfo';

import { useChooseResultsView } from '../../hooks/useChooseResultsView';

import { showInvalidSourcesInfo } from './utils';

import { ResultsView } from '@/context/EditorContext/types';

import styles from './styles.module.scss';

export const EditorContent = ({
  showResults,
  sidepanelCollapsed,
}: {
  showResults: boolean;
  sidepanelCollapsed?: boolean;
}) => {
  const { resultsView, userSelectedView3D, sources, taskType, performanceMode, isAuralizerOpen } = useEditorContext();

  const { showFreqResponsePopup, gridReceiverSubView } = useResultsContext();

  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();
  const [prevTaskType, setPrevTaskType] = useState('');
  const [prevSimulation, setPrevSimulation] = useState('');

  const showSubHeader = useChooseResultsView(userSelectedView3D, showResults);

  useEffect(() => {
    // If it's the same simulation and previous tasktype was GA, then trigger showInvalidSourcesInfo()
    if (taskType !== 'GA' && prevTaskType == 'GA' && prevSimulation == selectedSimulation?.id) {
      showInvalidSourcesInfo(sources);
    }
    setPrevTaskType(taskType);
    setPrevSimulation(selectedSimulation?.id || '');
  }, [sources]);

  return (
    <div className={`${styles['editor-main']} ${showResults ? styles['results-mode'] : ''}`}>
      {showSubHeader && <ResultsSubHeader resultsView={resultsView} />}
      <div className={styles['content-and-footer-panel']}>
        <div
          className={styles['content']}
          style={{
            gap:
              resultsView !== ResultsView.ResultsModelView && resultsView !== ResultsView.ResultsReflectogramView
                ? 0
                : undefined,
          }}>
          {showResults && <ResultsContainer view={resultsView} />}
          {/*  We keep the viewport rendered since we don't want to canvas to initialize again when switching views */}
          <div
            style={{
              visibility: resultsView === ResultsView.ResultsReportView ? 'hidden' : undefined,
              flex: resultsView === ResultsView.ResultsReportView ? '0 1 0' : undefined,
            }}
            className={`${styles['viewport-container']}`}>
            <ModelViewOptions showResults={showResults} position={sidepanelCollapsed ? { right: '40px' } : undefined} />

            {gridReceiverSubView === 'modal' && showFreqResponsePopup && <FrequencyResponsePopup />}

            {showResults && resultsView === ResultsView.ResultsReflectogramView && <ReceiverDirectionOptions />}
            {performanceMode && isAuralizerOpen && <ViewportInfo />}
            <Viewport />
          </div>
        </div>
        {showResults && <ResultsComparisonsPanel />}
      </div>
    </div>
  );
};
