import { Tutorial } from './types';

export const COMING_SOON_TUTORIALS: Tutorial[] = [
  {
    id: 'forced-modal-analysis',
    name: 'Forced modal analysis',
    description:
      'Learn how to navigate the forced modal analysis view, where you can see how the chosen sources excite the mode shapes of a space',
    tags: 'Results',
    documentationUrl: '',
    thumbnailUrl: 'images/tutorials/forced-modal-analysis.png',
    isComingSoon: true,
  },
  {
    id: 'sti-analysis-in-treble',
    name: 'STI analysis',
    description: 'Introduction to the STI calculation workflow in Treble',
    tags: 'Results',
    documentationUrl: '',
    thumbnailUrl: 'images/tutorials/sti-analysis.png',
    isComingSoon: true,
  },
  {
    id: 'reflection-tracking',
    name: 'Reflection tracking',
    description:
      'Get familiar with the reflection tracking feature, enabling you to analyze individual reflections in detail',
    tags: 'Results',
    documentationUrl: '',
    thumbnailUrl: 'images/tutorials/reflection-tracking.png',
    isComingSoon: true,
  },
  {
    id: 'sharing-auralization',
    name: 'Sharing an Auralization',
    description: 'Learn how to set up auralizations and share them with external partners',
    tags: 'Results,Auralizations',
    documentationUrl: '',
    thumbnailUrl: 'images/tutorials/auralizer.png',
    isComingSoon: true,
  },
];
