import { IconProps } from '@/types';

export const TrblQuestionMarkIcon = ({ fill = '#dadada', width = '18', height = '19' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0.250977C8.9543 0.250977 0 9.20528 0 20.251C0 31.2967 8.9543 40.251 20 40.251C31.0457 40.251 40 31.2967 40 20.251C39.9881 9.21023 31.0407 0.262918 20 0.250977ZM20 31.9176C18.6193 31.9176 17.5 30.7984 17.5 29.4176C17.5 28.0369 18.6193 26.9176 20 26.9176C21.3807 26.9176 22.5 28.0369 22.5 29.4176C22.5 30.7984 21.3807 31.9176 20 31.9176ZM21.6667 23.3126C21.6663 22.6496 22.059 22.0495 22.6667 21.7843V21.786C25.7183 20.4541 27.3194 17.0749 26.4173 13.8697C25.5153 10.6646 22.3867 8.6165 19.0883 9.07184C15.7899 9.52718 13.3333 12.3463 13.3333 15.676C13.3333 16.5965 14.0795 17.3426 15 17.3426C15.9205 17.3426 16.6667 16.5965 16.6667 15.676C16.6658 14.0109 17.8937 12.6006 19.5431 12.3724C21.1924 12.1441 22.7572 13.168 23.2085 14.7707C23.6599 16.3733 22.8594 18.0633 21.3333 18.7293C19.5109 19.5247 18.333 21.3243 18.3333 23.3126C18.3333 24.2331 19.0795 24.9793 20 24.9793C20.9205 24.9793 21.6667 24.2331 21.6667 23.3126Z"
        fill={fill}
      />
    </g>
  </svg>
);
