import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Organization, UserDto } from './types';

import axios from '@/axios';

export type BillingType =
  | 'Trial'
  | 'EarlyBird'
  | 'Unlimited'
  | 'PrepaidOnly'
  | 'OnDemandOnly'
  | 'Free'
  | 'SinglePremium'
  | 'SingleStandard'
  | 'TeamPremium'
  | 'TeamStandard'
  | 'Enterprise';

export type CreateOrganizationRequest = {
  name: string;
  description: string;
  chargebeeCustomerId: string;
  skipChargebee?: boolean;
  products?: {
    productKey: string;
    billingType: BillingType;
    paymentFrequency: string;
    concurrency: number;
    activeSeats: number;
    maxSeats: number;
    expiresAt?: string;
    sdkMaxCpuConcurrency?: number;
    sdkMaxGpuConcurrency?: number;
    sdkMaxGpuCountPerTask?: number;
  }[];
  initialUser?: UserDto;
};

type CreateOrganizationResponse = Organization;

const createOrganization = async (organization: CreateOrganizationRequest) => {
  const { data } = await axios.post<CreateOrganizationResponse>(`/api/Organization/Create`, organization);
  return data;
};

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: CreateOrganizationRequest) => await createOrganization(data), {
    onSuccess: () => queryClient.invalidateQueries(['organizations']),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      if (error.response?.data.message) toast.error(error.response?.data.message);
      else toast.error(`An error occurred while creating the organization!`);
    },
  });
};
