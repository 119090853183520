import { ReactNode } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { TrblIconButton } from '@/components/Shared/Buttons';
import { LoadingSpinner } from '@/components/Shared/LoadingSpinner';
import { ServiceBanner } from '@/components/Banners';
import { TrblChevronRight } from '@/components/Icons';
import { SubscriptionBanners } from './components';
import { Header } from '../Header/Header';
import { Navpanel } from '../Navpanel/Navpanel';

import styles from './styles.module.scss';

type PageLayout = {
  isFetching?: boolean;
  emptyResults?: boolean;
  extraHeader?: ReactNode;
  navpanel?: boolean;
  sidepanel?: ReactNode;
  sidepanelWidth?: string;
  mainContentMinWidth?: string;
  sidepanelCollapsible?: boolean;
  sidepanelCollapsed?: boolean;
  sidepanelExtraHeader?: ReactNode;
  onCollapseSidepanel?: () => void;
  children: ReactNode;
};

export const PageLayout = ({
  isFetching,
  emptyResults = false,
  extraHeader,
  navpanel,
  sidepanel,
  children,
  sidepanelWidth,
  sidepanelCollapsible = false,
  sidepanelCollapsed = false,
  sidepanelExtraHeader,
  onCollapseSidepanel,
  mainContentMinWidth,
}: PageLayout) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <SubscriptionBanners />
      <ServiceBanner />
      <Header mainContent={extraHeader} sideContent={sidepanelExtraHeader} mainContentMinWidth={mainContentMinWidth} />
      <div className={styles['page-layout']}>
        {navpanel && <Navpanel />}
        <div
          style={{
            minWidth: mainContentMinWidth,
            width: sidepanel ? `calc(100% - 352px)` : undefined,
          }}
          className="page-layout-content">
          {isFetching ? <LoadingSpinner /> : children}
        </div>
        {sidepanel !== undefined && (
          <div
            className={`page-layout-sidepanel ${sidepanelCollapsed ? 'collapsed' : ''}`}
            style={{ width: sidepanelWidth || 'auto' }}>
            {sidepanel}
            {onCollapseSidepanel && !emptyResults && (
              <TrblTooltip title={sidepanelCollapsed ? 'Expand sidepanel' : 'Collapse sidepanel'}>
                <span className="collapse-sidepanel">
                  <TrblIconButton
                    className={`collapse-button ${sidepanelCollapsed ? 'collapsed' : ''} ${
                      sidepanelCollapsible ? 'active' : ''
                    }`}
                    onClick={onCollapseSidepanel}
                    label={sidepanelCollapsed ? 'Expand sidepanel' : 'Collapse sidepanel'}
                    icon={<TrblChevronRight width="11" height="11" fill="#dadada" />}></TrblIconButton>
                </span>
              </TrblTooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
