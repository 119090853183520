import { FC, useEffect, useState } from 'react';

import { useBaseContext } from '@/context/BaseContext';
import { useEditorContext } from '@/context/EditorContext';
import { useModelContext } from '@/context/ModelContext';

import { Box, Stack } from '@mui/material';

import { RadioButtonGroup } from '../Shared/RadioButton';
import { SolverSettingsForm } from './SolverSettingsForm';

import { MAX_STANDARD_MESH_ELEMENTS } from './constants';
import { meshTooBigForLowerLimitMessage, nonWatertightMessage } from '@/utils/constants';

import { computeMeshElementCount } from './utils';

import { PresetTypes } from './types';
import { Simulation } from '@/types';

type SolverSettingsProps = {
  selectedSimulation: Simulation;
};

export type SolverSettingsFormState = {
  transitionFrequency?: number;
  impulseResponseLength: number;
  type: number;
  numberOfRays: number;
  imageSourceOrder: number;
};

export const SolverSettings: FC<SolverSettingsProps> = ({ selectedSimulation }) => {
  const {
    state: { subscriptionInfo },
  } = useBaseContext();
  const { modelInformation, modelVolume } = useModelContext();
  const { readonly } = useEditorContext();
  const [selectedPreset, setSelectedPreset] = useState(
    selectedSimulation.settingsPreset === null ? PresetTypes.Default : selectedSimulation.settingsPreset
  );
  const [meshTooBigForLowerLimit, setmeshTooBigForLowerLimit] = useState(false);
  const isStandardUser = subscriptionInfo.type == 'SingleStandard' || subscriptionInfo.type == 'TeamStandard';

  useEffect(() => {
    if (modelInformation?.nonWatertight) {
      updatePresetForLimitiedSimulationAbilities();
    } else {
      setSelectedPreset(
        selectedSimulation.settingsPreset === null ? PresetTypes.Default : selectedSimulation.settingsPreset
      );
    }
  }, []);

  useEffect(() => {
    if (modelVolume && isStandardUser) {
      const mesh177 = computeMeshElementCount(modelVolume, 177);
      setmeshTooBigForLowerLimit(mesh177 > MAX_STANDARD_MESH_ELEMENTS);
      if (mesh177 > MAX_STANDARD_MESH_ELEMENTS) {
        updatePresetForLimitiedSimulationAbilities();
      }
    }
  }, [modelVolume]);

  const updatePresetForLimitiedSimulationAbilities = () => {
    setSelectedPreset(
      selectedSimulation.settingsPreset === null || selectedSimulation.settingsPreset === PresetTypes.Default
        ? PresetTypes.Advanced
        : selectedSimulation.settingsPreset
    );
  };

  return (
    <Box component="div" padding="15px 20px" height={'100%'}>
      <Stack spacing="15px" height="100%">
        <RadioButtonGroup
          options={[
            {
              text: 'Survey',
              value: PresetTypes.Survey.toString(),
              readonly: readonly && selectedPreset.toString() !== PresetTypes.Survey.toString(),
            },
            {
              text: 'Default',
              value: PresetTypes.Default.toString(),
              disabled: !!modelInformation?.nonWatertight || meshTooBigForLowerLimit,
              readonly: readonly && selectedPreset.toString() !== PresetTypes.Default.toString(),
              tooltip: modelInformation?.nonWatertight
                ? nonWatertightMessage
                : meshTooBigForLowerLimit
                ? meshTooBigForLowerLimitMessage
                : '',
            },
            {
              text: 'Advanced',
              value: PresetTypes.Advanced.toString(),
              readonly: readonly && selectedPreset.toString() !== PresetTypes.Advanced.toString(),
            },
          ]}
          selectedValue={selectedPreset.toString()}
          onChange={(value) => setSelectedPreset(Number(value))}
          horizontal={true}
        />

        <SolverSettingsForm
          presetType={selectedPreset}
          selectedSimulation={selectedSimulation}
          readonly={readonly}
          isStandardUser={isStandardUser}
        />
      </Stack>
    </Box>
  );
};
