import { FC } from 'react';

import { TrblSearchIcon } from '@/components/Icons';

import style from './styles.module.scss';

type SearchInputProps = {
  value: string;
  className?: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

export const SearchInput: FC<SearchInputProps> = ({ value, className, onChange, placeholder }) => {
  return (
    <div className={style.search_input_wrapper}>
      <span className={style.search_icon}>
        <TrblSearchIcon />
      </span>
      <input
        className={className}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
