import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionType, useAppContext } from '@/context/AppContext';
import { useBaseContext } from '@/context/BaseContext';

import { Box, Tooltip } from '@mui/material';

import { TrblToggle, TrblTooltip } from '@/components/Shared';
import { SecondaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { ShareDisabledInformation } from '@/components/Shared/ShareDisabledInformation';
import { TrblDeleteIcon } from '@/components/Icons';
import { DeleteItemPopup } from '@/components/LibraryPanel/components/DeleteItemPopup';
import { RevokeSharedPopup } from '@/components/LibraryPanel/components/RevokeSharedPopup';
import { ActionType as LibActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { FrequenciesTable } from '@/components/SourceDefinition/FrequenciesTable';
import { SourceDefinitionDetails } from '@/components/SourceDefinition/SourceDefinitionDetails';
import { SourceDefinitionFooter } from '@/components/SourceDefinition/SourceDefinitionFooter';
import { SourceDefinitionPlots } from '@/components/SourceDefinition/SourceDefinitionPlots';
import { SourceDefinitionWarning } from '@/components/SourceDefinition/SourceDefinitionWarning';
import { TableDivider } from '@/components/SourceDefinition/TableDivider';
import { SourceDefinitionJson } from '@/components/SourceDefinition/types';

import { useOnDeleteSourceDefinition } from '../../hooks/useOnDeleteSourceDefinition';
import { useRevokeShareSourceDefinition, useShareSourceDefinition } from '@/hooks';

import { SourceDefinition } from '@/types';

import styles from '@/components/SourceRecieverSettings/SourceSettings/styles.module.scss';

type SourceDefinitionPopupProps = {
  sourceDefinitionId: string;
  sourceDefinitionJson: SourceDefinitionJson | null;
  setShowPopup: (show: boolean) => void;
};

export const SourceDefinitionPopup: FC<SourceDefinitionPopupProps> = ({
  sourceDefinitionId,
  sourceDefinitionJson,
  setShowPopup,
}) => {
  const [sourceDefinition, setSourceDefinition] = useState<SourceDefinition | undefined>(undefined);
  const [isPatternShared, setIsPatternShared] = useState(false);
  const [showRevokePatternPopup, setShowRevokePatternPopup] = useState(false);

  const { mutate: shareSourceDefinition } = useShareSourceDefinition();
  const { mutate: revokeShareSourceDefinition } = useRevokeShareSourceDefinition();

  const {
    state: { userInfo, subscriptionInfo },
  } = useBaseContext();
  const {
    appState: { filteredSourceDefinitions },
    dispatch,
  } = useAppContext();
  const { highlightedItemId, dispatch: libDispatch } = useLibraryPanelContext();

  useEffect(() => {
    setIsPatternShared(sourceDefinition?.isSharedWithOrganization ?? false);
  }, [sourceDefinition]);

  useEffect(() => {
    if (sourceDefinitionId) {
      const selectedSourceDef = filteredSourceDefinitions.find((sourceDef) => sourceDef.id === sourceDefinitionId);
      setSourceDefinition(selectedSourceDef);
    }
  }, [sourceDefinitionId]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<undefined | boolean>(undefined);

  const deleteSourceDefinition = useOnDeleteSourceDefinition();

  const onConfirmDelete = async () => {
    if (sourceDefinition) {
      setShowDeleteConfirmation(false);
      await deleteSourceDefinition(sourceDefinition.id, sourceDefinition.name);
      setShowPopup(false);
    }
  };

  const toggleShareSourceDefinition = () => {
    const newValue = !isPatternShared;
    if (newValue) {
      sharePatternFunc();
    } else {
      setShowRevokePatternPopup(true);
    }
  };

  const sharePatternFunc = () => {
    if (!sourceDefinitionId) return;
    shareSourceDefinition(sourceDefinitionId, {
      onSuccess: (updatedSourceDefinition) => {
        setIsPatternShared(true);
        updatePatterns(updatedSourceDefinition);
        toast.info("'" + updatedSourceDefinition.name + "' has been shared with your organization!");
      },
      onError: () => {
        toast.error('Source definition could not be shared');
      },
    });
  };

  const revokeShared = () => {
    if (!sourceDefinitionId) return;
    revokeShareSourceDefinition(sourceDefinitionId, {
      onSuccess: (updatedSourceDefinition) => {
        setShowRevokePatternPopup(false);
        setIsPatternShared(false);
        updatePatterns(updatedSourceDefinition);
        toast.info("'" + updatedSourceDefinition.name + "' is no longer shared with your organization.");
      },
      onError: () => {
        setShowRevokePatternPopup(false);
        toast.error('Sharing of source definition could not be revoked');
      },
    });
  };

  const updatePatterns = (updatedSourceDefinition: SourceDefinition) => {
    const sourceDefinitionIndex = filteredSourceDefinitions.findIndex(
      (filteredSourceDefinition) => filteredSourceDefinition.id === updatedSourceDefinition.id
    );
    const newSourceDefinitions = [...filteredSourceDefinitions];
    newSourceDefinitions[sourceDefinitionIndex] = updatedSourceDefinition;
    dispatch({
      type: ActionType.SET_SOURCE_DEFINITIONS,
      payload: newSourceDefinitions,
    });
    if (updatedSourceDefinition.id === highlightedItemId) {
      libDispatch({
        type: LibActionType.SET_HIGHLIGHTED_ITEM,
        highlightedItemId: updatedSourceDefinition.id,
      });
    }
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      <TrblPopup
        aria-labelledby={sourceDefinition?.name}
        width={1120}
        minheight={450}
        open={true}
        onClose={handleClose}>
        <TrblPopupTitle onClose={handleClose}>Source definition details</TrblPopupTitle>
        <TrblPopupContent>
          <Box component={'div'} display={'inline-flex'} width={'100%'}>
            <div className={styles['box-left']}>
              <div>
                {sourceDefinition && <SourceDefinitionDetails sd={sourceDefinition} />}
                <TableDivider />
                {sourceDefinitionJson && <FrequenciesTable sourceDefinitionJson={sourceDefinitionJson} />}
              </div>
              <SourceDefinitionFooter sourceDefinition={sourceDefinition} />
            </div>
            <div className={styles['box-right']}>
              {sourceDefinitionJson && <SourceDefinitionPlots sourceDefinitionJson={sourceDefinitionJson} />}
            </div>
          </Box>
        </TrblPopupContent>
        <TrblPopupActions framed>
          {sourceDefinition?.isUserCreated &&
            (sourceDefinition.userId === userInfo?.id || (isPatternShared && userInfo?.roles.includes('Admin'))) && (
              <TrblTooltip
                title={
                  !subscriptionInfo?.hasAccessToShare ? <ShareDisabledInformation type="source definitions" /> : null
                }>
                <div className={styles['share-source-action']}>
                  <TrblToggle
                    checked={isPatternShared}
                    ariaLabel="Share source definition toggle"
                    onChangeToggle={toggleShareSourceDefinition}
                    disabled={!subscriptionInfo?.hasAccessToShare}
                  />
                  <span
                    className={
                      !subscriptionInfo?.hasAccessToShare ? styles['share-text-disabled'] : styles['share-text']
                    }>
                    {' '}
                    Share with organization{' '}
                  </span>
                </div>
              </TrblTooltip>
            )}
          <SourceDefinitionWarning />
          {sourceDefinition?.isUserCreated && sourceDefinition.userId === userInfo?.id && (
            <div>
              <Tooltip title="Delete source definition">
                <span>
                  <SecondaryButton
                    label=""
                    icon={<TrblDeleteIcon />}
                    onClick={() => setShowDeleteConfirmation(true)}></SecondaryButton>
                </span>
              </Tooltip>
            </div>
          )}
        </TrblPopupActions>
      </TrblPopup>
      {showDeleteConfirmation && (
        <DeleteItemPopup
          onCancelDelete={() => setShowDeleteConfirmation(false)}
          onConfirmDelete={onConfirmDelete}
          type="Source definition"
          name={sourceDefinition ? sourceDefinition.name : ''}
        />
      )}
      {showRevokePatternPopup && (
        <RevokeSharedPopup
          item="Source definition"
          message={
            sourceDefinition?.userId !== userInfo?.id
              ? 'This source definition will no longer be accessible for you or other users and you will not be able to re-run simulations using this source definition.'
              : undefined
          }
          onConfirm={revokeShared}
          onCancel={() => setShowRevokePatternPopup(false)}
        />
      )}
    </>
  );
};
