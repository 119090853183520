import { useEffect, useState } from 'react';

import { TabButtons } from '@/components/Shared/TabButtons';
import { MaterialDetailsPlot } from './MaterialDetailsPlot';

import { FREQUENCY_RANGE_FULL_OCTAVE, GREEN_PLOT_COLOR, ORANGE_PLOT_COLOR } from '../CreateMaterial/constants';
import {
  FREQ_LABELS,
  IMPEDANCE_PLOT_TITLE_SIMPLE,
  REF_PLOT_TITLE,
  REF_RANGE,
  REF_TITLE,
  REF_X_VALS,
  SURFACE_TITLE,
  SURFACE_TITLE_SPECIFIC,
  TICK_VALS,
  TOGGLE_MATERIAL_REFLECTION_PLOT,
} from './constants';

import styles from './styles.module.scss';

const TAB_OPTIONS = [
  { key: 'Reflection', label: 'R', tooltip: TOGGLE_MATERIAL_REFLECTION_PLOT[0] },
  { key: 'Impedance', label: 'Z', tooltip: TOGGLE_MATERIAL_REFLECTION_PLOT[1] },
];

export const ImpedanceAndReflectionPlots = ({
  showTabs,
  resultRealReflection,
  targetRealReflection = [],
  resultImagReflection,
  targetImagReflection = [],
  impedanceFirst = false,
  resultRealImpedance = [],
  targetRealImpedance = [],
  resultImagImpedance = [],
  targetImagImpedance = [],
  showTitle = false,
  isSpecific = null,
  plotHeight = 344,
  plotWidth = 400,
  impedanceLabel = IMPEDANCE_PLOT_TITLE_SIMPLE,
  plotTitles = ['Target real', 'Target imag', 'Result real', 'Result imag'],
}: {
  showTabs: boolean;
  resultRealReflection: number[];
  targetRealReflection?: number[];
  resultImagReflection: number[];
  targetImagReflection?: number[];
  impedanceFirst?: boolean;
  resultRealImpedance?: number[];
  targetRealImpedance?: number[];
  resultImagImpedance?: number[];
  targetImagImpedance?: number[];
  showTitle?: boolean;
  isSpecific?: boolean | null;
  plotHeight?: number;
  plotWidth?: number;
  impedanceLabel?: string;
  plotTitles?: string[];
}) => {
  const [selectedPlot, setSelectedPlot] = useState(impedanceFirst ? 'Impedance' : 'Reflection');
  const [tabOptions, setTabOptions] = useState(TAB_OPTIONS);

  useEffect(() => {
    if (impedanceFirst) {
      setTabOptions([...TAB_OPTIONS].reverse());
    }
  }, [impedanceFirst]);

  return (
    <div className={styles['tabs-buttons-parent']}>
      {showTitle && selectedPlot === 'Reflection' && (
        <p className={styles['plot-title']}>{selectedPlot === 'Reflection' ? REF_TITLE : SURFACE_TITLE_SPECIFIC}</p>
      )}
      {showTitle && selectedPlot === 'Impedance' && (
        <p className={styles['plot-title']}>{isSpecific ? SURFACE_TITLE_SPECIFIC : SURFACE_TITLE}</p>
      )}
      {showTabs && (
        <div className={`${styles['tabs-buttons-container']} ${showTitle ? '' : styles['close']}`}>
          <TabButtons
            options={tabOptions}
            selectedValue={selectedPlot}
            onChange={(value) => setSelectedPlot(value)}
            small
            dark
          />
        </div>
      )}
      {selectedPlot === 'Reflection' ? (
        <MaterialDetailsPlot
          ticktext={FREQUENCY_RANGE_FULL_OCTAVE}
          range={REF_RANGE}
          tickvals={TICK_VALS}
          xData={REF_X_VALS}
          plots={[targetRealReflection, targetImagReflection, resultRealReflection, resultImagReflection]}
          plotTitles={plotTitles}
          plotColors={[ORANGE_PLOT_COLOR, ORANGE_PLOT_COLOR, GREEN_PLOT_COLOR, GREEN_PLOT_COLOR]}
          plotMarker={['circle', 'cross', 'circle', 'cross']}
          plotDashed={[false, false, true, true]}
          yAxesTitle={REF_PLOT_TITLE}
          plotHeight={plotHeight}
          plotWidth={plotWidth}
        />
      ) : (
        <MaterialDetailsPlot
          ticktext={FREQ_LABELS}
          tickvals={TICK_VALS}
          xData={REF_X_VALS}
          plots={[targetRealImpedance, targetImagImpedance, resultRealImpedance, resultImagImpedance]}
          plotTitles={plotTitles}
          plotColors={[ORANGE_PLOT_COLOR, ORANGE_PLOT_COLOR, GREEN_PLOT_COLOR, GREEN_PLOT_COLOR]}
          plotMarker={['circle', 'cross', 'circle', 'cross']}
          plotDashed={[false, false, true, true]}
          yAxesTitle={impedanceLabel}
          plotHeight={plotHeight}
          plotWidth={plotWidth}
        />
      )}
    </div>
  );
};
