import { Divider, Stack } from '@mui/material';

import { RadioButtonGroup } from '@/components/Shared/RadioButton';
import { TrblFilterListIcon } from '@/components/Icons';
import { TrblCustomSelect } from '@/components/New/TrblCustomSelect';
import { SortButtons } from './SortButtons';

import { FilterType, ListType, SortType } from '../constants';

import classes from './styles.module.scss';

type SimulationFilterMenuProps = {
  sortListBy: SortType;
  filterType: FilterType;
  listType: ListType;
  setSortListBy: (value: SortType) => void;
  setFilterType: (type: FilterType) => void;
};

export const SimulationFilterMenu = ({
  sortListBy,
  filterType,
  listType,
  setSortListBy,
  setFilterType,
}: SimulationFilterMenuProps) => {
  const sortOptions = [
    { label: 'Name', type: SortType.NAME },
    { label: 'Modified time', type: SortType.MODIFIED, disabled: listType === ListType.GEOMETRIES },
    { label: 'Created time', type: SortType.CREATED },
  ];

  return (
    <div className={classes.filter_dropdown}>
      <TrblCustomSelect
        selectedItem={
          <div className={classes.filter_title}>
            <TrblFilterListIcon width="14" height="14" fill="#adadad" />
          </div>
        }
        dropdownWidth="200px">
        <div className={`${classes.filter_dropdown_content} filter-dropdown`}>
          <p className={classes.filter_label}>Sort by</p>
          <SortButtons sortListBy={sortListBy} setSortListBy={setSortListBy} sortOptions={sortOptions} />
          {listType === ListType.SIMULATIONS && (
            <>
              <span style={{ display: 'block' }}>
                <Divider />
              </span>
              <Stack>
                <p className={`${classes.filter_label} ${classes.extra_padding}`}>Show simulations</p>
                <div className={classes.radio_button_group}>
                  <RadioButtonGroup
                    options={[
                      {
                        text: 'in Space',
                        value: 'space',
                      },
                      {
                        text: 'in current geometry',
                        value: 'geometry',
                      },
                    ]}
                    selectedValue={filterType}
                    onChange={(selectedValue) => setFilterType(selectedValue as FilterType)}
                    justifyContent="star"
                    noSelectedColor
                  />
                </div>
              </Stack>
            </>
          )}
        </div>
      </TrblCustomSelect>
    </div>
  );
};
