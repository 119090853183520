import { useMemo, useState } from 'react';

import { BaseType } from '@/types';

export const useSourceSearch = (sources: BaseType[]) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredSourceIds = useMemo(() => {
    return searchQuery.trim() === ''
      ? sources.map((item) => item.id)
      : sources.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase())).map((item) => item.id);
  }, [sources, searchQuery]);

  return {
    searchQuery,
    setSearchQuery,
    filteredSourceIds,
  };
};
