import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Data, PlotMouseEvent } from 'plotly.js';

import { ActionType, useResultsContext } from '@/components/Results/context/ResultsContext';

import { LAYOUT_CONFIG } from './constants';

import styles from '../styles.module.scss';

export const FreqResponsePlot = ({
  yData,
  xData,
  selectedFrequency,
}: {
  yData: number[];
  xData: number[];
  selectedFrequency: number;
}) => {
  const { dispatch } = useResultsContext();
  const [plotData, setPlotData] = useState<Data[]>([]);

  useEffect(() => {
    if (xData && xData.length > 0 && yData && yData.length > 0) {
      const plotData = [
        {
          x: xData,
          y: yData,
          mode: 'lines',
          line: { color: '#4870ff' },
        },
      ];
      setPlotData(plotData);
    } else {
      setPlotData([]);
    }
  }, [yData, xData]);

  const handleClick = (event: PlotMouseEvent) => {
    dispatch({
      type: ActionType.SET_IS_PLAYING_MODAL_ANALYSIS,
      isPlayingModalAnalysis: false,
    });
    if (event?.points) {
      const clickedPoint = event.points[0];
      if (clickedPoint.x) {
        dispatch({
          type: ActionType.SET_MODAL_FREQUENCY_SELECTED,
          modalFrequencySelected: clickedPoint.x as number,
        });
      }
    }
  };

  return (
    <div className={styles['plot-container']}>
      {plotData.length > 0 ? (
        <AutoSizer>
          {({ height, width }) => (
            <Plot
              data={plotData}
              layout={{
                ...LAYOUT_CONFIG,
                xaxis: {
                  ...LAYOUT_CONFIG.xaxis,
                  // yData length is the number of points in the frequency response
                  // but since we start counting at 20 (or later) we have to add that to the length
                  range: [Math.log10(20), Math.log10(yData.length + xData[0] - 1)],
                },
                yaxis: {
                  ...LAYOUT_CONFIG.yaxis,
                  hoverformat: '.1f',
                },
                shapes: selectedFrequency
                  ? [
                      {
                        type: 'line',
                        x0: selectedFrequency,
                        y0: 0,
                        x1: selectedFrequency,
                        y1: 1,
                        xref: 'x',
                        yref: 'paper',
                        line: {
                          color: '#B8C7FF',
                          width: 2,
                        },
                      },
                    ]
                  : undefined,
              }}
              style={{ width, height }}
              config={{ displaylogo: false, responsive: true, displayModeBar: false }}
              useResizeHandler={true}
              onClick={handleClick} // Attach the click handler
            />
          )}
        </AutoSizer>
      ) : (
        <div className={styles['text-container']}>
          <p> Select grid square in the heatmap to see frequency response</p>
        </div>
      )}
    </div>
  );
};
