import { useEffect, useState } from 'react';

import { GeometryImportProvider } from '@/context/GeometryImportContext';

import { CircularProgress } from '@mui/material';

import { MultiSpaceImportPopup } from '@/components/MultiSpaceImport';
import { RecentTasksList } from './RecentTasksList';

import { useRecentTasks } from './hooks';
import {
  useGetLatestSpaceExtractionTasks,
  useGetSimulationRunStatusesByUser,
  useGetSimulationRunStatusesFromSharedProjects,
} from '@/hooks';
import { useGetEstimatedQueueTimesForQueuedSimulations } from '@/hooks/SimulationRun/useGetEstimatedQueueTimesForQueuedSimulations';

import { FilterType } from './utils';

import { RunStatus } from '@/types';

import classes from './styles.module.scss';

export const RecentSimulationsContent = ({
  shared,
  showNumber,
  filterType,
}: {
  shared?: boolean;
  showNumber: number;
  filterType: FilterType;
}) => {
  const {
    data: recentSimulations,
    isLoading: isFetchingTaskStatuses,
    refetch: refetchSimulations,
  } = useGetSimulationRunStatusesByUser(showNumber, !shared);

  const {
    data: recentSharedSimulations,
    isLoading: isFetchingSharedTaskStatuses,
    refetch: refetchSharedSimulations,
  } = useGetSimulationRunStatusesFromSharedProjects(showNumber, shared);
  const { data: ifcImportTasks, refetch: refetchImportTasks } = useGetLatestSpaceExtractionTasks(showNumber, !shared);

  const { data: estimatedQueueTimes = [] } = useGetEstimatedQueueTimesForQueuedSimulations(
    recentSimulations?.some((sim) => sim.status === RunStatus.Queued)
  );

  const [showModelImportPopup, setShowModelImportPopup] = useState(false);
  const [geometryChosen, setGeometryChosen] = useState<{ id: string; filename: string } | null>(null);

  const recentTasks = useRecentTasks(
    recentSimulations,
    ifcImportTasks,
    recentSharedSimulations,
    filterType,
    shared,
    showNumber
  );

  useEffect(() => {
    if (shared) {
      refetchSharedSimulations();
    } else {
      refetchSimulations();
      refetchImportTasks();
    }
  }, [showNumber]);

  const openGeometryImport = (id: string, filename: string) => {
    setGeometryChosen({ id, filename });
    setShowModelImportPopup(true);
  };

  const isLoading = (!shared && isFetchingTaskStatuses) || (shared && isFetchingSharedTaskStatuses);

  return (
    <div className={classes.recent_simulations_container}>
      {isLoading ? (
        <div className={classes.loading_container}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.recent_simulations_list}>
          {recentTasks && (
            <RecentTasksList
              recentTasks={recentTasks}
              queuedTasks={estimatedQueueTimes ?? []}
              onGeometryImport={openGeometryImport}
            />
          )}
          {showModelImportPopup && geometryChosen && (
            <GeometryImportProvider>
              <MultiSpaceImportPopup
                setShowPopup={setShowModelImportPopup}
                uploadModelDone={() => {}}
                geometryImportChosen={geometryChosen}
              />
            </GeometryImportProvider>
          )}
        </div>
      )}
    </div>
  );
};
