export enum ParameterKeys {
  EDT = 'edt',
  T20 = 't20',
  T30 = 't30',
  C50 = 'c50',
  C80 = 'c80',
  D50 = 'd50',
  TS = 'ts',
  G = 'g',
  SPL = 'spl',
  STI = 'sti',
  OOP = 'oop',
  STI_USING_SPL = 'sti_using_spl',
}

export enum ParameterNames {
  EDT = 'edt',
  T20 = 't20',
  T30 = 't30',
  C50 = 'c50',
  C80 = 'c80',
  D50 = 'd50',
  TS = 'ts',
  G = 'g',
  SPL = 'spl',
  STI = 'sti',
  OOP = 'oop',
  STI_USING_SPL = 'sti using spl',
}

export enum SplKeys {
  SPL_A = 'spl_a',
  SPL_C = 'spl_c',
  SPL_Z = 'spl_z',
}

export const AVAILABLE_PARAMETER_KEYS = {
  [ParameterKeys.EDT]: { key: ParameterKeys.EDT, name: ParameterNames.EDT },
  [ParameterKeys.T20]: { key: ParameterKeys.T20, name: ParameterNames.T20 },
  [ParameterKeys.T30]: { key: ParameterKeys.T30, name: ParameterNames.T30 },
  [ParameterKeys.C50]: { key: ParameterKeys.C50, name: ParameterNames.C50 },
  [ParameterKeys.C80]: { key: ParameterKeys.C80, name: ParameterNames.C80 },
  [ParameterKeys.D50]: { key: ParameterKeys.D50, name: ParameterNames.D50 },
  [ParameterKeys.TS]: { key: ParameterKeys.TS, name: ParameterNames.TS },
  [ParameterKeys.G]: { key: ParameterKeys.G, name: ParameterNames.G },
  [ParameterKeys.SPL]: { key: ParameterKeys.SPL, name: ParameterNames.SPL },
  [ParameterKeys.STI]: { key: ParameterKeys.STI, name: ParameterNames.STI },
};

export const SPEECH_LABEL = 'Speech spectrum - ISO 3382-3';

export const STI_DISABLED_TEXT_OMNI = 'This option is not available when a source is not an Omni source';
export const STI_DISABLED_TEXT = 'This option is not available when a source is a summed source';
