import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Backdrop } from '@mui/material';

import { PeriodToggle } from './components/PeriodToggle';
import { SubscriptionOption } from './components/SubscriptionOption';
import { LoadingSpinner } from '../Shared/LoadingSpinner';

import { useWindowSize } from '@/hooks';

import { useChargebee } from './utils/useChargebee';

import { SubscriptionLength, SubscriptionType } from './types';

import styles from './styles.module.scss';

export const Subscriptions = ({ subscriptionSelected }: { subscriptionSelected: SubscriptionType | null }) => {
  const windowSize = useWindowSize();
  const [searchParams] = useSearchParams();
  const childContainer = useRef<HTMLDivElement | null>(null);
  const [stubHeight, setStubHeight] = useState(0);
  const [monthlyPeriod, setMonthlyPeriod] = useState(false);

  const onChargebeeLoaded = () => {
    const subscriptionType = searchParams.get('type');
    const subscriptionPeriod = searchParams.get('period');

    // Check if the user has not yet subscribed (trial or earlyBird)
    // and then check if there are query parameters that should trigger the subscription popup
    if (
      subscriptionSelected &&
      ['Trial', 'EarlyBird'].includes(subscriptionSelected) &&
      subscriptionType &&
      subscriptionPeriod &&
      ['flexible', 'unlimited', 'singlestandard', 'singlepremium'].includes(subscriptionType) &&
      ['yearly', 'monthly'].includes(subscriptionPeriod)
    ) {
      setMonthlyPeriod(subscriptionPeriod === 'monthly');
      subscribe(subscriptionType as SubscriptionType, subscriptionPeriod as SubscriptionLength);
    }
  };

  const { subscribe, isSubscriptionPending } = useChargebee(onChargebeeLoaded);

  useEffect(() => {
    if (childContainer.current) {
      const height = childContainer.current.clientHeight;
      setStubHeight(height);
    }
  }, [childContainer, windowSize]);

  return (
    <>
      {/* We need to make the parent element take the height of the child element to enable scroll on the page for small screens */}
      <div style={{ height: stubHeight }} className={styles['subscription-container-stub']}></div>
      <div ref={childContainer} className={styles['subscription-container']}>
        <div className={styles['toggle-container']}>
          <PeriodToggle
            checked={monthlyPeriod}
            label={'Subscription period toggle'}
            onChangeToggle={() => setMonthlyPeriod(!monthlyPeriod)}
          />
        </div>
        <div className={styles['subscription-items']}>
          <SubscriptionOption
            type="singlestandard"
            yearlyPeriod={!monthlyPeriod}
            selectSubscription={subscribe}
            selectedSubscription={subscriptionSelected}
          />
          <SubscriptionOption
            type="singlepremium"
            yearlyPeriod={!monthlyPeriod}
            selectSubscription={subscribe}
            selectedSubscription={subscriptionSelected}
          />
          <SubscriptionOption
            type="teams"
            yearlyPeriod={!monthlyPeriod}
            selectSubscription={subscribe}
            selectedSubscription={subscriptionSelected}
          />
          <SubscriptionOption
            type="enterprise"
            yearlyPeriod={!monthlyPeriod}
            selectSubscription={subscribe}
            selectedSubscription={subscriptionSelected}
          />
        </div>
        <br />
        <p>
          *Wave solver frequency range: In the ‘Standard' tier, the frequency range of the wave solver is capped at 355
          Hz or 250k mesh elements, whichever limit is hit first. 250k mesh elements allows for simulating ~6,000 m3
          space up to 355 Hz or ~50,000 m3 space up to 177 Hz. In the ‘Premium' tier, the wave solver can be run up to
          2840 Hz or 30 million mesh elements, whichever limit is hit first. 30 million mesh elements allows for
          simulating ~100,000 m3 space up to 710 Hz or ~6,500,000 m3 space up to 177 Hz. The geometrical acoustics
          solver for the entire frequency range is included in all subscription tiers.
        </p>
        <br />
        <p>
          **Concurrent simulations: In the 'Standard' tier, simulation jobs are executed sequentially, but in the
          'Premium' tier, multiple simulation jobs can be run simultaneously. One "simulation job" refers to the
          simulation of one source in the 3D model. If the concurrency limit is hit, jobs are queued and executed as
          cloud computing resources become available.
        </p>
        <br />
        <p>
          ***Multi-GPU acceleration: Unlocks significantly shorter simulation times for the wave solver by using up to 4
          GPUs per simulation job (optimal GPU count for a given simulation job is selected automatically by the
          software).
        </p>
      </div>
      <Backdrop sx={{ backdropFilter: 'blur(3px)', zIndex: 100 }} open={isSubscriptionPending}>
        <LoadingSpinner />
      </Backdrop>
    </>
  );
};
