import { useEffect } from 'react';

import { ActionType as EdActionType, useEditorContext } from '@/context/EditorContext';
import { useModelContext } from '@/context/ModelContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { getAudioSettings } from '@/components/AuralizerStandalone/utils/getAudioSettings';
import { getFirstSimNormMax } from '@/components/AuralizerStandalone/utils/getFirstSimNormMax';
import { AudioEngine } from '../AudioEngine';
import { ActionType, useAuralizerContext } from '../AuralizerContext';
import { useCreateGainNodes } from './useCreateGainNodes';
import { useGetURLsFromSimID } from './useGetURLsFromSimId';
import { useSetupAllAudioNodesForSim } from './useSetupAllAudioNodesForSim';

import { useGetSimulationsBySpaceId } from '@/hooks';

import { MAX_PRELOAD_SOURCE_RECEIVER_PAIRS } from '../constants';

import { createAllSimsAurObject, createAllSimSrcRecHash, getSimFromLastSimRun, getSourceList } from '../utils';

import { AllSimsAurObject, AudioSettings } from '../types';

export const useInitializeAuralizer = () => {
  const { dispatch, simSrcRecHash, recUrls, audioNodesDict, fetching, hasInitialized } = useAuralizerContext();
  const { modelInformation } = useModelContext();

  const {
    simulationState: { originalSim },
  } = useSimulationContext();

  const { dispatch: edDispatch, performanceMode } = useEditorContext();

  const audioEngine = AudioEngine.getInstance();

  const { data: urlsForSimId } = useGetURLsFromSimID(originalSim?.id ?? '', originalSim?.taskType ?? '');

  // fetch all simulations in a space
  const { data: simulationsFromSpace } = useGetSimulationsBySpaceId(modelInformation?.spaceId || '');

  const createGainNodes = useCreateGainNodes();
  const setupAllAudioNodesForSim = useSetupAllAudioNodesForSim();

  useEffect(() => {
    if (
      hasInitialized &&
      urlsForSimId &&
      originalSim &&
      simSrcRecHash &&
      Object.keys(simSrcRecHash).length > 0 &&
      // eslint-disable-next-line no-prototype-builtins
      !recUrls?.hasOwnProperty(originalSim.id)
    ) {
      let newAudioSettings: AudioSettings = {
        ...audioEngine.audioSettings,
        [originalSim.id]: getAudioSettings(urlsForSimId, simSrcRecHash, originalSim.id, originalSim.id),
      };

      newAudioSettings = getFirstSimNormMax(newAudioSettings, originalSim.id);
      audioEngine.audioSettings = newAudioSettings;
      dispatch({ type: ActionType.SET_REC_URLS, recUrls: { [originalSim.id]: urlsForSimId } });
      if (!performanceMode) {
        setupAllAudioNodesForSim(originalSim, originalSim.sources, urlsForSimId);
      }
    }
  }, [urlsForSimId, originalSim, simSrcRecHash, recUrls, hasInitialized]);

  useEffect(() => {
    if (fetching && audioNodesDict) {
      const simStillFetching = Object.keys(fetching).filter((key) => fetching[key].isFetching);

      if (simStillFetching.length > 0) {
        simStillFetching.forEach((simId) => {
          // eslint-disable-next-line no-prototype-builtins
          if (audioNodesDict.hasOwnProperty(simId)) {
            dispatch({
              type: ActionType.SET_FETCHING,
              isFetching: false,
              simId: simId,
            });
          }
        });
      }
    }
  }, [audioNodesDict, fetching]);

  useEffect(() => {
    // initialize the Auralizer whenever the route changes, the route changes only
    // when the original simulation changes, not when we switch between simulations
    if (originalSim) {
      const newSelectedSim = getSimFromLastSimRun(originalSim);
      const sourceIdList = getSourceList(newSelectedSim);

      audioEngine.originalSourceIdArray = sourceIdList;
      audioEngine.onLoad(sourceIdList);
      createGainNodes(sourceIdList);

      dispatch({
        type: ActionType.SET_INITIAL_AUR_SIM,
        simulation: newSelectedSim,
      });

      edDispatch({
        type: EdActionType.SET_IS_CAMERA_INSIDE_MODEL,
        payload: false,
      });

      if (originalSim?.sources.length) {
        const shouldEnhancePerformance =
          originalSim?.sources.length * originalSim?.receivers.length > MAX_PRELOAD_SOURCE_RECEIVER_PAIRS;
        edDispatch({ type: EdActionType.SET_PERFORMANCE_MODE, performanceMode: shouldEnhancePerformance });
      }
    }
  }, [originalSim]);

  useEffect(() => {
    if (modelInformation) {
      // reset srcRecHash when modelInfo changes
      dispatch({ type: ActionType.SET_SIM_SRC_REC_HASH, simSrcRecHash: null });
    }
  }, [modelInformation]);

  useEffect(() => {
    if (simulationsFromSpace) {
      dispatch({
        type: ActionType.SET_AVAILABLE_SIMS_TO_COMPARE,
        allSimulations: simulationsFromSpace,
      });

      // reset srcRecHash and aurSim if the original simulation changes
      if (originalSim && simulationsFromSpace.length > 0) {
        const allSimulationsInSpace = [...simulationsFromSpace].flatMap((space) => space.simulations);
        // create the SimSrcRecHash object that we use to filter out simulations we can not compare
        const simSrcRecHash = createAllSimSrcRecHash(allSimulationsInSpace);
        const simsAurObject: AllSimsAurObject = createAllSimsAurObject(allSimulationsInSpace);
        dispatch({ type: ActionType.SET_SIM_SRC_REC_HASH, simSrcRecHash });
        dispatch({ type: ActionType.SET_AUR_SIMS_OBJECT, simsAurObject });
        dispatch({
          type: ActionType.SET_HAS_INITIALIZED,
          hasInitialized: true,
        });
      }
    }
  }, [originalSim, simulationsFromSpace]);
};
