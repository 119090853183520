import { useEffect, useMemo, useState } from 'react';

import { useBaseContext } from '@/context/BaseContext';

import { Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { PrimaryButton } from '@/components/Shared/Buttons';
import { TrblAddIcon, TrblFolderIcon, TrblFolderSharedIcon } from '@/components/Icons';
import { PageLayout } from '@/components/New/PageLayout';
import { CreateProject } from '../CreateProject';
import { PageHeader } from '../PageHeader/PageHeader';
import { ProjectsCardView } from './ProjectsCardView';
import { ProjectsListView } from './ProjectsListView';

import { useLocalStoragePreference } from '@/hooks/Custom';
import { useGetProjectsAndSpaces } from '@/hooks/Project/useGetProjectsAndSpaces';

import { VIEW_TYPES } from '../PageHeader/constants';
import { VIEW_TYPE_LOCAL_STORAGE_KEY_PREFIX } from './constants';

import classes from './styles.module.scss';

export type ProjectFilter = 'Owned' | 'Shared';

export const ProjectsPage = ({ projectFilter }: { projectFilter: ProjectFilter }) => {
  const {
    state: { userInfo, subscriptionInfo },
  } = useBaseContext();

  const { data: availableProjects, isLoading } = useGetProjectsAndSpaces(projectFilter);

  const [projectSearchString, setProjectSearchString] = useState('');
  const [showCreateProjectPopup, setShowCreateProjectPopup] = useState<undefined | boolean>(undefined);
  const [viewType, setViewType, isLoadingSettings] = useLocalStoragePreference(
    VIEW_TYPE_LOCAL_STORAGE_KEY_PREFIX,
    userInfo?.id,
    VIEW_TYPES.CARDS
  );
  // check if Shared with me is chosen and user does not have acccess
  const sharedProjectsWithNoAccess = projectFilter == 'Shared' && !subscriptionInfo.hasAccessToShare;

  useEffect(() => {
    setProjectSearchString('');
  }, [projectFilter]);

  const filteredProjects = useMemo(() => {
    if (!availableProjects) return [];

    return availableProjects.filter((project) => {
      if (project.isDeleted) return false;

      let showProject = false;

      // if no search string then show the projects
      // or if there is a search string and it's included in the project then also show project
      if (projectSearchString == '' || project.name.toLowerCase().includes(projectSearchString.toLowerCase())) {
        showProject = true;
      }

      return showProject;
    });
  }, [availableProjects, projectSearchString]);

  return (
    <PageLayout isFetching={isLoading || isLoadingSettings || !userInfo} navpanel={true}>
      {!sharedProjectsWithNoAccess ? (
        <div className={classes.projects_content}>
          <PageHeader
            key={`projects_page_header_${projectFilter}`}
            pageTitle={projectFilter === 'Shared' ? 'Shared with me' : 'My projects'}
            showViewToggle={availableProjects && availableProjects?.length > 0}
            selectedView={viewType}
            onChangeView={setViewType}
            searchValue={projectSearchString}
            setSearchValue={setProjectSearchString}
            searchPlaceholder="Search for project"
          />
          {filteredProjects && filteredProjects.length > 0 ? (
            <>
              {viewType === VIEW_TYPES.CARDS ? (
                <ProjectsCardView
                  projects={filteredProjects}
                  loggedInUserId={userInfo?.id ?? ''}
                  projectSearchString={projectSearchString}
                />
              ) : (
                <ProjectsListView
                  projects={filteredProjects}
                  loggedInUserId={userInfo?.id ?? ''}
                  searchValue={projectSearchString}
                />
              )}
            </>
          ) : (
            <div className={classes.projects_empty}>
              <Stack gap="10px" alignItems="center">
                {projectFilter === 'Owned' ? (
                  <>
                    <TrblFolderIcon width="30px" height="30px" fill="#616161" />
                    <h3>You have no projects yet</h3>
                    <PrimaryButton
                      label="+ Create project"
                      width="fit-content"
                      onClick={() => setShowCreateProjectPopup(true)}
                    />
                  </>
                ) : (
                  <>
                    <TrblFolderSharedIcon width="30px" height="30px" fill="#616161" />
                    <h3>No projects have been shared with you yet</h3>
                  </>
                )}
              </Stack>
            </div>
          )}
          {projectFilter === 'Owned' && (
            <TrblTooltip title="Create new project">
              <button className={classes.create_project_btn} onClick={() => setShowCreateProjectPopup(true)}>
                <TrblAddIcon fill="none" width="54" height="54" props="0.65" />
              </button>
            </TrblTooltip>
          )}
        </div>
      ) : (
        <div className={classes.projects_empty}>
          <Stack gap="10px" alignItems="center" margin="170px 0 0 -140px">
            <TrblFolderSharedIcon width="30px" height="30px" fill="#616161" />

            <h3>You do not have access to shared projects</h3>
            <p>
              Click{' '}
              <a target="_blank" style={{ textDecoration: 'underline' }} href="https://treble.tech/pricing">
                here
              </a>{' '}
              for more information
            </p>
          </Stack>
        </div>
      )}

      {showCreateProjectPopup && <CreateProject onClose={() => setShowCreateProjectPopup(false)} />}
    </PageLayout>
  );
};
