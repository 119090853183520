export type MaterialInfoJsonDto = {
  DataType: string;
  RealPolesCount: number;
  ComplexPairsCount: number;
  RealPoles: number[];
  RealResidues: number[];
  ComplexPolesReal: number[];
  ComplexPolesImag: number[];
  ComplexResiduesReal: number[];
  ComplexResiduesImag: number[];
  DirectTerm: number;
  FittedAbsorptionCoefficients: number[];
  InputAbsorptionCoefficients: number[];
};

export type FormValues = {
  name: string;
  category: string;
  description: string;
  defaultScattering: number;
  isSpecific: boolean | null;
};

export type ComplexData = Record<number, { real: number; imag: number }>;

export type SimpleData = Record<number, number | undefined>;

export type Frequency = 63 | 125 | 250 | 500 | 1000 | 2000 | 4000 | 8000;

export enum PorousAbsorberInputType {
  FlowResistivity = 'flowResistivity',
  Density = 'density',
}

export enum PorousAbsorberWoolType {
  Rockwool = 'rock_wool',
  Glasswool = 'glass_wool',
}

export type PorousAbsorberFormValues = {
  thickness: number | undefined;
  flowResistivity: number | undefined;
  density: number | undefined;
  woolType: string;
  inputType: PorousAbsorberInputType;
};

export type AirCavityFormValues = {
  thickness: number | undefined;
};

export type RigidBackingFormValues = {
  density: number | undefined;
  bulkModulus: number | undefined;
};
