import { FC } from 'react';

type ShareDisabledInformation = {
  type: string;
};

export const ShareDisabledInformation: FC<ShareDisabledInformation> = ({ type }) => (
  <div>
    <p>
      To share {type} within your organization, you need to updgrade to a Team plan.
      <br />
      <br />
      Click{' '}
      <a target="_blank" style={{ textDecoration: 'underline', fontWeight: 700 }} href="https://treble.tech/pricing">
        here
      </a>{' '}
      for more information.
    </p>
  </div>
);
