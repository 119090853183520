import { useQuery } from '@tanstack/react-query';

import { ModelBaseDto } from '@/types';

import axios from '@/axios';

const getModelBasesBySpaceId = async (spaceId: string) => {
  const { data } = await axios.get(`/api/Model/GetModelBases`, { params: { spaceId } });
  return data;
};

export const useGetModelBasesBySpaceId = (spaceId: string | undefined) => {
  return useQuery<ModelBaseDto[]>(['modelBasesBySpaceId', spaceId], () => getModelBasesBySpaceId(spaceId!), {
    enabled: !!spaceId,
    refetchOnWindowFocus: false,
  });
};
