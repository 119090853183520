import { useEffect, useState } from 'react';

import { ParameterKeys } from '../ParameterResults/constants';
import { AVAILABLE_NC_CURVES, L_P_S_1M, LP_OSS_1M, MIN_NUMBER_OF_RECEIVERS, OCTAVE_BANDS } from './constants';

import {
  calculate_D_2_S,
  calculate_L_p_A_B,
  calculate_L_p_A_S_4m,
  calculate_L_p_A_S_n,
  calculate_r_C,
  calculate_r_D,
  calculateDistance,
} from './utils';

import { ParsedParameterData } from '../ParameterResults/types';
import { OpenOfficeParameter, ReceiverOpenOfficeData } from './types';

const initialState = [
  {
    key: 'rD',
    label: (
      <span title="Distraction distance, in m">
        r<sub>D</sub>, (m)
      </span>
    ),
    values: [],
  },
  {
    key: 'D2S',
    label: (
      <span title="Spatial decay rate of A-weighted SPL of speech, in dB">
        D<sub>2,s</sub>, (dB)
      </span>
    ),
    values: [],
  },
  {
    key: 'LpAS4m',
    label: (
      <span title="A-weighted SPL of speech at 4,0 meters, in dB">
        L<sub>p,A,S,4m</sub>, (dB)
      </span>
    ),
    values: [],
  },
  {
    key: 'rC',
    label: (
      <span title="Comfort distance, in m">
        r<sub>C</sub>, (m)
      </span>
    ),
    values: [],
  },
  {
    key: 'LpAB',
    label: (
      <span title="Average A-weighted SPL of background noise, in dB">
        L<sub>p,A,B</sub>, (dB)
      </span>
    ),
    values: [],
  },
];

export const useCalculateOpenOfficeParameters = (
  parameterData: ParsedParameterData[],
  selectedNC: Record<string, string>
) => {
  const [openOfficeParameters, setOpenOfficeParameters] = useState<OpenOfficeParameter[]>(initialState);

  useEffect(() => {
    const newOpenOfficeParameters: OpenOfficeParameter[] = openOfficeParameters.map((parameter) => ({
      ...parameter,
      values: [],
    }));

    parameterData.forEach((comparison) => {
      let D_2_S: number | null = null;
      let L_p_A_S_4m: number | null = null;
      let r_C: number | null = null;
      let r_D: number | null = null;
      let L_p_A_B: number | null = null;

      if (
        !comparison.isSummedSource &&
        comparison.resultParametersForReceivers.length >= MIN_NUMBER_OF_RECEIVERS &&
        Object.keys(selectedNC).length
      ) {
        let receiversData: ReceiverOpenOfficeData[] = comparison.resultParametersForReceivers.map(
          (receiverParameters, i) => ({
            resultParameters: receiverParameters,
            distanceFromSource: calculateDistance(comparison.sourcePosition, comparison.receiverDetails[i].position),
          })
        );

        // Lets order the receivers by distance from the source, ascending
        receiversData = receiversData.sort((a, b) => a.distanceFromSource - b.distanceFromSource);

        // STI values for all receivers and the selected nc curve
        const sti_nc_n = new Array<number>(receiversData.length);
        // SPL of normal effort speech L_p_S_n_i
        const L_p_S_n_i = new Array<number[]>(receiversData.length);
        // A-weighted SPL of speech in position L_p_A_S_n
        const L_p_A_S_n = new Array<number>(receiversData.length);

        // Selected noise curve
        const ncCurveName = selectedNC[comparison.color];
        const ncCurveIndex = AVAILABLE_NC_CURVES.findIndex((nc) => nc.name === ncCurveName);

        receiversData.forEach((receiver, n) => {
          // Get the receiver's spl values for all octave bands
          const spl = receiver.resultParameters[ParameterKeys.SPL];
          // We remove the first and last element because we are only interested in the 125 to 4000 Hz range
          const L_p_oss_n_i = spl.slice(1, -1);

          // Get the receiver's sti values for the selected nc curve
          sti_nc_n[n] = receiver.resultParameters[ParameterKeys.STI][ncCurveIndex];

          // Calculate the D_n_i ( the difference between the sound power level of the source and the sound pressure level at the receiver at 1m), formula (2) in ISO 3382-3
          const D_n_i = L_p_oss_n_i.map((x) => LP_OSS_1M - x);

          L_p_S_n_i[n] = new Array<number>(OCTAVE_BANDS.length);
          for (let i = 0; i < OCTAVE_BANDS.length; i++) {
            // Calculate SPL of normal effort speech L_p_S_n_i [dB], formula (4) in ISO 3382-3
            L_p_S_n_i[n][i] = L_P_S_1M[i] - D_n_i[i];
          }

          // Calculate A-weighted SPL of speech in position L_p_A_S_n, formula (5) in ISO 3382-3
          L_p_A_S_n[n] = calculate_L_p_A_S_n(L_p_S_n_i, n);
        });

        // SPL values of the selected nc curve
        const ncCurve_spl = AVAILABLE_NC_CURVES[ncCurveIndex].value;

        // r_n contains an array of all the distances from the source
        const r_n = receiversData.map((x) => x.distanceFromSource);

        D_2_S = calculate_D_2_S(L_p_A_S_n, r_n);
        L_p_A_S_4m = calculate_L_p_A_S_4m(L_p_A_S_n, r_n, D_2_S);
        r_C = calculate_r_C(L_p_A_S_4m, D_2_S);
        r_D = calculate_r_D(r_n, sti_nc_n);
        L_p_A_B = calculate_L_p_A_B(ncCurve_spl);
      }

      newOpenOfficeParameters[0].values.push(r_D);
      newOpenOfficeParameters[1].values.push(D_2_S);
      newOpenOfficeParameters[2].values.push(L_p_A_S_4m);
      newOpenOfficeParameters[3].values.push(r_C);
      newOpenOfficeParameters[4].values.push(L_p_A_B);
    });

    setOpenOfficeParameters(newOpenOfficeParameters);
  }, [parameterData, selectedNC]);

  return openOfficeParameters;
};
