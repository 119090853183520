import { useEffect, useState } from 'react';

import { useBaseContext } from '@/context/BaseContext';

import { TrblPopup, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { ManageAccessContent } from './ManageAccessContent';

import { ProjectAndUsersDto, UserBasicDto } from '@/types';

const POPUP_TITLE = 'Project members';

export const ManageAccessPopup = ({
  project,
  onClose,
}: {
  project: ProjectAndUsersDto;
  onClose: (value: boolean) => void;
}) => {
  const {
    state: { organizationUsers },
  } = useBaseContext();
  const [creator, setCreator] = useState<UserBasicDto>();
  const [projectUsersSansCreator, setProjectUsersSansCreator] = useState<UserBasicDto[]>([]);
  const [organizationUsersSansCreator, setOrganizationUsersSansCreator] = useState<UserBasicDto[]>([]);
  const [projectUpdated, setProjectUpdated] = useState(false);

  useEffect(() => {
    setCreator(project.projectUsers.find((user) => user.id == project.createdBy));
    setProjectUsersSansCreator(project.projectUsers.filter((user) => user.id != project.createdBy));
  }, []);

  useEffect(() => {
    if (organizationUsers) {
      setOrganizationUsersSansCreator(organizationUsers.filter((user) => user.id != project.createdBy));
    }
  }, [organizationUsers]);

  const handleClose = () => {
    onClose(projectUpdated);
  };

  return (
    <TrblPopup
      width="480px"
      height="auto"
      hideBackdrop={false}
      aria-labelledby={POPUP_TITLE}
      sx={{ fontSize: '12px' }}
      onClose={handleClose}
      open={true}>
      <TrblPopupTitle onClose={handleClose}>
        <div>{POPUP_TITLE}</div>
      </TrblPopupTitle>
      <TrblPopupContent>
        <ManageAccessContent
          projectId={project.id}
          creator={creator}
          projectUsers={projectUsersSansCreator}
          organizationUsers={organizationUsersSansCreator}
          setProjectUpdated={setProjectUpdated}
        />
      </TrblPopupContent>
    </TrblPopup>
  );
};
