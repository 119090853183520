import { FC, useEffect, useState } from 'react';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

import { useMeshContext } from '@/context/MeshContext';

import { Box, CircularProgress } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { TrblInfoIcon, TrblQueueIcon, TrblTimewatchUnfilledIcon } from '@/components/Icons';
import { GetTokenStatusResponse } from '@/components/ManagementPortal/hooks';
import { TrblTooltip } from '../../Shared';
import { getGpuAllocationStrategyText, MESH_ERROR_TEXT } from '../const';
import { ErrorContent } from './ErrorContent';
import { TokenEstimationContent } from './TokenEstimationContent';

import { useGetConcurrencyOverview } from '@/hooks/Organization/useGetConcurrencyOverview';
import {
  GpuAllocationStrategy,
  SimulationEstimateDtoSimulationEstimateDto,
} from '@/hooks/SolveTask/useCalculateSimulationRunEstimatedTime';

import { getConcurrencyInfoText, getQueueInfoText, secToTimeString } from '../utils';

interface EstimationContentProps {
  isTrialUser: boolean;
  showTokenEstimation: boolean;
  isGAOnly: boolean;
  currentSimTimeEstimate: SimulationEstimateDtoSimulationEstimateDto | null;
  meshHasError: boolean;
  tokenStatus: GetTokenStatusResponse | undefined;
}

export const EstimationContent: FC<EstimationContentProps> = ({
  isTrialUser,
  showTokenEstimation,
  isGAOnly,
  currentSimTimeEstimate,
  meshHasError,
  tokenStatus,
}) => {
  const { isEstimating, calculationError } = useMeshContext();

  const { data: concurrencyOverview } = useGetConcurrencyOverview();

  const [gpuAllocationStrategy, setGpuAllocationStrategy] = useState<GpuAllocationStrategy | null>(null);
  const [isPartiallyQueued, setIsPartiallyQueued] = useState(false);
  const [isFullyQueued, setIsFullyQueued] = useState(false);
  const [totalTimeInQueue, setTotalTimeInQueue] = useState<number | null>(null);

  useEffect(() => {
    if (!currentSimTimeEstimate || !concurrencyOverview) return;

    const strategy = currentSimTimeEstimate.estimatePerSource.find(
      (s) => s.estimatedGpusAllocated && s.estimatedGpusAllocated > 1
    )?.gpuAllocationStrategy;

    // 1. GPUCountOptimized && estimatedGpusAllocated > 1 = scale up
    // 2. UtilizationOptimized && estimatedGpusAllocated > 1 = speed up
    if (strategy) setGpuAllocationStrategy(strategy);

    const {
      maxConcurrency,
      currentConcurrencyInUse,
      queuedSourcesEstimatedRuntime,
      estimatedTimeUntilNextRunningSourceFinishes,
    } = concurrencyOverview;

    const available = maxConcurrency - currentConcurrencyInUse;
    setIsFullyQueued(available <= 0);
    setIsPartiallyQueued(available > 0 && available < currentSimTimeEstimate.estimatePerSource.length);

    // we have 2 separate values for the queue time, so we sum them up to get more accurate number
    // queuedSourcesEstimatedRuntime is for the sources that are in the queue
    // estimatedTimeUntilNextRunningSourceFinishes is for the source that is currently running
    setTotalTimeInQueue(queuedSourcesEstimatedRuntime + estimatedTimeUntilNextRunningSourceFinishes);
  }, [currentSimTimeEstimate, concurrencyOverview]);

  const renderLoadingState = () => (
    <Box component={'div'} display="flex" height={'44px'} gap="8px" alignItems="center" justifyContent="center">
      <CircularProgress size={15} /> <Text type="regular-12px">Estimating</Text>
    </Box>
  );

  const renderQueueTime = () => (
    <>
      <Text type="bold-11px">Queue time</Text>
      <Box component={'div'} display="flex" gap="5px" alignItems="center">
        <span style={{ fontSize: '14px', display: 'flex', alignItems: 'center' }}>
          <HourglassTopIcon fontSize="inherit" />
        </span>
        <Text type="medium-11px">
          {totalTimeInQueue && totalTimeInQueue > 0 ? secToTimeString(Math.round(totalTimeInQueue)) : 'Next in queue'}
        </Text>
        <TrblTooltip title={getQueueInfoText()}>
          <span style={{ marginTop: '-3px', marginLeft: '-3px' }}>
            <TrblInfoIcon width="11" height="11" />
          </span>
        </TrblTooltip>
      </Box>
    </>
  );

  return (
    <Box
      component={'div'}
      pb="16px"
      pt="16px"
      px="20px"
      mx="-20px"
      display="flex"
      flexDirection="column"
      gap="8px"
      minHeight="70px"
      style={{ backgroundColor: '#1C1C1C' }}>
      {isEstimating ? (
        renderLoadingState()
      ) : (
        <>
          <Text type="bold-11px">Estimation</Text>
          <Box component={'div'} display="flex">
            <Box component={'div'} display="flex" flexDirection="column" gap="7px" width="100%">
              {meshHasError && !isGAOnly ? (
                <ErrorContent errorTitle="Error preparing geometry" errorText={MESH_ERROR_TEXT} />
              ) : calculationError && !isGAOnly ? (
                <ErrorContent errorTitle="Error calculating time" errorText={calculationError} />
              ) : (
                <>
                  <Box component={'div'} display="flex" gap="5px" alignItems="center">
                    <TrblTooltip title="Runtime">
                      <span style={{ marginTop: '5px' }}>
                        {!isPartiallyQueued && !isFullyQueued && <TrblTimewatchUnfilledIcon />}
                        {(isPartiallyQueued || isFullyQueued) && <TrblQueueIcon />}
                      </span>
                    </TrblTooltip>
                    {currentSimTimeEstimate && (
                      <Text type="medium-11px">
                        {secToTimeString(
                          Math.round(currentSimTimeEstimate.totalEstimatedRuntimeAdjustedForConcurrency)
                        )}
                      </Text>
                    )}
                    {isPartiallyQueued && !isFullyQueued && concurrencyOverview && (
                      <TrblTooltip title={getConcurrencyInfoText()}>
                        <span style={{ marginTop: '-3px', marginLeft: '-3px' }}>
                          <TrblInfoIcon width="11" height="11" />
                        </span>
                      </TrblTooltip>
                    )}
                  </Box>
                  {showTokenEstimation &&
                    currentSimTimeEstimate &&
                    currentSimTimeEstimate.totalEstimatedTokensSeconds && (
                      <TokenEstimationContent
                        isTrialUser={isTrialUser}
                        tokenStatus={tokenStatus}
                        totalEstimatedTokensSeconds={currentSimTimeEstimate.totalEstimatedTokensSeconds}
                      />
                    )}
                  {isFullyQueued && renderQueueTime()}
                </>
              )}
            </Box>
            {gpuAllocationStrategy && (
              <Box component={'div'} display="flex" flexDirection="column" width="100%" alignItems="flex-end">
                <TrblTooltip title={getGpuAllocationStrategyText(gpuAllocationStrategy)}>
                  <span>
                    <Text type="bold-11px">Multi GPU enabled</Text>
                    <span style={{ marginLeft: '5px' }}>
                      <TrblInfoIcon width="11" height="11" />
                    </span>
                  </span>
                </TrblTooltip>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
