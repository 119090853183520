import { FC, useState } from 'react';
import dayjs from 'dayjs';

import { Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { HighlightedText } from '@/components/Shared/HighlightedText';
import { NameMarkerRowNew } from '@/components/Shared/NameMarker';
import { TrblSpaceIcon } from '@/components/Icons';
import { ShareProjectButton } from '@/components/New/ShareProjectButton/ShareProjectButton';
import { SpaceCard } from '@/components/New/SpaceCard/SpaceCard';
import { TrblCustomSelect } from '@/components/New/TrblCustomSelect';
import { ProjectActionsMenu } from '../../ProjectActionsMenu';

import { useGetSpacesDashboardByProjectId } from '@/hooks';

import { ProjectAndSpacesDto } from '@/types';

import styles from '../styles.module.scss';

interface ProjectSpacesColumnProps {
  project: ProjectAndSpacesDto;
}

const ProjectSpacesColumn: FC<ProjectSpacesColumnProps> = ({ project }) => {
  const [activeProjectId, setActiveProjectId] = useState('');
  const { data: spaces } = useGetSpacesDashboardByProjectId(activeProjectId);

  const handleOpenSpacesDropdown = async () => {
    if (!activeProjectId) {
      setActiveProjectId(project.id);
    }
  };

  return project.spacesCount > 0 ? (
    <TrblCustomSelect
      onClick={handleOpenSpacesDropdown}
      selectedItem={
        <Stack flexDirection="row" gap="6px" alignItems="center">
          <TrblSpaceIcon fill="#ADADAD" />
          {project.spacesCount}
        </Stack>
      }
      dropdownWidth="380px">
      {spaces
        ?.filter((s) => s.modelCount > 0)
        ?.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))
        .map((space) => (
          <SpaceCard key={space.id} space={space} small />
        ))}
    </TrblCustomSelect>
  ) : (
    <Stack flexDirection="row" gap="6px" alignItems="center">
      <TrblSpaceIcon fill="#ADADAD" /> 0
    </Stack>
  );
};

export const useGetColumns = (
  loggedInUserId: string,
  setSelectedRow: (projectId: string | null) => void,
  setShowProjectMembersPopup: (show: boolean) => void,
  searchValue: string
) => {
  return [
    {
      field: 'name',
      headerName: 'Project',
      sortable: true,
      width: '400px',
      renderCell: (row: ProjectAndSpacesDto) => {
        return (
          <TrblTooltip title={row.name}>
            <span style={{ fontWeight: 600 }}>
              <HighlightedText text={row.name} highlight={searchValue} />
            </span>
          </TrblTooltip>
        );
      },
    },
    {
      field: 'spacesCount',
      headerName: 'Spaces',
      sortable: true,
      maxWidth: '180px',
      renderCell: (project: ProjectAndSpacesDto) => <ProjectSpacesColumn project={project} />,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      sortable: true,

      renderCell: (row: ProjectAndSpacesDto) => {
        const member = row.projectUsers.find((user) => user.id == row.createdBy)!;
        const fullName = member.lastName ? `${member.firstName} ${member.lastName}` : member.firstName;
        const createdBy = member.firstName ? fullName : member.email;

        return createdBy;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Modified',
      sortable: true,
      renderCell: (row: ProjectAndSpacesDto) => {
        const updatedAt = row.updatedAt ? dayjs(row.updatedAt).format('D MMM YYYY, HH:MM') : '';

        return <TrblTooltip title={updatedAt + ' by ' + row.updatedByUserEmail}>{updatedAt}</TrblTooltip>;
      },
    },
    {
      field: 'sharedWith',
      headerName: 'Shared With',
      sortable: false,
      renderCell: (project: ProjectAndSpacesDto) => {
        return (
          <div className={styles['shared-with-cell']}>
            {project.projectUsers.length > 1 && (
              <NameMarkerRowNew
                projectUsers={project.projectUsers}
                loggedInUserId={loggedInUserId === project.createdBy ? loggedInUserId : undefined}
                creatorId={project.createdBy}
                background="#272727"
                size="medium"
              />
            )}
          </div>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      maxWidth: '70px',
      displayOnHover: true,
      sortable: false,
      renderCell: (project: ProjectAndSpacesDto) => {
        return (
          <Stack flexDirection="row" alignItems="center" gap="8px" width="70px">
            <div className={styles.project_share_button}>
              <ShareProjectButton
                onClick={() => {
                  setShowProjectMembersPopup(true);
                  setSelectedRow(project.id);
                }}
                tooltip={project.projectUsers.length > 1 ? 'Manage members' : 'Add members'}
              />
            </div>
            <ProjectActionsMenu
              project={project}
              setSelected={(isSelected) => {
                setSelectedRow(isSelected ? project.id : null);
              }}
              classNames={styles.project_menu}
              onOpen={() => setSelectedRow(project.id)}
              onClose={() => {
                setSelectedRow(null);
              }}
              isMyProject={loggedInUserId === project.createdBy}
            />
          </Stack>
        );
      },
    },
  ];
};
