import { useEffect, useState } from 'react';

export function useLocalStoragePreference<T>(key: string, userId: string | undefined, defaultValue: T) {
  const [value, setValue] = useState<T>(defaultValue);
  const [isLoading, setIsLoading] = useState(!!userId); // Start loading if userId exists

  // Load saved preference when userId is available
  useEffect(() => {
    if (!userId) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const storageKey = `${key}_${userId}`;
    const savedValue = localStorage.getItem(storageKey);
    if (savedValue) {
      setValue(savedValue as T);
    }
    setIsLoading(false);
  }, [userId, key]);

  // Save preference when value changes
  useEffect(() => {
    if (!userId) return;

    const storageKey = `${key}_${userId}`;
    localStorage.setItem(storageKey, value as string);
  }, [value, userId, key]);

  return [value, setValue, isLoading] as const;
}
