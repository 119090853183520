import { FC, useEffect, useMemo } from 'react';
import { Material, Mesh } from 'three';

import { createInterpolatedHeatmap, createSimpleHeatmap } from './utils';
// @ts-expect-error Could not find a declaration file for module '@/utils/ColorBar'.
import { ColorBar } from '@/utils/ColorBar';
import { roundFloat } from '@/utils/trebleFunctions';

import { GridReceiverPoint, GridReceiverResultsDto } from '@/types';

type HeatmapProps = {
  result: GridReceiverResultsDto;
  points: GridReceiverPoint[];
  colorBar: ColorBar;
  isExporting: boolean;
  valuesChosen: number[] | undefined;
  isVisible: boolean;
  isInterpolated: boolean;
  decimals: number;
  targetValue: boolean;
  isPlayingModalAnalysis: boolean;
};

export const Heatmap: FC<HeatmapProps> = ({
  result,
  points,
  isExporting,
  colorBar,
  valuesChosen,
  isVisible,
  isInterpolated,
  decimals,
  targetValue = false,
  isPlayingModalAnalysis = false,
}) => {
  const roundedValuesChosen = useMemo(() => {
    return valuesChosen ? valuesChosen.map((value) => roundFloat(value, decimals)) : [];
  }, [valuesChosen]);

  const interpolatedHeatmap = useMemo(() => {
    return createInterpolatedHeatmap(
      colorBar,
      result,
      points,
      roundedValuesChosen,
      isVisible,
      decimals,
      isInterpolated,
      targetValue
    );
  }, [roundedValuesChosen, colorBar, isInterpolated]);

  const simpleHeatmap = useMemo(() => {
    return createSimpleHeatmap(colorBar, result, points, roundedValuesChosen, isVisible, targetValue);
  }, [roundedValuesChosen, colorBar, isInterpolated, isPlayingModalAnalysis]);

  useEffect(() => {
    if (!isInterpolated) {
      simpleHeatmap?.traverse(function (object) {
        if (object instanceof Mesh) {
          object.material.visible = isVisible;
        }
      });
    }
    if (isInterpolated && interpolatedHeatmap) {
      const heatmapMaterial = interpolatedHeatmap.material as Material;
      heatmapMaterial.visible = isVisible;
    }
  }, [isVisible]);

  const handlePointerOver = () => {
    document.body.style.cursor = 'crosshair';
  };

  const handlePointerOut = () => {
    document.body.style.removeProperty('cursor');
  };

  return interpolatedHeatmap !== null || simpleHeatmap !== null ? (
    <>
      {isExporting ? (
        <>
          {/* when we export an image we only render either the simple heatmap, or the interpolated heatmap */}
          {!isInterpolated && simpleHeatmap && <primitive object={simpleHeatmap} />}
          {isInterpolated && interpolatedHeatmap && <primitive object={interpolatedHeatmap} />}
        </>
      ) : (
        <>
          {/* we always create the simple heatmap, for assigning pressure text
        but don't show the simple heatmap if interpolated is selected OR when the
        modal analysis is playing and it is interpolated */}
          {simpleHeatmap && (
            <primitive
              object={simpleHeatmap}
              onPointerEnter={!isInterpolated ? handlePointerOver : null}
              onPointerLeave={!isInterpolated ? handlePointerOut : null}
              visible={!isInterpolated}
            />
          )}
          {isInterpolated && interpolatedHeatmap && (
            <primitive
              onPointerEnter={handlePointerOver}
              onPointerLeave={handlePointerOut}
              object={interpolatedHeatmap}
            />
          )}
        </>
      )}
    </>
  ) : null;
};
