import { SimulationsInSpace } from '@/context/AppContext';
import { useModelContext } from '@/context/ModelContext';

import { getEstimatedTransitionFrequency } from '@/components/SolverSettings/utils';

import { useCreateSimulation } from '@/hooks';

import { NewSimulationTypes } from '../constants';

import { createFromScratch, createFromSimulation } from '../utils';
import { getModelVolume } from '@/context/ModelContext/utils';

import { ModelFile } from '@/context/ModelContext/types';
import { Simulation } from '@/types';

type SelectedModelData = {
  modelId: string;
  isNotWatertight: boolean;
  newModelSelected: boolean;
  isRhino3dmFileLoaded: boolean;
  modelFile: ModelFile | null;
  modelVolume: number | null;
};

export const useCreateNewSimulation = () => {
  const { mutate: createSimulation } = useCreateSimulation();
  const { addModelFromFile, rhino3dmFiles } = useModelContext();

  const createNewSimulation = async (
    selectedRadio: NewSimulationTypes,
    name: string,
    description: string,
    selectedModel: SelectedModelData,
    simulationToCopyId: string,
    simulationsInSpace: SimulationsInSpace[]
  ): Promise<Simulation | undefined> => {
    const newSimulationObject: Simulation =
      selectedRadio === NewSimulationTypes.Copy
        ? await createFromSimulation(simulationsInSpace, selectedModel.modelId, simulationToCopyId)
        : createFromScratch(selectedModel.isNotWatertight);
    if (newSimulationObject) {
      if (selectedRadio === NewSimulationTypes.New) {
        let modelVolume = selectedModel.modelVolume;
        if (!selectedModel.isRhino3dmFileLoaded && selectedModel.modelFile) {
          const modelFile = await addModelFromFile(selectedModel.modelId, selectedModel.modelFile);

          // If this is a new watertight model, we need to calculate the volume
          if (!selectedModel.isNotWatertight) {
            modelVolume = getModelVolume(modelFile?.rhino3dm);
          }
        } else if (selectedModel.newModelSelected && selectedModel.isRhino3dmFileLoaded) {
          // If the model is a new model but the rhino3dm file is loaded, we just need to calculate the volume
          modelVolume = getModelVolume(rhino3dmFiles[selectedModel.modelId]);
        }

        const transitionFrequency = getEstimatedTransitionFrequency(modelVolume ?? 0);
        newSimulationObject.solverSettings.gaSettings.crossoverFrequency = transitionFrequency;
        newSimulationObject.solverSettings.dgSettings.crossoverFrequency = transitionFrequency;
      }

      newSimulationObject.modelId = selectedModel.modelId;
      newSimulationObject.name = name;
      newSimulationObject.description = description || '';

      return new Promise((resolve) => {
        createSimulation(newSimulationObject, {
          onSuccess: (data) => {
            const savedSim: Simulation = data;
            if (savedSim === null) {
              throw new Error();
            }
            // used for storing temporary data like meshTaskId and run status
            savedSim.extra = {};

            resolve(savedSim);
          },
        });
      });
    }
  };
  return createNewSimulation;
};
