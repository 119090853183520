import { Stack } from '@mui/material';

import { TrblDGSolverIcon, TrblGASolverIcon } from '@/components/Icons';

import { GeometryInfo } from '../types';

import styles from '../styles.module.scss';

export const MultiSpaceInfoIcons = ({ geometryInfo }: { geometryInfo: GeometryInfo | null }) => {
  return geometryInfo ? (
    <Stack flexDirection={'row'} className={styles['info-icons-container']}>
      <div className={styles['info-icons-item']}>
        <TrblDGSolverIcon width="14" height="14" fill={geometryInfo.watertight ? '#00F5BA' : '#999999'} />

        <p>{geometryInfo.watertight ? 'Wave solver enabled' : 'Geometry not watertight'}</p>
      </div>
      <div className={styles['info-icons-item']}>
        <TrblGASolverIcon width="14" height="14" fill="#00F5BA" />
        <p>Geometrical solver enabled</p>
      </div>
    </Stack>
  ) : (
    <Stack flexDirection={'row'} className={styles['info-icons-container']} style={{ opacity: 0 }}></Stack>
  );
};
