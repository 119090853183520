import { useQuery } from '@tanstack/react-query';

import { MixerSettings } from '@/components/AuralizerPresets/types';

import axios from '@/axios';

export type AuralizationPresetInfoDtoAuralizationPresetInfoDto = {
  id: string;
  name: string;
  description: string;
  simulations: string[];
  projectId: string;
  spaceId: string;
  mixerSettings: MixerSettings;
  userId: string;
  createdAt: string;
  updatedAt: string;
  projectName: string;
  spaceName: string;
  simulationNames: string[];
  createdByUserEmail: string;
  updatedByUserEmail: string;
  modelId: string;
  simulationId: string;
};

export const getAuralizationPresets = async (): Promise<AuralizationPresetInfoDtoAuralizationPresetInfoDto[]> => {
  const { data } = await axios.get(`/api/AuralizationPreset/GetAuralizationPresets`);
  return data;
};

export const useGetAuralizationPresets = () => {
  return useQuery<AuralizationPresetInfoDtoAuralizationPresetInfoDto[]>(
    ['all-auralization-presets'],
    () => getAuralizationPresets(),
    {
      refetchOnWindowFocus: false,
    }
  );
};
