import { ReactNode } from 'react';

import { HighlightedText } from '@/components/Shared/HighlightedText';
import { NameMarker } from '@/components/Shared/NameMarker';
import { getColorById } from '@/components/Shared/NameMarker/utils';

import { UserBasicDto } from '@/types';

import classes from './styles.module.scss';

export const TrblMemberListItem = ({
  member,
  readOnly = false,
  searchValue = '',
  selectMember,
  children,
}: {
  member: UserBasicDto;
  readOnly?: boolean;
  searchValue?: string;
  selectMember?: (member: UserBasicDto) => void;
  children?: ReactNode;
}) => {
  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      selectMember && selectMember(member);
    }
  };

  return (
    <div
      onClick={() => selectMember && !readOnly && selectMember(member)}
      onKeyDown={(event) => !readOnly && handleKeyDown(event)}>
      <div className={classes.initials}>
        <NameMarker
          content={member.email?.charAt(0).toUpperCase() ?? ''}
          color={getColorById(member.id)}
          size="large"
          lineStyle="solid"
        />
        <div className={classes.details_container}>
          <div className={classes.details_container__name}>
            <HighlightedText
              text={`${member.firstName ?? ''} ${member.lastName ?? ''}`}
              highlight={searchValue}
              highlightColor="inherit"
            />
          </div>
          <div className={classes.details_container__email}>
            <HighlightedText text={member?.email ?? ''} highlight={searchValue} highlightColor="inherit" />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
