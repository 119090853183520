import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';

import { useDeleteSimulation } from '@/hooks';

import './styles.scss';

export const DeleteSimulationPopup = ({
  showDeleteConfirmation,
  setShowDeleteConfirmation,
  setIsDisabled,
}: {
  showDeleteConfirmation: boolean;
  setShowDeleteConfirmation: (show: boolean) => void;
  setIsDisabled: (show: boolean) => void;
}) => {
  const {
    simulationState: { selectedSimulation, availableSimulations },
    dispatch,
  } = useSimulationContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate: deleteSimulation } = useDeleteSimulation();

  const deleteSimulationFunc = (simulationId?: string) => {
    if (!simulationId) return;
    setIsDisabled(true);
    deleteSimulation(simulationId, {
      onSuccess: () => {
        if (availableSimulations) {
          const newSimulations = availableSimulations.filter((simulations) => simulations.id !== simulationId);
          dispatch({
            type: ActionType.SET_MODEL_SIMULATIONS,
            simulations: newSimulations,
          });

          const newSim = newSimulations[newSimulations.length - 1];

          if (newSim) {
            setSearchParams({ mid: newSim.modelId, sid: newSim.id }, { replace: true });
          } else {
            const currentModelId = searchParams.get('mid');
            if (currentModelId) {
              setSearchParams({ mid: currentModelId }, { replace: true });
            }
          }
        }

        toast.info(`'${selectedSimulation?.name}' deleted`, { className: 'editor-toast' });
      },
      onError: () => {
        toast.error('An error occurred while deleting simulation');
      },
    });
  };

  return (
    <>
      {showDeleteConfirmation && (
        <ConfirmationDialog
          title="Delete Simulation"
          message={() => (
            <span>
              Are you sure you want to delete <b>{selectedSimulation?.name}</b> ?
            </span>
          )}
          onConfirm={() => {
            deleteSimulationFunc(selectedSimulation?.id);
            setShowDeleteConfirmation(false);
          }}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}
    </>
  );
};
