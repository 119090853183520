import { FC } from 'react';

import { FilterTag } from '@/components/Shared/Tags';
import { Text } from '@/components/Shared/Text';

import styles from './styles.module.scss';

type TutorialCardProps = {
  name: string;
  description: string;
  tags: string[];
  imageUrl: string;
  isComingSoon?: boolean;
  onClick?: () => void;
};

export const TutorialCard: FC<TutorialCardProps> = ({ name, description, tags, imageUrl, isComingSoon, onClick }) => {
  return (
    <button
      className={`${styles['tutorial-card']} ${isComingSoon ? styles['coming-soon'] : ''}`}
      onClick={onClick}
      disabled={isComingSoon}
      onKeyDown={(e) => e.key === 'Enter' && onClick?.()}
      tabIndex={0}>
      <div className={styles['tutorial-card-image']}>
        <img src={imageUrl} alt={name} />
      </div>
      <div className={styles['tutorial-card-info']}>
        <div className={styles['main-info']}>
          <span className={styles['title']}>{name}</span>
          <span className={styles['description']}>{description}</span>
        </div>
        <div className={styles['tags']}>
          {tags.map((tag) => (
            <FilterTag key={tag} tag={tag} size="small" />
          ))}
        </div>
      </div>
      {isComingSoon ? (
        <div className={styles['coming-soon-label']}>
          <Text type="bold-12px" color="#F5F5F5">
            Coming soon
          </Text>
        </div>
      ) : (
        <div className={styles['start-tutorial-button']}>
          <div>Start tutorial</div>
        </div>
      )}
    </button>
  );
};
