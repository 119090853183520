import { useState } from 'react';

import { CreateNewSimulationPopup } from '../New/CreateNewSimulationPopup';
import { PrimaryButton } from '../Shared/Buttons';

export const EmptySimulations = () => {
  const [showSimulationPopup, setShowSimulationPopup] = useState(false);

  return (
    <>
      <div className="new-simulation-div">
        <h3>
          This geometry iteration has no
          <br /> simulations yet
        </h3>
        <p>
          Create a simulation by selecting the
          <span style={{ color: '#00f5ba' }}> +New</span> button in the header, or by clicking the button below.
        </p>

        <PrimaryButton
          className="add-simulation-btn"
          label="Create simulation"
          onClick={() => setShowSimulationPopup(true)}
          width="fit-content"
        />
      </div>
      {showSimulationPopup && <CreateNewSimulationPopup setShowPopup={setShowSimulationPopup} />}
    </>
  );
};
