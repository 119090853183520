import { FC } from 'react';

import { Box } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { TrblIcon } from '@/components/Icons';
import { GetTokenStatusResponse } from '@/components/ManagementPortal/hooks';
import { TrblTooltip } from '../../Shared';
import { MAX_TOKENS_FOR_TRIAL, TOKEN_EXCEEDS_TEXT, TOKEN_EXCEEDS_TRIAL_TEXT } from '../const';

import { formatEstimatedTokens, getTokenText, getTrialTokenText } from '../utils';
import { roundFloat } from '@/utils/trebleFunctions';

type TokenEstimationContentProps = {
  isTrialUser: boolean;
  totalEstimatedTokensSeconds: number;
  tokenStatus: GetTokenStatusResponse | undefined;
};

export const TokenEstimationContent: FC<TokenEstimationContentProps> = ({
  isTrialUser,
  totalEstimatedTokensSeconds,
  tokenStatus,
}) => {
  return (
    <Box component="div" display="flex" flexDirection="column" gap="15px" pl="3px" mb="5px">
      <Box component="div" display="flex" gap="5px" alignItems="self-end">
        {isTrialUser ? (
          <Text type="medium-11px">
            {roundFloat((formatEstimatedTokens(totalEstimatedTokensSeconds) / MAX_TOKENS_FOR_TRIAL) * 100, 2)}% of trial
            allowance
          </Text>
        ) : (
          <Box component="div" display="flex" gap="5px" alignItems="self-end">
            <TrblIcon icon="timeComputer" />
            <Text type="medium-11px">{formatEstimatedTokens(totalEstimatedTokensSeconds)} tokens estimated</Text>
          </Box>
        )}
        <TrblTooltip title={isTrialUser ? getTrialTokenText() : getTokenText()}>
          <span style={{ cursor: 'pointer', width: '11px', margin: '-1px 0 1px -1px' }}>
            <TrblIcon icon="info" width="18" height="19" />
          </span>
        </TrblTooltip>
      </Box>
      {tokenStatus && tokenStatus?.reservableTokens < formatEstimatedTokens(totalEstimatedTokensSeconds) && (
        <Text type="medium-11px" style={{ marginTop: '0px', color: '#ffbd59' }}>
          {isTrialUser ? TOKEN_EXCEEDS_TRIAL_TEXT : TOKEN_EXCEEDS_TEXT}
        </Text>
      )}
    </Box>
  );
};
