import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { Material } from '@/types';

import axios from '@/axios';

type MaterialPayload = {
  name: string;
  description: string;
  category: string | null;
  defaultScattering?: number;
  isSharedWithOrganization: boolean;
  materialJson: string;
  materialMetaDataJson: string;
  materialFitId: string;
  isSpecific: boolean | null;
};

const createMaterial = async (materialPayload: MaterialPayload): Promise<Material> => {
  const { data } = await axios.post(`/api/Material/CreateMaterial`, materialPayload);
  return data;
};

export const useCreateMaterial = () => {
  return useMutation(async (newMaterial: MaterialPayload) => await createMaterial(newMaterial), {
    onError: () => {
      toast.error('An error occurred while creating the material. Please refresh the browser.', {
        className: 'editor-toast',
      });
    },
    onSuccess: (data) => {
      toast.success(`New material ${data.name && "'" + data.name + "' "}created!`, { className: 'editor-toast' });
    },
  });
};
