import { ReactNode } from 'react';

import classes from './styles.module.scss';

export const EmptyResourceContent = ({ title, icon }: { title: string; icon?: ReactNode }) => {
  return (
    <div className={classes['empty_container']}>
      <div className={classes['icon_container']}>{icon}</div>
      <p className={classes['container_title']}> {title} </p>
    </div>
  );
};
