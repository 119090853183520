import { IconProps } from '@/types';

export const TrblCheckIcon = ({ fill = '#F5F5F5', width = '14', height = '14' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2105 4.26443C10.0549 4.14972 9.86008 4.10156 9.66895 4.13056C9.47781 4.15955 9.30603 4.26332 9.19144 4.41901L6.47447 8.237L4.27706 6.47883C3.96191 6.23742 3.51159 6.29222 3.26353 6.60217C3.01548 6.91211 3.0607 7.3635 3.36531 7.61808L6.15539 9.84991C6.31114 9.97173 6.50931 10.0258 6.70536 10.0001C6.9014 9.97428 7.07885 9.87077 7.19781 9.71283L10.3674 5.2841C10.4819 5.1281 10.5297 4.933 10.5003 4.74175C10.4709 4.55049 10.3666 4.37878 10.2105 4.26443Z"
      fill={fill}
    />
  </svg>
);
