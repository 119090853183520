import { Box, FormControl } from '@mui/material';

import { RadioButtonGroup } from '@/components/Shared/RadioButton';
import { TextArea } from '@/components/Shared/TextArea';
import { TextField } from '@/components/Shared/TextField';
import { SelectOptionWithGrouping, TrblSelect } from '@/components/Shared/TrblSelect';
import { TrblModelsIcon } from '@/components/Icons';
import { GeometryInfoCard } from '../GeometryInfoCard';

import { NewSimulationTypes } from './constants';

import styles from './styles.module.scss';

type PopupContent = {
  modelOptions: {
    id: string;
    name: string;
    thumbnailUrl: string | undefined;
    createdAt: string;
  }[];
  simulationOptions: SelectOptionWithGrouping[];
  modelId?: string;
  setModelId: (modelId: string) => void;
  name: string;
  setName: (name: string) => void;
  description: string;
  setDescription: (description: string) => void;
  selectedRadio: string;
  setSelectedRadio: (option: NewSimulationTypes) => void;
  selectedSimulationToCopyId: string;
  setSelectedSimulationToCopyId: (prevSimId: string) => void;
};

export const PopupContent = ({
  modelOptions,
  simulationOptions,
  modelId,
  setModelId,
  name,
  setName,
  description,
  setDescription,
  selectedRadio,
  setSelectedRadio,
  selectedSimulationToCopyId,
  setSelectedSimulationToCopyId,
}: PopupContent) => {
  return (
    <>
      <Box component="div" paddingBottom={'16px'}>
        <TrblSelect
          value={modelId ?? ''}
          setValue={setModelId}
          menuItems={modelOptions}
          placeholder={'Choose geometry iteration'}
          menuItemClassNames={{
            root: styles['geometry-iteration-select-menu-item'],
          }}
          renderMenuItem={(_, index) => (
            <GeometryInfoCard
              name={modelOptions[index].name}
              thumbnailUrl={modelOptions[index].thumbnailUrl ?? ''}
              createdAt={modelOptions[index].createdAt}
            />
          )}
          renderSelectedItem={(_, index) => (
            <div className={styles['geometry-iteration-select-selected-item']}>
              <TrblModelsIcon height="12" width="12" />
              {modelOptions[index].name}
            </div>
          )}
        />
      </Box>
      <Box component="div" padding={'10px 10px 0px 10px'}>
        <FormControl>
          <RadioButtonGroup
            options={[
              {
                text: 'New simulation',
                value: NewSimulationTypes.New.toString(),
              },
              {
                text: 'Duplicate simulation settings',
                value: NewSimulationTypes.Copy.toString(),
              },
            ]}
            selectedValue={selectedRadio.toString()}
            onChange={(value) => {
              setSelectedRadio(value as NewSimulationTypes);
            }}
            horizontal={true}
          />
        </FormControl>
      </Box>
      <Box component="div">
        <FormControl
          disabled={simulationOptions.length === 0}
          // Style to show or hide dropdown depending on selected radio button
          style={
            selectedRadio === NewSimulationTypes.Copy
              ? { maxHeight: '50px', transition: '0.2s max-height, 0.05s margin', overflow: 'hidden', margin: '16px 0' }
              : { maxHeight: 0, transition: '0.2s max-height, 0.05s margin', overflow: 'hidden', margin: '0' }
          }>
          <div>
            <TrblSelect
              value={selectedSimulationToCopyId}
              setValue={setSelectedSimulationToCopyId}
              menuItems={simulationOptions}
              hasCategories={true}
              disabled={selectedRadio !== NewSimulationTypes.Copy || !simulationOptions.length}
              placeholder={
                simulationOptions.length ? 'Choose simulation to duplicate' : 'No other simulations available'
              }
            />
          </div>
        </FormControl>
        <FormControl>
          <div>
            <TextField autoFocus placeholder="Simulation name (required)" value={name} onChange={setName} />
          </div>
        </FormControl>
        <FormControl>
          <Box component="div" paddingTop={2} height={'100%'}>
            <TextArea
              placeholder="Simulation description"
              value={description}
              onChange={setDescription}
              style={{ height: '100%' }}
            />
          </Box>
        </FormControl>
      </Box>
    </>
  );
};
