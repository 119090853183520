import styles from './styles.module.scss';

type FilterTagProps = {
  label: string;
  color?: 'yellow' | 'trebleNeonGreen' | null;
  margin?: string;
  position?: 'relative' | 'absolute';
};

export const LabelTag = ({ label, color = null, margin, position }: FilterTagProps) => {
  return (
    <span
      style={{ margin: margin, position: position }}
      className={`${styles['label-tag']} ${styles['readonly']} ${!!color && styles[color]}`}>
      {label}
    </span>
  );
};
