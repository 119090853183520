import { useQuery } from '@tanstack/react-query';

import { TutorialDto } from '../types';

import axios from '@/axios';

const getTutorial = async (tutorialId: string) => {
  const { data } = await axios.get<TutorialDto>(`/api/Space/GetTutorial`, { params: { tutorialId } });

  return data;
};

export const useGetTutorialById = (tutorialId?: string, enabled: boolean = true) => {
  const query = useQuery<TutorialDto, boolean>(['tutorial', tutorialId], () => getTutorial(tutorialId!), {
    refetchOnWindowFocus: false,
    enabled: !!tutorialId && enabled,
  });

  return query;
};
