import { Box } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { TrblIcon } from '@/components/Icons';

export const ErrorContent = ({ errorTitle, errorText }: { errorTitle: string; errorText: string }) => {
  return (
    <>
      <Box component="div" display="flex" gap="5px" alignItems="self-end">
        <TrblIcon icon="timewatchUnfilled" />
        <Text type="medium-11px"> {errorTitle}</Text>
      </Box>
      <Text type="medium-11px" style={{ marginTop: '0px', color: '#ffbd59' }}>
        {errorText}
      </Text>
    </>
  );
};
