import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionType as EditorActionType, useEditorContext } from '@/context/EditorContext';
import { ActionType, useMeshContext } from '@/context/MeshContext';
import { useModelContext } from '@/context/ModelContext';

import { Box, CircularProgress, Stack } from '@mui/material';

import { SecondaryButton } from '@/components/Shared/Buttons';
import { Divider } from '@/components/Shared/Divider';
import { Label } from '@/components/Shared/Label';
import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { RadioButtonGroup } from '@/components/Shared/RadioButton';
import { useFeatureFlags } from '@/components/FeatureToggles/hooks/useFeatureFlags';
import { TrblAlertIcon, TrblInfoIcon, TrblTimewatchIcon } from '@/components/Icons';
import { ParameterKeys } from '../Results/components/ParameterResults/constants';
import { TrblTooltip } from '../Shared';
import { FrequencySlider } from './FrequencySlider';
import { FrequencySliderStandard } from './FrequencySliderStandard';

import { useSolverSettings } from './hooks';
import { useEstimatedImpulseLength } from './hooks/useEstimatedImpulseLength';
import { useGetEstimatedSimulationTime } from './hooks/useGetEstimatedSimulationTime';
import { useUpdateSolverSettings } from './hooks/useUpdateSolverSettings';

import {
  IMAGE_SOURCE_ORDER_DEFAULT,
  IMAGE_SOURCE_ORDER_DEFAULT_SURVEY,
  MAX_STANDARD_MESH_ELEMENTS,
  maxImpulseResponse,
  minImpulseResponse,
  NUMBER_OF_RAYS_DEFAULT,
  NUMBER_OF_RAYS_DEFAULT_SURVEY,
  RADIOSITY_RAYS_MAX,
  RADIOSITY_RAYS_MIN,
  RADIOSITY_RAYS_STEP,
  SolverTypes,
  STANDARD_FREQUENCY_LOWER_LIMIT,
  STANDARD_FREQUENCY_UPPER_LIMIT,
} from './constants';
import {
  defaultEnergyDecayThreshold,
  meshTooBigForLowerLimitMessage,
  meshTooBigForUpperLimitMessage,
  nonWatertightMessage,
  standardTransitionFrequencyMessage,
} from '@/utils/constants';

import { secToTimeString } from '../RunSimulationPopup/utils';
import { computeMeshElementCount, getEstimatedTransitionFrequency } from './utils';

import { PresetTypes } from './types';
import { CompletedMeshTask } from '@/context/MeshContext/types';
import { Simulation } from '@/types';

type SolverSettingsFormProps = {
  presetType: PresetTypes;
  selectedSimulation: Simulation;
  readonly: boolean;
  isStandardUser: boolean;
};

export const SolverSettingsForm: FC<SolverSettingsFormProps> = ({
  presetType,
  selectedSimulation,
  readonly,
  isStandardUser,
}) => {
  const { modelVolume, modelInformation } = useModelContext();
  const { dispatch: editorDispatch } = useEditorContext();
  const {
    dispatch,
    currentSimTimeEstimate,
    meshHasError,
    isEstimating,
    activeMeshTasks,
    completedMeshTasks,
    calculationError,
  } = useMeshContext();

  const { imageSourceOrderFeature } = useFeatureFlags();

  const { dgSettings, gaSettings } = selectedSimulation.solverSettings;

  const [transitionFrequency, setTransitionFrequency] = useState<number | undefined>(dgSettings?.crossoverFrequency);
  const [impulseResponseLength, setImpulseResponseLength] = useState<number | undefined>();
  const [taskType, setTaskType] = useState(selectedSimulation.taskType);
  const [numberOfRays, setNumberOfRays] = useState<number | undefined>(gaSettings?.rayCount);
  const [imageSourceOrder, setImageSourceOrder] = useState<number | undefined>(gaSettings?.reflectionOrder);
  const [energyDecayThreshold, setEnergyDecayThreshold] = useState<number | null>(dgSettings?.energyDecayThreshold);
  const [autoStop, setAutoStop] = useState(!!dgSettings.energyDecayThreshold);
  const [meshTooBigForLowerLimit, setmeshTooBigForLowerLimit] = useState(false);
  const [meshTooBigForUpperLimit, setmeshTooBigForUpperLimit] = useState(false);

  const updateSolverSettings = useUpdateSolverSettings();
  const {
    saveImpulseResponseLength,
    saveEnergyDecayThreshold,
    saveTaskType,
    saveTransitionFrequency,
    saveImageSourceOrder,
    saveNumberOfRays,
    startNewMeshTask,
  } = useSolverSettings();

  const [prevPresetType, setPrevPresetType] = useState(presetType);

  const estimateImpulseLength = useEstimatedImpulseLength();
  const getEstimatedSimulationTime = useGetEstimatedSimulationTime();

  // run this once when the simulation is loaded in the Editor
  useEffect(() => {
    if (!readonly) {
      let impulseLength = dgSettings.impulseLengthSeconds;

      // if autostop is selected (edt has value) then calculate default impulse response length
      if (dgSettings.energyDecayThreshold) {
        const estImpulseLength = estimateImpulseLength(selectedSimulation?.modelSettings?.materialIdByObjectId || {});

        if (estImpulseLength !== undefined) {
          impulseLength = estImpulseLength;
        }
      }
      // a catch for if Default or Survey selected and not set to autostop (edt is null), then set to autostop
      // (unless simulation is Complete or InProgress and not being edited)
      else if (!dgSettings.energyDecayThreshold && presetType !== PresetTypes.Advanced) {
        if (
          (selectedSimulation.extra?.status !== 2 && selectedSimulation.extra?.status !== 1) ||
          (selectedSimulation.extra?.status == 2 && selectedSimulation.hasBeenEdited)
        ) {
          handleAutoStopChange(ParameterKeys.EDT);
        }
      }
      setImpulseResponseLength(impulseLength);

      if (isStandardUser && selectedSimulation.extra?.status !== 2 && transitionFrequency) {
        // For backwards compatability with Standard users who had models set to higer transition frequencies before we
        // implemented the 355/177hz limit. then we call saveAndUpdate() whichs sets it to 177Hz
        if (
          (presetType === PresetTypes.Default && transitionFrequency !== STANDARD_FREQUENCY_LOWER_LIMIT) ||
          (presetType === PresetTypes.Advanced && transitionFrequency > STANDARD_FREQUENCY_UPPER_LIMIT)
        ) {
          saveAndUpdate();
        }
        // if mesh is too big for using the wave solver and taskType is not set to GA,
        // then we call saveAndUpdate() whichs sets it to the correct settings
        if (meshTooBigForLowerLimit && taskType !== SolverTypes.GA) {
          saveAndUpdate();
        }
      }
    }
  }, []);

  useEffect(() => {
    if (modelVolume && isStandardUser) {
      const meshLowerLimit = computeMeshElementCount(modelVolume, STANDARD_FREQUENCY_LOWER_LIMIT);
      const meshUpperLimit = computeMeshElementCount(modelVolume, STANDARD_FREQUENCY_UPPER_LIMIT);

      setmeshTooBigForLowerLimit(meshLowerLimit > MAX_STANDARD_MESH_ELEMENTS);
      setmeshTooBigForUpperLimit(meshUpperLimit > MAX_STANDARD_MESH_ELEMENTS);

      // for debugging
      //console.log([meshLowerLimit, meshUpperLimit]);

      if (meshLowerLimit > MAX_STANDARD_MESH_ELEMENTS && taskType !== SolverTypes.GA) {
        saveAndUpdate();
      }
    }
  }, [modelVolume]);

  useEffect(() => {
    if (presetType !== prevPresetType) {
      saveAndUpdate();
      // the default of all presets should be Autostop is ON
      setAutoStop(true);
    }
  }, [presetType]);

  const saveAndUpdate = async () => {
    let newTransitionFrequency = null;

    if (isStandardUser) {
      // For Standard users who had models set to higer transition frequencies before we implemented the 355/177hz limit
      // we set the transition frequency to 177Hz
      if (
        (presetType === PresetTypes.Default && transitionFrequency !== STANDARD_FREQUENCY_LOWER_LIMIT) ||
        (presetType === PresetTypes.Advanced && (transitionFrequency || 0) > STANDARD_FREQUENCY_UPPER_LIMIT)
      ) {
        newTransitionFrequency = STANDARD_FREQUENCY_LOWER_LIMIT;
        setTransitionFrequency(newTransitionFrequency);
      }
    } else {
      // For Premium users, if we are on Default preset type, we need to set the default transition frequency
      if (presetType === PresetTypes.Default && prevPresetType == PresetTypes.Advanced) {
        newTransitionFrequency = getEstimatedTransitionFrequency(modelVolume || 0);
        setTransitionFrequency(newTransitionFrequency);
      }
    }

    let taskType = presetType === PresetTypes.Survey ? SolverTypes.GA : SolverTypes.Hybrid;
    const numberOfRays = presetType === PresetTypes.Survey ? NUMBER_OF_RAYS_DEFAULT_SURVEY : NUMBER_OF_RAYS_DEFAULT;
    const imageSourceOrder =
      presetType === PresetTypes.Survey ? IMAGE_SOURCE_ORDER_DEFAULT_SURVEY : IMAGE_SOURCE_ORDER_DEFAULT;
    const energyDecayThreshold =
      presetType !== PresetTypes.Advanced ? defaultEnergyDecayThreshold : dgSettings.energyDecayThreshold;
    const estImpulseLength =
      estimateImpulseLength(selectedSimulation.modelSettings?.materialIdByObjectId || {}, energyDecayThreshold) ??
      dgSettings.impulseLengthSeconds;

    // Update the local state
    if (modelInformation?.nonWatertight || meshTooBigForLowerLimit) {
      taskType = SolverTypes.GA;
    }

    setTaskType(taskType);
    setNumberOfRays(numberOfRays);
    setImageSourceOrder(imageSourceOrder);
    setPrevPresetType(presetType);
    setEnergyDecayThreshold(energyDecayThreshold);
    setImpulseResponseLength(estImpulseLength);
    // Save new settings
    const updatedSimulation = {
      ...selectedSimulation,
      taskType,
      settingsPreset: presetType,
      solverSettings: {
        ...selectedSimulation.solverSettings,
        gaSettings: {
          ...selectedSimulation.solverSettings.gaSettings,
          reflectionOrder: imageSourceOrder,
          rayCount: numberOfRays,
          energyDecayThreshold: energyDecayThreshold,
          impulseLengthSeconds: estImpulseLength,
          crossoverFrequency: newTransitionFrequency ?? selectedSimulation.solverSettings.gaSettings.crossoverFrequency,
        },
        dgSettings: {
          ...selectedSimulation.solverSettings.dgSettings,
          energyDecayThreshold: energyDecayThreshold,
          impulseLengthSeconds: estImpulseLength,
          crossoverFrequency: newTransitionFrequency ?? selectedSimulation.solverSettings.dgSettings.crossoverFrequency,
        },
      },
    };

    editorDispatch({
      type: EditorActionType.SET_TRANSITION_FREQUENCY,
      transitionFrequency: newTransitionFrequency ?? selectedSimulation.solverSettings.dgSettings.crossoverFrequency,
    });

    await updateSolverSettings(updatedSimulation);
  };

  const handleTaskTypeChange = (taskType: string) => {
    setTaskType(taskType);
    saveTaskType(taskType);
    editorDispatch({
      type: EditorActionType.SET_TASK_TYPE,
      taskType,
    });
  };

  const handleSaveTransitionFrequency = (newTransitionFrequency: number | undefined) => {
    saveTransitionFrequency(newTransitionFrequency);
    if (newTransitionFrequency) {
      editorDispatch({
        type: EditorActionType.SET_TRANSITION_FREQUENCY,
        transitionFrequency: newTransitionFrequency,
      });
    }
  };

  const handleAutoStopChange = (type: string) => {
    // set the Impulse response to default
    setEstimatedImpulseResponseLength(defaultEnergyDecayThreshold);

    if (type === ParameterKeys.EDT) {
      setAutoStop(true);
      setEnergyDecayThreshold(defaultEnergyDecayThreshold);
      saveEnergyDecayThreshold(defaultEnergyDecayThreshold);
    } else if (type === 'irl') {
      setAutoStop(false);
      setEnergyDecayThreshold(null);
      saveEnergyDecayThreshold(null);
    }
  };

  const setEstimatedImpulseResponseLength = (edt: number | null) => {
    const irl =
      estimateImpulseLength(selectedSimulation.modelSettings!.materialIdByObjectId, edt) ??
      dgSettings.impulseLengthSeconds;
    setImpulseResponseLength(irl);
  };

  useEffect(() => {
    if (meshHasError) {
      toast.warning(
        "Failed to prepare geometry for the wave solver. Select 'Geometrical acoustics solver only' in Advanced settings to run a simulation, or contact support.",
        { autoClose: false }
      );
    }
  }, [meshHasError]);

  useEffect(() => {
    if (impulseResponseLength !== undefined && numberOfRays !== undefined && !readonly) {
      dispatch({ type: ActionType.SET_CURRENT_SIM_ESTIMATE, estimate: null });
    }
  }, [
    transitionFrequency,
    impulseResponseLength,
    numberOfRays,
    taskType,
    imageSourceOrder, // added this from a comment from Bjarni, this is BasisOrder
    selectedSimulation.gridReceivers?.length,
    selectedSimulation.receivers.length,
    selectedSimulation.sources.length,
    // EnergyDecayThreshold does also trigger this useEffect
  ]);

  useEffect(() => {
    if (
      transitionFrequency &&
      (activeMeshTasks.length === 0 ||
        activeMeshTasks.findIndex((x) => x.transitionFrequency === transitionFrequency) === -1)
    ) {
      getEstimatedSimulationTime(transitionFrequency, selectedSimulation);
    } else {
      dispatch({ type: ActionType.SET_IS_ESTIMATING, isEstimating: true });
    }
  }, [activeMeshTasks.length, completedMeshTasks.length]);

  const estimateSimulationTime = async () => {
    if (
      !currentSimTimeEstimate?.totalEstimatedRuntimeAdjustedForConcurrency &&
      transitionFrequency &&
      impulseResponseLength !== undefined &&
      impulseResponseLength >= minImpulseResponse &&
      impulseResponseLength <= maxImpulseResponse
    ) {
      dispatch({ type: ActionType.SET_IS_ESTIMATING, isEstimating: true });

      if (taskType === 'GA') {
        getEstimatedSimulationTime(0, selectedSimulation);
      } else {
        // Check if there is an existing mesh task for the selected transition frequency
        // TODO: consider moving this to the CalculateSimulationRunEstimatedTime endpoint so the frontend doesn't have to do this all the time
        const meshTask = completedMeshTasks.find(
          (completedMeshTask: CompletedMeshTask) => completedMeshTask.crossoverFrequency === transitionFrequency
        );
        if (meshTask) {
          getEstimatedSimulationTime(transitionFrequency, selectedSimulation);
        } else {
          startNewMeshTask(transitionFrequency, selectedSimulation.modelId);
        }
      }
    }
  };

  return (
    <Stack spacing={'20px'} flex="1 1 0">
      {presetType === PresetTypes.Survey && (
        <Stack spacing={'8px'} mt={'8px'}>
          <TrblAlertIcon />
          <Box component={'div'} fontWeight={400} fontSize="12px" lineHeight={'20px'} color={'#FFFFFF'}>
            Survey settings will generate a quick simulation using only the geometrical acoustics solver.
          </Box>
        </Stack>
      )}
      <Stack spacing={'15px'} mt={'4px'}>
        {presetType == PresetTypes.Default && (
          <>
            <Label sx={{ marginTop: '4px' }}>
              Transition frequency
              {isStandardUser && (
                <TrblTooltip title={standardTransitionFrequencyMessage} arrow>
                  <span style={{ margin: '-2px 0 0 6px', display: 'inline-block' }}>
                    <TrblInfoIcon width="10" height="10" />
                  </span>
                </TrblTooltip>
              )}
            </Label>
            {isStandardUser ? (
              <FrequencySliderStandard
                value={taskType !== SolverTypes.GA ? transitionFrequency : undefined}
                disabled={true}
                readOnly={readonly}
                onChange={setTransitionFrequency}
                onChangeCommitted={handleSaveTransitionFrequency}
                singleColorStyling={taskType === SolverTypes.DG}
              />
            ) : (
              <FrequencySlider
                value={taskType !== SolverTypes.GA ? transitionFrequency : undefined}
                disabled={true}
                readOnly={readonly}
                onChange={setTransitionFrequency}
                onChangeCommitted={handleSaveTransitionFrequency}
                singleColorStyling={taskType === SolverTypes.DG}
              />
            )}
          </>
        )}
        {presetType === PresetTypes.Advanced && (
          <>
            <Label sx={{ marginTop: '4px' }}>
              Transition frequency
              {isStandardUser && (
                <TrblTooltip title={standardTransitionFrequencyMessage} arrow>
                  <span style={{ margin: '-2px 0 0 6px', display: 'inline-block' }}>
                    <TrblInfoIcon width="10" height="10" />
                  </span>
                </TrblTooltip>
              )}
            </Label>
            {isStandardUser ? (
              <TrblTooltip
                title={
                  meshTooBigForLowerLimit
                    ? meshTooBigForLowerLimitMessage
                    : meshTooBigForUpperLimit
                    ? meshTooBigForUpperLimitMessage
                    : ''
                }
                arrow>
                <span>
                  <FrequencySliderStandard
                    value={taskType !== SolverTypes.GA ? transitionFrequency : undefined}
                    disabled={taskType === SolverTypes.GA}
                    isCapped={meshTooBigForUpperLimit}
                    readOnly={readonly}
                    onChange={setTransitionFrequency}
                    onChangeCommitted={handleSaveTransitionFrequency}
                    singleColorStyling={taskType === SolverTypes.DG}
                  />
                </span>
              </TrblTooltip>
            ) : (
              <FrequencySlider
                value={taskType !== SolverTypes.GA ? transitionFrequency : undefined}
                disabled={taskType === SolverTypes.GA}
                readOnly={readonly}
                onChange={setTransitionFrequency}
                onChangeCommitted={handleSaveTransitionFrequency}
                singleColorStyling={taskType === SolverTypes.DG}
              />
            )}
            <Divider marginOffset={20} marginTopOffset={20} />
            <Box component="p" fontWeight={700}>
              Termination criterion
            </Box>
            <Box component="div" display="flex" flexDirection={'row'}>
              <Stack width={'172px'} padding={'2px 0 0'}>
                <RadioButtonGroup
                  options={[
                    {
                      text: 'Energy decay threshold',
                      value: ParameterKeys.EDT,
                      readonly: readonly,
                    },
                    {
                      text: 'Impulse response length',
                      value: 'irl',
                      readonly: readonly,
                    },
                  ]}
                  selectedValue={autoStop ? ParameterKeys.EDT : 'irl'}
                  onChange={handleAutoStopChange}
                />
              </Stack>

              <Stack spacing={'8px'} width={'50%'} alignItems={'self-end'}>
                <TrblNumberInput
                  label={''}
                  value={energyDecayThreshold ?? undefined}
                  onChange={(value) => {
                    if (value === null || value == undefined) setEnergyDecayThreshold(null);
                    else setEnergyDecayThreshold(value);
                  }}
                  onBlur={(value) => {
                    if (value !== undefined) {
                      saveEnergyDecayThreshold(value);
                      setEstimatedImpulseResponseLength(value);
                    }
                  }}
                  decimals={0}
                  min={10}
                  max={60}
                  endAdornment={'dB'}
                  step={1}
                  disabled={!autoStop}
                  blurOnStep={false}
                  readOnly={readonly}
                />
                <TrblNumberInput
                  label={autoStop ? <span style={{ color: '#999999' }}></span> : ''}
                  value={autoStop ? undefined : impulseResponseLength}
                  onChange={setImpulseResponseLength}
                  onBlur={(value) => {
                    if (value !== undefined && value < minImpulseResponse) {
                      value = minImpulseResponse;
                      setImpulseResponseLength(minImpulseResponse);
                    }

                    saveImpulseResponseLength(value);
                  }}
                  decimals={3}
                  min={0} // Needs to be set to 0 so that the step doesn't react weird. Actually this min is 0.005
                  max={maxImpulseResponse}
                  endAdornment={'s'}
                  step={0.1}
                  disabled={autoStop}
                  readOnly={readonly}
                  blurOnStep={false}
                />
              </Stack>
            </Box>
          </>
        )}
      </Stack>
      {presetType !== PresetTypes.Survey && !readonly && (
        <SecondaryButton
          label={
            isEstimating
              ? 'Estimating...'
              : currentSimTimeEstimate?.totalEstimatedRuntimeAdjustedForConcurrency
              ? `${secToTimeString(
                  Math.round(currentSimTimeEstimate?.totalEstimatedRuntimeAdjustedForConcurrency)
                )} estimated`
              : currentSimTimeEstimate?.totalEstimatedRuntimeSeconds
              ? `${secToTimeString(Math.round(currentSimTimeEstimate?.totalEstimatedRuntimeSeconds))} estimated`
              : meshHasError
              ? 'Error preparing geometry'
              : calculationError
              ? 'Error calculating time'
              : 'Estimate simulation'
          }
          icon={isEstimating ? <CircularProgress size={15} /> : <TrblTimewatchIcon />}
          sx={
            currentSimTimeEstimate?.totalEstimatedRuntimeAdjustedForConcurrency
              ? {
                  ':hover': {
                    backgroundColor: '#3c3c3c',
                    cursor: 'default',
                  },
                  margin: '25px 0 5px !important',
                }
              : { margin: '25px 0 5px !important' }
          }
          disabled={!!isEstimating}
          onClick={estimateSimulationTime}
        />
      )}

      {presetType === PresetTypes.Advanced && (
        <>
          <RadioButtonGroup
            options={[
              {
                text: 'Both solvers',
                value: SolverTypes.Hybrid.toString(),
                disabled: !!modelInformation?.nonWatertight || meshTooBigForLowerLimit,
                readonly: readonly,
                tooltip: modelInformation?.nonWatertight
                  ? nonWatertightMessage
                  : meshTooBigForLowerLimit
                  ? meshTooBigForLowerLimitMessage
                  : '',
              },
              {
                text: 'Wave solver only',
                value: SolverTypes.DG.toString(),
                disabled: !!modelInformation?.nonWatertight || meshTooBigForLowerLimit,
                readonly: readonly,
                tooltip: modelInformation?.nonWatertight
                  ? nonWatertightMessage
                  : meshTooBigForLowerLimit
                  ? meshTooBigForLowerLimitMessage
                  : '',
              },
              {
                text: 'Geometrical acoustics solver only',
                value: SolverTypes.GA.toString(),
                readonly: readonly,
              },
            ]}
            selectedValue={taskType}
            onChange={handleTaskTypeChange}
          />
          <Divider marginOffset={20} marginTopOffset={25} />
          <Box
            component="div"
            sx={{
              transition: '0.2s opacity',
              opacity: taskType === SolverTypes.DG ? '0.5 !important' : '1',
            }}
            fontWeight={700}>
            Geometrical acoustics
          </Box>
          <Stack spacing="10px" paddingBottom="20px">
            <TrblNumberInput
              label={'Number of radiosity rays'}
              value={taskType !== SolverTypes.DG ? numberOfRays : undefined}
              onChange={setNumberOfRays}
              onBlur={saveNumberOfRays}
              step={RADIOSITY_RAYS_STEP}
              min={RADIOSITY_RAYS_MIN}
              max={RADIOSITY_RAYS_MAX}
              disabled={taskType === SolverTypes.DG}
              readOnly={readonly}
              blurOnStep={false}
            />

            <TrblNumberInput
              label={'Image source order'}
              value={taskType !== SolverTypes.DG ? imageSourceOrder : undefined}
              onChange={setImageSourceOrder}
              onBlur={saveImageSourceOrder}
              min={0}
              // If user has imageSourceOrder featureFlag then max is 20, default max is 10
              max={imageSourceOrderFeature ? 20 : 10}
              disabled={taskType === SolverTypes.DG}
              readOnly={readonly}
              blurOnStep={false}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};
