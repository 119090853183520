import { ChangeEvent } from 'react';

import { TrblSearchIcon } from '@/components/Icons';

import styles from './styles.module.scss';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
}

export const SearchInput = ({ value, onChange, placeholder = 'Search...', className }: SearchInputProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={`${styles['search-container']} ${className ?? ''}`}>
      <span className={styles['search-icon']}>
        <TrblSearchIcon width="12" height="12" fill="#adadad" />
      </span>
      <input type="text" value={value} onChange={handleChange} placeholder={placeholder} className={styles.input} />
    </div>
  );
};
