import { useBaseContext } from '@/context/BaseContext';

import { EarlyBirdExpiringBanner, TrialExpiringBanner } from '@/components/Banners';

export const SubscriptionBanners = () => {
  const {
    state: { subscriptionInfo },
  } = useBaseContext();

  const isEarlyBird = subscriptionInfo?.type === 'EarlyBird';
  const isTrial = subscriptionInfo?.type === 'Trial';
  const hasNotExpired =
    subscriptionInfo &&
    // checking if it is "not null" because it can be 0
    subscriptionInfo?.daysUntilExpired !== null &&
    subscriptionInfo.daysUntilExpired <= 14 &&
    subscriptionInfo.daysUntilExpired >= 0;
  const hasExpired = subscriptionInfo?.daysUntilExpired && subscriptionInfo.daysUntilExpired < 0;

  const showTrialExpiringBanner = (isTrial || isEarlyBird) && hasNotExpired;
  const showEarlyBirdExpiringBanner = isEarlyBird && hasExpired;

  return (
    <>
      {showTrialExpiringBanner ? (
        <TrialExpiringBanner
          daysUntilExpired={subscriptionInfo.daysUntilExpired}
          type={isEarlyBird ? 'Early bird subscription' : 'trial'}
        />
      ) : showEarlyBirdExpiringBanner ? (
        <EarlyBirdExpiringBanner />
      ) : null}
    </>
  );
};
