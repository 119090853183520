import React, { FC } from 'react';

import { Text } from '@/components/Shared/Text';

import styles from './styles.module.scss';

type CheckboxProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange'
> & {
  id: string;
  label?: string | React.ReactNode;
  labelAlignment?: 'left' | 'right';
  spaceBetween?: boolean;
  isChecked: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  checkboxColor?: 'green' | 'white';
};

export const Checkbox: FC<CheckboxProps> = ({
  id,
  label,
  labelAlignment = 'right',
  spaceBetween = false,
  onChange,
  isChecked,
  disabled,
  checkboxColor = 'green',
  ...props
}) => {
  const handleClick = () => {
    if (!disabled && onChange) {
      onChange(!isChecked);
    }
  };

  return (
    <div
      className={`${styles['checkbox-wrapper']} ${label && labelAlignment === 'right' ? styles['label-right'] : ''} ${
        checkboxColor === 'white' ? styles['checkbox-color-white'] : ''
      } ${spaceBetween ? styles['space-between'] : ''} ${disabled ? styles['disabled'] : ''}`}
      onClick={handleClick}
      role="checkbox"
      aria-checked={isChecked}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          handleClick();
        }
      }}>
      {label && (
        <label htmlFor={id}>{React.isValidElement(label) ? label : <Text type="regular-11px">{label}</Text>}</label>
      )}
      <input id={id} type="checkbox" checked={isChecked} disabled={disabled} tabIndex={-1} {...props} />
    </div>
  );
};
