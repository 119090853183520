import { useQuery } from '@tanstack/react-query';

import { SpaceDashboardDto } from '@/types';

import axios from '@/axios';

const getRecentSpaces = async (ownershipType: string, limit: number) => {
  const { data } = await axios.get<SpaceDashboardDto[]>(
    `/api/Space/GetRecentSpaces?limit=${limit}&ownershipType=${ownershipType}`
  );

  return data;
};

export const useGetRecentSpaces = (ownershipType: string = 'Accessible', limit: number = 10) => {
  return useQuery<SpaceDashboardDto[]>(['recent_spaces', ownershipType], () => getRecentSpaces(ownershipType, limit), {
    refetchOnWindowFocus: false,
  });
};
