import { FC } from 'react';

import { Box } from '@mui/material';

import { PrimaryButton } from '@/components/Shared/Buttons';
import { TrblPopupActions } from '@/components/Shared/Popup';
import { TrblTooltip } from '@/components/Shared/TrblTooltip';

interface PopupActionsProps {
  validationError: string | null;
  sourceBeingEdited: boolean;
  onSubmit: () => void;
}

export const PopupActions: FC<PopupActionsProps> = ({ validationError, sourceBeingEdited, onSubmit }) => (
  <TrblPopupActions>
    <Box component={'div'}>
      <TrblTooltip title={validationError}>
        <span>
          <PrimaryButton
            disabled={!!validationError}
            width={'fit-content'}
            label={sourceBeingEdited ? 'Rerun summing' : 'Sum sources'}
            onClick={onSubmit}
          />
        </span>
      </TrblTooltip>
    </Box>
  </TrblPopupActions>
);
