import { useEffect, useState } from 'react';

import { useBaseContext } from '@/context/BaseContext';

import { Button } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { EMPTY_GUID } from '@/components/Shared/constants';
import { Text } from '@/components/Shared/Text';
import { OpenOfficeParameters } from '../OpenOfficeParameters';
import { ParameterPlot } from '../ParameterPlot';
import { ParameterResultsHeader } from './ParameterResultsHeader';

import { AVAILABLE_PARAMETER_KEYS, ParameterKeys, ParameterNames } from './constants';

import { ParsedResponseData } from '../ResponsePlot/types';
import { ParsedParameterData, ResultParameterOption } from './types';

import classes from './styles.module.scss';

export const ParameterResults = ({
  parameterData,
  inTabs = false,
}: {
  parameterData: ParsedParameterData[];
  inTabs?: boolean;
}) => {
  const {
    state: { sidepanelCollapsed },
  } = useBaseContext();

  const [plotlyData, setPlotlyData] = useState<ParsedResponseData[]>([]);

  const [selectedParameter, setSelectedParameter] = useState(ParameterKeys.T20);
  const [availableResultParameters, setAvailableResultParameters] = useState<ResultParameterOption[]>([]);
  const [hasTransitionFreq, setHasTransitionFreq] = useState(false);
  const [showTransitionFreq, setShowTransitionFreq] = useState(true);
  const [selectedSTIParameter, setSelectedSTIParameter] = useState<ParameterKeys.STI | ParameterKeys.STI_USING_SPL>(
    ParameterKeys.STI
  );

  useEffect(() => {
    const isSummedSource = parameterData.map((x) => x.isSummedSource).includes(true);
    const allAreOmniSource = parameterData.map((x) => x.sourceDefinitionId).every((id) => id === EMPTY_GUID);

    if (isSummedSource || !allAreOmniSource) {
      setSelectedSTIParameter(ParameterKeys.STI_USING_SPL);
    }
  }, [parameterData]);

  // lil logic to switch between STI and STI_USING_SPL data when changing the
  // selected parameter because they bot live under the STI option in the UI
  useEffect(() => {
    if (selectedParameter === ParameterKeys.STI && selectedSTIParameter === ParameterKeys.STI_USING_SPL) {
      setSelectedParameter(ParameterKeys.STI_USING_SPL);
    } else if (selectedParameter === ParameterKeys.STI_USING_SPL && selectedSTIParameter === ParameterKeys.STI) {
      setSelectedParameter(ParameterKeys.STI);
    }
  }, [selectedParameter, selectedSTIParameter]);

  useEffect(() => {
    let availableParameterOptions: {
      key: ParameterKeys;
      name: ParameterNames;
      isEnabled: boolean;
    }[] = Object.keys(AVAILABLE_PARAMETER_KEYS).map((param) => {
      return {
        key: param as ParameterKeys,
        // @ts-expect-error - TS doesn't like the fact that we're using the key to get the value
        name: AVAILABLE_PARAMETER_KEYS[param].name,
        isEnabled: true,
      };
    });

    if (parameterData.length) {
      if (parameterData.findIndex((x) => x.resultType === 'Hybrid' || x.resultType === 'GA') === -1) {
        availableParameterOptions = availableParameterOptions.filter(
          (x) => x.key !== ParameterKeys.STI && x.key !== ParameterKeys.STI_USING_SPL
        );
      } else {
        availableParameterOptions = [
          ...availableParameterOptions,
          { key: ParameterKeys.OOP, name: ParameterNames.OOP, isEnabled: true },
        ];
      }

      // If we only have summed sources we default to SPL (if SPL or STI are not selected) and disable the open office parameters
      if (parameterData.every((x) => x.isSummedSource)) {
        availableParameterOptions = availableParameterOptions.map((x) => ({
          key: x.key,
          name: x.name,
          isEnabled: ![ParameterKeys.OOP, ParameterKeys.G].includes(x.key),
        }));
        if ([ParameterKeys.OOP, ParameterKeys.G].includes(selectedParameter)) {
          setSelectedParameter(ParameterKeys.SPL);
        }
      } else if (!availableParameterOptions.some((x) => x.key === selectedParameter && x.isEnabled)) {
        setSelectedParameter(ParameterKeys.T20);
      }

      setAvailableResultParameters(availableParameterOptions);
    }
  }, [parameterData]);

  return (
    <div
      className={`${classes.plot_container} ${inTabs ? classes.tabs : ''} ${
        sidepanelCollapsed ? classes.collapsed : ''
      }`}>
      <div>
        <ParameterResultsHeader
          parameterData={parameterData}
          plotlyData={plotlyData}
          selectedParameter={selectedParameter}
          selectedSTIParameter={selectedSTIParameter}
          setSelectedParameter={setSelectedParameter}
          setAvailableResultParameters={setAvailableResultParameters}
          setSelectedSTIParameter={setSelectedSTIParameter}
        />
        <div className={classes.plot_parameters}>
          {availableResultParameters.map((parameter) => (
            <TrblTooltip
              enterDelay={500}
              key={parameter.key}
              title={!parameter.isEnabled ? 'Not available for summed sources' : ''}>
              <Button
                key={parameter.key}
                variant={
                  parameter.key === selectedParameter ||
                  // the STI_USING_SPL and STI live both under the STI key option
                  (selectedParameter === ParameterKeys.STI_USING_SPL && parameter.key === ParameterKeys.STI)
                    ? 'contained'
                    : undefined
                }
                disabled={!parameter.isEnabled}
                onClick={() => setSelectedParameter(parameter.key)}>
                {parameter.name}
              </Button>
            </TrblTooltip>
          ))}
        </div>
      </div>
      {selectedParameter !== ParameterKeys.OOP ? (
        <div className={classes.plot_container__graph}>
          <ParameterPlot
            plotlyData={plotlyData}
            onPlotlyDataCreated={setPlotlyData}
            parameterData={parameterData}
            selectedParameter={selectedParameter}
            showTransitionFrequency={showTransitionFreq}
            setHasTransitionFrequency={setHasTransitionFreq}
          />
          {hasTransitionFreq && (
            <TrblTooltip title="Show/Hide transition frequency">
              <div
                className={classes.transition_freq_legend}
                style={{
                  opacity: showTransitionFreq ? 1 : 0.5,
                }}
                onClick={() => setShowTransitionFreq(!showTransitionFreq)}>
                <svg width="10" height="10" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.8" d="M0 1.5L10 1.5" stroke="#DADADA" strokeWidth="2.5" strokeDasharray="2 2"></path>
                </svg>
                <Text type="semibold-10px" color="">
                  TF
                </Text>
              </div>
            </TrblTooltip>
          )}
        </div>
      ) : (
        <div className={classes.oop_container}>
          <OpenOfficeParameters parameterData={parameterData} />
        </div>
      )}
    </div>
  );
};
