import { useState } from 'react';

import { CircularProgress } from '@mui/material';

import { TrblListIcon, TrblSimulationIcon } from '@/components/Icons';
import { SortType } from '@/components/SimulationSidePanel/constants';
import { SortButtons } from '@/components/SimulationSidePanel/SimulationFilterMenu/SortButtons';
import { TrblCustomSelect } from '../TrblCustomSelect';
import { SimulationStatus } from './SimulationStatus';

import { toSorted } from '@/utils/trebleFunctions';

import { ModelBaseDto, ModelSimulationsDto, Simulation } from '@/types';

import classes from './styles.module.scss';

export const SimulationList = ({
  selectedSimulations,
  selectedGeometry,
  modelSimulations,
}: {
  selectedSimulations: Simulation[];
  selectedGeometry: ModelBaseDto | null;
  modelSimulations: ModelSimulationsDto[] | undefined;
}) => {
  const [sortListBy, setSortListBy] = useState(SortType.MODIFIED);

  const sortOptions = [
    { label: 'Name', type: SortType.NAME },
    { label: 'Modified time', type: SortType.MODIFIED },
    { label: 'Created time', type: SortType.CREATED },
  ];

  const sortSimulations = (simulations: Simulation[], sortListBy: SortType) => {
    switch (sortListBy) {
      case SortType.NAME: {
        return toSorted(simulations, (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      }
      case SortType.MODIFIED: {
        return toSorted(simulations, (a, b) =>
          new Date(b.updatedAt || b.lastSimulationRun?.completedAt || b.createdAt) >
          new Date(a.updatedAt || a.lastSimulationRun?.completedAt || a.createdAt)
            ? 1
            : -1
        );
      }

      case SortType.CREATED: {
        return toSorted(simulations, (a, b) => (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1));
      }
    }
  };

  return (
    <>
      <div className={classes.title_container}>
        <div className={classes.title_text}>
          <TrblSimulationIcon width="14" height="14" />
          <p>
            {selectedGeometry ? (
              <>
                Simulations for selected · <span style={{ fontWeight: '400' }}>{selectedSimulations.length}</span>
              </>
            ) : (
              <>Simulations</>
            )}
          </p>
        </div>
        <TrblCustomSelect
          selectedItem={
            <div className={classes.filter_title}>
              <TrblListIcon width="14" height="14" fill="#adadad" />
              <span>Sort</span>
            </div>
          }
          dropdownWidth="160px">
          <div className={`${classes.filter_dropdown_content} filter-dropdown`}>
            <SortButtons sortListBy={sortListBy} setSortListBy={setSortListBy} sortOptions={sortOptions} />
          </div>
        </TrblCustomSelect>
      </div>
      <div className={classes.space_simulations_list}>
        {modelSimulations ? (
          selectedSimulations.length > 0 ? (
            sortSimulations(selectedSimulations, sortListBy).map((simulation) => (
              <SimulationStatus key={simulation.id} simulation={simulation} />
            ))
          ) : (
            <p style={{ fontWeight: '400', color: '#adadad' }}>No simulations in this space</p>
          )
        ) : (
          <div style={{ textAlign: 'center', margin: '24px 0' }}>
            <CircularProgress size={20} />
          </div>
        )}
      </div>
    </>
  );
};
