import { ReactNode, useEffect, useState } from 'react';

import { IconButton } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { NameMarker } from '@/components/Shared/NameMarker';
import { getColorById, getMarkerContentForMember } from '@/components/Shared/NameMarker/utils';
import { TrblCloseIcon } from '@/components/Icons';

import { UserBasicDto } from '@/types';

import classes from './styles.module.scss';

export const MemberListItem = ({
  member,
  children,
  message,
  removeMember,
}: {
  member: UserBasicDto;
  children?: ReactNode;
  message?: string;
  removeMember?: (member: UserBasicDto) => void;
}) => {
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [activeMessage, setActiveMessage] = useState(false);
  const [showCloseBtn, setShowCloseBtn] = useState(false);

  useEffect(() => {
    if (message) {
      // if there is a message then hide closeBtn, fade confirmationMessage in and out, and then show closeBtn
      setConfirmationMessage(message);
      setTimeout(() => setActiveMessage(true), 50);
      setTimeout(() => {
        setActiveMessage(false);
        setShowCloseBtn(true);
      }, 1000);
      setTimeout(() => setConfirmationMessage(''), 1500);
    } else {
      if (removeMember) setShowCloseBtn(true);
    }
  }, [message]);

  const handleRemoveMember = (member: UserBasicDto) => {
    if (removeMember) {
      // fade confirmationMessage in and out before calling removeMember()
      setConfirmationMessage('Removed');
      setTimeout(() => setActiveMessage(true), 50);
      setTimeout(() => {
        setActiveMessage(false);
        setShowCloseBtn(false);
      }, 900);

      setTimeout(() => removeMember(member), 1000);
    }
  };

  return (
    <div className={classes.list_item}>
      <div className={classes.initials}>
        <NameMarker
          content={getMarkerContentForMember(member)}
          color="#1f1f1f"
          background={getColorById(member.id)}
          size="large"
          lineStyle="solid"
        />
        <div className={classes.details_container}>
          <div className={classes.details_container__name}>{`${member.firstName ?? ''} ${member.lastName ?? ''}`}</div>
          <div className={classes.details_container__email}>{member.email}</div>
        </div>
      </div>
      {children}
      {showCloseBtn && (
        <TrblTooltip title="Remove member from project">
          <IconButton aria-label="close" onClick={() => handleRemoveMember(member)}>
            <TrblCloseIcon width="11" height="11" />
          </IconButton>
        </TrblTooltip>
      )}
      {confirmationMessage && (
        <div className={`${classes.confirmation_message} ${activeMessage ? classes.active : ''}`}>
          {confirmationMessage}
        </div>
      )}
    </div>
  );
};
