import { FC, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useResultsContext } from '../Results/context/ResultsContext';
import { BaseContextActionType, useBaseContext } from '@/context/BaseContext';
import { ActionType as EdActionType, useEditorContext } from '@/context/EditorContext';
import { ModelActionType, useModelContext } from '@/context/ModelContext';
import { ActionType as SimActionType, useSimulationContext } from '@/context/SimulationContext';

import { Auralizer, EditSimulation, SimulationSidePanel, TrblModelsIcon } from '@/components';
import { EditorContent } from './components/EditorContent';
import { EditorHeader } from './components/EditorHeader';
import { ResultsHeader } from './components/ResultsHeader';
import { AutoSaveText } from '../EditSimulation/AutoSaveText';
import { useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';
import { MaterialLibrary } from '../MaterialLibrary';
import { PageLayout } from '../New/PageLayout/PageLayout';
import { SourceDefinitionLibrary } from '../SourceDefinitionLibrary';

import { useSaveUpdatedSimulation } from '../EditSimulation/hooks/useSaveUpdatedSimulation';
import { useGetSimulationFromRouteParam } from './hooks/useGetSimulationFromRouteParam';
import {
  useGetModelInformation,
  useGetProjectById,
  useGetSimulationsBySpaceId,
  useLoadAndExtractFileFromUrl,
} from '@/hooks';

import { ResultsView } from '@/context/EditorContext/types';

import './styles.scss';

type EditorProps = {
  showResults: boolean;
  showAuralizer: boolean;
};

export const EditorNew: FC<EditorProps> = ({ showResults, showAuralizer }) => {
  const { user } = useAuth0();
  const userRoles: string[] = user ? user['https://treble.tech/roles'] : [];
  const {
    dispatch: baseDispatch,
    state: { userInfo, sidepanelCollapsed },
  } = useBaseContext();

  const [searchParams] = useSearchParams();
  const queryModelId = searchParams.get('mid') || '';
  const querySimulationId = searchParams.get('sid') || '';
  const saveSimulation = useSaveUpdatedSimulation();
  const { pathname } = useLocation();

  const { dispatch: simDispatch } = useSimulationContext();
  const { isMaterialsLibraryOpen, isSourceDefinitionLibraryOpen } = useLibraryPanelContext();
  const { isLoading, editSimulation, readonly, dispatch: edDispatch } = useEditorContext();
  const { dispatch: modelDispatch, addModelFromFile, isModelLoaded } = useModelContext();
  const { emptyResults, availableComparisons, selectedComparisonIndex, selectedPreset } = useResultsContext();

  // if a result preset is selected we fetch the select simulation in the availableComparisons
  const resultPresetModelId =
    availableComparisons[selectedComparisonIndex]?.formState?.selectedSimulation?.modelId || '';
  // we always select the modelId from the route/url, but if it's not there we use the resultPresetModel id
  const currentModelId = queryModelId || resultPresetModelId;

  let thisBypassPointValidity = false;

  const {
    data: modelInformationData = null,
    isSuccess: modelInformationSuccess,
    isLoading: modelInformationLoading,
  } = useGetModelInformation(currentModelId);
  const { data: modelFile } = useLoadAndExtractFileFromUrl(modelInformationData?.modelUrl || null, currentModelId);

  const { data: project } = useGetProjectById(modelInformationData?.projectId || '');
  const { data: modelSimulations, isLoading: isLoadingSimulations } = useGetSimulationsBySpaceId(
    modelInformationData?.spaceId || ''
  );
  useGetSimulationFromRouteParam();

  useEffect(() => {
    // On initial load we fetch all the simulations in all geometries for a space
    // and keep them in state variable called "availableSimulations"
    if (modelSimulations) {
      simDispatch({
        type: SimActionType.SET_MODEL_SIMULATIONS,
        simulations: modelSimulations.flatMap((simsByModels) =>
          simsByModels.simulations.map((sim) => ({ ...sim, modelBaseName: simsByModels.modelBaseName }))
        ),
      });
    }
  }, [modelSimulations]);

  useEffect(() => {
    if (pathname.includes('/editor')) {
      // if currentModelId changes, then RESET Editor state to clear all Editor variables to prevent any
      // cross-contaminatin of objects between models
      edDispatch({
        type: EdActionType.RESET_STATE,
      });
    }
    edDispatch({
      type: EdActionType.SET_SELECTED_SIMULATION_LOADED,
      payload: false,
    });
    modelDispatch({
      type: ModelActionType.SET_CURRENT_MODEL_ID,
      modelId: currentModelId,
    });
  }, [currentModelId]);

  useEffect(() => {
    if (!showResults) {
      // Reset to default view when exiting results mode
      edDispatch({
        type: EdActionType.SET_RESULTS_VIEW,
        payload: ResultsView.ResultsModelView,
      });
    }

    edDispatch({
      type: EdActionType.SET_IS_IN_RESULTS_MODE,
      payload: showResults,
    });
  }, [showResults]);

  useEffect(() => {
    edDispatch({
      type: EdActionType.SET_IS_IN_AURALIZER_MODE,
      isOpen: showAuralizer,
    });
  }, [showAuralizer]);

  // extra keydown listener for Treble users to bypass point validation
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'u') {
      thisBypassPointValidity = !thisBypassPointValidity;
      modelDispatch({
        type: ModelActionType.SET_BYPASS_POINT_VALIDITY,
        bypass: thisBypassPointValidity,
      });
    }
  };

  // Since we are storing sources, receivers etc in the editor context we need to reset the state when we unmount
  useEffect(() => {
    //if Treble user then add extra keydown listener
    if (userRoles.includes('Superuser')) window.addEventListener('keydown', handleKeyDown);

    return () => {
      //if Treble user then remove extra keydown listener when exiting Editor
      if (userRoles.includes('Superuser')) window.removeEventListener('keydown', handleKeyDown);
      edDispatch({
        type: EdActionType.RESET_STATE,
      });
    };
  }, []);

  useEffect(() => {
    if (modelInformationData) {
      modelDispatch({
        type: ModelActionType.UPDATE_MODEL_INFORMATION,
        modelInformation: modelInformationData,
      });
    }
  }, [modelInformationData]);

  useEffect(() => {
    if (!isModelLoaded && currentModelId && modelFile && queryModelId) {
      addModelFromFile(currentModelId, modelFile);
    }
  }, [isModelLoaded, currentModelId, modelFile]);

  const onConfirm = async () => {
    await saveSimulation(editSimulation.updatedSimulation, editSimulation.saveText);
    edDispatch({
      type: EdActionType.SHOW_EDIT_MODAL,
      editSimulation: { showModal: false, updatedSimulation: null },
    });
  };

  const handleCollapseSidepanel = () => {
    baseDispatch({
      type: BaseContextActionType.SET_SIDEPANEL_COLLAPSED,
      sidepanelCollapsed: !sidepanelCollapsed,
    });
  };

  useEffect(() => {
    if (pathname.includes('/editor') && sidepanelCollapsed) {
      baseDispatch({
        type: BaseContextActionType.SET_SIDEPANEL_COLLAPSED,
        sidepanelCollapsed: false,
      });
    }
  }, [pathname]);

  const sidepanelReady = !modelInformationLoading && modelInformationSuccess && currentModelId;

  return (
    <PageLayout
      isFetching={isLoading}
      emptyResults={emptyResults}
      extraHeader={
        <EditorHeader
          modelInformation={modelInformationData}
          project={project ?? null}
          userInfo={userInfo}
          selectedPreset={selectedPreset}
          showResults={showResults}
          showAuralizer={showAuralizer}
          simulationId={querySimulationId}
        />
      }
      sidepanelWidth="352px"
      sidepanel={
        sidepanelReady ? <SimulationSidePanel isLoading={isLoadingSimulations} isNewEditor={true} /> : <div></div>
      }
      sidepanelCollapsible={readonly}
      onCollapseSidepanel={handleCollapseSidepanel}
      sidepanelCollapsed={sidepanelCollapsed}>
      <div className="editor-container">
        {showResults && <ResultsHeader />}
        <EditorContent showResults={showResults} sidepanelCollapsed={sidepanelCollapsed} />
        {!readonly && isMaterialsLibraryOpen ? (
          <MaterialLibrary />
        ) : !readonly && isSourceDefinitionLibraryOpen ? (
          <SourceDefinitionLibrary />
        ) : null}
        {showAuralizer ? <Auralizer /> : null}
        {!readonly ? (
          <EditSimulation
            showPopup={editSimulation.showModal}
            updatedSimulation={editSimulation.updatedSimulation}
            onConfirm={onConfirm}
            saveText={editSimulation.saveText}
          />
        ) : null}

        {!readonly ? <AutoSaveText /> : null}
        {!showResults && !showAuralizer && (
          <div className="editor-geometry-name">
            <TrblModelsIcon fill="#eaeaea" />
            <div>{modelInformationData?.modelBaseName}</div>
          </div>
        )}
      </div>
    </PageLayout>
  );
};
