import { IconFilterTag } from '@/components/Shared/Tags/IconFilterTag';
import { TrblOrganizationOwnedIcon, TrblUserCreatedIcon } from '@/components/Icons';

import './styles.scss';

const MAIN_FILTERS = [
  {
    tag: 'Default',
    icon: null,
  },
  { tag: 'Organization', icon: <TrblOrganizationOwnedIcon height="10" width="12" fill="#adadad" /> },
  { tag: 'Created by me', icon: <TrblUserCreatedIcon height="10" width="10" fill="#adadad" /> },
];

export const TabFilters = ({
  selectedCategories,
  selectFilters,
}: {
  selectedCategories: string[];
  selectFilters: (category: string[]) => void;
}) => {
  const mainFilterNames = MAIN_FILTERS.map((filter) => filter.tag);

  const selectCategory = (category: string, event: React.MouseEvent<HTMLElement>) => {
    if (event.type === 'click') event.stopPropagation();

    const index = selectedCategories.findIndex((selected) => selected === category);
    const filteredCategories = [...selectedCategories].filter((category) => !mainFilterNames.includes(category));
    let newCategories = [...filteredCategories];

    if (index === -1) {
      newCategories = [...filteredCategories, category];
    }

    selectFilters(newCategories);
  };

  return (
    <div className="main-filters">
      {MAIN_FILTERS.map((filter) => (
        <IconFilterTag
          key={filter.tag}
          tag={filter.tag}
          selectTag={selectCategory}
          icon={filter.icon}
          isSelected={selectedCategories.includes(filter.tag)}
        />
      ))}
    </div>
  );
};
