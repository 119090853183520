/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';

import { ResultComparisonAction } from './actions';
import { ResultComparisonReducer } from './reducer';

import { ActionType, initialState } from '../constants';

import { ResultComparisonState } from '../../../types';

type ResultComparisonProviderProps = { children: ReactNode };

export type ResultComparisonDispatch = (action: ResultComparisonAction) => void;

const ResultComparisonContext = createContext<
  | {
      state: ResultComparisonState;
      dispatch: ResultComparisonDispatch;
    }
  | undefined
>(undefined);

const ResultComparisonProvider = ({ children }: ResultComparisonProviderProps) => {
  const [state, dispatch] = useReducer(ResultComparisonReducer, initialState);

  const value = { state, dispatch };

  useEffect(() => {
    if (state.lastGridResults && !state.simulationData?.gridReceiverParameterValues) {
      const promisesWithParameters: {
        promise: Promise<AxiosResponse<any, any>>;
        sourcePointId: string;
        gridReceiverPointId: string;
      }[] = [];

      // We want to load the result parameter values for all source / receiver pairs for the default result type
      state.lastGridResults
        .filter((x) => x.resultType === state.resultType)
        .forEach((y) => {
          y.gridReceiverResults.forEach((x) => {
            const promise = axios.get(x.parametersDownloadUrl);

            promisesWithParameters.push({ promise, sourcePointId: y.sourcePointId, gridReceiverPointId: x.pointId });
          });
        });

      Promise.all(promisesWithParameters.map((p) => p.promise))
        .then((results) => {
          const gridReceiverParametersForSource: Record<string, Record<string, any>> = {};

          for (let i = 0; i < results.length; i++) {
            const result = results[i];
            const { sourcePointId, gridReceiverPointId } = promisesWithParameters[i];

            if (gridReceiverParametersForSource[sourcePointId]) {
              gridReceiverParametersForSource[sourcePointId][gridReceiverPointId] = result.data;
            } else {
              gridReceiverParametersForSource[sourcePointId] = {};
              gridReceiverParametersForSource[sourcePointId][gridReceiverPointId] = result.data;
            }
          }

          dispatch({
            type: ActionType.UPDATE_GRID_RECEIVER_RESULT_PARAMETERS,
            payload: gridReceiverParametersForSource,
          });
        })
        .catch(() => {
          toast.error('Failed to load results for surface receiver');
        });
    }
  }, [state.lastGridResults]);

  useEffect(() => {
    if (
      state.lastGridReceiverSourceSummingResults?.length &&
      !state.simulationData?.gridReceiverParameterValuesForSummedSources
    ) {
      const promisesWithParameters: {
        promise: Promise<AxiosResponse<any, any>>;
        sourcePointIds: string[];
        gridReceiverPointId: string;
      }[] = [];

      // We want to load the result parameter values for all source / receiver pairs for the default result type
      state.lastGridReceiverSourceSummingResults
        .filter((x) => x.resultType === state.resultType)
        .forEach((y) => {
          y.gridReceiverResults.forEach((x) => {
            const promise = axios.get(x.parametersDownloadUrl);

            promisesWithParameters.push({
              promise,
              sourcePointIds: y.sources.map((s) => s.id),
              gridReceiverPointId: x.pointId,
            });
          });
        });

      Promise.all(promisesWithParameters.map((p) => p.promise))
        .then((results) => {
          const gridReceiverParametersForSource: Record<string, Record<string, any>> = {};

          for (let i = 0; i < results.length; i++) {
            const result = results[i];
            const { sourcePointIds, gridReceiverPointId } = promisesWithParameters[i];

            const summedSourceKey = [...sourcePointIds].sort((a, b) => a.localeCompare(b)).join(';');

            if (gridReceiverParametersForSource[summedSourceKey]) {
              gridReceiverParametersForSource[summedSourceKey][gridReceiverPointId] = result.data;
            } else {
              gridReceiverParametersForSource[summedSourceKey] = {};
              gridReceiverParametersForSource[summedSourceKey][gridReceiverPointId] = result.data;
            }
          }

          dispatch({
            type: ActionType.UPDATE_GRID_RECEIVER_RESULT_PARAMETERS_FOR_SUMMED_SOURCES,
            payload: gridReceiverParametersForSource,
          });
        })
        .catch(() => {
          toast.error('Failed to load surface receiver results for summed sources');
        });
    }
  }, [state.lastGridReceiverSourceSummingResults]);

  return <ResultComparisonContext.Provider value={value}>{children}</ResultComparisonContext.Provider>;
};

// Custom Context hook to easily access the state and dispatch actions
const useResultComparisonContext = () => {
  const context = useContext(ResultComparisonContext);
  if (context === undefined) {
    throw new Error('useResultComparisonContext must be used within ResultComparisonProvider');
  }
  return context;
};

export { ResultComparisonProvider, useResultComparisonContext };
