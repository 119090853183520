import { FC } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { IconButton } from '@mui/material';

import { PageLayout } from '@/components';
import { TrblChevronLeft } from '@/components/Icons';
import { Organization } from '@/components/ManagementPortal';
import { useGetOrganizationById } from '@/components/ManagementPortal/hooks';

import { useGetOrganizationMine } from '@/hooks';

export const OrganizationPage: FC = () => {
  const location = useLocation();
  const { id: organizationId } = useParams();
  const { data: organization } = useGetOrganizationById(organizationId || '');
  const { data: myOrganization } = useGetOrganizationMine();

  return (
    <PageLayout
      extraHeader={
        <div className="breadcrumbs-div">
          <Link to={location.state ?? '/organizations'}>
            <IconButton className="back-btn">
              <TrblChevronLeft />
            </IconButton>
          </Link>
          <div className="top-info-text">
            <Link to={'/organizations'}>Organizations</Link>
            <span className="arrow"> &rsaquo; </span>
            <span className="active">{organization?.organization.name}</span>
          </div>
        </div>
      }>
      <Organization
        organizationId={organizationId || ''}
        isMyOrganization={myOrganization ? myOrganization.id === organizationId : false}
      />
    </PageLayout>
  );
};
