import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblEyeIcon: FC<IconProps> = ({ width = '12', height = '7', fill = '#616161' }) => (
  <svg width={width} height={height} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0002 0.000449803C8.20083 -0.0288726 10.395 1.4259 11.7178 2.80216C12.0936 3.19858 12.0943 3.79878 11.7193 4.19592C10.4105 5.558 8.24784 7 6.07372 7H5.91967C3.75306 7 1.58994 5.558 0.282566 4.1964C-0.0928865 3.79946 -0.0928865 3.1991 0.282566 2.80216C1.60544 1.42637 3.79957 -0.0293456 6.0002 0.000449803ZM5.99988 0.662354C4.34255 0.662354 2.99902 1.93282 2.99902 3.50001C2.99902 5.0672 4.34255 6.33766 5.99988 6.33766C7.65721 6.33766 9.00074 5.0672 9.00074 3.50001C9.00074 1.93282 7.65721 0.662354 5.99988 0.662354Z"
      fill={fill}
    />
    <path
      d="M6.00029 4.44597C6.55273 4.44597 7.00057 4.02248 7.00057 3.50008C7.00057 2.97769 6.55273 2.5542 6.00029 2.5542C5.44784 2.5542 5 2.97769 5 3.50008C5 4.02248 5.44784 4.44597 6.00029 4.44597Z"
      fill={fill}
    />
  </svg>
);
