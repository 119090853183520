export enum SolverTypes {
  GA = 'GA',
  DG = 'DG',
  Hybrid = 'Hybrid',
}

export const minImpulseResponse = 0.005;
export const maxImpulseResponse = 20;

export const NUMBER_OF_RAYS_DEFAULT_SURVEY = 10000;
export const NUMBER_OF_RAYS_DEFAULT = 20000;

export const IMAGE_SOURCE_ORDER_DEFAULT = 3;
export const IMAGE_SOURCE_ORDER_DEFAULT_SURVEY = 2;

export const RADIOSITY_RAYS_MAX = 150000;
export const RADIOSITY_RAYS_MIN = 1000;
export const RADIOSITY_RAYS_STEP = 100;

export const MAX_STANDARD_MESH_ELEMENTS = 250000;

export const STANDARD_FREQUENCY_LOWER_LIMIT = 177;
export const STANDARD_FREQUENCY_UPPER_LIMIT = 355;
