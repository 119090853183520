import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FilterTag } from '@/components/Shared/Tags/FilterTag';
import { PageLayout } from '@/components/New/PageLayout/PageLayout';
import { TutorialCard } from '../TutorialCard';

import { useGetTutorials, useStartTutorial } from './hooks';

import { COMING_SOON_TUTORIALS } from './constants';

import { handleOpenTutorialGuide } from './utils';

import { Tutorial } from './types';

import styles from './styles.module.scss';

export const TutorialsPage = () => {
  const navigate = useNavigate();

  const { data: tutorials, isLoading } = useGetTutorials();
  const { mutate: startTutorial } = useStartTutorial();

  const transformedTutorials: Tutorial[] = useMemo(() => {
    const liveTutorials =
      tutorials?.map((tutorial) => ({
        ...tutorial,
        isComingSoon: false,
      })) ?? [];

    return [...liveTutorials, ...COMING_SOON_TUTORIALS];
  }, [tutorials]);

  const tags = useMemo(() => {
    return Array.from(new Set(transformedTutorials?.flatMap((tutorial) => tutorial.tags.split(','))));
  }, [transformedTutorials]);

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const selectTag = (tag: string) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const filteredTutorials = useMemo(() => {
    return (
      transformedTutorials?.filter(
        (x) => selectedTags.length === 0 || x.tags.split(',').some((t) => selectedTags.includes(t))
      ) ?? []
    );
  }, [transformedTutorials, selectedTags]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTutorialCardClick = async (tutorial: Tutorial) => {
    startTutorial(tutorial.id, {
      onSuccess: (data) => {
        navigate(`/editor?mid=${data.modelId}&sid=${data.simulationId}`);
        setTimeout(() => {
          handleOpenTutorialGuide(tutorial.documentationUrl);
        }, 1000);
        toast.success(`Tutorial "${data.name}" started!\n You can now access the tutorial from "My Projects"`, {
          className: 'editor-toast',
        });
      },
    });
  };

  return (
    <PageLayout isFetching={isLoading} navpanel={true}>
      <div className={styles['page-content']}>
        <div className={styles['header']}>
          <span className={styles['header-title']}>Tutorials</span>
        </div>
        <div className={styles['sub-header']}>
          <div className={styles['filters']}>
            {tags.map((tag) => (
              <FilterTag
                key={tag}
                tag={tag}
                selectTag={selectTag}
                isSelected={selectedTags.includes(tag)}
                size="medium"
              />
            ))}
          </div>
        </div>
        <div className={styles['tutorials']}>
          {filteredTutorials.map((tutorial) => (
            <TutorialCard
              key={tutorial.id}
              name={tutorial.name}
              description={tutorial.description}
              tags={tutorial.tags.split(',')}
              imageUrl={tutorial.thumbnailUrl}
              isComingSoon={tutorial.isComingSoon}
              onClick={() => handleTutorialCardClick(tutorial)}
            />
          ))}
        </div>
      </div>
    </PageLayout>
  );
};
