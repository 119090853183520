import { useEffect, useState } from 'react';

import { ResultComparisonDispatch } from '../../ResultComparison/context/ResultComparisonContext';

import { ActionType } from '../../ResultComparison/constants';

import { SummedSourceOption } from '../../../types';

interface UseSourceSelectionProps {
  sourceBeingEdited: SummedSourceOption | null;
  dispatch: ResultComparisonDispatch;
}

export const useSourceSelection = ({ sourceBeingEdited, dispatch }: UseSourceSelectionProps) => {
  const [selectedSourceIds, setSelectedSourceIds] = useState<string[]>(
    sourceBeingEdited?.sources.map((item) => item.id) ?? []
  );

  useEffect(() => {
    dispatch({
      type: ActionType.SET_SELECTED_SOURCES_FOR_SUMMING,
      payload: selectedSourceIds,
    });
  }, [selectedSourceIds, dispatch]);

  const handleSelectAll = (sourceIds: string[]) => {
    setSelectedSourceIds(sourceIds);
  };

  const handleToggleSource = (id: string) => {
    setSelectedSourceIds((prev) => (prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]));
  };

  return {
    selectedSourceIds,
    handleSelectAll,
    handleToggleSource,
  };
};
