import { SyntheticEvent, useEffect, useState } from 'react';

import { GeometryImportActionType, useGeometryImportContext } from '@/context/GeometryImportContext';

import { Stack } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { MultiSpaceGeometryFeedbackBox } from './components/MultiSpaceGeometryFeedbackBox';
import { MultiSpaceInfoBox } from './components/MultiSpaceInfoBox';
import { MultiSpaceInfoIcons } from './components/MultiSpaceInfoIcons';
import { MultiSpaceLayerBox } from './components/MultiSpaceLayerBox';
import { MultiSpaceUploadBox } from './components/MultiSpaceUploadBox';
import { SpaceCard } from './components/SpaceCard/SpaceCard';
import { Viewport } from './components/Viewport/Viewport';
import { Checkbox } from '../Shared/Checkbox';
import { Divider } from '../Shared/Divider';
import { SelectOption } from '../Shared/TrblSelect';

import { useGetModelData } from './hooks/useGetModelData';
import { useLoadGeometryInfo } from './hooks/useLoadGeometryInfo';
import { useGetSpaces } from '@/hooks';

import { SpaceExtractionResults, SpaceResults } from './types';

import styles from './styles.module.scss';

export type SpaceInfoParams = 'spaceName' | 'modelName' | 'description' | 'thumbnailFile';

export type SpaceInfo = SpaceResults & {
  modelName: string;
  description?: string;
  thumbnailFile?: { fileData: ArrayBufferLike; fileName: string };
};

export const MultiSpaceImportStep2 = ({
  multiSpaceResults,
  thisFilename,
  projectChosen,
  uploadFinished,
  goBack,
}: {
  multiSpaceResults: SpaceExtractionResults;
  thisFilename: string;
  projectChosen?: string;
  uploadFinished: (id?: string, type?: string) => void;
  goBack: () => void;
}) => {
  const {
    dispatch: modelDispatch,
    addModelFromFile,
    isModelLoaded,
    modelsGeometryInfo,
    currentModelLayerGroups,
    currentModelGeometryInfo,
  } = useGeometryImportContext();
  const [hiddenLayers, setHiddenLayers] = useState<Set<string>>(new Set());
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const spacesImported = multiSpaceResults.spaces;
  const [allSpacesInfo, setAllSpacesInfo] = useState<SpaceInfo[]>(
    multiSpaceResults?.spaces.map((a: SpaceResults) => {
      return { ...a, modelName: '', description: '' };
    }) || []
  );
  const allSpaceIndexes = multiSpaceResults.spaces.map((_, i: number) => i);

  const [spaceSelected, setSpaceSelected] = useState(0);
  const [spacesChecked, setSpacesChecked] = useState<number[]>([]);

  const [projectName, setProjectName] = useState(projectChosen || '');
  const [modelNames, setModelNames] = useState('Geometry 1');
  const [availableSpacesInProject, setAvailableSpacesInProject] = useState<SelectOption[]>([]);

  const { modelFile } = useGetModelData(spacesImported[spaceSelected].meshUploadId);
  const { data: availableSpaces } = useGetSpaces();

  // Handles loading geometry info for all spaces
  useLoadGeometryInfo(spacesImported);

  // on init set all spaces as checked
  useEffect(() => {
    setSpacesChecked(allSpaceIndexes);
  }, []);

  useEffect(() => {
    modelDispatch({
      type: GeometryImportActionType.SET_CURRENT_MODEL_ID,
      modelId: `model_${spacesImported[spaceSelected].meshUploadId}`,
    });
  }, [spaceSelected]);

  useEffect(() => {
    if (!isModelLoaded && modelFile) {
      const selectedSpaceMeshUploadId = spacesImported[spaceSelected].meshUploadId;
      addModelFromFile(`model_${selectedSpaceMeshUploadId}`, modelFile);
    }
  }, [isModelLoaded, modelFile]);

  const handleCheckAllSpaces = (checked: boolean) => {
    if (checked === true) setSpacesChecked(allSpaceIndexes);
    else setSpacesChecked([]);
  };

  // when a SpaceCard is clicked, allSpacesInfo gets updated with the name and thumbnailFile of the clicked SpaceCard
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateAllSpacesInfo = (value: any, param: SpaceInfoParams, index: number) => {
    const updatedSpaceInfo: SpaceInfo[] = [...allSpacesInfo];

    updatedSpaceInfo[index][param] = value;
    setAllSpacesInfo(updatedSpaceInfo);
  };

  const toggleVisibility = (event: SyntheticEvent, id: string, parentId: string) => {
    // need to loop through currentModelLayerGroups to find out if the id is a parent or a child
    event.stopPropagation();

    const newHiddenLayers = new Set(hiddenLayers);
    const layerParent = currentModelLayerGroups?.find((group) => group.id === id);

    // if the id is a group layer
    if (layerParent) {
      // if the id is already hidden
      if (newHiddenLayers.has(id)) {
        // remove it from the hidden layers
        newHiddenLayers.delete(id);
        // and all the children for this group
        layerParent.children.forEach((child) => {
          const childId = child.userData.attributes.id;
          newHiddenLayers.delete(childId);
        });
      } else {
        // otherwise add the id to the hidden layers
        newHiddenLayers.add(id);
        // and all the children for this group
        layerParent.children.forEach((child) => {
          const childId = child.userData.attributes.id;
          newHiddenLayers.add(childId);
        });
      }
    } else {
      if (newHiddenLayers.has(id)) {
        newHiddenLayers.delete(id);
        newHiddenLayers.delete(parentId);
      } else {
        newHiddenLayers.add(id);
      }
    }
    setHiddenLayers(newHiddenLayers);
  };

  return (
    <div className={styles['main-box']}>
      {/* LEFT BOX */}
      <Stack maxWidth="370px" minWidth="350px" flexDirection="column" position="relative" flexGrow="1">
        <MultiSpaceInfoBox
          filename={thisFilename}
          modelNames={modelNames}
          setModelNames={setModelNames}
          projectName={projectName}
          setProjectName={setProjectName}
          setAvailableSpacesInProject={setAvailableSpacesInProject}
          availableSpaces={availableSpaces ?? []}
          goBack={goBack}
          manySpaces={spacesImported.length > 1}
          projectChosen={projectChosen}
        />

        <Divider marginTopOffset={4} />
        <div
          className={styles['panel-box']}
          style={{ height: '100%', overflowY: 'auto', scrollbarGutter: 'stable', padding: '28px 12px 20px 20px' }}>
          {spacesImported.length > 1 && (
            <Stack flexDirection="row" justifyContent="space-between" paddingRight="10px">
              <Text type="semibold-12px">Select spaces to import</Text>
              <Checkbox
                id={'all'}
                onChange={handleCheckAllSpaces}
                isChecked={spacesChecked.length === spacesImported.length}
                title="Select all spaces to import"
                style={{ background: 'none' }}></Checkbox>
            </Stack>
          )}{' '}
          {spacesImported.map((space: SpaceResults, index: number) => (
            <SpaceCard
              key={index}
              index={index}
              name={space.spaceName}
              modelName={modelNames}
              description={allSpacesInfo[index].description}
              modelUploadId={space.meshUploadId}
              thumbnailDownloadUrl={space.thumbnailDownloadUrl}
              selected={spaceSelected === index}
              setSpaceSelected={setSpaceSelected}
              spacesChecked={spacesChecked}
              setSpacesChecked={setSpacesChecked}
              allSpacesInfo={allSpacesInfo}
              updateAllSpacesInfo={updateAllSpacesInfo}
              availableSpacesInProject={availableSpacesInProject}
              warningCode={
                !modelsGeometryInfo[`model_${space.meshUploadId}`]?.watertight
                  ? 2
                  : modelsGeometryInfo[`model_${space.meshUploadId}`]?.warnings?.length
                  ? 1
                  : 0
              }
            />
          ))}
        </div>
        <MultiSpaceUploadBox
          spacesChecked={spacesChecked}
          allSpacesInfo={allSpacesInfo}
          projectName={projectName}
          modelName={modelNames}
          filename={thisFilename}
          availableSpacesInProject={availableSpacesInProject}
          availableSpaces={availableSpaces ?? []}
          uploadFinished={uploadFinished}
        />
      </Stack>

      {/* MIDDLE BOX */}
      <Stack width="650px" flex="1 1 0" flexGrow={1} position="relative">
        <Viewport
          layerGroups={currentModelLayerGroups ?? []}
          geometryInfo={currentModelGeometryInfo}
          hiddenLayers={hiddenLayers}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          modelId={spacesImported[spaceSelected].meshUploadId}
        />
      </Stack>

      {/* RIGHT BOX */}
      <Stack width="350px" flexDirection={'column'} justifyContent={'space-between'}>
        {currentModelGeometryInfo && currentModelLayerGroups && (
          <>
            <MultiSpaceGeometryFeedbackBox
              geometryInfo={currentModelGeometryInfo}
              modelId={`model_${spacesImported[spaceSelected].meshUploadId}`}
            />
            <Divider />
            <MultiSpaceLayerBox
              layers={currentModelLayerGroups}
              hiddenLayers={hiddenLayers}
              toggleVisibility={toggleVisibility}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
            />
          </>
        )}
        <Divider />
        <MultiSpaceInfoIcons geometryInfo={currentModelGeometryInfo} />
      </Stack>
    </div>
  );
};
