import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// @ts-expect-error jsfive does not have a type definition file
import * as hdf5 from 'jsfive';

import { ActionType, useResultsContext } from '@/components/Results/context/ResultsContext';

import {
  ModalResultDtoModalResultDto,
  SourceSummingResultDto,
  SourceSummingResultForGridReceiverDto,
} from '@/components/Results/components/ResultComparison/hooks';

import { SourceResultForGridReceiverDto, SourceResults } from '@/types';

export const useForcedModalAnalysis = (
  modalAnalysisResults: ModalResultDtoModalResultDto[] | undefined,
  gridReceiverIds: string[],
  selectedSource: SourceResults | SourceResultForGridReceiverDto | undefined,
  selectedSummedSource: SourceSummingResultDto | SourceSummingResultForGridReceiverDto | undefined,
  enabled?: boolean
) => {
  const { selectedComparisonIndex, availableComparisons, h5Files, dispatch } = useResultsContext();
  const selectedComparison = availableComparisons.length > 0 ? availableComparisons[selectedComparisonIndex] : null;
  const selectedSourceObject = selectedComparison?.formState?.simulationData?.selectedSourceObject;
  const selectedSourceSummingObject = selectedComparison?.formState?.simulationData?.selectedSourceSummingObject;

  const selectedSimulationId = selectedComparison?.formState?.simulationId;

  const [frequencyData, setFrequencyData] = useState<Record<string, Record<number, number[]>> | null>(null);
  const [frequencyValues, setFrequencyValues] = useState<Record<string, number[]> | null>(null);
  const [minMaxData, setMinMaxData] = useState<Record<string, { Min: number; Max: number }> | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (enabled && modalAnalysisResults) {
      fetchData();
    }
  }, [modalAnalysisResults, gridReceiverIds, selectedSource, selectedSummedSource]);

  const getFetchedH5File = () => {
    if (h5Files && selectedSimulationId && (selectedSourceObject || selectedSourceSummingObject)) {
      const sourcePointId = selectedSourceObject?.sourcePointId || selectedSourceSummingObject?.id || '';
      if (
        // eslint-disable-next-line no-prototype-builtins
        h5Files.hasOwnProperty(selectedSimulationId) &&
        // eslint-disable-next-line no-prototype-builtins
        h5Files[selectedSimulationId].hasOwnProperty(sourcePointId)
      ) {
        return h5Files[selectedSimulationId][sourcePointId];
      }
    }
    return null;
  };

  const fetchData = async () => {
    if (!modalAnalysisResults || gridReceiverIds.length === 0) {
      setIsLoading(false);
      return;
    }
    const selectedResults = modalAnalysisResults.find(
      (result) =>
        (selectedSource?.sourcePointId === result.sourcePointId && !result.sourceSummingName) ||
        selectedSummedSource?.id === result.solveResultId
    );

    if (!selectedResults?.modalResultDownloadUrl) {
      setIsLoading(false);
      return;
    }

    try {
      let h5File: hdf5.File | null = null;
      const fetchedH5File = getFetchedH5File();

      if (fetchedH5File !== null) {
        h5File = fetchedH5File;
      } else {
        setIsLoading(true);
        const response = await fetch(selectedResults.modalResultDownloadUrl);
        const arrayBuffer = await response.arrayBuffer();
        h5File = new hdf5.File(arrayBuffer);

        if (selectedSimulationId) {
          const sourceId = selectedSourceObject?.sourcePointId || selectedSourceSummingObject?.id || '';
          dispatch({
            type: ActionType.SET_MODAL_ANALYSIS_H5FILE,
            h5Files: {
              ...h5Files,
              [selectedSimulationId]: {
                ...h5Files?.[selectedSimulationId],
                [sourceId]: h5File,
              },
            },
          });
        }
      }

      const frequencyData: Record<string, Record<number, number[]>> = {};
      const minMaxData: Record<string, { Min: number; Max: number }> = {};
      const receiverFrequencies: Record<string, number[]> = {};

      await Promise.all(
        gridReceiverIds.map(async (gridReceiverId) => {
          const gridReceiver = h5File.get('GridReceivers').get(gridReceiverId);
          const splGroup = gridReceiver.get('SPL');
          const frequencies: number[] = gridReceiver.get('Frequency').value;
          const attributes = splGroup.attrs;

          const frequencyValues: Record<number, number[]> = {};

          frequencies.forEach((frequency) => {
            const formattedFrequency = frequency.toString().padStart(4, '0');
            const frequencyDataset = splGroup.get(`F_${formattedFrequency}`);
            if (frequencyDataset) {
              frequencyValues[frequency] = frequencyDataset.value;
            } else {
              console.warn(`Frequency ${frequency} not found for ID: ${gridReceiverId}`);
            }
          });

          frequencyData[gridReceiverId] = frequencyValues;
          receiverFrequencies[gridReceiverId] = frequencies;
          minMaxData[gridReceiverId] = { ...attributes };
        })
      );

      setFrequencyValues(receiverFrequencies);
      setFrequencyData(frequencyData);
      setMinMaxData(minMaxData);
    } catch (error) {
      toast.error('Failed to fetch and process data');
    } finally {
      setIsLoading(false);
    }
  };

  return { frequencyData, frequencyValues, minMaxData, isLoading };
};
