import { FC, useEffect, useState } from 'react';

import { Box, Slider, Stack } from '@mui/material';

import { TrblTooltip } from '../Shared';
import { TrblNumberInput } from '../Shared/NumberInput';

import styles from './slider.module.scss';

type FrequencySliderProps = {
  value?: number;
  disabled?: boolean;
  onChange: (value?: number) => void;
  onChangeCommitted: (value?: number) => void;
  singleColorStyling?: boolean;
  readOnly?: boolean;
};

const minFrequency = 177;
const maxFrequency = 3000;

const scale = (value: number) => {
  return 125 * Math.pow(2, -2 + value);
};

const reverseScale = (value: number) => {
  return Math.log2(value / 31.25);
};

const frequencyBands = [
  {
    value: 88,
    label: '63',
    snapLimit: 5,
  },
  {
    value: 177,
    label: '125',
    snapLimit: 10,
  },
  {
    value: 355,
    label: '250',
    snapLimit: 20,
  },
  {
    value: 710,
    label: '500',
    snapLimit: 40,
  },
  {
    value: 1420,
    label: '1k',
    snapLimit: 80,
  },
  {
    value: 2840,
    label: '2k',
    snapLimit: 160,
  },
];

// Controls the "snap" feature of the slider as well as min / max restriction
const adjustValue = (value: number) => {
  if (value < minFrequency) {
    value = minFrequency;
  } else if (value > maxFrequency) {
    value = maxFrequency;
  }

  frequencyBands.forEach((f) => {
    if (f.value === 2840) {
      // handle the last frequency specifically
      if ((value < f.value && f.value - value < f.snapLimit) || (value > f.value && value - f.value < 20)) {
        value = f.value;
      }
    } else {
      if (Math.abs(f.value - value) < f.snapLimit) {
        value = f.value;
      }
    }
  });

  return value;
};

const sliderMarks = frequencyBands.map((x) => ({
  value: reverseScale(x.value),
}));

export const FrequencySlider: FC<FrequencySliderProps> = ({
  value,
  disabled,
  onChange,
  onChangeCommitted,
  singleColorStyling = false,
  readOnly = false,
}) => {
  const [sliderValue, setSliderValue] = useState(reverseScale(minFrequency));

  useEffect(() => {
    if (value && value >= minFrequency) {
      setSliderValue(reverseScale(value));
    } else {
      setSliderValue(reverseScale(minFrequency));
    }
  }, [value]);

  return (
    <Box
      component={'div'}
      display="flex"
      gap={'10px'}
      justifyContent="space-between"
      alignItems="center"
      className={`${styles['slider-container']} ${readOnly ? styles['readonly'] : ''}`}>
      <Box component={'div'} display={'flex'} justifyContent="space-evenly" flexDirection={'column'} flexGrow={1}>
        <Stack direction={'row'} spacing={'1px'} className={styles['slider-buttons-container']}>
          {frequencyBands.map((x) => (
            <TrblTooltip
              enterDelay={1000}
              key={x.value}
              title={x.value === 88 ? 'The minimum Transition frequency selectable is at the 125Hz band' : ''}>
              <div
                className={`${styles['slider-button']} ${disabled ? styles['disabled'] : ''} ${
                  sliderValue && Math.round(scale(sliderValue)) >= x.value ? styles['active'] : ''
                } ${singleColorStyling ? styles['single-color'] : ''}`}
                onClick={() => {
                  if (x.value >= minFrequency) {
                    onChange(x.value);
                    onChangeCommitted(x.value);
                  } else {
                    onChange(minFrequency);
                    onChangeCommitted(minFrequency);
                  }
                }}>
                {x.label}
              </div>
            </TrblTooltip>
          ))}
        </Stack>
        <div className={styles['number-input-slider']}>
          <Slider
            size="small"
            value={sliderValue}
            disabled={disabled}
            valueLabelDisplay="auto"
            step={0.0001}
            min={reverseScale(44)}
            max={reverseScale(maxFrequency)}
            marks={sliderMarks}
            scale={scale}
            classes={{
              valueLabel: styles['slider-value-label'],
              mark: `${styles['slider-mark']} ${disabled ? styles['disabled'] : ''}`,
              markActive: styles['slider-mark-active'],
              root: styles['slider-root'],
              rail: !disabled && !singleColorStyling ? styles['slider-rail'] : undefined,
              thumb: `${styles['slider-thumb']} ${disabled ? styles['slider-thumb-disabled'] : ''}`,
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(_: any, value: any) => {
              let val = Number(value);

              val = Math.round(scale(val));

              val = adjustValue(val);

              onChange(val);
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChangeCommitted={(_: any, value: any) => {
              let val = Number(value);

              // Ensure that we do not go lower then the minFrequency
              val = Math.max(...[minFrequency, Math.round(scale(val))]);

              onChangeCommitted(val);
            }}
          />
        </div>
      </Box>
      <TrblNumberInput
        value={value}
        min={minFrequency}
        max={maxFrequency}
        step={1}
        endAdornment={'Hz'}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        onBlur={onChangeCommitted}
        blurOnStep={false}
      />
    </Box>
  );
};
