import { useQuery } from '@tanstack/react-query';

import { ResultType, StatusType, TaskResultForReceiverDto } from '@/types';

import axios from '@/axios';

export type SourceSummingPoint = {
  orderNumber: number;
  x: number;
  y: number;
  z: number;
  label: string;
  id: string;
  delay?: number;
};

export type SourceSummingResultDto = {
  id: string;
  label: string | null;
  createdAt: string;
  resultType: ResultType;
  taskId: string;
  sources: SourceSummingPoint[];
  frequencies: number[];
  resultParametersJson: string;
  hasIncompleteParameterResults: boolean;
  taskResultForReceiver: TaskResultForReceiverDto[];
};

export type IncompleteSourceSummingTaskDto = {
  id: string;
  taskType: ResultType;
  status: StatusType;
  createdAt: string;
  startedAt: string;
  completedAt: string;
  completedAtEst: string;
  updatedAt: string;
  progressPercentage: number;
  userErrorMessage: string;
  errorDetails: string;
  userStatusMsg: string;
  taskProvider: string;
  taskGroupId: string;
  label: string;
  sources: SourceSummingPoint[];
};

export type TaskGroupSourceSummingResultsDto = {
  taskGroupId: string;
  sourceResults: SourceSummingResultDto[];
  incompleteTasks: IncompleteSourceSummingTaskDto[] | null;
};

const getLastSourceSummingResults = async (simulationId: string): Promise<TaskGroupSourceSummingResultsDto> => {
  const { data } = await axios.get(`/api/SourceSumming/GetLastSourceSummingResults`, {
    params: {
      simulationId,
      resultTypesCsv: 'edc,ir,tf-db,mono-ir',
    },
  });

  return data;
};

export const useGetLastSourceSummingResultsBySimulationId = (simulationId: string, refetchInterval?: number) => {
  return useQuery<TaskGroupSourceSummingResultsDto, boolean>(
    ['lastSourceSummingResultsBySimulationId', simulationId],
    () => getLastSourceSummingResults(simulationId),
    {
      refetchInterval,
      refetchOnWindowFocus: false,
      enabled: !!simulationId.length,
      staleTime: 60000, // stale time is set to 1 minute, after that time it re-fetches
    }
  );
};
