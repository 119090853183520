import { Stack } from '@mui/material';

import { Divider } from '@/components/Shared/Divider';
import { RadioButtonGroup } from '@/components/Shared/RadioButton';
import { TrblEyeIcon, TrblFilterListIcon, TrblIfcIcon, TrblSimulationIcon } from '@/components/Icons';
import { TrblCustomSelect } from '../TrblCustomSelect';

import { FilterType } from './utils';

import classes from './styles.module.scss';

export const FilterDropdown = ({
  disabled,
  showNumber,
  setShowNumber,
  type,
  setType,
}: {
  disabled: boolean;
  showNumber: number;
  setShowNumber: React.Dispatch<React.SetStateAction<number>>;
  type: FilterType;
  setType: React.Dispatch<React.SetStateAction<FilterType>>;
}) => {
  const onChangeShowNumber = (value: string) => {
    setShowNumber(Number(value));
  };
  const onChangeType = (type: FilterType) => {
    setType(type);
  };

  return (
    <div className={classes.filter_dropdown}>
      <TrblCustomSelect
        selectedItem={
          <div className={classes.filter_title}>
            <TrblFilterListIcon width="14" height="14" fill="#adadad" />
            <span>Filter</span>
          </div>
        }
        dropdownWidth="200px">
        <div className={classes.filter_dropdown_content}>
          <div
            onClick={() => onChangeType(FilterType.All)}
            className={`${classes.filter_row} ${type == FilterType.All && !disabled ? classes.selected : ''} ${
              disabled ? classes.disabled : ''
            }`}>
            <TrblEyeIcon width="14" height="14" fill="#dadada" /> All tasks
          </div>
          <div
            onClick={() => onChangeType(FilterType.SimulationsOnly)}
            className={`${classes.filter_row} ${
              type == FilterType.SimulationsOnly && !disabled ? classes.selected : ''
            } ${disabled ? classes.disabled : ''}`}>
            <TrblSimulationIcon width="14" height="14" />
            Simulations only
          </div>
          <div
            onClick={() => onChangeType(FilterType.IFCImportsOnly)}
            className={`${classes.filter_row} ${
              type == FilterType.IFCImportsOnly && !disabled ? classes.selected : ''
            } ${disabled ? classes.disabled : ''}`}>
            <TrblIfcIcon width="14" height="14" />
            IFC imports only
          </div>
          <span style={{ display: 'block', margin: '4px 0' }}>
            <Divider />
          </span>
          <Stack>
            <p className={classes.filter_label}>Show number of tasks</p>

            <RadioButtonGroup
              options={[
                {
                  text: '8',
                  value: '8',
                },
                {
                  text: '25',
                  value: '25',
                },
                {
                  text: '50',
                  value: '50',
                },
              ]}
              selectedValue={showNumber.toString()}
              onChange={onChangeShowNumber}
              horizontal
              justifyContent="star"
              noSelectedColor
            />
          </Stack>
        </div>
      </TrblCustomSelect>
    </div>
  );
};
