import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { TrblTooltip } from '@/components/Shared';
import { TrblIconButton } from '@/components/Shared/Buttons';
import { EMPTY_GUID } from '@/components/Shared/constants';
import { Text } from '@/components/Shared/Text';
import { TrblSelect } from '@/components/Shared/TrblSelect';
import { TrblIcon } from '@/components/Icons';

import {
  AVAILABLE_PARAMETER_KEYS,
  ParameterKeys,
  ParameterNames,
  SPEECH_LABEL,
  STI_DISABLED_TEXT,
  STI_DISABLED_TEXT_OMNI,
} from './constants';

import { ParsedResponseData } from '../ResponsePlot/types';
import { ParsedParameterData } from './types';

import classes from './styles.module.scss';

export const ParameterResultsHeader = ({
  parameterData,
  plotlyData,
  selectedParameter,
  selectedSTIParameter,
  setSelectedParameter,
  setAvailableResultParameters,
  setSelectedSTIParameter,
}: {
  parameterData: ParsedParameterData[];
  plotlyData: ParsedResponseData[];
  selectedParameter: ParameterKeys;
  selectedSTIParameter: ParameterKeys.STI | ParameterKeys.STI_USING_SPL;
  setSelectedParameter: (key: ParameterKeys) => void;
  setAvailableResultParameters: (options: { key: ParameterKeys; name: ParameterNames; isEnabled: boolean }[]) => void;
  setSelectedSTIParameter: (key: ParameterKeys.STI | ParameterKeys.STI_USING_SPL) => void;
}) => {
  const handleCopyToClipboard = () => {
    // 1. Filter out the transition frequency data.
    // 2. Reduce the data to a string where each line contains the name of the data and the y values, tab separated
    // 3. Write to clipboard

    // find the regionally specific comma separator
    const numberFormat = new Intl.NumberFormat();
    const parts = numberFormat.formatToParts(1000.1);
    const regionalSeparator = parts.find((part) => part.type === 'decimal')!.value;
    const clipboardText = plotlyData
      .filter((x) => x.type !== 'scatter')
      .reduce((acc, cur) => {
        return acc + cur.name + '\t' + cur.y.join('\t').replaceAll('.', regionalSeparator) + '\n';
      }, '');

    navigator.clipboard.writeText(clipboardText);
    const toastId = toast.info('Copied to clipboard');
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 2000);
  };

  useEffect(() => {
    let availableParameterOptions: {
      key: ParameterKeys;
      name: ParameterNames;
      isEnabled: boolean;
    }[] = Object.keys(AVAILABLE_PARAMETER_KEYS).map((param) => {
      return {
        key: param as ParameterKeys,
        // @ts-expect-error - TS doesn't like the fact that we're using the key to get the value
        name: AVAILABLE_PARAMETER_KEYS[param].name,
        isEnabled: true,
      };
    });

    if (parameterData.length) {
      if (parameterData.findIndex((x) => x.resultType === 'Hybrid' || x.resultType === 'GA') === -1) {
        availableParameterOptions = availableParameterOptions.filter(
          (x) => x.key !== ParameterKeys.STI && x.key !== ParameterKeys.STI_USING_SPL
        );
      } else {
        availableParameterOptions = [
          ...availableParameterOptions,
          { key: ParameterKeys.OOP, name: ParameterNames.OOP, isEnabled: true },
        ];
      }

      // If we only have summed sources we disable everything except SPL and STI
      if (parameterData.every((x) => x.isSummedSource)) {
        availableParameterOptions = availableParameterOptions.map((x) => ({
          key: x.key,
          name: x.name,
          isEnabled:
            x.key === ParameterKeys.STI || x.key === ParameterKeys.STI_USING_SPL || x.key === ParameterKeys.SPL,
        }));
        if (
          selectedParameter !== ParameterKeys.SPL &&
          selectedParameter !== ParameterKeys.STI &&
          selectedParameter !== ParameterKeys.STI_USING_SPL
        ) {
          setSelectedParameter(ParameterKeys.SPL);
        }
      } else if (!availableParameterOptions.some((x) => x.key === selectedParameter && x.isEnabled)) {
        setSelectedParameter(ParameterKeys.T20);
      }

      setAvailableResultParameters(availableParameterOptions);
    }
  }, [parameterData]);

  const isOpenOfficeParametersResults = selectedParameter === ParameterKeys.OOP;

  return (
    <div className={classes.plot_header}>
      <Text type="semibold-12px">Select parameter</Text>
      <div className={classes['header-actions']}>
        {(selectedParameter === ParameterKeys.STI || selectedParameter === ParameterKeys.STI_USING_SPL) && (
          <TrblSelect
            menuItems={[
              {
                id: ParameterKeys.STI,
                name: SPEECH_LABEL,
                // Disable Speech source if there are summed sources in the comparison panel
                // or if any of the sources are NOT omni source
                disabled:
                  parameterData.map((x) => x.isSummedSource).includes(true) ||
                  !parameterData.map((x) => x.sourceDefinitionId).every((id) => id === EMPTY_GUID),
                tooltipText: parameterData.map((x) => x.isSummedSource).includes(true)
                  ? STI_DISABLED_TEXT
                  : !parameterData.map((x) => x.sourceDefinitionId).every((id) => id === EMPTY_GUID)
                  ? STI_DISABLED_TEXT_OMNI
                  : '',
              },
              {
                id: ParameterKeys.STI_USING_SPL,
                name: 'Source SPL level',
              },
            ]}
            value={selectedSTIParameter}
            setValue={(value) => setSelectedSTIParameter(value as ParameterKeys.STI | ParameterKeys.STI_USING_SPL)}
            className={classes['custom-select']}
          />
        )}
        {!isOpenOfficeParametersResults && (
          <TrblTooltip title="Copy current values to clipboard">
            <span>
              <TrblIconButton
                label="Copy to clipboard"
                icon={<TrblIcon icon="clipboard" />}
                className={classes.action_button}
                onClick={handleCopyToClipboard}
              />
            </span>
          </TrblTooltip>
        )}
      </div>
    </div>
  );
};
