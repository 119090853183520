import { FC, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Backdrop, Box } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { PrimaryButton } from '@/components/Shared/Buttons';
import { LoadingSpinner } from '@/components/Shared/LoadingSpinner';
import { TrblAddIcon } from '@/components/Icons';
import { OrganizationDetails } from './OrganizationDetails';
import { SubscriptionDetails } from './SubscriptionDetails';
import { SubscriptionDetailsSDK } from './SubscriptionDetailsSDK';
import { UsersGrid } from './UsersGrid';
import { UserFormState, UserSidepanel } from './UserSidepanel';

import {
  useGetOrganizationById,
  useGetOrganizationUsersAccesses,
  useGetSubscription,
  useGetTokenStatus,
  useUserActions,
} from '../hooks';
import { useGetPendingSubscriptionChanges } from '../hooks/useGetPendingSubscriptionChanges';

import { BILLABLE_SUBSCRIPTION_TYPES } from './constants';

import { SubscriptionDetails as SubscriptionDetailsType, UserAccess } from './types';

type OrginizationsProps = {
  organizationId: string;
  isMyOrganization: boolean;
};

export const Organization: FC<OrginizationsProps> = ({ organizationId, isMyOrganization }) => {
  const { user } = useAuth0();
  const userRoles: string[] = user ? user['https://treble.tech/roles'] : [];

  const isSuperUser = userRoles.includes('Superuser');
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [hasPendingSubscriptionChanges, setHasPendingSubscriptionChanges] = useState(false);
  const [hasPendingTokenChanges, setHasPendingTokenChanges] = useState(false);

  const [selectedUser, setSelectedUser] = useState<UserAccess | undefined>();

  const { data: organization, isLoading: isLoadingOrganization } = useGetOrganizationById(organizationId);
  const { data: productAccesses } = useGetOrganizationUsersAccesses(organizationId);
  const { data: subscriptionDetails } = useGetSubscription(organizationId, hasPendingSubscriptionChanges, () =>
    setHasPendingSubscriptionChanges(false)
  );
  const { data: tokenStatus } = useGetTokenStatus(organizationId, true, hasPendingTokenChanges, () =>
    setHasPendingTokenChanges(false)
  );

  const { data: pendingSubscriptionDetails } = useGetPendingSubscriptionChanges(
    organizationId,
    BILLABLE_SUBSCRIPTION_TYPES.includes(subscriptionDetails?.subscriptionType ?? '')
  );

  // User actions
  const { updateUser, createUser, addUserToOrganization, inProgress } = useUserActions(organizationId);

  const usersLoadingState = isLoadingOrganization || inProgress;

  const handleCancelUserSidepanel = () => {
    if (selectedUser) {
      setSelectedUser(undefined);
    } else {
      setIsCreatingUser(false);
    }
  };

  const handleAddUserButtonClick = () => {
    setIsCreatingUser(true);
  };

  const handleSubmitUser = async (user: UserFormState) => {
    if (user.id) {
      updateUser({
        firstName: user.firstName,
        lastName: user.lastName,
        userId: user.id,
      });
      setSelectedUser(undefined);
    } else {
      createUser(
        {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          organizationId: organizationId,
        },
        {
          onSuccess: (response) => {
            addUserToOrganization({
              userId: response.id,
              organizationId: organizationId,
              products: user.products,
            });
          },
        }
      );
      setIsCreatingUser(false);
    }
  };

  const handleSubscriptionChanged = () => {
    setHasPendingSubscriptionChanges(true);
  };

  const handleTokensChanged = () => {
    setHasPendingTokenChanges(true);
  };

  const checkSeatAvailability = () => {
    if (subscriptionDetails) {
      if (hasTASSSeats(subscriptionDetails) || hasTSDKSeats(subscriptionDetails)) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Box component={'div'} display="flex" flexDirection="column" gap={'6px'} height="100%" padding="20px 30px">
        <Box component={'div'} display="flex" flexDirection={'row'} gap={'20px'}>
          <Box component={'div'} width={396} mb={'10px'}>
            <OrganizationDetails organization={organization} canEdit={isSuperUser} />
          </Box>
          {subscriptionDetails?.productKey == 'TASS' && (
            <Box component={'div'} width={396} mb={'10px'}>
              <SubscriptionDetails
                organizationId={organizationId}
                isMyOrganization={isMyOrganization}
                subscriptionDetails={subscriptionDetails}
                pendingSubscriptionDetails={pendingSubscriptionDetails ?? undefined}
                canEdit={
                  isMyOrganization && BILLABLE_SUBSCRIPTION_TYPES.includes(subscriptionDetails?.subscriptionType || '')
                }
                tokensLeft={tokenStatus?.tokensLeftInPackages ?? 0}
                onSubscriptionChanged={handleSubscriptionChanged}
                onTokensChanged={handleTokensChanged}
                hasSDK={subscriptionDetails?.sdkProduct ? true : false}
              />
            </Box>
          )}
          {subscriptionDetails?.sdkProduct && (
            <Box component={'div'} width={396} mb={'10px'}>
              <SubscriptionDetailsSDK
                subscriptionDetails={subscriptionDetails.sdkProduct}
                pendingSubscriptionDetails={pendingSubscriptionDetails ?? undefined}
                tokensLeft={tokenStatus?.tokensLeftInPackages ?? 0}
              />
            </Box>
          )}
        </Box>
        <Box component={'div'} display="flex" alignItems={'end'} justifyContent={'space-between'}>
          <p style={{ fontWeight: '600' }}>Users</p>
          <TrblTooltip title={!checkSeatAvailability() ? 'You can not add new users - please add more seats' : null}>
            <span>
              <PrimaryButton
                label="Add user"
                disabled={!checkSeatAvailability()}
                icon={<TrblAddIcon fill="none" />}
                onClick={handleAddUserButtonClick}
                width={150}
                sx={{ marginBottom: '2px' }}
              />
            </span>
          </TrblTooltip>
        </Box>

        <Box component="div" flex="1 1 auto">
          <UsersGrid
            organizationId={organizationId}
            isUsingSso={organization?.organization.useSso ?? false}
            isLoading={usersLoadingState}
            subscription={subscriptionDetails ?? ({} as SubscriptionDetailsType)}
            users={productAccesses ?? []}
            onEditUser={(user) => setSelectedUser(user)}
          />
        </Box>
        {(isCreatingUser || selectedUser) && subscriptionDetails && (
          <UserSidepanel
            user={selectedUser}
            onCancel={handleCancelUserSidepanel}
            onSubmit={handleSubmitUser}
            subscription={subscriptionDetails}
          />
        )}
      </Box>
      <Backdrop
        sx={{ backdropFilter: 'blur(1px)', zIndex: 100 }}
        open={hasPendingSubscriptionChanges || hasPendingTokenChanges}>
        <LoadingSpinner />
      </Backdrop>
    </>
  );
};

export const hasTASSSeats = (subscription: SubscriptionDetailsType) => {
  if (subscription.productKey == 'TASS' && subscription.activeSeats < subscription.maxSeats) {
    return true;
  } else {
    return false;
  }
};

export const hasTSDKSeats = (subscription: SubscriptionDetailsType) => {
  if (subscription.sdkProduct && subscription.sdkProduct.activeSeats < subscription.sdkProduct.maxSeats) {
    return true;
  } else {
    return false;
  }
};
