import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblAddIconCircle: FC<IconProps> = ({ width = 15, height = 15, fill = '#ADADAD' }) => (
  <svg
    id="addIconCircle"
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.68484 14.5C11.5508 14.5 14.6848 11.366 14.6848 7.5C14.6848 3.63401 11.5508 0.5 7.68484 0.5C3.81885 0.5 0.684845 3.63401 0.684845 7.5C0.684845 11.366 3.81885 14.5 7.68484 14.5ZM8.28485 4.1C8.28485 3.76863 8.01622 3.5 7.68484 3.5C7.35347 3.5 7.08485 3.76863 7.08485 4.1V6.9L4.28485 6.9C3.95347 6.9 3.68484 7.16863 3.68484 7.5C3.68484 7.83137 3.95347 8.1 4.28485 8.1H7.08485V10.9C7.08485 11.2314 7.35347 11.5 7.68484 11.5C8.01622 11.5 8.28485 11.2314 8.28485 10.9V8.1H11.0848C11.4162 8.1 11.6848 7.83137 11.6848 7.5C11.6848 7.16863 11.4162 6.9 11.0848 6.9H8.28485V4.1Z"
      fill={fill}
    />
  </svg>
);
