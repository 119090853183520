import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { config } from '@/__config__/config';

import axios from '@/axios';

const { adminApiUrl } = config;

type SimulationUsageDto = {
  simulationId: string;
  simulationName?: string;
  usage: number;
  runtime?: number;
  userId: string;
  maxGPUCount?: number;
  completedAt?: string;
  email?: string;
};

export type SimulationUsageInfoDto = {
  projectId: string;
  projectName?: string;
  totalUsage: number;
  simulationUsages?: SimulationUsageDto[];
};

type GetTokenUsageReportRequest = {
  organizationId: string;
  from: string;
  to: string;
};

const getTokenUsageReport = async (request: GetTokenUsageReportRequest) => {
  const { data } = await axios.post<SimulationUsageInfoDto[]>(`/api/Token/GetTokenUsageReport`, request, {
    baseURL: adminApiUrl,
  });

  return data;
};

export const useGetTokenUsageReport = () => {
  return useMutation<SimulationUsageInfoDto[], Error, GetTokenUsageReportRequest>({
    mutationFn: getTokenUsageReport,
    onError: () => {
      toast.error('Failed to fetch token usage report');
    },
  });
};
