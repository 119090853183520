import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import { TrblResultsIcon } from '@/components/Icons';
import { PageLayout } from '@/components/New/PageLayout';
import { useGetResultPresets } from '@/components/ResultPresets/hooks/useGetResultPresets';
import { ResultPresetDto } from '@/components/ResultPresets/types';
import { EmptyResourceContent } from '../EmptyResourceContent';
import { PageHeader } from '../PageHeader/PageHeader';
import { TrblDataGrid } from '../TrblDataGrid';

import { useColumns, useFilterData, useTransformData } from './hooks';

import classes from './styles.module.scss';

export const SavedResultsPage = () => {
  const { data: allResultPresets = [], isFetching } = useGetResultPresets();
  const [searchValue, setSearchValue] = useState('');
  const columns = useColumns(searchValue);
  const navigate = useNavigate();

  const transformedResults = useTransformData(allResultPresets, isFetching);
  const filteredResults = useFilterData(transformedResults, searchValue);

  const selectPreset = (row: ResultPresetDto) => {
    navigate(`/results?resultsId=${row.id}`);
  };

  return (
    <PageLayout navpanel={true}>
      <div className={classes.saved_content}>
        <PageHeader
          pageTitle="Saved results"
          searchPlaceholder="Search results"
          showViewToggle={false}
          showSearch={allResultPresets.length !== 0}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />

        {isFetching ? (
          <div className={classes.loading_container}>
            <CircularProgress />
          </div>
        ) : allResultPresets.length === 0 ? (
          <EmptyResourceContent title="You have no saved results yet" icon={<TrblResultsIcon fill="#DADADA" />} />
        ) : (
          <TrblDataGrid
            rows={filteredResults}
            loading={isFetching}
            rowHeight={56}
            headerHeight={20}
            columns={columns}
            onRowDoubleClick={(_, row) => selectPreset(row)}
            onRowClick={(_, row) => selectPreset(row)}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedAt', sort: 'desc' }],
              },
            }}
          />
        )}
      </div>
    </PageLayout>
  );
};
