import { useState } from 'react';

import { GeometryImportProvider } from '@/context/GeometryImportContext';

import { PrimaryButton, SecondaryButton } from '@/components/Shared/Buttons';
import { TrblModelsIcon } from '@/components/Icons';
import { MultiSpaceImportPopup } from '@/components/MultiSpaceImport';

import { useGetLatestSpaceExtractionTasks } from '@/hooks';

export const ProjectGeometryImport = ({
  projectName,
  type = 'tertiary',
  label = 'Import geometry',
}: {
  projectName: string;
  type?: string;
  label?: string;
}) => {
  const [showModelImportPopup, setShowModelImportPopup] = useState(false);

  const { data: spaceExtractionTasks } = useGetLatestSpaceExtractionTasks(100, showModelImportPopup);

  const uploadModelDone = () => {
    setShowModelImportPopup(false);
  };

  return (
    <>
      {type == 'tertiary' ? (
        <SecondaryButton
          label={label}
          icon={<TrblModelsIcon />}
          onClick={() => setShowModelImportPopup(true)}
          width="fit-content"
        />
      ) : (
        <PrimaryButton label={label} onClick={() => setShowModelImportPopup(true)} width="fit-content" />
      )}

      {showModelImportPopup && spaceExtractionTasks && (
        <GeometryImportProvider>
          <MultiSpaceImportPopup
            setShowPopup={setShowModelImportPopup}
            uploadModelDone={uploadModelDone}
            spaceExtractionTasksOnLoad={spaceExtractionTasks}
            projectChosen={projectName}
          />
        </GeometryImportProvider>
      )}
    </>
  );
};
