import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useBaseContext } from '@/context/BaseContext';
import { ActionType as MeshActionType, useMeshContext } from '@/context/MeshContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { TrblPopup, TrblPopupTitle } from '@/components/Shared/Popup';
import { EstimationContent } from './components/EstimationContent';
import { RunSimulationPopupActions } from './components/RunSimulationPopupActions';
import { RunSimulationPopupContent } from './components/RunSimulationPopupContent';
import { useFeatureFlags } from '../FeatureToggles';
import { TrblMinimizeIcon } from '../Icons';
import { SUBSCRIPTION_TYPES_USING_TOKENS } from './const';

import { useGetTokenStatus } from '../ManagementPortal/hooks';
import { useGetEstimatedSimulationTime } from '../SolverSettings/hooks/useGetEstimatedSimulationTime';
import { startMeshTask } from '../SolverSettings/hooks/useStartMeshTask';
import { useGetSimulationById, useGetSimulationRunStatusById } from '@/hooks';
import { SimulationEstimateDtoSimulationEstimateDto } from '@/hooks/SolveTask/useCalculateSimulationRunEstimatedTime';

import { maxMeshElements, maxMeshElementsMessage } from '@/utils/constants';

import {
  formatEstimatedTokens,
  mapSimulationRunStatusToSimulationRunDetails,
  mapSimulationRunToSimulationRunDetails,
} from './utils';

import { SimulationRunDetails as SimulationRunDetailsType } from './types';
import { ActiveMeshTask, CompletedMeshTask } from '@/context/MeshContext/types';
import { Simulation, SimulationRunDto } from '@/types';

type RunSimulationPopupProps = {
  selectedSimulation: Simulation;
  activeSimulationRun: SimulationRunDto | null;
  onClose: () => void;
};

export const RunSimulationPopup: FC<RunSimulationPopupProps> = ({
  selectedSimulation,
  activeSimulationRun,
  onClose,
}) => {
  const {
    state: { userInfo },
  } = useBaseContext();
  const {
    state: { subscriptionInfo },
  } = useBaseContext();
  const { data: tokenStatus } = useGetTokenStatus(
    userInfo?.organizationId,
    SUBSCRIPTION_TYPES_USING_TOKENS.includes(subscriptionInfo?.type ?? '')
  );

  const [simulationRunDetails, setSimulationRunDetails] = useState<SimulationRunDetailsType | null>(
    activeSimulationRun != null ? mapSimulationRunToSimulationRunDetails(activeSimulationRun) : null
  );
  const { activeMeshTasks, currentSimTimeEstimate, currentMeshResult, meshHasError, completedMeshTasks } =
    useMeshContext();
  const { dispatch } = useSimulationContext();
  const { dispatch: meshDispatch } = useMeshContext();

  const isGAOnly = selectedSimulation.taskType == 'GA';

  const [totalEstimatedRuntimeAdjustedForConcurrency, setTotalEstimatedRuntimeAdjustedForConcurrency] = useState<
    number | null
  >(currentSimTimeEstimate?.totalEstimatedRuntimeAdjustedForConcurrency || null);

  const { data: newSimulationObject, refetch: refetchSimulation } = useGetSimulationById(selectedSimulation.id, false);

  const getEstimatedSimulationTime = useGetEstimatedSimulationTime();
  const { removeMaxElementBlock } = useFeatureFlags();

  const [meshTaskId, setMeshTaskId] = useState<string | null>(selectedSimulation.extra?.meshTaskId || null);
  const [tooManyMeshElements, setTooManyMeshElements] = useState(false);
  const [waitingToStart, setWaitingToStart] = useState(false);
  const [minimizePopup, setMinimizePopup] = useState(false);

  const { data: simulationRunStatus } = useGetSimulationRunStatusById(
    simulationRunDetails?.id || '',
    simulationRunDetails?.inProgress || false,
    3000
  );

  useEffect(() => {
    if (selectedSimulation.extra?.meshTaskId && currentSimTimeEstimate) {
      handleEstimationCompleted(
        selectedSimulation.extra?.meshTaskId,
        currentSimTimeEstimate,
        selectedSimulation.solverSettings.dgSettings.crossoverFrequency
      );
    }
  }, [
    selectedSimulation.extra.meshTaskId,
    currentSimTimeEstimate,
    selectedSimulation.solverSettings.dgSettings.crossoverFrequency,
  ]);

  useEffect(() => {
    if (simulationRunStatus) {
      const runDetails = mapSimulationRunStatusToSimulationRunDetails(simulationRunStatus, selectedSimulation.taskType);
      setSimulationRunDetails(runDetails);
      if (!runDetails.inProgress) {
        refetchSimulation();
      }
    }
  }, [simulationRunStatus]);

  useEffect(() => {
    if (newSimulationObject?.lastSimulationRun) {
      dispatch({
        type: ActionType.SET_LAST_SIMULATION_RUN,
        simulationRun: newSimulationObject.lastSimulationRun,
      });
    }
  }, [newSimulationObject]);

  const startNewEstimation = async (modelId: string) => {
    const meshTask = await startMeshTask(selectedSimulation.solverSettings.dgSettings.crossoverFrequency, modelId);
    const newActiveMeshTasks = [
      ...activeMeshTasks,
      {
        id: meshTask.id,
        transitionFrequency: meshTask.crossoverFrequency,
      },
    ];
    meshDispatch({
      type: MeshActionType.SET_ACTIVE_MESH_TASKS,
      activeMeshTasks: newActiveMeshTasks,
    });
  };

  // If we don't have the estimation we trigger a new mesh task when we open the popup, only for DG and Hybrid jobs
  useEffect(() => {
    if (isGAOnly && !currentSimTimeEstimate?.totalEstimatedRuntimeAdjustedForConcurrency) {
      getEstimatedSimulationTime(0, selectedSimulation);
    } else {
      const meshTask = completedMeshTasks.find(
        (completedMeshTask: CompletedMeshTask) =>
          completedMeshTask.crossoverFrequency === selectedSimulation.solverSettings.dgSettings.crossoverFrequency
      );
      if (meshTask) {
        // Check if there is an existing mesh task for the selected transition frequency
        getEstimatedSimulationTime(selectedSimulation.solverSettings.dgSettings.crossoverFrequency, selectedSimulation);
      } else if (
        !meshHasError &&
        totalEstimatedRuntimeAdjustedForConcurrency === null &&
        activeMeshTasks.findIndex(
          (x: ActiveMeshTask) =>
            x.transitionFrequency === selectedSimulation.solverSettings.dgSettings.crossoverFrequency
        ) === -1
      ) {
        startNewEstimation(selectedSimulation?.modelId);
      } else if (meshHasError) {
        setTotalEstimatedRuntimeAdjustedForConcurrency(null);
      }
    }
  }, []);

  const handleEstimationCompleted = (
    meshTaskId: string,
    estimatedSimulationTime: SimulationEstimateDtoSimulationEstimateDto,
    transitionFrequency: number
  ) => {
    if (
      estimatedSimulationTime &&
      transitionFrequency === selectedSimulation.solverSettings.dgSettings.crossoverFrequency
    ) {
      setTotalEstimatedRuntimeAdjustedForConcurrency(
        estimatedSimulationTime.totalEstimatedRuntimeAdjustedForConcurrency
      );
      setMeshTaskId(meshTaskId);
      if (currentMeshResult && currentMeshResult.elementCount > maxMeshElements && !removeMaxElementBlock) {
        setTooManyMeshElements(true);
        toast.warning(maxMeshElementsMessage);
      }
    } else if (estimatedSimulationTime === null) {
      setTotalEstimatedRuntimeAdjustedForConcurrency(null);
    }
  };

  const onClosePopup = () => {
    setMinimizePopup(true);
    onClose();
  };

  return (
    <TrblPopup
      className={minimizePopup ? 'minimize-popup' : ''}
      transitionDuration={{ exit: 800 }}
      width={420}
      maxheight={600}
      aria-labelledby="Run simulation"
      onClose={!waitingToStart ? onClosePopup : undefined}
      open={!minimizePopup}>
      <TrblPopupTitle onClose={onClosePopup} disabled={waitingToStart} closeIcon={<TrblMinimizeIcon />}>
        Run simulation
      </TrblPopupTitle>
      <RunSimulationPopupContent
        selectedSimulation={selectedSimulation}
        simulationRunDetails={simulationRunDetails}
        isGAOnly={isGAOnly}>
        <EstimationContent
          isTrialUser={subscriptionInfo?.type === 'Trial'}
          showTokenEstimation={SUBSCRIPTION_TYPES_USING_TOKENS.includes(subscriptionInfo?.type ?? '')}
          tokenStatus={tokenStatus}
          currentSimTimeEstimate={currentSimTimeEstimate}
          meshHasError={meshHasError}
          isGAOnly={isGAOnly}
        />
      </RunSimulationPopupContent>
      <RunSimulationPopupActions
        isTokenExceeded={
          (SUBSCRIPTION_TYPES_USING_TOKENS.includes(subscriptionInfo?.type ?? '') &&
            tokenStatus &&
            currentSimTimeEstimate &&
            tokenStatus?.reservableTokens <
              formatEstimatedTokens(currentSimTimeEstimate?.totalEstimatedTokensSeconds)) ??
          false
        }
        selectedSimulation={selectedSimulation}
        simulationRunDetails={simulationRunDetails}
        setSimulationRunDetails={setSimulationRunDetails}
        isGAOnly={isGAOnly}
        tooManyMeshElements={tooManyMeshElements}
        meshTaskId={meshTaskId}
        totalEstimatedRuntimeAdjustedForConcurrency={totalEstimatedRuntimeAdjustedForConcurrency}
        onClosePopup={onClosePopup}
        setWaitingToStart={setWaitingToStart}
      />
    </TrblPopup>
  );
};
