import { useMutation } from '@tanstack/react-query';

import { GridReceiver, ModelSettings, Point, SolverSettings, SourceParameter, TaskType } from '@/types';

import axios from '@/axios';

type CalculateSimulationRun = {
  simulationId: string;
  taskType: string | null;
  sources: Point[];
  sourceParameters: SourceParameter[];
  receivers: Point[];
  gridReceivers: GridReceiver[];
  modelSettings: ModelSettings | null;
  solverSettings: SolverSettings | null;
  settingsPreset: number | null;
  useEstimationService: boolean;
};

export type SimulationEstimateDtoSimulationEstimateDto = {
  simulationId: string;
  taskType: TaskType;
  totalEstimatedRuntimeSeconds: number;
  totalEstimatedRuntimeAdjustedForConcurrency: number;
  totalEstimatedTokensSeconds: number;
  estimatePerSource: SourceEstimateDto[];
};

export type SourceEstimateDto = {
  sourceId: string;
  sourceLabel: string;
  estimatedRunTimeSec: number;
  estimatedTokensSec: number;
  estimatedGpusAllocated: number;
  gpuAllocationStrategy: GpuAllocationStrategy | null;
};

export type GpuAllocationStrategy = 'GPUCountOptimized' | 'UtilizationOptimized';

const calculateSimulationRunEstimatedTime = async (
  simulation: CalculateSimulationRun,
  meshTaskId: string | null
): Promise<SimulationEstimateDtoSimulationEstimateDto> => {
  const { data } = await axios.post(`/api/SolveTask/CalculateSimulationRunEstimatedTime`, simulation, {
    params: {
      meshTaskId,
    },
  });
  return data;
};

export const useCalculateSimulationRunEstimatedTime = () => {
  return useMutation(
    async ({ simulation, meshTaskId }: { simulation: CalculateSimulationRun; meshTaskId: string | null }) =>
      await calculateSimulationRunEstimatedTime(simulation, meshTaskId),
    {
      onSuccess: (data) => data,
      onError: (error) => error,
    }
  );
};
