import { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ManageAccessPopup } from '@/components/ProjectsView/components/ManageAccessPopup';
import { TrblDataGrid } from '../../TrblDataGrid';

import { useGetColumns } from './hooks';

import { ProjectAndSpacesDto } from '@/types';

import styles from './styles.module.scss';

type ProjectsListViewProps = {
  projects: ProjectAndSpacesDto[];
  loggedInUserId: string;
  searchValue: string;
};

export const ProjectsListView: FC<ProjectsListViewProps> = ({ projects, loggedInUserId, searchValue }) => {
  const navigate = useNavigate();
  const listContainerRef = useRef<HTMLDivElement>(null);

  const [selectedProjectRow, setSelectedProjectRow] = useState<string | null>(null);
  const [showProjectMembersPopup, setShowProjectMembersPopup] = useState<boolean>(false);

  const handleSelectProject = (e: React.MouseEvent | React.KeyboardEvent, project: ProjectAndSpacesDto) => {
    // Do not navigate if the click is on a button (i.e. the spaces dropdown) or outside the list container
    if ((e.target as HTMLElement).closest('button') || !listContainerRef.current?.contains(e.target as Node)) return;

    navigate(`/project/${project.id}`);
  };

  const columns = useGetColumns(loggedInUserId, setSelectedProjectRow, setShowProjectMembersPopup, searchValue);

  const closeManageAccessPopup = () => {
    setShowProjectMembersPopup(false);
    setSelectedProjectRow(null);
  };

  const selectedProject = selectedProjectRow ? projects.find((project) => project.id === selectedProjectRow) : null;

  return (
    <div ref={listContainerRef} className={styles['projects-list-container']}>
      <TrblDataGrid
        rows={projects}
        rowHeight={56}
        headerHeight={24}
        columns={columns}
        onRowClick={(e, row) => {
          handleSelectProject(e, row);
        }}
        selectedRow={selectedProjectRow ?? undefined}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
      />
      {showProjectMembersPopup && selectedProject && (
        <ManageAccessPopup onClose={closeManageAccessPopup} project={selectedProject} />
      )}
    </div>
  );
};
