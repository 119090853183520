import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { ActionType, useEditorContext } from '@/context/EditorContext';
import { GeometryImportProvider } from '@/context/GeometryImportContext';

import { ClickAwayListener, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper } from '@mui/material';

import { MultiSpaceImportPopup } from '@/components/MultiSpaceImport';
import { CreateMaterial } from '../../CreateMaterial';
import { CreateSourceDefinition } from '../../CreateSourceDefinition';
import {
  TrblCreateIcon,
  TrblFolderIcon,
  TrblMaterialIcon,
  TrblModelsIcon,
  TrblSimulationIcon,
  TrblSourceDefinitionIcon,
} from '../../Icons';
import { TertiaryButton } from '../../Shared/Buttons';
import { CreateNewSimulationPopup } from '../CreateNewSimulationPopup';
import { CreateProject } from '../CreateProject';

import { useGetLatestSpaceExtractionTasks } from '@/hooks';

import './styles.scss';

export const CreateButton = ({ hidden }: { hidden?: boolean }) => {
  const { dispatch } = useEditorContext();

  const [open, setOpen] = useState(false);
  const [showSimulationPopup, setShowSimulationPopup] = useState<undefined | boolean>(undefined);
  const [showProjectPopup, setShowProjectPopup] = useState<undefined | boolean>(undefined);
  const [showMaterialPopup, setShowMaterialPopup] = useState<undefined | boolean>(undefined);
  const [showSourceDefinitionPopup, setShowSourceDefinitionPopup] = useState<undefined | boolean>(undefined);
  const [showModelImportPopup, setShowModelImportPopup] = useState(false);

  const { data: spaceExtractionTasks } = useGetLatestSpaceExtractionTasks(100, showModelImportPopup);

  const uploadModelDone = () => {
    setShowModelImportPopup(false);
  };

  const anchorRef = useRef<HTMLButtonElement>(null);

  // For keyboard navigation we need to keep track globally when popups
  // are open that should have their own keyboard navigation or shortcuts
  useEffect(() => {
    if (showSimulationPopup !== undefined) {
      dispatch({
        type: ActionType.SET_IS_POPUP_OPEN,
        isOpen: showSimulationPopup,
      });
    }
  }, [showSimulationPopup]);

  useEffect(() => {
    if (showMaterialPopup !== undefined) {
      dispatch({
        type: ActionType.SET_IS_POPUP_OPEN,
        isOpen: showMaterialPopup,
      });
    }
  }, [showMaterialPopup]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_IS_CREATE_MENU_OPEN,
      isOpen: open,
    });
  }, [open]);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const createNewSimulation = (event: Event | SyntheticEvent<Element, Event>) => {
    setShowSimulationPopup(true);
    handleClose(event);
  };

  const createNewProject = (event: Event | SyntheticEvent<Element, Event>) => {
    setShowProjectPopup(true);
    handleClose(event);
  };

  const createNewMaterial = (event: Event | SyntheticEvent<Element, Event>) => {
    setShowMaterialPopup(true);
    handleClose(event);
  };

  const createNewSourceDefinition = (event: Event | SyntheticEvent<Element, Event>) => {
    setShowSourceDefinitionPopup(true);
    handleClose(event);
  };

  const importSpace = (event: Event | SyntheticEvent<Element, Event>) => {
    setShowModelImportPopup(true);
    handleClose(event);
  };

  return (
    <div>
      <TertiaryButton
        className={`create-button ${hidden ? 'hide-up' : ''}`}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        label="New"
        data-dd-action-name="New button"
        icon={<TrblCreateIcon />}
        onClick={handleToggle}></TertiaryButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        style={{ zIndex: 1 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'right top' : 'right bottom',
            }}>
            <Paper className="create-button-paper">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  className="create-button-list">
                  {location.pathname.includes('/editor') && (
                    <MenuItem onClick={createNewSimulation} data-dd-action-name="Create simulation option">
                      <ListItemIcon>
                        <TrblSimulationIcon width="16" height="16" />
                      </ListItemIcon>
                      <ListItemText>Simulation</ListItemText>
                    </MenuItem>
                  )}
                  <MenuItem onClick={importSpace} data-dd-action-name="Import space option">
                    <ListItemIcon>
                      <TrblModelsIcon width="16" height="16" />
                    </ListItemIcon>
                    <ListItemText>Import geometry</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={createNewProject} data-dd-action-name="Create project option">
                    <ListItemIcon>
                      <TrblFolderIcon width="16" height="16" />
                    </ListItemIcon>
                    <ListItemText>Project</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={createNewMaterial} data-dd-action-name="Create material option">
                    <ListItemIcon>
                      <TrblMaterialIcon width="16" height="16" />
                    </ListItemIcon>
                    <ListItemText>Material</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={createNewSourceDefinition} data-dd-action-name="Create Source Definition option">
                    <ListItemIcon>
                      <TrblSourceDefinitionIcon width="16" height="16" />
                    </ListItemIcon>
                    <ListItemText>Source definition</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {showSimulationPopup && <CreateNewSimulationPopup setShowPopup={setShowSimulationPopup} />}
      {showProjectPopup && <CreateProject onClose={() => setShowProjectPopup(false)} />}
      {showMaterialPopup && <CreateMaterial setShowPopup={setShowMaterialPopup} />}
      {showSourceDefinitionPopup && <CreateSourceDefinition setShowPopup={setShowSourceDefinitionPopup} />}
      {showModelImportPopup && spaceExtractionTasks && (
        <GeometryImportProvider>
          <MultiSpaceImportPopup
            setShowPopup={setShowModelImportPopup}
            uploadModelDone={uploadModelDone}
            spaceExtractionTasksOnLoad={spaceExtractionTasks}
          />
        </GeometryImportProvider>
      )}
    </div>
  );
};
