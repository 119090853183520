import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SimulationPickerOption } from './SimulationPickerOption';

import { FilterType, ListType, SortType } from './constants';

import { setSimulationStatus } from './utils';
import { toSorted } from '@/utils/trebleFunctions';

import { Simulation } from '@/types';

import './styles2.scss';

type SimulationPicker = {
  selectedSimulation: Simulation | null;
  availableSimulations: Simulation[] | null;
  listType: ListType;
  sortListBy: SortType;
  filterType: FilterType;
  showMenu: boolean;
  menuContent: React.RefObject<HTMLDivElement>;
  searchValue: string;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SimulationPicker = ({
  selectedSimulation,
  availableSimulations,
  listType,
  sortListBy,
  showMenu,
  filterType,
  menuContent,
  searchValue,
  setShowMenu,
}: SimulationPicker) => {
  const [selectedSim, setSelectedSim] = useState<Simulation | null>(selectedSimulation);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredSimulations, setFilteredSimulations] = useState<Simulation[]>([]);

  useEffect(() => {
    if (showMenu) {
      setTimeout(() => {
        scrollToSimulation();
      }, 0);
      document.addEventListener('mousedown', onOutsideClick);
    }
    return () => document.removeEventListener('mousedown', onOutsideClick);
  }, [showMenu]);

  const onOutsideClick = (e: Event) => {
    const target = e.target as HTMLElement;
    // hack to not close the menu if sort dropdown is clicked
    if (menuContent.current?.contains(e.target as Node) || target.closest('.filter-dropdown')) {
      return;
    }
    setShowMenu(false);
  };

  const scrollToSimulation = () => {
    if (selectedSim) {
      const item = document.querySelector('#sim-' + selectedSim.id);
      item?.scrollIntoView({ block: 'center' });
    }
  };

  useEffect(() => {
    if (availableSimulations) {
      for (const simulation of availableSimulations) {
        setSimulationStatus(simulation);
      }
      filterSimulations(filterType);
    }
  }, [availableSimulations, filterType]);

  useEffect(() => {
    // This is for when the global state changes like when a new simulation is created or deleted
    if (selectedSimulation) {
      setSelectedSim(selectedSimulation);
    }
  }, [selectedSimulation]);

  useEffect(() => {
    if (searchValue) {
      const filteredSimulations = (availableSimulations ? [...availableSimulations] : []).filter((simulation) => {
        return [simulation.name, simulation.modelName].some((prop) =>
          prop?.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
      setFilteredSimulations(filteredSimulations);
    }
  }, [searchValue, availableSimulations]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeSelectedSim = (thisSimId: string | null) => {
    if (availableSimulations) {
      const selectedOption = availableSimulations.find((sim: Simulation) => sim.id === thisSimId);
      const currentModelId = searchParams.get('mid');

      if (selectedOption && currentModelId) {
        setSearchParams({ mid: selectedOption.modelId, sid: selectedOption.id });
        setSelectedSim(selectedOption);
      }
    }
    setShowMenu(false);
  };

  const sortSimulations = (simulations: Simulation[], sortListBy: SortType) => {
    switch (sortListBy) {
      case SortType.NAME: {
        return toSorted(simulations, (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      }
      case SortType.MODIFIED: {
        return toSorted(simulations, (a, b) =>
          new Date(b.updatedAt || b.lastSimulationRun?.completedAt || b.createdAt) >
          new Date(a.updatedAt || a.lastSimulationRun?.completedAt || a.createdAt)
            ? 1
            : -1
        );
      }

      case SortType.CREATED: {
        return toSorted(simulations, (a, b) => (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1));
      }
    }
  };

  const filterSimulations = (type: FilterType) => {
    if (type === FilterType.SPACE) {
      setFilteredSimulations(availableSimulations ? [...availableSimulations] : []);
    } else if (type === FilterType.GEOMETRY) {
      setFilteredSimulations(
        (availableSimulations ? [...availableSimulations] : []).filter(
          (simulation) => simulation.modelId == selectedSimulation?.modelId
        )
      );
    }
  };

  return (
    <div
      className="simulations-list dropdown-content-list"
      style={{ display: listType == 'simulations' ? 'block' : 'none' }}>
      {filteredSimulations.length > 0 &&
        sortSimulations(filteredSimulations, sortListBy).map((simulation: Simulation) => (
          <div
            onClick={() => changeSelectedSim(simulation.id)}
            key={simulation.id}
            id={'sim-' + simulation.id}
            className={simulation.id == selectedSim?.id ? 'sim-opt-selected' : ''}>
            <SimulationPickerOption
              name={simulation.name}
              modelName={simulation.modelName ?? ''}
              searchValue={searchValue}
              status={simulation.extra.status}
              createdAt={simulation.createdAt}
              simulationRunCreatedAt={simulation.lastSimulationRun?.createdAt}
              simulationRunCompletedAt={simulation.lastSimulationRun?.completedAt}
              hasBeenEdited={simulation.hasBeenEdited}
            />
          </div>
        ))}
    </div>
  );
};
