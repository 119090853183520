import { FC, useState } from 'react';

import { Box, Collapse, Stack } from '@mui/material';

import { TrblIconButton } from '@/components/Shared/Buttons';
import { Divider } from '@/components/Shared/Divider';
import { ProgressBar } from '@/components/Shared/ProgressBar';
import { Text } from '@/components/Shared/Text';
import { TrblChevronDownIcon, TrblChevronUpIcon } from '../../Icons';

import { getStatusText } from '../utils';

import { SimulationRunDetails as SimulationRunDetailsType, SimulationStatusText } from '../types';

type SimulationRunDetailsProps = {
  simulationRunDetails: SimulationRunDetailsType;
};

export const SimulationRunDetails: FC<SimulationRunDetailsProps> = ({ simulationRunDetails }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpandStatus = () => {
    setIsExpanded(!isExpanded);
  };

  const { progressBarColor, statusText, showEllipsis, percentage, timeRemainingText, showRemaining, sourceDetails } =
    simulationRunDetails;

  return (
    <Box
      component="div"
      margin="-4px -19px 5px"
      padding="10px 20px 10px"
      bgcolor={isExpanded ? 'rgba(33, 33, 33, 0.98)' : undefined}>
      <Stack>
        {statusText === SimulationStatusText.Queued ? (
          <Text type="medium-11px" ellipsis={showEllipsis}>
            Simulation in queue...
          </Text>
        ) : (
          <Text type="medium-11px" ellipsis={showEllipsis}>
            {getStatusText(statusText, showRemaining, percentage, timeRemainingText)}
          </Text>
        )}

        <Box component="div" display="flex" gap="6px" alignItems={'center'}>
          <ProgressBar
            sx={{ flexGrow: 1 }}
            color={progressBarColor}
            valueBuffer={100} // fill the buffer to 100% to show the buffer color
            variant={statusText === SimulationStatusText.Queued ? 'buffer' : 'determinate'}
            value={statusText === SimulationStatusText.Queued ? 100 : percentage ?? 0}
          />
          <TrblIconButton
            label={isExpanded ? 'Collapse' : 'Expand'}
            size="small"
            icon={isExpanded ? <TrblChevronUpIcon /> : <TrblChevronDownIcon />}
            onClick={handleExpandStatus}
            sx={{
              visibility: sourceDetails.length == 0 ? 'hidden' : undefined,
            }}
          />
        </Box>
      </Stack>
      <Collapse in={isExpanded} timeout="auto">
        <Box component="div" pl="44px" pr="22px">
          {sourceDetails.map((source, index) => (
            <Box component="div" key={`source_${index}`}>
              <Text type="semibold-10px">{source.label}</Text>
              <Text type="regular-10px">
                {source.inProgress ? ` · Running ${source.percentage || 0}% ` : ` - ${source.statusText}`}
              </Text>
              <ProgressBar color={source.progressBarColor} variant="determinate" value={source.percentage || 0} />
            </Box>
          ))}
        </Box>
      </Collapse>
      <Divider marginOffset={20} marginBottomOffset={-12} marginTopOffset={12} />
    </Box>
  );
};
