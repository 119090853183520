export type CheckoutType = 'subscribe' | 'update' | 'tokens-volume' | 'tokens-tier';

export type SubscriptionType =
  | 'flexible'
  | 'unlimited'
  | 'singlestandard'
  | 'singlepremium'
  | 'teams'
  | 'enterprise'
  | 'tokens';

export type SubscriptionLength = 'monthly' | 'yearly';

export enum Layout {
  IN_APP = 'in_app',
  FULL_PAGE = 'full_page',
}

export type SubscriptionContent = {
  name: string;
  description: string;
  yearlyPrice: string;
  monthlyPrice?: string;
  per: string;
  includedMonthly: string[];
  includedYearly: string[];
  access: string[];
  actionText: string;
  actionLink?: string;
  highlight?: string;
  tokenBundlePricing?: string[];
};

export type SubscriptionParams = {
  product: 'TASS' | 'SDK';
  subscriptionType: SubscriptionType;
  paymentFrequency: SubscriptionLength;
  additionalSeats: number;
}[];

export type TokenParams = {
  item_prices: {
    item_price_id: string;
    quantity: number;
    item_type: 'addon';
  }[];
};
