import { FC } from 'react';

import { IconProps } from '@/types';

// old fill color '#DADADA'
export const ChatIcon: FC<IconProps> = ({ width = '12', height = '12', fill = '#dadada' }) => (
  <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.53827 7.79309H5.84595C5.84827 7.79309 5.85059 7.7931 5.8529 7.79313L8.92318 7.79305C9.94275 7.79305 10.7693 6.99019 10.7693 5.99981V4.28846C10.769 4.28113 10.7689 4.27377 10.7689 4.26638V3.60886C10.7689 2.61847 9.94234 1.81561 8.92277 1.81561H3.07682C2.05725 1.81561 1.23073 2.61847 1.23073 3.60886V5.99985C1.23073 6.99023 2.05725 7.79309 3.07682 7.79309H3.3845C3.72435 7.79309 3.99986 8.06071 3.99986 8.39084V9.19301L5.0749 7.99752C5.19175 7.86762 5.36068 7.79309 5.53827 7.79309ZM11.9991 3.55939C11.97 1.9324 10.6042 0.622074 8.92277 0.620117H3.07682C1.37838 0.622093 0.00203443 1.95904 0 3.60886V5.99985C0.0019455 7.53413 1.19778 8.8186 2.76914 8.97424V10.7818C2.76888 11.031 2.92782 11.2542 3.16796 11.3419C3.4081 11.4296 3.67905 11.3634 3.84787 11.1757L5.81322 8.97644C6.73728 8.98405 8.2821 8.97786 8.93678 8.97524C9.09009 8.97462 9.19459 8.9742 9.23086 8.9742C10.8022 8.81857 11.9981 7.53409 12 5.99981V3.60882C12 3.59246 11.9997 3.57598 11.9991 3.55939Z"
      fill={fill}
    />
  </svg>
);
