import { FC } from 'react';
import { Link } from 'react-router-dom';

import { IconButton } from '@mui/material';

import { PageLayout } from '@/components';
import { TrblChevronLeft } from '@/components/Icons';
import { SDKUsage } from '@/components/ManagementPortal/SDKUsage/SDKUsage';

import { useGetOrganizationMine } from '@/hooks';

export const AdministrationUsagePage: FC = () => {
  const { data: myOrganization } = useGetOrganizationMine();

  return (
    <PageLayout
      extraHeader={
        <div className="breadcrumbs-div">
          <Link to="/administration">
            <IconButton className="back-btn">
              <TrblChevronLeft />
            </IconButton>
          </Link>
          <div className="top-info-text">
            <Link to="/administration">Administration</Link>
            <span className="arrow"> &rsaquo; </span>
            <span className="active">SDK token usage</span>
          </div>
        </div>
      }>
      {myOrganization && <SDKUsage organizationId={myOrganization.id} />}
    </PageLayout>
  );
};
