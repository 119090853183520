import { SimulationLightDto, SpaceSimulationsDto } from '@/components/Results/types';

import { ProjectAndSimulationData } from './types';
import { StatusType } from '@/types';

const mapSimulation = (sim: SimulationLightDto, spaces: SpaceSimulationsDto[], currentSpace: SpaceSimulationsDto) => {
  const originalSpace = spaces.find((s) => s.simulations.some((simulation) => simulation.id === sim.id));

  return {
    simulationDetails: sim,
    modelId: originalSpace?.modelId ?? currentSpace.modelId,
    modelName: originalSpace?.modelBaseName ?? currentSpace.modelBaseName,
  };
};

export const extractProjectAndSimulationData = (
  allSpacesWithSims: SpaceSimulationsDto[]
): ProjectAndSimulationData[] => {
  // Group spaces by projectId
  const projectMap = new Map<string, SpaceSimulationsDto[]>();

  allSpacesWithSims.forEach((spaceData) => {
    const existing = projectMap.get(spaceData.projectId) || [];
    projectMap.set(spaceData.projectId, [...existing, spaceData]);
  });

  // Transform the data
  return Array.from(projectMap.entries())
    .map(([projectId, spaces]) => {
      const firstSpace = spaces[0];

      // Create a Map to deduplicate spaces by spaceId
      const uniqueSpacesMap = new Map<string, SpaceSimulationsDto>();
      spaces.forEach((space) => {
        const existingSpace = uniqueSpacesMap.get(space.spaceId);
        if (existingSpace) {
          existingSpace.simulations = [...existingSpace.simulations, ...space.simulations];
        } else {
          uniqueSpacesMap.set(space.spaceId, { ...space });
        }
      });

      return {
        projectId,
        projectName: firstSpace.projectName,
        spaces: Array.from(uniqueSpacesMap.values())
          .map((space) => {
            const completedSimulations = space.simulations.filter(
              (sim) => sim.lastSimulationRun?.status === StatusType.Completed
            );

            if (completedSimulations.length === 0) return null;

            return {
              spaceId: space.spaceId,
              spaceName: space.spaceName,
              simulations: [...completedSimulations]
                .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                .map((sim) => mapSimulation(sim, spaces, space)),
            };
          })
          .filter((space): space is NonNullable<typeof space> => space !== null),
      };
    })
    .filter((project) => project.spaces.length > 0);
};
