import dayjs from 'dayjs';

import { TrblTooltip } from '@/components/Shared';
import {
  TrblArrowThinRight,
  TrblCancelledIcon,
  TrblCompletedDottedIcon,
  TrblCompletedIcon,
  TrblErrorIcon,
  TrblInprogressIcon,
} from '@/components/Icons';

import { TaskStatus } from '@/types';

import classes from './styles.module.scss';

interface RecentUploadStatusDisplayProps {
  task: TaskStatus;
  uploadStatus?: string;
  hasBeenImported?: boolean;
  goNext: () => void;
}

export const RecentUploadStatusDisplay = ({
  task,
  uploadStatus,
  hasBeenImported,
  goNext,
}: RecentUploadStatusDisplayProps) => {
  return (
    <div className={classes.row}>
      <div className={classes.status}>
        {(!uploadStatus || uploadStatus === 'Created') && (
          <>
            <TrblInprogressIcon />
            <p className={classes.status_text}>
              Created
              {' ' + dayjs(task.createdAt).format('DD MMM, HH:mm')}
            </p>
          </>
        )}

        {(uploadStatus === 'InProgress' || uploadStatus === 'Pending' || uploadStatus === 'ProcessingResults') && (
          <>
            <TrblInprogressIcon />
            <p className={classes.status_text}>
              In progress
              <span title="Started at">{' · ' + dayjs(task.createdAt).format('DD MMM, HH:mm')}</span>
            </p>
          </>
        )}
        {uploadStatus === 'Completed' && (
          <>
            {hasBeenImported ? <TrblCompletedIcon /> : <TrblCompletedDottedIcon />}

            <p className={classes.status_text}>
              {hasBeenImported ? 'Import completed' : 'Ready for import'}
              <span title="Finished at">{' · ' + dayjs(task.completedAt).format('DD MMM, HH:mm')}</span>
            </p>
          </>
        )}
        {uploadStatus === 'Cancelled' && (
          <>
            <TrblCancelledIcon />
            <p className={classes.status_text}>
              Cancelled
              <span title="Started at">{' · ' + dayjs(task.createdAt).format('DD MMM, HH:mm')}</span>
            </p>
          </>
        )}
        {(uploadStatus === 'Error' || uploadStatus === 'TaskError') && (
          <>
            <TrblTooltip title={task.userStatusMsg ?? 'Error occurred while uploading IFC file'}>
              <span className={`${classes.error_text} ${classes.status_text} `}>
                <TrblErrorIcon />
                Error
              </span>
            </TrblTooltip>
            <p className={classes.status_text}>
              <span title="Errored at">{' · ' + dayjs(task.completedAt).format('DD MMM, HH:mm')}</span>
            </p>
          </>
        )}
      </div>
      {uploadStatus === 'Completed' && !hasBeenImported && (
        <div className={classes.button_container}>
          <TrblTooltip title="Import IFC geometries">
            <span style={{ float: 'right' }}>
              <button onClick={goNext} className={`${classes.quick_btn} ${classes.stroke_hover}`}>
                <TrblArrowThinRight fill="#dadada" />
              </button>
            </span>
          </TrblTooltip>
        </div>
      )}
    </div>
  );
};
