import { Link } from 'react-router-dom';

import { QuickLinks } from '../RecentActivity';
import { SimulationStatusDisplay } from './SimulationStatusDisplay';

import { RunStatus, Simulation } from '@/types';

import classes from '../RecentActivity/styles.module.scss';

interface SimulationStatusProps {
  simulation: Simulation;
}

export const SimulationStatus = ({ simulation }: SimulationStatusProps) => {
  return (
    <div className={classes.recent_simulations_status}>
      <Link className={classes.simulation_name} to={`/editor?mid=${simulation.modelId}&sid=${simulation.id}`}>
        {simulation.name}
      </Link>
      <div className={classes.row}>
        {simulation.lastSimulationRun?.status ? (
          <>
            <SimulationStatusDisplay
              simulationStatus={simulation.lastSimulationRun.status}
              date={simulation.lastSimulationRun.completedAt ?? simulation.lastSimulationRun.createdAt}
              hasBeenEdited={simulation.hasBeenEdited ?? undefined}
            />
            {simulation.lastSimulationRun.status == RunStatus.Completed && (
              <QuickLinks simulationId={simulation.id} modelId={simulation.modelId} />
            )}
          </>
        ) : (
          <SimulationStatusDisplay date={simulation.createdAt} />
        )}
      </div>
    </div>
  );
};
