import { TabButton } from '@/components/Shared';

export enum VIEW_TYPES {
  CARDS = 'cards',
  LIST = 'list',
}

export const VIEW_TYPES_OPTIONS: TabButton[] = [
  { key: VIEW_TYPES.CARDS, icon: 'cardsView', tooltip: 'Card view' },
  { key: VIEW_TYPES.LIST, icon: 'listView', tooltip: 'List view' },
];
