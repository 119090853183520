import { FragmentsGroup } from '@thatopen/fragments';

import * as OBC from '@thatopen/components';
import { IfcQueryGroup } from '@thatopen/components/dist/ifc/IfcFinder/src/ifc-query-group';

export interface Space {
  expressId: number;
  GUID: string;
  LongName?: string;
  Name?: string | null;
}

export interface Element {
  expressId: number;
  GUID: string;
  Name?: string;
}

export interface IfcValidator {
  components: OBC.Components | null;
  ifcLoader: OBC.IfcLoader | null;
  finder: OBC.IfcFinder | null;
  indexer: OBC.IfcRelationsIndexer | null;
  queryElement: IfcQueryGroup | null;
  queryProxyElement: IfcQueryGroup | null;
  querySpace: IfcQueryGroup | null;
  isWithElements: boolean | null;
  isWithProxyElements: boolean | null;
  isWithIfcSpaces: boolean | null;
}

const categoryList: RegExp[] = [
  /IfcCovering/,
  /IfcBeam/,
  /IfcColumn/,
  /IfcCurtainWall/,
  /IfcDoor/,
  /IfcMember/,
  /IfcRailing/,
  /IfcRamp/,
  /IfcRampFlight/,
  /IfcWall/,
  /IfcSlab/,
  /IfcStairFlight/,
  /IfcWindow/,
  /IfcStair/,
  /IfcRoof/,
  /IfcPile/,
  /IfcFooting/,
  /IfcBuildingElementComponent/,
  /IfcPlate/,
];

export const runIFCPreprocess = async (file: File) => {
  const ifcValidator = initIfcValidator();

  let status = 'failed';
  let message: string | (() => JSX.Element) = '';

  if (ifcValidator.ifcLoader) {
    await ifcValidator.ifcLoader.setup();
    const data = await file.arrayBuffer();
    const buffer = new Uint8Array(data);
    const ifcModel = await ifcValidator.ifcLoader.load(buffer);

    const uuid = ifcModel.uuid;

    if (ifcModel && ifcValidator.indexer) {
      await ifcValidator.indexer.process(ifcModel);

      // final argument is fast check, does not create the list of objects
      // if set to fastCheck = false, it also prints the lists
      ifcValidator.isWithProxyElements = await checkElements(
        file,
        ifcValidator.queryProxyElement,
        uuid,
        ifcModel,
        true
      );

      ifcValidator.isWithElements = await checkElements(file, ifcValidator.queryElement, uuid, ifcModel, true);
      ifcValidator.isWithIfcSpaces = await checkIfcSpaces(file, ifcValidator.querySpace, uuid, ifcModel, true);

      if (ifcValidator.isWithElements == true) {
        status = 'success';
        message = 'Preprocessing succeeded';
        if (ifcValidator.isWithIfcSpaces == false) {
          status = 'info';
          message = 'No IfcSpace definition found, detected spaces will be unnamed.';
        }
      } else {
        status = 'failed';
        message = 'Error: IFC file has no content';
        if (ifcValidator.isWithProxyElements) {
          status = 'info';
          message = () => (
            <div>
              Only Proxy elements in the model, the import quality will be greatly affected (see{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href="https://docs.treble.tech/user-guide/importing-models/ifc-import/importing-ifc#only-proxy-elements-warning"
                target="_blank">
                documentation
              </a>
              ) <br /> No IfcSpace definition found, detected spaces will be unnamed.
            </div>
          );
        }
      }
    } else {
      console.log('No ifc indexer module found');
    }
  } else {
    console.log('No ifc loader module found');
  }

  return { status, message };
};

const initIfcValidator = () => {
  const _ifcValidator: IfcValidator = {
    components: null,
    ifcLoader: null,
    indexer: null,
    finder: null,
    queryElement: null,
    queryProxyElement: null,
    querySpace: null,
    isWithElements: null,
    isWithProxyElements: null,
    isWithIfcSpaces: null,
  };
  const components = new OBC.Components();

  _ifcValidator.components = components;
  _ifcValidator.ifcLoader = components.get(OBC.IfcLoader);
  _ifcValidator.indexer = components.get(OBC.IfcRelationsIndexer);
  _ifcValidator.finder = components.get(OBC.IfcFinder);
  _ifcValidator.queryElement = createElementQueryGroup(components);
  _ifcValidator.queryProxyElement = createProxyElementQueryGroup(components);
  _ifcValidator.querySpace = createSpaceQueryGroup(components);

  return _ifcValidator;
};

function createProxyElementQueryGroup(components: OBC.Components) {
  // create a query group
  const queryGroupElements = components.get(OBC.IfcFinder).create();

  // create a basic query for an IFC category and add
  const elementBasicQuery = new OBC.IfcBasicQuery(components, {
    name: 'category',
    inclusive: true,
    rules: [],
  });
  queryGroupElements.add(elementBasicQuery);

  const newCategoryRule: OBC.IfcCategoryRule = {
    type: 'category',
    value: /IfcBuildingElementProxy/,
  };
  elementBasicQuery.rules.push(newCategoryRule);

  return queryGroupElements;
}

function createElementQueryGroup(components: OBC.Components) {
  // create a query group
  const queryGroupElements = components.get(OBC.IfcFinder).create();

  // create a basic query for an IFC category and add
  const elementBasicQuery = new OBC.IfcBasicQuery(components, {
    name: 'category',
    inclusive: true,
    rules: [],
  });
  queryGroupElements.add(elementBasicQuery);

  categoryList.forEach((cat: RegExp) => {
    // create the rule for the category and push
    const newCategoryRule: OBC.IfcCategoryRule = {
      type: 'category',
      value: cat,
    };
    elementBasicQuery.rules.push(newCategoryRule);
  });

  return queryGroupElements;
}

function createSpaceQueryGroup(components: OBC.Components) {
  // create a query group
  const queryGroupSpace = components.get(OBC.IfcFinder).create();

  // create a basic query for an IFC category and add
  const ifcBasicQuery = new OBC.IfcBasicQuery(components, {
    name: 'category',
    inclusive: false,
    rules: [],
  });
  queryGroupSpace.add(ifcBasicQuery);

  // create the rule for the category and push
  const categoryRule: OBC.IfcCategoryRule = {
    type: 'category',
    value: /IfcSpace/,
  };
  ifcBasicQuery.rules.push(categoryRule);

  return queryGroupSpace;
}

function setAlreadyIncluded(_array: Set<number>[], _set: Set<number>): boolean {
  const areSetsEqual = (a: Set<number>, b: Set<number>) => a.size === b.size && [...a].every((value) => b.has(value));

  const booleanMask: boolean[] = [];

  _array.forEach((element) => {
    // if true return true
    if (areSetsEqual(_set, element)) {
      booleanMask.push(true);
      return true;
    } else {
      booleanMask.push(false);
    }
  });

  const isIncluded = (element: boolean) => element === true;
  return booleanMask.some(isIncluded);
}

async function checkElements(
  file: File,
  queryGroup: IfcQueryGroup | null,
  uuid: string,
  ifcModel: FragmentsGroup,
  fastCheck: boolean
) {
  if (!queryGroup) return null;
  // apply the rule to the loaded model
  await queryGroup.update(uuid, file);

  const items = queryGroup.items;
  const elementIdList: number[] = [];
  const elementList: Element[] = [];
  const expressIDSetList: Set<number>[] = [];

  if (Object.keys(items).length === 0) {
    return false;
  } else {
    if (!fastCheck) {
      Object.keys(items).forEach(async (key) => {
        const expressIDSet = items[key];

        if (expressIDSetList.length === 0) {
          expressIDSetList.push(expressIDSet);

          runLoop(expressIDSet);
        } else if (!setAlreadyIncluded(expressIDSetList, expressIDSet)) {
          expressIDSetList.push(expressIDSet);
          runLoop(expressIDSet);
        }
      });
    }

    // fast check no list created
    return true;
  }

  async function runLoop(_expressIDSet: Set<number>) {
    for (const entry of _expressIDSet.entries()) {
      const expressId = entry[0];

      if (!elementIdList.includes(expressId)) {
        const pset = await ifcModel.getProperties(expressId);
        if (pset) {
          const ifcElement: Element = {
            expressId: expressId,
            GUID: pset.GlobalId.value,
            Name: pset.Name.value,
          };
          elementList.push(ifcElement);
          elementIdList.push(expressId);
        }
      }
    }
  }
}

async function checkIfcSpaces(
  file: File,
  queryGroup: IfcQueryGroup | null,
  uuid: string,
  ifcModel: FragmentsGroup,
  fastCheck: boolean
) {
  if (!queryGroup) return null;
  // apply the rule to the loaded model
  await queryGroup.update(uuid, file);

  const items = queryGroup.items;
  const spaceGuidList: string[] = [];
  const spaceList: Space[] = [];

  if (Object.keys(items).length === 0) {
    return false;
  } else {
    if (!fastCheck) {
      Object.keys(items).forEach(async (key) => {
        const expressIDSet = items[key];

        for (const entry of expressIDSet.entries()) {
          const expressId = entry[0];

          const ifcSpace = await spaceBuilder(expressId, ifcModel);

          if (ifcSpace) {
            if (!spaceGuidList.includes(ifcSpace.GUID)) {
              spaceGuidList.push(ifcSpace.GUID);
              spaceList.push(ifcSpace);
            }
          }
        }
      });

      return true;
    }
    // fast check no list created
    return true;
  }
}

async function spaceBuilder(_expressId: number, ifcModel: FragmentsGroup) {
  const pset = await ifcModel.getProperties(_expressId);

  if (pset) {
    let pname = '';
    let plongname = '';
    if (pset.Name) {
      pname = pset.Name.value;
    } else {
      pname = '';
      if (pset.LongName) {
        plongname = pset.LongName;
      } else {
        plongname = '';
      }
    }
    const ifcSpace: Space = {
      expressId: _expressId,
      GUID: pset.GlobalId.value,
      LongName: plongname,
      Name: pname,
    };

    return ifcSpace;
  } else return null;
}
