import { useMemo } from 'react';
import dayjs from 'dayjs';

import { TrblTooltip } from '@/components/Shared';
import { HighlightedText } from '@/components/Shared/HighlightedText';
import { Trbl3DWireframeIcon, TrblFolderIcon, TrblSimulationIcon } from '@/components/Icons';
import { ResultPresetDto } from '@/components/ResultPresets/types';

import { SavedResultsRowData } from '../types';

export const useColumns = (searchValue: string) => {
  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Results',
        sortable: true,
        width: '290px',
        renderCell: (row: SavedResultsRowData) => {
          return (
            <TrblTooltip title={row.name.length > 100 ? row.name : ''}>
              <span style={{ fontWeight: 600 }}>
                <HighlightedText text={row.name} highlight={searchValue} />
              </span>
            </TrblTooltip>
          );
        },
      },
      {
        field: 'updatedAt',
        headerName: 'Modified',
        sortable: true,
        maxWidth: '200px',
        renderCell: (row: ResultPresetDto) => {
          const updatedAt = row.updatedAt ? dayjs(row.updatedAt).format('D MMM YYYY, HH:MM') : '';

          return <TrblTooltip title={updatedAt + ' by ' + row.updatedByUserEmail}>{updatedAt}</TrblTooltip>;
        },
      },
      {
        field: 'allSimulations',
        headerName: 'Simulation',
        sortable: true,
        icon: <TrblSimulationIcon fill="#ADADAD" width="14" height="14" />,
        renderCell: (row: SavedResultsRowData) => {
          return (
            <TrblTooltip
              title={
                row.allSimulations.length > 30 ? (
                  <div style={{ whiteSpace: 'pre-line' }}>{row.allSimulations.replaceAll(',', '\n')}</div>
                ) : (
                  ''
                )
              }>
              <span>
                <HighlightedText text={row.allSimulations} highlight={searchValue} />
              </span>
            </TrblTooltip>
          );
        },
      },
      {
        field: 'allSpaces',
        headerName: 'Space',
        sortable: true,
        icon: <Trbl3DWireframeIcon fill="#ADADAD" />,
        renderCell: (row: SavedResultsRowData) => {
          return (
            <TrblTooltip
              title={
                row.allSpaces.length > 30 ? (
                  <div style={{ whiteSpace: 'pre-line' }}>{row.allSpaces.replaceAll(',', '\n')}</div>
                ) : (
                  ''
                )
              }>
              <span>
                <HighlightedText text={row.allSpaces} highlight={searchValue} />
              </span>
            </TrblTooltip>
          );
        },
      },
      {
        field: 'allProjects',
        headerName: 'Project',
        sortable: true,
        icon: <TrblFolderIcon fill="#ADADAD" />,
        renderCell: (row: SavedResultsRowData) => {
          return (
            <TrblTooltip
              title={
                row.allProjects.length > 30 ? (
                  <div style={{ whiteSpace: 'pre-line' }}>{row.allProjects.replaceAll(',', '\n')}</div>
                ) : (
                  ''
                )
              }>
              <span>
                <HighlightedText text={row.allProjects} highlight={searchValue} />
              </span>
            </TrblTooltip>
          );
        },
      },
      {
        field: 'createdByUserEmail',
        headerName: 'Created by',
        sortable: true,
        renderCell: (row: SavedResultsRowData) => {
          const createdAt = row.createdAt ? dayjs(row.createdAt).format('D MMM YYYY') : '';

          return (
            <TrblTooltip title={createdAt + ' by ' + row.createdByUserEmail}>
              <span>
                <HighlightedText text={row.createdByUserEmail} highlight={searchValue} />
              </span>
            </TrblTooltip>
          );
        },
      },
    ],
    [searchValue]
  );

  return columns;
};
