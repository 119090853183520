import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { TrblSearchIcon } from '@/components/Icons';

import styles from './styles.module.scss';

interface ExpandableSearchInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  expandedWidth?: string; // e.g., '300px'
}

export const ExpandableSearchInput = ({
  value,
  onChange,
  placeholder = 'Search...',
  className,
  expandedWidth = '300px',
}: ExpandableSearchInputProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus();
    }
  }, [isExpanded]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (!value) {
        setIsExpanded(false);
      }
    }, 100);
  };

  const handleClickIcon = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    } else if (!value) {
      setIsExpanded(false);
    }
  };

  return (
    <div
      className={`${styles.container} ${isExpanded ? styles.expanded : ''} ${className ?? ''}`}
      style={{ '--expanded-width': expandedWidth } as React.CSSProperties}>
      <TrblTooltip title={placeholder} disableInteractive>
        <button className={styles.iconButton} onClick={handleClickIcon} onFocus={(e) => e.preventDefault()}>
          <TrblSearchIcon width="15.5" height="15.5" fill="#DADADA" />
        </button>
      </TrblTooltip>
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        className={styles.input}
      />
    </div>
  );
};
