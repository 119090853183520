import { useEffect, useState } from 'react';

import { SourceDefinitionCategories, useAppContext } from '@/context/AppContext';

import { Box, Stack } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { SelectOption, TrblSelect } from '@/components/Shared/TrblSelect';
import { CORRECT_IR_SPL_TEXT } from '@/components/SourceDefinition/constants';
import { TrblToggle, TrblTooltip } from '../Shared';
import { TextArea } from '../Shared/TextArea';

type SourceDefinitionInput = {
  description: string;
  setDescription: (value: string) => void;
  category: string | null;
  setCategory: (value: string) => void;
  subCategory: string | null;
  setSubCategory: (value: string) => void;
  correctIr: boolean;
  setCorrectIr: (value: boolean) => void;
};

export const SourceDefinitionInput = ({
  description,
  setDescription,
  category,
  setCategory,
  subCategory,
  setSubCategory,
  correctIr,
  setCorrectIr,
}: SourceDefinitionInput) => {
  const [categoryItems, setCategoryItems] = useState<SelectOption[]>([]);
  const [subCategoryItems, setSubCategoryItems] = useState<SelectOption[]>([]);
  const {
    appState: { sourceDefinitionCategories, sourceDefinitionSubCategories },
  } = useAppContext();

  useEffect(() => {
    const categoryItem = sourceDefinitionCategories.map((item) => {
      return {
        name: item,
        id: item,
      };
    });
    setCategoryItems(categoryItem);
  }, []);

  useEffect(() => {
    setSubCategory('');
    if (category) {
      const subCategoryItem = sourceDefinitionSubCategories[category as SourceDefinitionCategories].map((item) => {
        return {
          name: item,
          id: item,
        };
      });
      setSubCategoryItems(subCategoryItem);
    }
  }, [category]);

  return (
    <Box component={'div'} display="flex" flexDirection="column" gap="16px" mb="16px" mt="4px">
      <Box component={'div'} display="flex" flexGrow={1} gap="16px">
        <Stack gap={1} flex={'1 1 0'}>
          <TrblSelect
            value={category ?? ''}
            setValue={setCategory}
            menuItems={categoryItems}
            placeholder="Select type (required)"
          />
          <TrblSelect
            value={subCategory ?? ''}
            setValue={setSubCategory}
            menuItems={subCategoryItems}
            placeholder="Select category (required)"
          />
        </Stack>
        <Box component="div" flex={'1 1 0'}>
          <TextArea
            placeholder="Description"
            value={description}
            onChange={setDescription}
            style={{ minHeight: '80px' }}
          />
        </Box>
      </Box>
      <Stack gap="30px" alignItems={'center'} direction="row" justifyContent={'end'} padding={'8px 0'}>
        <TrblTooltip title={<div style={{ whiteSpace: 'pre-line' }}>{CORRECT_IR_SPL_TEXT}</div>}>
          <Stack gap="12px" alignItems={'center'} direction="row">
            <Text type="regular-11px">Correct IR with on-axis SPL</Text>
            <TrblToggle
              id="irToggle"
              ariaLabel="Set correct IR with on-axis SPL toggle"
              checked={correctIr}
              onChangeToggle={() => setCorrectIr(!correctIr)}
            />
          </Stack>
        </TrblTooltip>
      </Stack>
    </Box>
  );
};
