import { useThree } from '@react-three/fiber';
import SpriteText from 'three-spritetext';

export const useCreateGridLabel = (label: string) => {
  const color = '#dddddd';
  const spriteText = useCreateSpriteText(label, color);

  return spriteText;
};

export const useCreatePointLabel = (
  label: string,
  color: string = '#dddddd',
  textHeight: number = 10,
  xOffset: number = -0.9,
  sizeAttenuation?: boolean
) => {
  const spriteText = useCreateSpriteText(label, color, textHeight, xOffset, sizeAttenuation);
  return spriteText;
};

const useCreateSpriteText = (
  label: string,
  color: string,
  textHeight: number = 10,
  xOffset: number = -0.9,
  sizeAttenuation: boolean = false
) => {
  const size = useThree((three) => three.size);

  const myText = new SpriteText(label, textHeight);
  myText.fontFace = 'Helvetica, Arial';
  myText.fontWeight = sizeAttenuation ? '500' : '600';
  myText.fontSize = 60;
  myText.color = color;
  myText.strokeColor = '#222222';
  myText.strokeWidth = sizeAttenuation ? 1.25 : 1.5;
  myText.center.set(xOffset / label.length, -0.5);
  myText.scale.set(myText.scale.x / size.height, myText.scale.y / size.height, 0);
  myText.material.alphaTest = 0.12;
  myText.material.sizeAttenuation = sizeAttenuation;
  myText.material.color.set(0xbdbdbd);
  myText.material.opacity = sizeAttenuation ? 0.85 : 1;

  return myText;
};
