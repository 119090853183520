import { createContext, ReactNode, useContext, useMemo, useReducer } from 'react';

import {
  FORM_INITIAL,
  INITIAL_VALUES_AIR_CAVITY,
  INITIAL_VALUES_FULL_OCTAVE,
  INITIAL_VALUES_POROUS_ABSORBER,
  MaterialInputType,
} from '../constants';

import { AirCavityFormValues, ComplexData, FormValues, PorousAbsorberFormValues, SimpleData } from '../types';
import { FitMaterial, MaterialDto } from '@/types';

type ReflectogramResultsProviderProps = { children: ReactNode };

enum ActionType {
  RESET_FORM = 'RESET_FORM',
  EDIT_FORM = 'EDIT_FORM',
  VALID_FORM = 'VALID_FORM',
  SET_DISABLED_FORM = 'SET_DISABLED_FORM',
  SET_TARGET_COEFFICIENTS = 'SET_TARGET_COEFFICIENTS',
  SET_COMPLEX_DATA = 'SET_COMPLEX_DATA',
  SET_MATERIAL_FIT = 'SET_MATERIAL_FIT',
  SET_IMPEDANCE_FITTED_DATA = 'SET_IMPEDANCE_FITTED_DATA',
  SET_REFLECTION_FITTED_DATA = 'SET_REFLECTION_FITTED_DATA',
  SET_FORM_VALUES = 'SET_FORM_VALUES',
  SET_SHARE_MATERIAL = 'SET_SHARE_MATERIAL',
  SET_MATERIAL_INPUT_TYPE = 'SET_MATERIAL_INPUT_TYPE',
  SET_UPLOADED_FILE = 'SET_UPLOADED_FILE',
  SET_POROUS_ABSORBER_FORM_VALUES = 'SET_POROUS_ABSORBER_FORM_VALUES',
  SET_AIR_CAVITY_FORM_VALUES = 'SET_AIR_CAVITY_FORM_VALUES',
  SET_BUILDER_FITTED_DATA = 'SET_BUILDER_FITTED_DATA',
}

type CreateMaterialContextAction =
  | { type: ActionType.RESET_FORM }
  | { type: ActionType.EDIT_FORM }
  | { type: ActionType.VALID_FORM; isFormValid: boolean }
  | { type: ActionType.SET_DISABLED_FORM; formDisabled: boolean }
  | { type: ActionType.SET_TARGET_COEFFICIENTS; fullOctaveData: SimpleData }
  | { type: ActionType.SET_COMPLEX_DATA; complexData: ComplexData }
  | { type: ActionType.SET_MATERIAL_FIT; materialFit: FitMaterial | null }
  | { type: ActionType.SET_IMPEDANCE_FITTED_DATA; impedanceFittedData: MaterialDto | null }
  | { type: ActionType.SET_REFLECTION_FITTED_DATA; reflectionFittedData: MaterialDto | null }
  | { type: ActionType.SET_FORM_VALUES; formValues: FormValues }
  | { type: ActionType.SET_SHARE_MATERIAL; shouldShare: boolean }
  | { type: ActionType.SET_MATERIAL_INPUT_TYPE; inputType: MaterialInputType }
  | { type: ActionType.SET_UPLOADED_FILE; upload: { file: File; contents: string | ArrayBuffer | null | undefined } }
  | { type: ActionType.SET_POROUS_ABSORBER_FORM_VALUES; formValues: PorousAbsorberFormValues }
  | { type: ActionType.SET_AIR_CAVITY_FORM_VALUES; formValues: AirCavityFormValues }
  | { type: ActionType.SET_BUILDER_FITTED_DATA; builderFittedData: MaterialDto | null };

type State = {
  isFormValid: boolean;
  formDisabled: boolean;
  shouldShare: boolean;
  inputType: MaterialInputType | null;
  materialFit: FitMaterial | null;
  impedanceData: ComplexData;
  reflectionData: ComplexData;
  fullOctaveData: SimpleData;
  porousAbsorberFormValues: PorousAbsorberFormValues;
  airCavityFormValues: AirCavityFormValues;
  formValues: FormValues;
  impedanceFittedData: MaterialDto | null;
  reflectionFittedData: MaterialDto | null;
  builderFittedData: MaterialDto | null;
  dispatch: React.Dispatch<CreateMaterialContextAction>;
  upload: { file: File; contents: string | ArrayBuffer | null | undefined } | null;
};

const initialState: State = {
  isFormValid: false,
  formDisabled: false,
  shouldShare: false,
  inputType: MaterialInputType.FullOctaveAbsorption,
  materialFit: null,
  impedanceData: {},
  reflectionData: {},
  fullOctaveData: INITIAL_VALUES_FULL_OCTAVE,
  porousAbsorberFormValues: INITIAL_VALUES_POROUS_ABSORBER,
  airCavityFormValues: INITIAL_VALUES_AIR_CAVITY,
  formValues: FORM_INITIAL,
  impedanceFittedData: null,
  reflectionFittedData: null,
  builderFittedData: null,
  upload: null,
  dispatch: () => null,
};

const CreateMaterialContext = createContext<State | undefined>(undefined);

const createMaterialReducer = (state: State, action: CreateMaterialContextAction): State => {
  switch (action.type) {
    case ActionType.RESET_FORM: {
      return { ...initialState, inputType: state.inputType };
    }

    case ActionType.EDIT_FORM: {
      return {
        ...state,
        impedanceFittedData: null,
        reflectionFittedData: null,
        builderFittedData: null,
        materialFit: null,
      };
    }

    case ActionType.VALID_FORM: {
      return { ...state, isFormValid: action.isFormValid };
    }

    case ActionType.SET_DISABLED_FORM: {
      return { ...state, formDisabled: action.formDisabled };
    }

    case ActionType.SET_TARGET_COEFFICIENTS: {
      return { ...state, fullOctaveData: action.fullOctaveData };
    }

    case ActionType.SET_COMPLEX_DATA: {
      if (state.inputType === MaterialInputType.SurfaceImpedance) {
        return { ...state, impedanceData: action.complexData };
      } else {
        return { ...state, reflectionData: action.complexData };
      }
    }

    // Material fit for the FullOctaveAbsorption
    case ActionType.SET_MATERIAL_FIT: {
      return { ...state, materialFit: action.materialFit, formDisabled: false };
    }

    // Material fit for the SurfaceImpedance
    case ActionType.SET_IMPEDANCE_FITTED_DATA: {
      return { ...state, impedanceFittedData: action.impedanceFittedData, formDisabled: false };
    }

    // Material fit for the ReflectionCoefficient
    case ActionType.SET_REFLECTION_FITTED_DATA: {
      return { ...state, reflectionFittedData: action.reflectionFittedData, formDisabled: false };
    }

    // Material fit for the Material builder
    case ActionType.SET_BUILDER_FITTED_DATA: {
      return { ...state, builderFittedData: action.builderFittedData, formDisabled: false };
    }

    case ActionType.SET_SHARE_MATERIAL: {
      return { ...state, shouldShare: action.shouldShare };
    }

    case ActionType.SET_MATERIAL_INPUT_TYPE: {
      return { ...state, inputType: action.inputType, upload: null };
    }

    case ActionType.SET_FORM_VALUES: {
      return { ...state, formValues: action.formValues };
    }

    case ActionType.SET_UPLOADED_FILE: {
      return { ...state, upload: action.upload };
    }

    case ActionType.SET_AIR_CAVITY_FORM_VALUES: {
      return { ...state, airCavityFormValues: action.formValues };
    }

    case ActionType.SET_POROUS_ABSORBER_FORM_VALUES: {
      return { ...state, porousAbsorberFormValues: action.formValues };
    }

    default:
      return state;
  }
};

const CreateMaterialProvider = ({ children }: ReflectogramResultsProviderProps) => {
  const [state, dispatch] = useReducer(createMaterialReducer, initialState);

  const value = useMemo(() => {
    return { ...state, dispatch };
  }, [state]);

  return <CreateMaterialContext.Provider value={value}>{children}</CreateMaterialContext.Provider>;
};

const useCreateMaterialContext = () => {
  const context = useContext(CreateMaterialContext);
  if (!context) {
    throw new Error('useCreateMaterialContext must be used within a CreateMaterialProvider');
  }
  return context;
};

export { ActionType, CreateMaterialProvider, useCreateMaterialContext };
