import { SyntheticEvent, useState } from 'react';
import dayjs from 'dayjs';

import { Skeleton } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { HighlightedText } from '@/components/Shared/HighlightedText';
import defaultPlaceholderImageUrl from './images/default-placeholder.png';

import classes from './styles.module.scss';

interface GeometryInfoCardProps {
  thumbnailUrl: string;
  name: string;
  createdAt: string;
  tabIndex?: number;
  onClick?: () => void;
  selected?: boolean;
}

export const GeometryInfoCard = ({
  thumbnailUrl,
  name,
  createdAt,
  tabIndex = 0,
  onClick,
  selected,
}: GeometryInfoCardProps) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  const handleClickSpace = (e: SyntheticEvent) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <div
      className={`${classes.geometry_card} ${selected ? classes.selected : ''} ${imgLoaded ? classes.loaded : ''}`}
      onClick={handleClickSpace}
      tabIndex={tabIndex}>
      <div className={classes.geometry_card_item}>
        <div className={classes.geometry_thumbnail}>
          <div>
            <img
              src={thumbnailUrl || defaultPlaceholderImageUrl}
              onLoad={() => {
                setImgLoaded(true);
              }}
              alt="Geometry thumbnail"></img>
          </div>
        </div>
      </div>

      {!imgLoaded ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{
            width: '100%',
            height: '36px',
            opacity: 0.15,
          }}
        />
      ) : (
        <div className={classes.geometry_card_item}>
          <TrblTooltip title={name.length > 45 ? name : ''}>
            <div className={classes.geometry_name}>
              <HighlightedText text={name} highlight={''} highlightColor="#00f5ba" />
              <div className={classes.modified_tag}>{'Created ' + dayjs(createdAt).format('DD MMM YYYY')}</div>
            </div>
          </TrblTooltip>
        </div>
      )}
    </div>
  );
};
