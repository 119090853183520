import React from 'react';

import { PERFORMANCE_INFO_TEXT, PERFORMANCE_INFO_TEXT_2 } from '../../Editor/components/EditorContent/constants';
import { TrblInfoIcon } from '../../Icons';
import { TrblPopup, TrblPopupContent, TrblPopupTitle } from '../../Shared/Popup';

export const PerformanceDialog = ({
  setShowPopup,
}: {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <TrblPopup
      width="374px"
      top="-18vh"
      right="170px"
      hideBackdrop={true}
      aria-labelledby={'Auralizer performance mode'}
      open={true}>
      <TrblPopupTitle onClose={() => setShowPopup(false)} backgroundColor="rgba(38, 38, 38, 0.98)">
        {/* Empty span to get the x-button to the right */}
        <span></span>
      </TrblPopupTitle>
      <TrblPopupContent minimal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '20px',
            paddingTop: '0px',
          }}>
          <div style={{ textAlign: 'center', marginBottom: '12px' }}>
            <TrblInfoIcon width="20" height="20" />
          </div>
          <p>{PERFORMANCE_INFO_TEXT}</p>
          <p style={{ marginTop: '8px' }}>{PERFORMANCE_INFO_TEXT_2}</p>
        </div>
      </TrblPopupContent>
    </TrblPopup>
  );
};
