import { FC, useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';

import { Box } from '@mui/material';

import { SDKUsageGrid } from './SDKUsageGrid';

import { useGetTokenUsageReport } from './hooks/useGetTokenUsageReport';

type SDKUsageProps = {
  organizationId: string;
};

export const SDKUsage: FC<SDKUsageProps> = ({ organizationId }) => {
  const [fromDate, setFromDate] = useState<Dayjs>(dayjs().subtract(6, 'day').startOf('day'));
  const [toDate, setToDate] = useState<Dayjs>(dayjs().endOf('day'));

  const { mutate: fetchUsageReport, data: tokenUsage } = useGetTokenUsageReport();

  useEffect(() => {
    fetchUsageReport({
      organizationId,
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    });
  }, [organizationId, fromDate, toDate, fetchUsageReport]);

  return (
    <Box component="div" sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Box component={'div'} display="flex" alignItems={'center'} justifyContent={'space-between'}>
        <p style={{ fontWeight: '600' }}>SDK token usage</p>
        <Box component="div" display="flex" gap={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From date"
              value={fromDate}
              onChange={(newValue) => newValue && setFromDate(newValue)}
              format="DD/MM/YYYY"
              slotProps={{ textField: { size: 'small' } }}
            />
            <DatePicker
              label="To date"
              value={toDate}
              onChange={(newValue) => newValue && setToDate(newValue)}
              format="DD/MM/YYYY"
              slotProps={{ textField: { size: 'small' } }}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box component="div" flex="1 1 auto">
        <SDKUsageGrid tokenUsage={tokenUsage ?? []} />
      </Box>
    </Box>
  );
};
