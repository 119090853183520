import { SyntheticEvent, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { ClickAwayListener, Stack } from '@mui/material';

import { HighlightedText } from '@/components/Shared/HighlightedText';
import { NameMarkerRowNew } from '@/components/Shared/NameMarker';
import { TrblFolderIcon, TrblFolderSharedIcon } from '@/components/Icons';
import { ManageAccessPopup } from '@/components/ProjectsView/components/ManageAccessPopup';
import { ShareProjectButton } from '../ShareProjectButton/ShareProjectButton';
import { SpaceCard } from '../SpaceCard/SpaceCard';
import { TrblCustomSelect } from '../TrblCustomSelect';
import { ProjectActionsMenu } from './ProjectActionsMenu';

import { useGetSpacesDashboardByProjectId } from '@/hooks';

import { ProjectAndSpacesDto } from '@/types';

import classes from './styles.module.scss';

interface ProjectCardProps {
  project: ProjectAndSpacesDto;
  loggedInUserId: string;
  projectSearchString: string;
  onUpdate?: () => void;
}

export const ProjectCard = ({ project, loggedInUserId, projectSearchString, onUpdate }: ProjectCardProps) => {
  const location = useLocation();

  const queryClient = useQueryClient();
  const cardRef = useRef<HTMLAnchorElement>(null);
  const isMyProject = loggedInUserId === project.createdBy;

  const [isSelected, setIsSelected] = useState(false);
  const [projectClicked, setProjectClicked] = useState(false);

  const { data: spaces } = useGetSpacesDashboardByProjectId(projectClicked ? project.id : '');

  const [showMembersPopup, setShowMembersPopup] = useState(false);

  const openMembers = (e: SyntheticEvent) => {
    e.preventDefault();
    setShowMembersPopup(true);
    setIsSelected(true);
  };

  const closeManageAccessPopup = (projectUpdated?: boolean) => {
    setShowMembersPopup(false);
    setIsSelected(false);
    if (projectUpdated) {
      queryClient.invalidateQueries(['projects-and-spaces', isMyProject ? 'Owned' : 'Shared']);
      if (onUpdate) onUpdate();
    }
  };

  const handleClickProject = (e: SyntheticEvent) => {
    const target = e.target as Node;
    if (cardRef.current?.contains(target)) {
      return;
    }
    // prevent project link to open if action menu is clicked
    e.preventDefault();

    // hack to enable link in tooltip within a project card
    if (target.nodeName === 'A') {
      const link = target as HTMLAnchorElement;
      window.open(link.href, '_blank');
    }
  };

  const handleClickSpacesDropdown = () => {
    if (!projectClicked) {
      setProjectClicked(true);
    }
  };

  return (
    <>
      <Link
        ref={cardRef}
        key={project.id}
        className={`${classes.project_card} ${isSelected ? classes.selected : ''}`}
        to={'/project/' + project.id}
        onClick={handleClickProject}
        state={{ prevLocation: location.pathname }}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
          <span className={classes.project_name}>
            {isMyProject ? <TrblFolderIcon /> : <TrblFolderSharedIcon />}
            <HighlightedText text={project.name} highlight={projectSearchString} highlightColor="#00f5ba" />
          </span>

          <Stack flexDirection="row" alignItems="center" gap="6px" marginTop="-4px">
            <div className={classes.project_share_button}>
              <ShareProjectButton
                onClick={openMembers}
                tooltip={project.projectUsers.length > 1 ? 'Manage members' : 'Add members'}
              />
            </div>
            <ClickAwayListener disableReactTree onClickAway={() => setIsSelected(false)}>
              <div onMouseDown={() => setIsSelected(true)}>
                <ProjectActionsMenu
                  project={project}
                  setSelected={setIsSelected}
                  classNames={classes.project_menu}
                  onUpdate={onUpdate}
                  isMyProject={isMyProject}
                />
              </div>
            </ClickAwayListener>
          </Stack>
        </Stack>

        <Stack flexDirection="row" justifyContent="space-between" width="100%" height="20px">
          {project.spacesCount > 0 ? (
            <TrblCustomSelect
              onClick={handleClickSpacesDropdown}
              selectedItem={project.spacesCount + (project.spacesCount > 1 ? ' spaces' : ' space')}
              dropdownWidth="380px">
              {spaces
                ?.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))
                .map((space) => (
                  <SpaceCard key={space.id} space={space} small />
                ))}
            </TrblCustomSelect>
          ) : (
            <div className={classes.spaces_info}>0 spaces</div>
          )}

          <div className={classes.project_actions}>
            {project.projectUsers.length > 1 && (
              <NameMarkerRowNew
                projectUsers={project.projectUsers}
                loggedInUserId={isMyProject ? loggedInUserId : undefined}
                creatorId={project.createdBy}
                background="#272727"
                size="medium"
              />
            )}
          </div>
        </Stack>
      </Link>

      {showMembersPopup && <ManageAccessPopup onClose={closeManageAccessPopup} project={project} />}
    </>
  );
};
