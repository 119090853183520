/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';

import classes from './styles.module.scss';

// Add base type constraint for rows
interface BaseRow {
  id: string;
}

interface Column<T> {
  field: string;
  headerName: string;
  icon?: ReactNode;
  sortable?: boolean;
  width?: string;
  maxWidth?: string;
  displayOnHover?: boolean;
  renderCell?: (row: T) => React.ReactNode;
}

interface DataGridProps<T extends BaseRow> {
  rows: T[];
  columns: Column<T>[];
  loading?: boolean;
  rowHeight?: number;
  headerHeight?: number;
  selectedRow?: string;
  onRowDoubleClick?: (e: React.MouseEvent, row: T) => void;
  onRowClick?: (e: React.MouseEvent | React.KeyboardEvent, row: T) => void;
  initialState?: {
    sorting: {
      sortModel: { field: string; sort: 'asc' | 'desc' }[];
    };
  };
}

export const TrblDataGrid = <T extends BaseRow>({
  rows,
  columns,
  loading = false,
  rowHeight = 56,
  headerHeight = 42,
  selectedRow,
  onRowDoubleClick,
  onRowClick,
  initialState = { sorting: { sortModel: [] } },
}: DataGridProps<T>): React.ReactElement => {
  const [sortedRows, setSortedRows] = useState<T[]>([]);
  const [sortingByField, setSortingByField] = useState<string | null>(null);
  const [isAscending, setIsAscending] = useState<boolean | null>(null);
  const [showSortIconForField, setShowSortIconForField] = useState<string>('');

  useEffect(() => {
    if (initialState.sorting?.sortModel?.length) {
      const { field, sort } = initialState.sorting.sortModel[0];
      setSortingByField(field);
      setIsAscending(initialState.sorting?.sortModel?.[0]?.sort === 'asc');
      const sortedRows = sortRows(rows, field, sort);
      setSortedRows(sortedRows);
    }
  }, [rows]);

  const handleHeaderClick = (field: string) => {
    setSortingByField(field);
    setIsAscending(!isAscending);

    setSortedRows((prevRows) => {
      const isAscending = initialState.sorting?.sortModel?.[0]?.sort === 'asc';
      const newSort = isAscending ? 'desc' : 'asc';
      initialState.sorting.sortModel = [{ field, sort: newSort }];
      return sortRows(prevRows, field, newSort);
    });
  };

  const sortRows = (arrayToSort: T[], field: string, newSort: string) => {
    return [...arrayToSort].sort((a, b) => {
      const aValue = String(a[field as keyof T])
        .toLowerCase()
        .trim();
      const bValue = String(b[field as keyof T])
        .toLowerCase()
        .trim();

      if (aValue < bValue) return newSort === 'asc' ? -1 : 1;
      if (aValue > bValue) return newSort === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const showSortedArrow = (field: string, isSortable: boolean): string => {
    if (!isSortable) return '';
    if (sortingByField === field) {
      return ` ${isAscending ? '↓' : '↑'}`;
    }
    if (showSortIconForField === field) {
      return ' ↓';
    }
    return '';
  };

  return (
    <div className={classes['data-grid']}>
      {/* Header */}
      <div
        className={classes['data-grid-header']}
        style={{
          height: headerHeight,
        }}>
        {columns.map((col) => (
          <div
            key={col.field}
            className={`${classes['data-grid-column']} ${col.sortable ? classes['sortable'] : ''}`}
            role="button"
            tabIndex={0}
            style={{ maxWidth: col.maxWidth, minWidth: col.width }}
            onClick={() => handleHeaderClick(col.field)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleHeaderClick(col.field);
              }
            }}
            onMouseEnter={() => setShowSortIconForField(col.field)}
            onMouseLeave={() => setShowSortIconForField('')}>
            {col.headerName}
            {showSortedArrow(col.field, col.sortable ?? false)}
          </div>
        ))}
      </div>
      <div className={classes['data-grid-content']}>
        {/* Loading State */}
        {loading ? (
          <div className={classes['data-grid-loading']}>
            <CircularProgress />
          </div>
        ) : (
          // Rows
          <>
            {sortedRows.map((row) => (
              <div
                key={row.id}
                tabIndex={0}
                role="button"
                onClick={(e) => {
                  onRowClick?.(e, row);
                }}
                onDoubleClick={(e) => {
                  onRowDoubleClick?.(e, row);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onRowClick?.(e, row);
                  }
                }}
                className={`${classes['data-grid-row']} ${row.id === selectedRow ? classes['selected'] : ''}`}
                style={{
                  height: rowHeight,
                }}>
                {columns.map((col) => (
                  <div
                    key={col.field}
                    style={{ maxWidth: col.maxWidth, minWidth: col.width }}
                    className={`${classes['data-grid-cell']} ${col.displayOnHover ? classes['display-on-hover'] : ''}`}>
                    {col.icon && <span className={classes['icon']}> {col.icon}</span>}
                    {col.renderCell ? (
                      <span className={classes['text']}>{col.renderCell(row)}</span>
                    ) : (
                      <span className={classes['text']}>{String(row[col.field as keyof T])}</span>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
