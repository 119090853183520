import { ResultComparisonAction } from './actions';

import { ActionType } from '../constants';

import {
  getAvailableResultTypes,
  getAvailableSummedSources,
  getDefaultResultType,
  getSelectedSourcePointIdsBySelectedSourceObject,
  getSourcesAndGridReceivers,
  getSourcesAndReceivers,
} from '../utils';

import { ResultComparisonState } from '../../../types';
import { SolveTask } from '@/types';

export const ResultComparisonReducer = (
  state: ResultComparisonState,
  action: ResultComparisonAction
): ResultComparisonState => {
  switch (action.type) {
    case ActionType.SELECT_SIMULATION: {
      const {
        selectedSimulation,
        spaceName,
        modelName,
        title,
        resultType,
        sourcesSelected,
        receiversSelected,
        surfaceReceiversSelected,
        projectId,
        color,
      } = action.payload;
      return {
        ...state,
        modelName,
        spaceName,
        title,
        resultType: resultType ?? '',
        simulationId: selectedSimulation.id,
        sourcePointIds: sourcesSelected ?? [],
        receiverPointIds: receiversSelected?.length ? receiversSelected : undefined,
        gridReceiverPointIds: surfaceReceiversSelected,
        selectedSimulation: { ...selectedSimulation },
        projectId,
        color,
      };
    }

    case ActionType.UPDATE_SOLVE_RESULTS: {
      const lastSolveResults = action.payload;

      const availableResultTypes = getAvailableResultTypes(lastSolveResults.sourceResults);
      const defaultResultType = getDefaultResultType(availableResultTypes, state.resultType);
      const {
        selectedSourceObject,
        selectedSourceSummingObject,
        selectedReceiverObjects,
        availableSources,
        availableReceivers,
      } = getSourcesAndReceivers(
        lastSolveResults,
        state.lastSourceSummingResults,
        defaultResultType,
        state.selectedSimulation?.sourceParameters,
        state.sourcePointIds,
        state.receiverPointIds
      );

      return {
        ...state,
        lastSolveResults,
        availableResultTypes,
        resultType: defaultResultType,
        lastSelectedResultType: defaultResultType,
        availableSources,
        sourcePointIds: getSelectedSourcePointIdsBySelectedSourceObject(
          selectedSourceObject,
          selectedSourceSummingObject
        ),
        availableReceivers,
        receiverPointIds: state.receiverPointIds ?? selectedReceiverObjects?.map((x) => x.pointId),
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedSourceSummingObject,
          selectedReceiverObjects,
        },
      };
    }
    case ActionType.UPDATE_GRID_RECEIVERS_SOLVE_RESULTS: {
      const lastGridResults = action.payload;
      // For grid receivers we always default to Hybrid result (if available)
      const availableResultTypes = getAvailableResultTypes(lastGridResults);
      const defaultResultType = getDefaultResultType(availableResultTypes);

      const {
        selectedSourceObject,
        selectedSourceSummingObject,
        selectedGridReceiverObjects,
        availableSources,
        availableGridReceivers,
      } = getSourcesAndGridReceivers(
        lastGridResults,
        state.lastGridReceiverSourceSummingResults,
        defaultResultType,
        state.selectedSimulation?.sourceParameters,
        state.sourcePointIds,
        state.gridReceiverPointIds
      );

      return {
        ...state,
        lastGridResults,
        availableResultTypes,
        resultType: defaultResultType,
        availableSources,
        sourcePointIds: getSelectedSourcePointIdsBySelectedSourceObject(
          selectedSourceObject,
          selectedSourceSummingObject
        ),
        availableGridReceivers,
        gridReceiverPointIds: selectedGridReceiverObjects?.map((x) => x.pointId) || [],
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedSourceSummingObject,
          selectedGridReceiverObjects,
        },
      };
    }
    case ActionType.UPDATE_GRID_RECEIVER_RESULT_PARAMETERS: {
      return {
        ...state,
        simulationData: {
          ...state.simulationData,
          gridReceiverParameterValues: action.payload,
        },
      };
    }
    case ActionType.UPDATE_GRID_RECEIVER_RESULT_PARAMETERS_FOR_SUMMED_SOURCES: {
      return {
        ...state,
        simulationData: {
          ...state.simulationData,
          gridReceiverParameterValuesForSummedSources: action.payload,
        },
      };
    }
    case ActionType.UPDATE_LABEL: {
      return {
        ...state,
        title: action.payload,
      };
    }
    case ActionType.UPDATE_SOURCE_SUMMING_RESULTS: {
      const availableSummedSources = getAvailableSummedSources(action.payload);

      return {
        ...state,
        availableSummedSources,
        lastSourceSummingResults: action.payload,
      };
    }
    case ActionType.UPDATE_GRID_RECEIVER_SOURCE_SUMMING_RESULTS: {
      return {
        ...state,
        lastGridReceiverSourceSummingResults: action.payload,
      };
    }
    case ActionType.SELECT_SOURCE: {
      const newSourcePointIds = action.payload;
      const { availableReceivers, selectedSourceObject, selectedSourceSummingObject, selectedReceiverObjects } =
        getSourcesAndReceivers(
          state.lastSolveResults as SolveTask,
          state.lastSourceSummingResults,
          state.resultType,
          state.selectedSimulation?.sourceParameters,
          newSourcePointIds,
          state.receiverPointIds
        );

      return {
        ...state,
        sourcePointIds: newSourcePointIds,
        availableReceivers,
        receiverPointIds: selectedReceiverObjects?.map((x) => x.pointId) || [],
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedSourceSummingObject,
          selectedReceiverObjects,
        },
      };
    }
    case ActionType.SET_SELECTED_SOURCES_FOR_SUMMING: {
      return {
        ...state,
        selectedSourcesForSumming: action.payload,
      };
    }
    case ActionType.SET_SOURCE_SUMMING_SELECTION_ENABLED: {
      return {
        ...state,
        sourceSummingSelectionEnabled: action.payload,
        selectedSourcesForSumming: action.payload ? state.selectedSourcesForSumming : [],
      };
    }
    case ActionType.ADD_IN_PROGRESS_SOURCE_SUMMING: {
      const summedSourceId = action.payload.summedSourceId;
      const availableSummedSources = [...(state.availableSummedSources ?? [])];
      const summedSourceIndex = availableSummedSources.findIndex((x) => x.id === summedSourceId);
      if (summedSourceIndex !== -1) {
        availableSummedSources[summedSourceIndex].inProgressTasks.push({
          taskId: action.payload.taskId,
          resultType: action.payload.resultType,
        });
      } else {
        availableSummedSources.push({
          id: summedSourceId,
          name: action.payload.label,
          createdAt: new Date(),
          // TODO: Remove the sourceIds once sourceSummingWithDelay feature flag is removed
          sourceIds: action.payload.sourceIds,
          sources: action.payload.sourceIds.map((id) => ({
            id,
          })),
          completedTasks: [],
          inProgressTasks: [
            {
              taskId: action.payload.taskId,
              resultType: action.payload.resultType,
            },
          ],
        });
      }

      return {
        ...state,
        availableSummedSources,
      };
    }
    case ActionType.REMOVE_IN_PROGRESS_SOURCE_SUMMING: {
      const summedSourceId = action.payload.summedSourceId;
      const availableSummedSources = [...(state.availableSummedSources ?? [])];
      const summedSourceIndex = availableSummedSources.findIndex((x) => x.id === summedSourceId);

      if (summedSourceIndex !== -1) {
        const taskIndex = availableSummedSources[summedSourceIndex].inProgressTasks.findIndex(
          (x) => x.taskId === action.payload.taskId
        );
        if (taskIndex !== -1) {
          availableSummedSources[summedSourceIndex].inProgressTasks.splice(taskIndex, 1);
        }
      }

      return {
        ...state,
        availableSummedSources,
      };
    }
    case ActionType.UPDATE_SUMMED_SOURCE_LABEL: {
      const summedSourceId = action.payload.summedSourceId;
      const availableSummedSources = [...(state.availableSummedSources ?? [])];
      const summedSourceIndex = availableSummedSources.findIndex((x) => x.id === summedSourceId);

      if (summedSourceIndex !== -1) {
        availableSummedSources[summedSourceIndex].name = action.payload.label;
      }

      return {
        ...state,
        availableSummedSources,
      };
    }

    case ActionType.SELECT_RECEIVERS: {
      const receiverPointIds = action.payload;
      const { selectedSourceObject, selectedSourceSummingObject, selectedReceiverObjects } = getSourcesAndReceivers(
        state.lastSolveResults as SolveTask,
        state.lastSourceSummingResults,
        state.resultType,
        state.selectedSimulation?.sourceParameters,
        state.sourcePointIds,
        receiverPointIds
      );

      return {
        ...state,
        receiverPointIds,
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedSourceSummingObject,
          selectedReceiverObjects,
        },
      };
    }

    case ActionType.SELECT_GRID_SOURCE: {
      const newSourcePointIds = action.payload;

      if (!state.lastGridResults) {
        return state;
      }
      const { availableGridReceivers, selectedSourceObject, selectedSourceSummingObject, selectedGridReceiverObjects } =
        getSourcesAndGridReceivers(
          state.lastGridResults,
          state.lastGridReceiverSourceSummingResults,
          state.resultType,
          state.selectedSimulation?.sourceParameters,
          newSourcePointIds,
          state.gridReceiverPointIds
        );

      return {
        ...state,
        sourcePointIds: newSourcePointIds,
        availableGridReceivers,
        gridReceiverPointIds: selectedGridReceiverObjects?.map((x) => x.pointId),
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedSourceSummingObject,
          selectedGridReceiverObjects,
        },
      };
    }

    case ActionType.SELECT_GRID_RECEIVERS: {
      const gridReceiverPointIds = action.payload;
      const { selectedSourceObject, selectedSourceSummingObject, selectedGridReceiverObjects } =
        getSourcesAndGridReceivers(
          state.lastGridResults || [],
          state.lastGridReceiverSourceSummingResults,
          state.resultType,
          state.selectedSimulation?.sourceParameters,
          state.sourcePointIds,
          gridReceiverPointIds
        );

      return {
        ...state,
        gridReceiverPointIds,
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedSourceSummingObject,
          selectedGridReceiverObjects,
        },
      };
    }

    case ActionType.SELECT_RESULT_TYPE: {
      // when the result type changes we need to select new sources,
      // new receivers and new simulation data
      const resultType = action.resultType;
      const {
        selectedSourceObject,
        selectedSourceSummingObject,
        selectedReceiverObjects,
        availableSources,
        availableReceivers,
      } = getSourcesAndReceivers(
        state.lastSolveResults as SolveTask,
        state.lastSourceSummingResults,
        resultType,
        state.selectedSimulation?.sourceParameters,
        state.sourcePointIds,
        state.receiverPointIds
      );

      return {
        ...state,
        resultType,
        lastSelectedResultType: action.isUserSelected ? resultType : state.lastSelectedResultType,
        availableSources,
        sourcePointIds: getSelectedSourcePointIdsBySelectedSourceObject(
          selectedSourceObject,
          selectedSourceSummingObject
        ),
        availableReceivers,
        receiverPointIds: selectedReceiverObjects?.map((x) => x.pointId) || [],
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedSourceSummingObject,
          selectedReceiverObjects,
        },
      };
    }
  }
};
