import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // load on demand

import {
  TrblCancelledIcon,
  TrblCompletedIcon,
  TrblErrorIcon,
  TrblInprogressIcon,
  TrblWarningIcon,
} from '@/components/Icons';

import { getProgressMessages } from './utils';
import { getTimeStringByDuration } from '@/utils/trebleFunctions';

import { RunStatus, SimulationRunStatusDto, TaskStatus } from '@/types';

import classes from './styles.module.scss';

// Extend dayjs with duration plugin
dayjs.extend(duration);

interface RecentSimulationStatusDisplayProps {
  timeEstimate: number | null;
  queueTime: number | null;
  taskStatus?: TaskStatus | SimulationRunStatusDto | null;
  simulationStatus?: RunStatus;
  percentage?: number;
  parent?: string;
  startedAt?: string;
  completedAt?: string;
}

export const RecentSimulationStatusDisplay = ({
  taskStatus,
  simulationStatus,
  percentage,
  parent,
  timeEstimate,
  startedAt,
  completedAt,
  queueTime,
}: RecentSimulationStatusDisplayProps) => {
  const progressMessage = getProgressMessages(simulationStatus);

  const getTimeLeft = (percentage: number, timeEst: number | null) => {
    if (timeEst == null) return null;

    // we use the percentage to adjust remaining time, since timeEst can sometimes a bit wrong
    const difference = timeEst * (1 - percentage / 100) * 1000;
    const differenceDuration = dayjs.duration(difference);

    if (difference > 0 && simulationStatus !== RunStatus.ProcessingResults) {
      return getTimeStringByDuration(differenceDuration);
    } else {
      return '< 1 min';
    }
  };

  const getSimulationRuntime = (started: string, completed: string) => {
    const difference = dayjs(completed).diff(dayjs(started), 'ms');
    const differenceDuration = dayjs.duration(difference);

    return getTimeStringByDuration(differenceDuration);
  };

  return (
    <div className={classes.status}>
      {(!simulationStatus || simulationStatus === RunStatus.Created) && (
        <>
          {parent !== 'Nav' && simulationStatus && <TrblInprogressIcon />}
          <p className={classes.status_text}>
            {!simulationStatus ? 'Created ' : 'Started '}
            {dayjs(taskStatus?.createdAt).format('DD MMM, HH:mm')}
          </p>
        </>
      )}
      {simulationStatus === RunStatus.Completed && (
        <>
          <TrblCompletedIcon />
          <p className={classes.status_text}>
            Completed
            {taskStatus?.completedAt && (
              <span title="Completed at">{' · ' + dayjs(taskStatus.completedAt).format('DD MMM, HH:mm')}</span>
            )}
            {startedAt && completedAt && (
              <span title="Runtime">{' · ' + getSimulationRuntime(startedAt, completedAt)}</span>
            )}
          </p>
        </>
      )}
      {/* "Pending" status is not part of RunStatus, not sure why we are checking for it?  */}
      {simulationStatus && [RunStatus.InProgress, 'Pending'].includes(simulationStatus) && (
        <>
          {parent !== 'Nav' && <TrblInprogressIcon />}
          {parent === 'ModelDetails' ? (
            <span className={classes.status_text}>{progressMessage}</span>
          ) : (
            <>
              <span className={classes.status_percentage}>{`${percentage}% ${progressMessage}`}</span>
              <p className={classes.status_text} title="Time remaining">
                {timeEstimate && percentage ? (
                  <>{' · ' + getTimeLeft(percentage, timeEstimate)} remaining</>
                ) : (
                  <>{' · '}Initializing solver</>
                )}
              </p>
            </>
          )}
        </>
      )}
      {simulationStatus === RunStatus.Queued && (
        <>
          {parent !== 'Nav' && (
            <span className={classes.queued_icon_container}>
              <HourglassTopIcon fontSize="inherit" />
            </span>
          )}
          <span className={classes.status_percentage}>
            Queued
            {queueTime
              ? ` · Estimated queue time · ${getTimeStringByDuration(dayjs.duration(queueTime, 'seconds'))}`
              : ''}
          </span>
        </>
      )}
      {simulationStatus === RunStatus.ProcessingResults && (
        <>
          {parent !== 'Nav' && <TrblInprogressIcon />}
          {parent === 'ModelDetails' ? (
            <span className={classes.status_text}>{progressMessage}</span>
          ) : (
            <>
              <span className={classes.status_percentage}>{`${progressMessage}`}</span>
              <p className={classes.status_text} title="Time remaining">
                {timeEstimate && percentage && <>{' · ' + getTimeLeft(percentage, timeEstimate)} remaining</>}
              </p>
            </>
          )}
        </>
      )}
      {simulationStatus === RunStatus.Cancelled && (
        <>
          <TrblCancelledIcon />
          <p className={classes.status_text}>
            Cancelled
            <span title="Started at">{' · ' + dayjs(taskStatus?.createdAt).format('DD MMM, HH:mm')}</span>
          </p>
        </>
      )}
      {simulationStatus && [RunStatus.Error, RunStatus.TaskError].includes(simulationStatus) && (
        <>
          <TrblErrorIcon />
          <p className={classes.status_text}>
            Error
            <span title="Started at">{' · ' + dayjs(taskStatus?.createdAt).format('DD MMM, HH:mm')}</span>
          </p>
        </>
      )}
      {simulationStatus === RunStatus.InsufficientTokens && (
        <>
          <TrblWarningIcon />
          <p className={classes.status_text}>
            Insufficient tokens
            {' · ' + dayjs(taskStatus?.createdAt).format('DD MMM, HH:mm')}
          </p>
        </>
      )}
    </div>
  );
};
