import { useEffect, useState } from 'react';

import { MemberListItem } from './MemberListItem';

import { sortMembers } from '@/utils/trebleFunctions';

import { UserBasicDto } from '@/types';

export const ProjectMembers = ({
  projectMembers,
  creator,
  removeMember,
  newMemberId,
}: {
  projectMembers: UserBasicDto[];
  creator?: UserBasicDto;
  removeMember: (member: UserBasicDto) => void;
  newMemberId?: string | null;
}) => {
  const [sortedMembers, setSortedMembers] = useState<UserBasicDto[]>([]);

  useEffect(() => {
    if (projectMembers) {
      // All project members are sorted, the creator as well
      // We might want to consider to pin the creator at the top
      const newSortedMembers = sortMembers(projectMembers);
      setSortedMembers(newSortedMembers);
    }
  }, [projectMembers]);

  return (
    <>
      {creator && (
        <MemberListItem member={creator}>
          <p>Creator</p>
        </MemberListItem>
      )}
      {sortedMembers.map((member) => (
        <MemberListItem
          key={member.id}
          member={member}
          removeMember={removeMember}
          message={member.id == newMemberId ? 'Added' : ''}></MemberListItem>
      ))}
    </>
  );
};
