import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { TrblSimulationIcon } from '@/components/Icons';
import { QuickLinks } from './QuickLinks';
import { RecentSimulationStatusDisplay } from './RecentSimulationStatusDisplay';

import { RunStatus, SimulationRunStatusDto, SourceStatus, TaskStatus } from '@/types';

import classes from './styles.module.scss';

interface RecentSimulationStatusProps {
  simulationRunStatus: SimulationRunStatusDto;
  queueTime: number | null;
}
export const RecentSimulationStatus = ({ simulationRunStatus, queueTime }: RecentSimulationStatusProps) => {
  const [latestTaskRun, setLatestTaskRun] = useState<TaskStatus | null>(null);
  const [status, setStatus] = useState<RunStatus>(RunStatus.Completed);
  const [percentage, setPercentage] = useState<number>(0);

  const setStatusFunc = (sim: SimulationRunStatusDto) => {
    if (!sim.status) return;

    setStatus(sim.status);

    const isProgressStatus = [
      RunStatus.InProgress,
      RunStatus.Created,
      RunStatus.ProcessingResults,
      RunStatus.Queued,
    ].includes(sim.status);

    if (isProgressStatus && sim.progressPercentage) {
      const percentage =
        sim.status !== RunStatus.ProcessingResults && sim.progressPercentage === 100 ? 99 : sim.progressPercentage;
      setPercentage(percentage);
    }
  };

  const sortTaskStatusesByDate = (taskStatuses: TaskStatus[]) => {
    return taskStatuses.sort(
      (taskA: TaskStatus, taskB: TaskStatus) => dayjs(taskB.completedAt).unix() - dayjs(taskA.completedAt).unix()
    );
  };

  const getLatestTaskRun = (sources: SourceStatus[]) => {
    return sources.reduce<TaskStatus | null>((latest, source) => {
      if (!source.taskStatuses?.length) return latest;

      const sortedStatuses = sortTaskStatusesByDate(source.taskStatuses);
      const currentTaskStatus = sortedStatuses[0];

      if (!latest || dayjs(currentTaskStatus.completedAt).unix() > dayjs(latest.completedAt).unix()) {
        return currentTaskStatus;
      }

      return latest;
    }, null);
  };

  useEffect(() => {
    if (simulationRunStatus) {
      setStatusFunc(simulationRunStatus);
      const latestTaskRun = getLatestTaskRun([...(simulationRunStatus.sources || [])]);
      setLatestTaskRun(latestTaskRun);
    }
  }, [simulationRunStatus]);

  return (
    <div className={classes.recent_simulations_status}>
      <Link to={`/editor?mid=${simulationRunStatus.modelId}&sid=${simulationRunStatus.simulationId}`}>
        <p className={classes.simulation_name}>
          <TrblSimulationIcon width="14" height="14" />
          {simulationRunStatus.simulationName}
        </p>
      </Link>

      {(latestTaskRun || status === RunStatus.Created) && (
        <div className={classes.row}>
          <RecentSimulationStatusDisplay
            simulationStatus={status}
            taskStatus={latestTaskRun}
            percentage={percentage}
            timeEstimate={simulationRunStatus.timeEstimate}
            startedAt={simulationRunStatus.createdAt}
            completedAt={simulationRunStatus.completedAt}
            parent="Sidebar"
            queueTime={queueTime}
          />
          {status === RunStatus.Completed && (
            <QuickLinks simulationId={simulationRunStatus.simulationId} modelId={simulationRunStatus.modelId} />
          )}
        </div>
      )}
      {!latestTaskRun && status === RunStatus.InsufficientTokens && (
        <RecentSimulationStatusDisplay
          simulationStatus={status}
          taskStatus={simulationRunStatus}
          timeEstimate={null}
          parent="Sidebar"
          queueTime={queueTime}
        />
      )}
    </div>
  );
};
