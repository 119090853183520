import { ReactNode, SyntheticEvent, useRef, useState } from 'react';

import { ClickAwayListener, Popper, PopperPlacementType } from '@mui/material';

import { TrblChevronDownIcon } from '@/components/Icons';

import classes from './styles.module.scss';

interface TrblCustomSelectProps {
  children: ReactNode;
  selectedItem: ReactNode;
  placement?: PopperPlacementType;
  dropdownWidth?: string;
  onClick?: () => void;
}

export const TrblCustomSelect = ({
  children,
  selectedItem,
  placement = 'bottom-start',
  dropdownWidth,
  onClick,
}: TrblCustomSelectProps) => {
  const dropdownBtn = useRef<HTMLButtonElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleOpenDropdown = (e: SyntheticEvent) => {
    e.preventDefault();
    setShowDropdown(!showDropdown);

    if (onClick) onClick();
  };

  return (
    <>
      <button
        ref={dropdownBtn}
        className={`${classes.dropdown_btn} ${showDropdown ? classes.selected : ''} `}
        onClick={(e) => handleOpenDropdown(e)}>
        <div className={classes.selected_item}>{selectedItem}</div>
        <TrblChevronDownIcon width="8" height="6" fill="#999999" />
      </button>

      <Popper
        open={showDropdown}
        sx={{ zIndex: 1300 }}
        placement={placement}
        anchorEl={dropdownBtn.current}
        role={undefined}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}>
        <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
          <div className={classes.dropdown_content} style={{ width: dropdownWidth }}>
            {children}
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
