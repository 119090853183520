import { useEffect, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
// @ts-expect-error jsfive does not have a type definition file
import * as hdf5 from 'jsfive';

export const useModalReceiverData = (
  h5File: hdf5.File | null,
  gridReceiverIds: string[],
  gridIndex: number | undefined,
  gridReceiverId: number | undefined
) => {
  const [receiverData, setReceiverData] = useState<Record<string, Record<string, number[]>> | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (gridIndex) {
      const fetchData = async () => {
        setIsLoading(true);
        if (gridReceiverId === undefined) {
          setIsLoading(false);
          return;
        }

        if (h5File === null) {
          setIsLoading(false);
          return;
        }

        try {
          const initialReceiverData: Record<string, Record<string, number[]>> = {};

          const receiverDataForReceiver: Record<string, number[]> = {};

          const receiverKey = `Receiver_${gridIndex}`;
          const receiverPath = `GridReceivers/${gridReceiverId}/SPL/Receivers/${receiverKey}`;
          const receiverDataset = h5File.get(receiverPath);
          if (receiverDataset) {
            receiverDataForReceiver[receiverKey] = receiverDataset.value;
          } else {
            console.warn(`Receiver ${receiverKey} not found.`);
          }

          initialReceiverData[gridReceiverId] = receiverDataForReceiver;
          setReceiverData((prev) => ({ ...prev, [gridReceiverId]: receiverDataForReceiver }));
          setIsLoading(false);
        } catch (error) {
          datadogRum.addError(`Failed to fetch data for grid receiver: ${gridIndex}`);
          setReceiverData(null);
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [h5File, gridReceiverIds, gridIndex, gridReceiverId]);

  return { receiverData, isLoading };
};
