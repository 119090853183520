import dayjs from 'dayjs';

import { TrblTooltip } from '@/components/Shared';
import {
  TrblCancelledIcon,
  TrblCompletedIcon,
  TrblErrorIcon,
  TrblInprogressIcon,
  TrblWarningIcon,
} from '@/components/Icons';
import yellowCircleImageUrl from '@/components/SimulationSidePanel/images/yellowCircle.png';

import { RunStatus } from '@/types';

import classes from '../RecentActivity/styles.module.scss';

interface SimulationStatusDisplayProps {
  simulationStatus?: RunStatus;
  date: string;
  hasBeenEdited?: boolean;
}

export const SimulationStatusDisplay = ({ simulationStatus, date, hasBeenEdited }: SimulationStatusDisplayProps) => {
  let progressMessage = 'In progress';
  if (simulationStatus === RunStatus.ProcessingResults) progressMessage = 'Processing results';
  else if (simulationStatus === RunStatus.Queued) progressMessage = 'Queued';
  else if (simulationStatus === RunStatus.Created) progressMessage = 'Started';

  return (
    <div className={classes.status}>
      {!simulationStatus && <p className={classes.status_text}>{'Created ' + dayjs(date).format('DD MMM YYYY')}</p>}

      {simulationStatus === RunStatus.Completed && (
        <>
          <TrblCompletedIcon />

          {hasBeenEdited ? (
            <TrblTooltip title="Completed [editing]">
              <span>
                <img src={yellowCircleImageUrl} className={classes.changed_img} />
              </span>
            </TrblTooltip>
          ) : null}

          <p className={classes.status_text}>Completed{date && ' · ' + dayjs(date).format('DD MMM YYYY')}</p>
        </>
      )}

      {simulationStatus &&
        [RunStatus.Created, RunStatus.InProgress, RunStatus.Queued, RunStatus.ProcessingResults].includes(
          simulationStatus
        ) && (
          <>
            <TrblInprogressIcon />
            <p className={classes.status_text}>
              {progressMessage} · {dayjs(date).format('DD MMM YYYY')}
            </p>
          </>
        )}

      {simulationStatus === RunStatus.Cancelled && (
        <>
          <TrblCancelledIcon />
          <p className={classes.status_text}>Cancelled · {dayjs(date).format('DD MMM YYYY')}</p>
        </>
      )}

      {simulationStatus && [RunStatus.Error, RunStatus.TaskError].includes(simulationStatus) && (
        <>
          <TrblErrorIcon />
          <p className={classes.status_text}>Error · {dayjs(date).format('DD MMM YYYY')}</p>
        </>
      )}

      {simulationStatus === RunStatus.InsufficientTokens && (
        <>
          <TrblWarningIcon />
          <p className={classes.status_text}>Insufficient tokens · {dayjs(date).format('DD MMM YYYY')}</p>
        </>
      )}
    </div>
  );
};
