import { FC } from 'react';

import { TextField } from '@/components/Shared/TextField';
import { EditableTextField } from '@/components/Shared/TextField/EditableTextField';

interface SourceNameFieldProps {
  name: string;
  sourceBeingEdited: boolean;
  onNameChange: (name: string) => void;
  onNameSave: (name: string) => void;
}

export const SourceNameField: FC<SourceNameFieldProps> = ({ name, sourceBeingEdited, onNameChange, onNameSave }) => {
  if (sourceBeingEdited) {
    return (
      <EditableTextField
        label="Summed source name"
        value={name}
        onSave={onNameSave}
        editTitle="Edit summed source name"
      />
    );
  }

  return <TextField disabled={sourceBeingEdited} label="Summed source name" value={name} onChange={onNameChange} />;
};
