import { useMemo } from 'react';

import { SummedSourceOption } from '../../../types';

interface UseSourceValidationProps {
  name: string;
  selectedSourceIds: string[];
  sourceBeingEdited: SummedSourceOption | null;
  availableSummedSources: SummedSourceOption[];
}

export const useSourceValidation = ({
  name,
  selectedSourceIds,
  sourceBeingEdited,
  availableSummedSources,
}: UseSourceValidationProps) => {
  const validationError = useMemo(() => {
    if (
      sourceBeingEdited &&
      sourceBeingEdited.sources.length === selectedSourceIds.length &&
      sourceBeingEdited.sources.every((item) => selectedSourceIds.includes(item.id))
    ) {
      return 'You need to make changes to the selected sources to sum again.';
    }

    if (name.length === 0) {
      return 'Name is required';
    }

    if (selectedSourceIds.length < 2) {
      return 'At least two sources must be selected';
    }

    const existingSummedSource = availableSummedSources.find(
      (item) =>
        item.sources.length === selectedSourceIds.length &&
        item.sources.every((source) => selectedSourceIds.includes(source.id))
    );
    if (existingSummedSource) {
      return `This combination of sources has already been summed as "${existingSummedSource.name}"`;
    }

    return null;
  }, [name, selectedSourceIds, sourceBeingEdited, availableSummedSources]);

  return validationError;
};
