import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { useBaseContext } from '@/context/BaseContext';

import { Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { TrblFolderIcon, TrblFolderSharedIcon, TrblSpaceIcon } from '@/components/Icons';
import { TrblQuestionMarkIcon } from '@/components/Icons/TrblQuestionMarkIcon';
import { BreadcrumbTab } from '../BreadcrumbTab/BreadcrumbTab';
import { CardsHeader } from '../CardsHeader/CardsHeader';
import { PageHeader } from '../PageHeader/PageHeader';
import { PageLayout } from '../PageLayout';
import { SpaceCard } from '../SpaceCard/SpaceCard';
import { SpaceInfoSidepanel } from '../SpaceInfoSidepanel';
import { ProjectGeometryImport } from './ProjectGeometryImport';
import { ProjectInfoSidepanel } from './ProjectInfoSidepanel';

import { useGetProjectById, useGetSpacesDashboardByProjectId } from '@/hooks';

import { SpaceDashboardDto } from '@/types';

import classes from './styles.module.scss';

export const ProjectPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const {
    state: { userInfo, subscriptionInfo },
  } = useBaseContext();
  const params = useParams();
  const projectId = params.id as string;
  const spaceIdParam = searchParams.get('space');

  const { data: project, isLoading } = useGetProjectById(projectId || '');
  const { data: spaces } = useGetSpacesDashboardByProjectId(projectId || '');

  const [sortedSpaces, setSortedSpaces] = useState<SpaceDashboardDto[]>([]);
  const [sortedAsc, setSortedAsc] = useState(false);
  const [sortedType, setSortedType] = useState('modified');
  const [selectedSpace, setSelectedSpace] = useState<SpaceDashboardDto | null>(null);

  const [searchValue, setSearchValue] = useState('');

  const filterSpace = (name: string) => {
    // if no search string or there is a search string and it's included in the space name, then show the space
    if (searchValue == '' || name.toLowerCase().includes(searchValue.toLowerCase())) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // set sortedSpaces in correct order
    orderSpacesBy(sortedType, sortedAsc);

    if (spaces) {
      // update selected space if project or spaces have been edited/updates
      if (selectedSpace) {
        setSelectedSpace(spaces.find(({ id }) => id == selectedSpace.id) ?? selectedSpace);
      }
      if (spaceIdParam && !selectedSpace) {
        setSelectedSpace(spaces.find(({ id }) => id === spaceIdParam)!);
      }
    }
  }, [spaces]);

  const orderSpacesBy = (type: string, asc: boolean) => {
    if (spaces) {
      const spacesToSort = [...spaces];
      if (type !== sortedType) {
        setSortedType(type);
        asc = false;
      }
      if (type == 'modified') {
        if (asc) setSortedSpaces(spacesToSort.sort((a, b) => (a.updatedAt < b.updatedAt ? -1 : 1)));
        else setSortedSpaces(spacesToSort.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1)));
      }
      if (type == 'name') {
        if (asc) setSortedSpaces(spacesToSort.sort((a, b) => b.name.localeCompare(a.name)));
        else setSortedSpaces(spacesToSort.sort((a, b) => a.name.localeCompare(b.name)));
      }
      setSortedAsc(asc);
    }
  };

  const clickSpaceCard = (space: SpaceDashboardDto) => {
    // hack to make double click smoother
    setTimeout(() => {
      if (selectedSpace?.id !== space.id) {
        setSelectedSpace(space);
      }
    }, 100);

    setSearchParams({ space: space.id }, { replace: true });
    if (selectedSpace?.id == space.id) {
      setSelectedSpace(null);
      setSearchParams({}, { replace: true });
    }
  };

  const onOutsideClick = (e: Event) => {
    const target = e.target as HTMLElement;

    //don't de-select space if clicked target is Sidepanel, modelCard, MuiMenuItem, or MuiModal
    if (
      document.querySelector('.page-layout-sidepanel')?.contains(target) ||
      target.classList.contains('MuiMenuItem-root') ||
      document.querySelector('.MuiDialog-root')?.contains(target) ||
      document.querySelector('.menu-list')?.contains(target) ||
      document.querySelector('.MuiPopperUnstyled-root')?.contains(target) ||
      target.closest('a') ||
      target.closest('button')
    ) {
      return;
    }
    setSelectedSpace(null);
    setSearchParams({}, { replace: true });
  };

  useEffect(() => {
    if (selectedSpace) {
      document.addEventListener('mousedown', onOutsideClick);
    }
    return () => document.removeEventListener('mousedown', onOutsideClick);
  }, [selectedSpace]);

  const onSpaceUpdate = () => {
    queryClient.invalidateQueries(['projectById', projectId]);
    queryClient.invalidateQueries(['spaces-for-project', projectId]);
  };

  return (
    <PageLayout
      navpanel
      mainContentMinWidth="600px"
      extraHeader={
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <div style={{ display: 'flex' }}>
            <BreadcrumbTab
              items={[
                {
                  text: project?.name,
                  icon:
                    project?.createdBy !== userInfo?.id ? (
                      <TrblFolderSharedIcon fill="#c0c0c0" />
                    ) : (
                      <TrblFolderIcon fill="#c0c0c0" />
                    ),
                },
              ]}
            />
          </div>
        </div>
      }
      sidepanel={
        selectedSpace ? (
          <SpaceInfoSidepanel spaceDetails={selectedSpace} />
        ) : (
          project && (
            <ProjectInfoSidepanel
              project={project}
              loggedInUserId={userInfo.id}
              hasAccessToShare={subscriptionInfo.hasAccessToShare}
            />
          )
        )
      }>
      {!isLoading && !project && (
        <div className={`${classes.project_restricted_access_page}`}>
          <div className={classes.restricted_access_content}>
            <Stack gap="10px" alignItems="center" margin="36px 0 0">
              <TrblQuestionMarkIcon height="40" width="40" />
              <h3>You don't have access to this project</h3>
            </Stack>
          </div>
        </div>
      )}
      {project && (
        <div id={project.id} className={`${classes.project_page_content}`}>
          <PageHeader
            showViewToggle={false}
            showSearch={true}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            searchPlaceholder="Search for space"
          />
          {project.spaces.length > 0 && (
            <CardsHeader>
              <Stack width="calc(20vw + 90px)" marginLeft="20px">
                <TrblTooltip title="Sort by name" disableInteractive>
                  <button onClick={() => orderSpacesBy('name', !sortedAsc)} style={{ width: 'fit-content' }}>
                    Spaces {sortedType == 'name' ? (sortedAsc ? '↑' : '↓') : ''}
                  </button>
                </TrblTooltip>
              </Stack>

              <Stack
                width="100%"
                flexDirection="row"
                justifyContent="space-between"
                gap="8px"
                padding="0 132px 0 0"
                maxWidth="calc(30vw + 65px)">
                <span style={{ width: '110px' }}></span>
                <TrblTooltip title="Sort by modified" disableInteractive>
                  <button style={{ width: '112px' }} onClick={() => orderSpacesBy('modified', !sortedAsc)}>
                    Modified {sortedType == 'modified' ? (sortedAsc ? '↑' : '↓') : ''}
                  </button>
                </TrblTooltip>
              </Stack>
            </CardsHeader>
          )}
          {project.spaces.length > 0 ? (
            <div className={classes.project_list_container}>
              {sortedSpaces
                .filter((space) => filterSpace(space.name))
                .map((space) => (
                  <SpaceCard
                    key={space.id}
                    space={space}
                    searchValue={searchValue}
                    onClick={() => clickSpaceCard(space)}
                    selected={space.id == selectedSpace?.id}
                    onUpdate={onSpaceUpdate}
                  />
                ))}
            </div>
          ) : (
            <div className={classes.empty_project}>
              <Stack gap="10px" alignItems="center" margin="32px 0 0">
                <TrblSpaceIcon width="30px" height="30px" fill="#616161" />
                <h3>You have no spaces in this project</h3>
                <ProjectGeometryImport projectName={project.name} type="primary" label="Import space" />
              </Stack>
            </div>
          )}
        </div>
      )}
    </PageLayout>
  );
};
