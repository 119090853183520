import { useMemo } from 'react';

import { SavedAuralizerRowData } from '../types';

export const useFilterData = (data: SavedAuralizerRowData[], searchValue: string): SavedAuralizerRowData[] => {
  return useMemo(() => {
    if (!searchValue.trim()) return data;

    const searchTerm = searchValue.toLowerCase();
    return data.filter((row) => {
      return [row.name, row.allSimulations, row.projectName, row.spaceName, row.createdByUserEmail].some((field) =>
        field?.toLowerCase().includes(searchTerm)
      );
    });
  }, [data, searchValue]);
};
