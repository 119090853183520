import { toast } from 'react-toastify';
import { datadogRum } from '@datadog/browser-rum';

export const validateAndDownloadJson = async (url: string): Promise<object | null> => {
  try {
    const urlObj = new URL(url);
    const amzDateStr = urlObj.searchParams.get('X-Amz-Date');
    const expiresStr = urlObj.searchParams.get('X-Amz-Expires');

    if (amzDateStr && expiresStr) {
      // AWS X-Amz-Date format: YYYYMMDDTHHmmssZ (ISO 8601 UTC)
      const amzDate = new Date(
        `${amzDateStr.substring(0, 4)}-${amzDateStr.substring(4, 6)}-${amzDateStr.substring(
          6,
          8
        )}T${amzDateStr.substring(9, 11)}:${amzDateStr.substring(11, 13)}:${amzDateStr.substring(13, 15)}Z`
      );

      const expiresInSeconds = parseInt(expiresStr, 10);
      if (isNaN(expiresInSeconds)) {
        datadogRum.addError('Invalid expiration value.');
        return null;
      }

      // Calculate expiration time
      const expirationTime = amzDate.getTime() + expiresInSeconds * 1000;
      const currentTime = Date.now();

      if (currentTime > expirationTime) {
        toast.error('Unable to fetch data. Please refresh the page.');
        return null;
      }
    }

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      datadogRum.addError(`HTTP error! Status: ${response.status}`);
      return null;
    }

    return await response.json();
  } catch (error) {
    datadogRum.addError(error);
    return null;
  }
};
