import { FC, useEffect, useState } from 'react';

import { GridResultsHeader, ReflectogramResultsHeader } from '@/components/Results/components/SubHeaders';

import { ResultsView } from '@/context/EditorContext/types';

import styles from './styles.module.scss';

type ResultsSubHeaderProps = {
  resultsView: ResultsView;
};

export const ResultsSubHeader: FC<ResultsSubHeaderProps> = ({ resultsView }) => {
  const [active, setActive] = useState(resultsView === ResultsView.ResultsReflectogramView);

  useEffect(() => {
    if (!active) {
      setActive(true);
    }
  }, []);

  return (
    <div>
      {resultsView === ResultsView.ResultsGridReceiversView ? (
        <div className={`${styles['results-sub-header-container-v2']}  ${active ? styles['active'] : ''}`}>
          <div className={`${styles['results-sub-header-v2']}  ${active ? styles['active'] : ''}`}>
            <GridResultsHeader />
          </div>
        </div>
      ) : (
        <div className={`${styles['results-sub-header-container']}  ${active ? styles['active'] : ''}`}>
          <div className={`${styles['results-sub-header']}  ${active ? styles['active'] : ''}`}>
            <ReflectogramResultsHeader />
          </div>
        </div>
      )}
    </div>
  );
};
