import { TrblOpenLibraryIcon } from '@/components';

export const pricingUrl = 'https://www.treble.tech/pricing;';

export const nonWatertightMessage = 'This geometry is non watertight so it has limited simulation abilities';
export const meshTooBigForLowerLimitMessage = (
  <div>
    The computational resources needed to run the wave solver for this geometry exceed your subscription allowance.{' '}
    <a href={pricingUrl} target="_blank" className="flip-icon">
      See our premium subscriptions <TrblOpenLibraryIcon fill="#171717" />
    </a>
  </div>
);

export const meshTooBigForUpperLimitMessage = (
  <div>
    The computational resources needed to run the wave solver for this geometry at higher frequencies exceed your
    subscription allowance.{' '}
    <a href={pricingUrl} target="_blank" className="flip-icon">
      See our premium subscriptions <TrblOpenLibraryIcon fill="#171717" props />
    </a>
  </div>
);

export const MoreInformationLink = ({ href }: { href: string }) => (
  <a href={href} target="_blank" className="flip-icon" rel="noopener noreferrer">
    More information <TrblOpenLibraryIcon fill="#171717" props />
  </a>
);

export const standardTransitionFrequencyMessage = (
  <div>
    Simulation capabilities for the wave solver, such as the transition frequency, are limited for the standard
    subscription. <MoreInformationLink href={pricingUrl} />
  </div>
);

export const maxMeshElementsId = 'mesh-too-big';
export const maxMeshElements = 2500000;
export const maxMeshElementsMessage =
  'The size of the geometry exceeds our current cloud hardware capacity with the transition frequency used. Try lowering the transition frequency and try again.';

// if (elementMinLength * 3.76) is smaller than 0.02, that means that the smalles element in the mesh
// is smaller than 2cm, which is quite small and the user should be warned
export const smallMeshElementsId = 'mesh-small-element';
export const smallMeshElements = 0.02;
export const meshElementSizeFactor = 3.76;
export const smallMeshElementsMessage = (
  <p>
    Very small elements were detected in the prepared geometry. This can increase the duration of the simulation. <br />
    <br />
    See{' '}
    <a
      target="_blank"
      style={{ textDecoration: 'underline' }}
      href="https://docs.treble.tech/user-guide/importing-models/treble-in-sketchup/geometry_feedback#short-edges-se-and-short-gaps-sg">
      <b>documentation</b>
    </a>{' '}
    for more info.
  </p>
);
export const blockSmallMeshElements = 0.002;
export const blockSmallMeshElementsMessage = (
  <p>
    The wave solver cannot run due to elements that are too small in the prepared geometry.
    <br />
    <br /> See{' '}
    <a
      target="_blank"
      style={{ textDecoration: 'underline' }}
      href="https://docs.treble.tech/user-guide/importing-models/treble-in-sketchup/geometry_feedback#short-edges-se-and-short-gaps-sg">
      <b>documentation</b>
    </a>{' '}
    for likely causes.
  </p>
);

export const noMeshElementsMessage = 'This geometry appears to be invalid. Mesh has no elements.';

export const defaultEnergyDecayThreshold = 35;
