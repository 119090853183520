import { FC } from 'react';

import { Box } from '@mui/material';

type DividerProps = {
  marginOffset?: number;
  marginTopOffset?: number;
  marginBottomOffset?: number;
};

export const Divider: FC<DividerProps> = ({ marginOffset, marginTopOffset, marginBottomOffset }) => {
  return (
    <Box
      component="div"
      height="0.5px"
      display={'flex'}
      alignSelf={'stretch'}
      bgcolor="#171717"
      marginLeft={marginOffset ? `-${marginOffset}px !important` : undefined}
      marginRight={marginOffset ? `-${marginOffset}px !important` : undefined}
      marginTop={marginTopOffset ? `${marginTopOffset}px !important` : undefined}
      marginBottom={marginBottomOffset ? `${marginBottomOffset}px !important` : undefined}
      boxShadow={'0px 1px 0px rgb(96 96 96 / 22%)'}
      zIndex={2}
      flexShrink={0}
    />
  );
};
