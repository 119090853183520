import { Fragment, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { TrblChevronRight } from '@/components/Icons';

import styles from './styles.module.scss';

export type BreadcrumbTab = {
  text: string | ReactNode;
  to?: string;
  icon?: ReactElement;
  hidden?: boolean;
};

export const BreadcrumbTab = ({ items }: { items: BreadcrumbTab[] }) => {
  return (
    <div className={styles['breadcrumbs-div']}>
      <div className={styles['top-info-text']}>
        {items.map((item: BreadcrumbTab, index: number) => (
          <Fragment key={index}>
            {!item.hidden && (
              <>
                {item.icon && <div className={styles['breadcrumbs-icon']}>{item.icon}</div>}
                {item.to ? (
                  <Link to={item.to}>{item.text}</Link>
                ) : (
                  <span className={index == items.length - 1 ? styles['active'] : ''}>{item.text}</span>
                )}
                {index < items.length - 1 && !items[index + 1].hidden && (
                  <div className={styles['arrow']}>
                    <TrblChevronRight />
                  </div>
                )}
              </>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
