import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import { TrblAuralizeIcon } from '@/components/Icons';
import { PageLayout } from '@/components/New/PageLayout';
import { useGetAuralizationPresets } from '@/components/ResultPresets/hooks/useGetAuralizationPresets';
import { EmptyResourceContent } from '../EmptyResourceContent';
import { PageHeader } from '../PageHeader/PageHeader';
import { TrblDataGrid } from '../TrblDataGrid';

import { useColumns, useFilterData, useTransformData } from './hooks';

import { SavedAuralizerRowData } from './types';

import classes from '../SavedResultsPage/styles.module.scss';

export const SavedAuralizationsPage = () => {
  const { data: allAuralizationPresets = [], isFetching } = useGetAuralizationPresets();
  const [searchValue, setSearchValue] = useState('');

  const navigate = useNavigate();

  const columns = useColumns(searchValue);

  const transformedData = useTransformData(allAuralizationPresets, isFetching);
  const filteredData = useFilterData(transformedData, searchValue);

  const selectPreset = (row: SavedAuralizerRowData) => {
    navigate(`/auralizer?presetId=${row.id}&mid=${row.modelId}&sid=${row.simulationId}`);
  };

  return (
    <PageLayout navpanel={true}>
      <div className={classes.saved_content}>
        <PageHeader
          pageTitle="Saved auralizations"
          showViewToggle={false}
          showSearch={allAuralizationPresets.length !== 0}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchPlaceholder="Search auralizations"
        />
        {isFetching ? (
          <div className={classes.loading_container}>
            <CircularProgress />
          </div>
        ) : allAuralizationPresets.length === 0 ? (
          <EmptyResourceContent
            title="You have no saved auralizations yet"
            icon={<TrblAuralizeIcon fill="#DADADA" />}
          />
        ) : (
          <TrblDataGrid
            rows={filteredData}
            loading={isFetching}
            rowHeight={56}
            headerHeight={20}
            columns={columns}
            onRowDoubleClick={(_, row) => selectPreset(row)}
            onRowClick={(_, row) => selectPreset(row)}
            initialState={{
              sorting: {
                sortModel: [{ field: 'updatedAt', sort: 'desc' }],
              },
            }}
          />
        )}
      </div>
    </PageLayout>
  );
};
