import { TrblTooltip } from '@/components/Shared';
import { Text } from '@/components/Shared/Text';
import { getSPLValues } from '@/components/SourceDefinition/utils';
import { TableDivider } from './TableDivider';

import { aWeighting, FREQUENCY_BANDS, FREQUENCY_VALUES, INTERPOLATED_HOVER_TEXT } from './constants';

import { roundFloat } from '@/utils/trebleFunctions';

import { SourceDefinitionJson } from './types';

import styles from './styles.module.scss';

export const FrequenciesTable = ({
  sourceDefinitionJson,
  small = false,
}: {
  sourceDefinitionJson: SourceDefinitionJson;
  small?: boolean;
}) => {
  const splValues = getSPLValues(sourceDefinitionJson);
  const maxFrequency = 'clf_max_freq' in sourceDefinitionJson ? sourceDefinitionJson.clf_max_freq : null;
  const minFrequency = 'clf_min_freq' in sourceDefinitionJson ? sourceDefinitionJson.clf_min_freq : null;

  const fontType = small ? 'medium-10px' : 'regular-11px';
  const marginLeft = small ? 6 : 12;
  const textAlign = 'center';
  const width = small ? 28 : 40;
  const widthAll = 90;

  const calculateTotalSPL = (isAWeighting = false) => {
    let sum = 0;
    for (let i = 0; i < splValues.length; i++) {
      const val = Number(splValues[i]);

      if (!isNaN(val)) {
        if (!isAWeighting) sum += Math.pow(10, val / 10);
        else sum += Math.pow(10, (val + aWeighting[i]) / 10);
      }
    }
    sum = 10 * Math.log10(sum);

    return roundFloat(sum, 1);
  };

  const isWithinMinMaxFrequency = (index: number) => {
    // Check if this frequency is over or under the min and max frequencies in the Source definition object
    if (
      (minFrequency && minFrequency > FREQUENCY_VALUES[index]) ||
      (maxFrequency && maxFrequency < FREQUENCY_VALUES[index])
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className={styles['source-details-table']}>
      <div className={styles['row-container']}>
        <Text type={fontType} color="#999999" className={styles['label']}>
          Frequency (Hz)
        </Text>
        <div className={styles['values-container']}>
          {FREQUENCY_BANDS.map((frequency) => (
            <Text
              key={`freq_${frequency}`}
              color="#999999"
              type={fontType}
              numberFontStyleEnabled={true}
              style={{ width, marginLeft, textAlign }}>
              {frequency}
            </Text>
          ))}
          {!small && (
            <Text
              color="#999999"
              type={fontType}
              numberFontStyleEnabled={true}
              style={{ marginLeft, textAlign, width: widthAll }}>
              Total (dB)
            </Text>
          )}
        </div>
      </div>
      <TableDivider small={small}></TableDivider>
      <div className={styles['row-container']}>
        <Text type={fontType} className={styles['label']}>
          SPL on axis at 1m (dB)
        </Text>

        <div className={styles['values-container']}>
          {splValues.map((spl, i) =>
            isWithinMinMaxFrequency(i) ? (
              <Text
                key={`spl_${i}`}
                style={{ width, marginLeft, textAlign }}
                type={fontType}
                numberFontStyleEnabled={true}
                color="#dadada">
                {roundFloat(spl, 1) ?? '-'}
              </Text>
            ) : (
              // If not within min max frequencies, then display the value in an orange color and a tooltip
              <TrblTooltip title={INTERPOLATED_HOVER_TEXT} key={`spl_${i}`}>
                <span style={{ width, marginLeft, textAlign, display: 'inline-grid' }}>
                  <Text type={fontType} numberFontStyleEnabled={true} color="#ffbd59">
                    {roundFloat(spl, 1) ?? '-'}
                  </Text>
                </span>
              </TrblTooltip>
            )
          )}

          {!small && (
            <Text
              type={fontType}
              numberFontStyleEnabled={true}
              color="#dadada"
              style={{ marginLeft, textAlign, width: widthAll, lineHeight: 0.5 }}>
              {calculateTotalSPL()} / {calculateTotalSPL(true)} <sup>(A)</sup>
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};
