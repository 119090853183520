import { Dispatch, useEffect, useState } from 'react';

import { Autocomplete, createFilterOptions, MenuItem, TextField } from '@mui/material';

import { TrblChevronDownIcon, TrblSearchIcon } from '@/components/Icons';
import { TrblMemberListItem } from './TrblMemberListItem';

import { UserBasicDto } from '@/types';

import styles from '../styles.module.scss';

// Variable used to change the searchKey to clear/re-render components when member is selected
let searchKeyIncrement = 0;

type MenuOption = { label: string | null; name: string | null; email: string; id: string };

export const TrblMemberSearch = ({
  dropdownList,
  searchValue,
  setSearchValue,
  selectMember,
}: {
  dropdownList: UserBasicDto[];
  searchValue: string;
  setSearchValue: Dispatch<React.SetStateAction<string>>;
  selectMember: (member: UserBasicDto) => void;
}) => {
  const [key, setKey] = useState(searchKeyIncrement);
  const [options, setOptions] = useState<MenuOption[]>([]);

  useEffect(() => {
    if (dropdownList) {
      const optionList = dropdownList.map((item) => ({
        name: item?.firstName || item?.lastName ? `${item?.firstName ?? ''} ${item?.lastName ?? ''}` : item.email,
        email: item.email ?? '',
        // using the email as a label because it's the only thing that is uniq
        // the getOptionKey function from the API does not work for some reason
        label: item.email ?? '',
        id: item.id,
      }));
      setOptions(optionList);
    }
  }, [dropdownList]);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    stringify: (option: MenuOption) => `${option?.email} ${option?.name}`,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (_: any, value: MenuOption) => {
    const selectedMember = dropdownList.find((member) => member.id === value.id);
    if (selectedMember) {
      selectMember(selectedMember);
      setSearchValue('');
      setKey(++searchKeyIncrement);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputChange = (_: any, value: string) => {
    setSearchValue(value);
  };

  return (
    <div className={styles['input-div']}>
      <Autocomplete
        key={key}
        options={options}
        disableClearable
        selectOnFocus
        filterOptions={filterOptions}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        noOptionsText="No results"
        renderInput={(params) => (
          <>
            <span className={styles['search-icon']}>
              <TrblSearchIcon fill="#dadada" />
            </span>
            <TextField
              className={`${styles['text-field']} ${styles['with-start-icon']}`}
              placeholder="Add members"
              autoFocus={true}
              {...params}
            />
          </>
        )}
        renderOption={(props, option: MenuOption) => (
          <MenuItem {...props} className={`${styles['custom-select-item']} ${styles['extra-padding']}`}>
            <TrblMemberListItem
              member={dropdownList.find((member) => member.id === option.id)!}
              searchValue={searchValue}
            />
          </MenuItem>
        )}
        sx={{
          '.MuiAutocomplete-endAdornment': {
            top: ' calc(50% - 10px)',
            right: '12px !important',
          },
        }}
        className={`${styles['combobox']} ${styles['custom-select']}`}
        popupIcon={<TrblChevronDownIcon width="10" height="10" fill="#ADADAD" />}
        componentsProps={{
          paper: {
            sx: {
              position: 'relative',
              background: '#1f1f1f',
              marginTop: '2px',

              boxShadow:
                '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
              ul: {
                padding: 0,
                margin: 0,
                maxHeight: 'calc(50vh + 50px)',
              },
              li: {
                padding: '10px 12px !important',
                "&[aria-selected='true']": {
                  background: '#313131 !important',
                },
              },
              '.MuiAutocomplete-noOptions': {
                fontSize: 11,
                fontStyle: 'italic',
              },
            },
          },
        }}
      />
    </div>
  );
};
