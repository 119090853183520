import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { Box, Stack } from '@mui/material';

import { PrimaryButton, SecondaryButton } from '@/components/Shared/Buttons';
import { TrblPopupActions } from '@/components/Shared/Popup';
import { Text } from '@/components/Shared/Text';
import { useFeatureFlags } from '@/components/FeatureToggles';
import { TrblTooltip } from '../../Shared';
import { ConfirmNewRunPopup } from './ConfirmNewRunPopup';

import { useUpdateSimulationAndSettings } from '../../EditSimulation/hooks/useUpdatedSimulationAndSettings';
import { useCancelSimulationRun, useStartSolveTask } from '@/hooks';

import { mapSimulationRunToSimulationRunDetails } from '../utils';
import { getSimStatus } from '@/utils/getSimStatus';

import { SimulationRunDetails as SimulationRunDetailsType, SimulationStatusText } from '../types';
import { RunStatus, Simulation, TaskType } from '@/types';

type RunSimulationPopupActionsProps = {
  isTokenExceeded: boolean;
  selectedSimulation: Simulation;
  simulationRunDetails: SimulationRunDetailsType | null;
  isGAOnly: boolean;
  setSimulationRunDetails: React.Dispatch<React.SetStateAction<SimulationRunDetailsType | null>>;
  tooManyMeshElements: boolean;
  meshTaskId: string | null;
  totalEstimatedRuntimeAdjustedForConcurrency: number | null;
  onClosePopup: () => void;
  setWaitingToStart: Dispatch<SetStateAction<boolean>>;
};

export const RunSimulationPopupActions: FC<RunSimulationPopupActionsProps> = ({
  isTokenExceeded,
  selectedSimulation,
  simulationRunDetails,
  isGAOnly,
  setSimulationRunDetails,
  tooManyMeshElements,
  meshTaskId,
  totalEstimatedRuntimeAdjustedForConcurrency,
  onClosePopup,
  setWaitingToStart,
}) => {
  const queryClient = useQueryClient();

  const { dispatch } = useSimulationContext();
  const { mutate: updateSimulationAndSettings } = useUpdateSimulationAndSettings();
  const { mutate: startSolveTask } = useStartSolveTask();
  const { mutate: cancelSimulationRun } = useCancelSimulationRun();
  const { removeMaxElementBlock } = useFeatureFlags();

  const [needConfirm, setNeedConfirm] = useState(false);
  const [cancelInProgress, setCancelInProgress] = useState(false);
  const [showRunConfirmation, setShowRunConfirmation] = useState(false);

  useEffect(() => {
    if (selectedSimulation?.lastSimulationRun?.status === RunStatus.Completed) {
      setNeedConfirm(true);
    } else {
      setNeedConfirm(false);
    }
  }, [selectedSimulation]);

  const handleStartSimulation = () => {
    // Update hasBeenEdited flag so the user gets prompted again
    // if they want to edit their simulation or not
    updateSimulationAndSettings(
      { ...selectedSimulation, hasBeenEdited: false },
      {
        onSuccess: () => {
          dispatch({
            type: ActionType.UPDATE_SELECTED_SIMULATION,
            simulation: { ...selectedSimulation, hasBeenEdited: false },
          });
          setSimulationRunDetails({
            id: null,
            inProgress: false,
            progressBarColor: 'primary',
            statusText: SimulationStatusText.Starting,
            showEllipsis: true,
            percentage: null,
            createdAt: null,
            completedAt: null,
            timeRemainingText: null,
            showRemaining: false,
            sourceDetails: [],
          });
          getSimStatus(selectedSimulation, 1);
          setWaitingToStart(true);
          startSolveTask(
            {
              simulationId: selectedSimulation.id,
              taskType: selectedSimulation.taskType as TaskType,
              sources: selectedSimulation.sources,
              sourceParameters: selectedSimulation.sourceParameters,
              receivers: selectedSimulation.receivers,
              gridReceivers: selectedSimulation.gridReceivers,
              modelSettings: selectedSimulation.modelSettings!,
              solverSettings: selectedSimulation.solverSettings,
              settingsPreset: selectedSimulation.settingsPreset,
              meshTaskId: meshTaskId || undefined,
            },
            {
              onSuccess: (simulationRun) => {
                setWaitingToStart(false);
                if (simulationRun) {
                  if (simulationRun?.status !== RunStatus.InsufficientTokens) {
                    toast.info('Simulation run started', { className: 'editor-toast', autoClose: 3000 });
                  }

                  setSimulationRunDetails(mapSimulationRunToSimulationRunDetails(simulationRun));
                  dispatch({
                    type: ActionType.SET_LAST_SIMULATION_RUN,
                    simulationRun: simulationRun,
                  });

                  queryClient.invalidateQueries({ queryKey: ['simulation-run-statuses-by-user'] });
                } else {
                  toast.error('An error occurred while starting the simulation');
                  throw new Error('Error');
                }
              },
              onError: () => {
                updateSimulationAndSettings({ ...selectedSimulation, hasBeenEdited: true });
                dispatch({
                  type: ActionType.UPDATE_SELECTED_SIMULATION,
                  simulation: { ...selectedSimulation, hasBeenEdited: true },
                });
                setWaitingToStart(false);
                toast.error('An error occurred while starting the simulation');
              },
            }
          );
        },
      }
    );
  };

  const handleCancelSimulation = () => {
    if (simulationRunDetails?.id) {
      setCancelInProgress(true);
      cancelSimulationRun(simulationRunDetails.id);
    }
  };

  return (
    <>
      <TrblPopupActions>
        <Box component="div" display="flex" width="100%" justifyContent={'space-between'} alignItems={'center'}>
          {simulationRunDetails && (
            <Stack height="28px" justifyContent="center">
              <span>
                <Text type="bold-11px">Started: </Text>
                <Text type="regular-11px">
                  {simulationRunDetails?.createdAt
                    ? dayjs(simulationRunDetails?.createdAt).format('MMM DD, HH:mm')
                    : '--'}
                </Text>
              </span>
              <span style={{ display: simulationRunDetails?.completedAt ? 'inline-block' : 'none' }}>
                <Text type="bold-11px">Completed at: </Text>
                <Text type="regular-11px">
                  {simulationRunDetails?.completedAt &&
                  simulationRunDetails?.statusText === SimulationStatusText.Completed
                    ? dayjs(simulationRunDetails?.completedAt).format('MMM DD, HH:mm')
                    : '--'}
                </Text>
              </span>
            </Stack>
          )}
          {simulationRunDetails?.inProgress && (
            <TrblTooltip title="Cancel simulation run">
              <span>
                <SecondaryButton
                  disabled={cancelInProgress}
                  width={'fit-content'}
                  label="Cancel"
                  onClick={handleCancelSimulation}
                />
              </span>
            </TrblTooltip>
          )}

          {simulationRunDetails?.completedAt && !simulationRunDetails?.inProgress && (
            <PrimaryButton width={'fit-content'} label="Close" onClick={onClosePopup} />
          )}
        </Box>
        {!simulationRunDetails && (
          <Box component="div" display="flex" width="100%" justifyContent={'flex-end'} alignItems={'center'}>
            <PrimaryButton
              disabled={
                (!isGAOnly && !totalEstimatedRuntimeAdjustedForConcurrency) ||
                (tooManyMeshElements && !removeMaxElementBlock) ||
                isTokenExceeded
              }
              width={'fit-content'}
              label="Start"
              onClick={() => (needConfirm ? setShowRunConfirmation(true) : handleStartSimulation())}
            />
          </Box>
        )}
      </TrblPopupActions>
      <ConfirmNewRunPopup
        showRunConfirmation={showRunConfirmation}
        setShowRunConfirmation={setShowRunConfirmation}
        runSimulation={handleStartSimulation}
      />
    </>
  );
};
