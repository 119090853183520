import { UserBasicDto } from '@/types';

export const TREBLE_COLORS = [
  '#FF8A8A',
  '#00BFFF',
  '#39bd9d',
  '#ffbd59',
  '#d04aff',
  '#8E59FF',
  '#999999',
  '#00F5BA',
  '#45ADE5',
  '#FFBB6F',
  '#FD5B5B',
  '#F2D367',
];

export const getColorByIndex = (index: number) => {
  const colorIndex = index % TREBLE_COLORS.length;

  return TREBLE_COLORS[colorIndex];
};

export const getColorById = (id: string) => {
  const colorIndex = stringToNumberSum(id) % TREBLE_COLORS.length;

  return TREBLE_COLORS[colorIndex];
};

const stringToNumberSum = (str: string) => {
  // Sum string of numbers, letters and dashes to a uniq number
  const sum = str.split('').reduce((acc, numStr) => {
    return acc + numStr.charCodeAt(0);
  }, 0);
  return sum;
};

const getMemberFullName = (member: UserBasicDto) => {
  const name = member.firstName ? member.firstName + (member.lastName ? ' ' + member.lastName : '') : '';
  return name;
};

export const getMarkerTooltipForMember = (member: UserBasicDto, creatorId: string) => {
  let tooltip = getMemberFullName(member);

  if (!tooltip) {
    tooltip = member.email;
  }

  if (member.id === creatorId && tooltip) {
    tooltip += ' (Creator)';
  }

  return tooltip;
};

export const getMarkerContentForMember = (member: UserBasicDto) => {
  const name = member.firstName ? member.firstName + (member.lastName ? ' ' + member.lastName : '') : '';
  return name ? name.charAt(0).toUpperCase() : member.email ? member.email.charAt(0).toUpperCase() : '';
};

export const getRestOfMemberNames = (members: UserBasicDto[], creatorId: string) => {
  let restOfMembers = '';
  members.map((member, index) => {
    if (index >= 4) {
      restOfMembers += getMarkerTooltipForMember(member, creatorId) + '\n';
    }
  });
  return restOfMembers;
};
