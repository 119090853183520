import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

import { TrblTooltip } from '../TrblTooltip';
import { RadioButton } from './RadioButton';

export type RadioGroupOption = {
  text: string;
  value: string;
  disabled?: boolean;
  readonly?: boolean;
  tooltip?: string | ReactNode | '';
};

export type RadioButtonGroupProps = {
  options: Array<RadioGroupOption>;
  selectedValue: string | null;
  onChange: (selectedValue: string) => void;
  horizontal?: boolean;
  justifyContent?: string;
  noSelectedColor?: boolean;
};
export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  options,
  selectedValue,
  onChange,
  horizontal = false,
  justifyContent = 'space-between',
  noSelectedColor,
}) => {
  return (
    <Box
      component={'div'}
      display={'flex'}
      flexDirection={horizontal ? 'row' : 'column'}
      gap={'10px'}
      justifyContent={horizontal ? justifyContent : undefined}>
      {options.map((option) => {
        if (option.tooltip) {
          return (
            <TrblTooltip title={option.tooltip} key={option.text || option.value} arrow>
              <span style={{ display: 'flex' }}>
                <RadioButton
                  label={option.text}
                  selected={selectedValue === option.value}
                  onClick={() => onChange(option.value)}
                  disabled={option.disabled}
                  readonly={option.readonly}
                  noSelectedColor={noSelectedColor}
                />
              </span>
            </TrblTooltip>
          );
        } else {
          return (
            <RadioButton
              key={option.text || option.value}
              label={option.text}
              selected={selectedValue === option.value}
              onClick={() => onChange(option.value)}
              disabled={option.disabled}
              readonly={option.readonly}
              noSelectedColor={noSelectedColor}
            />
          );
        }
      })}
    </Box>
  );
};
