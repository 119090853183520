import { useEffect, useState } from 'react';

import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';

import { Tab, Tabs, useTheme } from '@mui/material';

import { LabelTag } from '@/components/Shared/Tags/LabelTag';
import { MaterialBuilder } from './MaterialBuilder';
import { MaterialInput } from './MaterialInput';

import { MaterialInputType, PLOT_HEIGHT, PLOT_HEIGHT_SMALL } from '../constants';

import styles from '../styles.module.scss';

enum TabValue {
  Manual = 0,
  Builder = 1,
}

export const CreateMaterialTabs = () => {
  const { formDisabled, dispatch } = useCreateMaterialContext();
  const [tabValue, setTabValue] = useState<TabValue>(TabValue.Manual);
  const theme = useTheme();
  const [plotHeight, setPlotHeight] = useState(PLOT_HEIGHT);

  useEffect(() => {
    if (window.innerHeight < theme.breakpoints.values.heightSmall) {
      setPlotHeight(PLOT_HEIGHT_SMALL);
    }
  }, [theme]);

  const handleChange = (_: React.SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue);
    if (newValue === TabValue.Builder) {
      dispatch({ type: ActionType.SET_MATERIAL_INPUT_TYPE, inputType: MaterialInputType.PorousAbsorberBuilder });
    } else {
      dispatch({ type: ActionType.SET_MATERIAL_INPUT_TYPE, inputType: MaterialInputType.FullOctaveAbsorption });
    }
  };

  function a11yProps(index: number) {
    return {
      id: `receivers-tab-${index}`,
      'aria-controls': `receivers-tabpanel-${index}`,
    };
  }

  return (
    <div style={{ marginBottom: '-16px' }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="Material tabs"
        className={styles.material_tabs}
        TabIndicatorProps={{
          sx: {
            width: '50% !important',
            left: tabValue === TabValue.Manual ? '0px !important' : '50% !important',
          },
        }}>
        <Tab
          className={styles.material_tab}
          label="Material import / input"
          {...a11yProps(TabValue.Manual)}
          disabled={formDisabled}
        />
        <Tab
          className={styles.material_tab}
          label="Porous material builder"
          {...a11yProps(TabValue.Builder)}
          disabled={formDisabled}
          icon={<LabelTag label="Beta" color="yellow" margin="0px 0px 0px 6px" position="absolute" />}
          iconPosition="end"
        />
      </Tabs>
      {tabValue === TabValue.Manual ? (
        <MaterialInput plotHeight={plotHeight} />
      ) : (
        <MaterialBuilder plotHeight={plotHeight} />
      )}
    </div>
  );
};
