import { useEffect, useRef, useState } from 'react';

import { CollapsibleInfo, TrblTooltip } from '@/components/Shared';
import { TextArea } from '@/components/Shared/TextArea';
import { useUpdateAuralizationPreset } from '@/components/AuralizerPresets/hooks';
import { EditControls } from '@/components/AuralizerPresets/SharePreset/EditControls';
import { TrblIcon } from '@/components/Icons';
import { ActionType as LibActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { ActionType, usePresetContext } from '../../PresetContext';

import '../../style.scss';

export const PresetDetails = () => {
  const presetDescriptionRef = useRef<HTMLParagraphElement>(null);
  const { selectedPreset, dispatch, availablePresets } = usePresetContext();
  const { dispatch: libDispatch } = useLibraryPanelContext();

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [presetDescription, setPresetDescription] = useState('');
  const [presetDescriptionHeight, setPresetDescriptionHeight] = useState(0);
  const { mutate: updateAuralizationPreset } = useUpdateAuralizationPreset();

  useEffect(() => {
    if (presetDescriptionRef?.current?.clientHeight) {
      setPresetDescriptionHeight(presetDescriptionRef.current.clientHeight);
    }
  }, [presetDescriptionRef?.current?.clientHeight]);

  useEffect(() => {
    if (selectedPreset) {
      setPresetDescription(selectedPreset?.description);
    }
  }, [selectedPreset]);

  const saveNewDescription = async () => {
    setIsInEditMode(!isInEditMode);
    if (selectedPreset) {
      updateAuralizationPreset(
        {
          ...selectedPreset,
          description: presetDescription,
        },
        {
          onSuccess: (data) => {
            dispatch({
              type: ActionType.SET_SELECTED_PRESET,
              preset: data,
            });
            libDispatch({
              type: LibActionType.SET_HIGHLIGHTED_ITEM,
              highlightedItemId: data.id,
            });
            const presetIndex = availablePresets.findIndex((preset) => preset.id === selectedPreset.id);
            const newAvailablePresets = [...availablePresets];
            newAvailablePresets[presetIndex] = data;
            dispatch({
              type: ActionType.SET_AVAILABLE_PRESETS,
              presets: newAvailablePresets,
            });
          },
        }
      );
    }
  };

  const dismissChanges = () => {
    setPresetDescription(selectedPreset?.description || '');
    setIsInEditMode(!isInEditMode);
  };

  return (
    <CollapsibleInfo small top isOpen={false}>
      <div className="preset-description-actions">
        <EditControls
          editTitle="Edit description"
          isInEditMode={isInEditMode}
          setIsInEditMode={() => setIsInEditMode(true)}
          disabled={selectedPreset?.description === presetDescription}
          onSave={saveNewDescription}
          onCancel={dismissChanges}
        />
      </div>

      {isInEditMode && (
        <div style={{ position: 'relative' }}>
          <TextArea
            autoFocus
            value={presetDescription}
            onChange={setPresetDescription}
            style={{
              width: '273px',
              maxHeight: 'calc(100vh - 500px)',
              minHeight: '100px',
              height: `${presetDescriptionHeight}px`,
            }}
          />
          <TrblTooltip
            title={
              <div style={{ whiteSpace: 'pre-line' }}>
                {`Please note that the preset description will be visible to external parties once the Auralization preset is shared.
                    \n While the description is optional, it is recommended to provide a short description of the simulation(s) and their setup.
                    `}
              </div>
            }>
            <span style={{ position: 'absolute', cursor: 'pointer', width: '11px', top: '3px', right: '6px' }}>
              <TrblIcon icon="info" width="18" height="19" />
            </span>
          </TrblTooltip>
        </div>
      )}
      {!isInEditMode && presetDescription && (
        <p ref={presetDescriptionRef} className="preset-description">
          {presetDescription}
        </p>
      )}
      {!isInEditMode && !presetDescription && <span className="preset-placeholder">No description</span>}
    </CollapsibleInfo>
  );
};
