import { useSimulationContext } from '../../../context/SimulationContext';

export const useUpdateObjScats = () => {
  const {
    simulationState: { surfaceLayers },
  } = useSimulationContext();

  const updateObjScats = (simObjScats: { [key: string]: number[] }, layerIds: string[], scat: number[] | null) => {
    if (scat === null) return simObjScats;
    const objScats = { ...simObjScats };

    layerIds.forEach((layerId) => {
      const currLayer = surfaceLayers.find((layer) => layerId === layer.id);
      const idsToUpdate = currLayer?.children.length
        ? currLayer.children.filter((child) => child.type === 'Mesh').map((child) => child.id)
        : [layerId];
      idsToUpdate.forEach((id) => {
        objScats[id] = scat;
      });
    });
    return objScats;
  };

  return updateObjScats;
};
