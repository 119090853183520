import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblQueueIcon: FC<IconProps> = ({ fill = '#F5F5F5' }) => (
  <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <g id="synchronize-arrow-clock">
        <path
          id="Shape"
          d="M8.24447 3C7.9223 3 7.66113 3.26117 7.66113 3.58333V6.79167C7.66113 7.11383 7.9223 7.375 8.24447 7.375H11.1611C11.4833 7.375 11.7445 7.11383 11.7445 6.79167C11.7445 6.4695 11.4833 6.20833 11.1611 6.20833H8.97363C8.89309 6.20833 8.8278 6.14304 8.8278 6.0625V3.58333C8.8278 3.26117 8.56663 3 8.24447 3Z"
          fill={fill}
        />
        <path
          id="Shape_2"
          d="M8.01253 0.233169C5.44582 0.33552 3.19707 1.98292 2.32561 4.39934C2.3022 4.46457 2.23612 4.50431 2.16753 4.49442L1.18228 4.34625C1.07225 4.33214 0.963422 4.37994 0.899362 4.4705C0.836448 4.56272 0.831692 4.68276 0.887112 4.77967L2.44986 7.52134C2.49486 7.60004 2.57389 7.65334 2.66372 7.66557C2.75356 7.6778 2.84396 7.64756 2.90836 7.58375L5.10753 5.39859C5.18607 5.32083 5.21391 5.20536 5.17945 5.10035C5.14498 4.99534 5.05412 4.91884 4.94478 4.90275L3.94436 4.75342C3.89907 4.74683 3.8595 4.71937 3.83749 4.67924C3.81548 4.63912 3.81358 4.59099 3.83236 4.54925C4.79417 2.37165 7.20225 1.22298 9.49988 1.84582C11.7975 2.46866 13.2958 4.67626 13.0261 7.04149C12.7565 9.40672 10.7997 11.2204 8.42086 11.3101C8.16036 11.3148 7.92214 11.4581 7.79595 11.686C7.66975 11.914 7.67476 12.1919 7.80907 12.4152C7.94339 12.6385 8.18661 12.7731 8.44711 12.7684H8.47453C10.7232 12.6994 12.7627 11.4312 13.8191 9.44486C14.8756 7.45856 14.7871 5.05855 13.5873 3.15541C12.3875 1.25227 10.2602 0.137608 8.01253 0.234336V0.233169Z"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);
