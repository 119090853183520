import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '@mui/material';

import styles from './styles.module.scss';

export const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className={styles['frontpage-container']}>
      <img src={'/frontpage.png'} alt="" className={styles['frontpage-img']}></img>

      <div className={styles['frontpage-panel']}>
        <h5>Treble Web Application</h5>
        <h1>Welcome to Treble!</h1>
        <h3>Please login to get things started</h3>

        <Button
          className={styles['login-btn-frontpage']}
          onClick={() =>
            loginWithRedirect({
              appState: {
                returnTo: `${window.location.href}`,
              },
            })
          }
          data-cy="logIn">
          Login
        </Button>
        <p className={styles['sign-up-text']}>
          Don't have an account? <a href="https://treble.tech/free-trial">Start your free trial now</a>
        </p>
      </div>
    </div>
  );
};
