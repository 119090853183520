import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { SyncProblemOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';

import { Box, InputAdornment, Stack, TextField } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { TertiaryButton } from '@/components/Shared/Buttons';
import { Text } from '@/components/Shared/Text';
import { TrblTimeComputerIcon } from '@/components/Icons';

import { BILLABLE_SUBSCRIPTION_TYPES } from './constants';

import { roundFloat } from '@/utils/trebleFunctions';

import { PendingSubscriptionInfo, ProductDto } from './types';

import styles from './styles.module.scss';

type SubscriptionDetailsSDKProps = {
  subscriptionDetails: ProductDto;
  pendingSubscriptionDetails?: PendingSubscriptionInfo;
  tokensLeft: number;
};

export const SubscriptionDetailsSDK: FC<SubscriptionDetailsSDKProps> = ({
  subscriptionDetails,
  pendingSubscriptionDetails,
  tokensLeft,
}) => {
  const navigate = useNavigate();

  const showPendingSeatsChange =
    pendingSubscriptionDetails &&
    subscriptionDetails &&
    pendingSubscriptionDetails.maxSeats !== subscriptionDetails.maxSeats;

  return (
    <Box component="div" display="flex" flexDirection="column" gap="6px" height="100%">
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
        <p style={{ fontWeight: '600' }}>SDK subscription</p>
        <TertiaryButton
          label="View token usage"
          icon={<TrblTimeComputerIcon fill="#00f5ba" />}
          onClick={() => navigate('usage')}
        />
      </Box>
      <Box component="div" display={'flex'} flex={'1 1 0'} bgcolor={'#272727'} borderRadius={'4px'}>
        <Stack spacing={1.8} padding="24px 20px 12px 10px" width={'100%'}>
          <Stack flexDirection="row" gap="2">
            <TextField
              className={styles['text-field-input']}
              InputProps={{
                disabled: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Subscription type"
              value={subscriptionDetails.billingType}
            />
          </Stack>
          {subscriptionDetails?.expiresAt && (
            <TextField
              className={styles['text-field-input']}
              InputProps={{
                disabled: true,
              }}
              label={
                BILLABLE_SUBSCRIPTION_TYPES.includes(subscriptionDetails.billingType) ? 'Renewal date' : 'Expires at'
              }
              value={subscriptionDetails.expiresAt ? dayjs(subscriptionDetails.expiresAt).format('DD/MM/YYYY') : ''}
            />
          )}
          <Stack flexDirection="row" justifyContent="space-between">
            <TextField
              className={styles['text-field-input']}
              InputProps={{
                disabled: true,
                endAdornment: showPendingSeatsChange && (
                  <InputAdornment position="end">
                    <TrblTooltip
                      title={
                        <Box component="div" display="flex" flexDirection={'column'} gap="8px">
                          <Text type="bold-12px">{`Scheduled changes (${dayjs(
                            pendingSubscriptionDetails.scheduledDate
                          ).format('DD/MM/YYYY')})`}</Text>
                          <Text type="medium-12px">{`${pendingSubscriptionDetails.maxSeats} total seats`}</Text>
                        </Box>
                      }>
                      <Box component={'span'} display="flex" alignItems="center">
                        <SyncProblemOutlined sx={{ width: 22 }} />
                      </Box>
                    </TrblTooltip>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Seats"
              value={`${subscriptionDetails?.activeSeats || 0} / ${subscriptionDetails?.maxSeats || 0}`}
            />

            <TextField
              className={styles['text-field-input']}
              InputProps={{
                disabled: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Tokens"
              value={roundFloat(tokensLeft, 2)}
            />
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between">
            <TextField
              className={styles['text-field-input']}
              InputProps={{
                disabled: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="GPUs per job"
              value={`${subscriptionDetails?.sdkMaxGpuCountPerTask}`}
            />
            <TextField
              className={styles['text-field-input']}
              InputProps={{
                disabled: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Concurency"
              value={subscriptionDetails?.sdkMaxGpuConcurrency}
            />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
