import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

import { Divider } from '@/components/Shared/Divider/Divider';
import { TrblPopupContent } from '@/components/Shared/Popup';
import { Text } from '@/components/Shared/Text';
import { TrblSimulationIcon } from '../../Icons';
import { SimulationDetailsContent } from './SimulationDetailsContent';
import { SimulationRunDetails } from './SimulationRunDetails';

import { SimulationRunDetails as SimulationRunDetailsType } from '../types';
import { Simulation } from '@/types';

type RunSimulationPopupContentProps = {
  selectedSimulation: Simulation;
  simulationRunDetails: SimulationRunDetailsType | null;
  isGAOnly: boolean;
  children: ReactNode;
};

export const RunSimulationPopupContent: FC<RunSimulationPopupContentProps> = ({
  selectedSimulation,
  simulationRunDetails,
  isGAOnly,
  children,
}) => {
  return (
    <TrblPopupContent>
      <Box component="div" mb="16px" display="flex" alignItems="center">
        <span style={{ marginRight: '8px', marginTop: '4px' }}>
          <TrblSimulationIcon />
        </span>
        <Text type="bold-12px">{selectedSimulation.name}</Text>
      </Box>

      {!simulationRunDetails ? (
        <>
          <Divider marginOffset={20} />
          <SimulationDetailsContent selectedSimulation={selectedSimulation} isGAOnly={isGAOnly} />
          {children}
        </>
      ) : (
        <SimulationRunDetails simulationRunDetails={simulationRunDetails} />
      )}
    </TrblPopupContent>
  );
};
