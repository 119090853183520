import { Material } from '@/types';

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const ZEROS_ONE_GUID = '00000000-0000-0000-0000-000000000001';

// EMPTY_GUID is used as the id for unassigned materials and the id for Omni sources
// for backwards compatibility, a Omni source can both be a EMPTY_GUID or ZEROS_ONE_GUID

export const EMPTY_MATERIAL: Material = {
  id: EMPTY_GUID,
  name: 'unassigned',
  userId: '',
  category: '',
  materialJson: '',
  isSharedWithOrganization: false,
  materialMetadataJson: '',
  absorptionCoefficients: [],
  defaultAbsorption: 0,
  defaultScattering: 0,
  description: '',
  userEmail: null,
  isUserCreated: false,
  isDeleted: false,
  isSpecific: null,
};

export const PROJECT_FILTER_OPTIONS = [
  { id: 'all', name: 'All' },
  { id: 'myProjects', name: 'My projects' },
  { id: 'sharedWithMe', name: 'Shared with me' },
];
