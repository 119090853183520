import { Link } from 'react-router-dom';

import { TrblIfcIcon } from '@/components/Icons';
import { SpaceExtractionTask } from '@/components/MultiSpaceImport/types';
import { RecentUploadStatusDisplay } from './RecentUploadStatusDisplay';

import classes from './styles.module.scss';

interface RecentUploadStatusProps {
  spaceExtractionTask: SpaceExtractionTask;
  onClick: (id: string, filename: string) => void;
}
export const RecentUploadStatus = ({ spaceExtractionTask, onClick }: RecentUploadStatusProps) => {
  return (
    <div key={spaceExtractionTask.id} className={classes.recent_simulations_status}>
      {spaceExtractionTask.task.status == 'Completed' && !spaceExtractionTask.hasImportedModel ? (
        <Link
          to=""
          className={classes.simulation_name}
          onClick={() => onClick(spaceExtractionTask.id, spaceExtractionTask.inputModelFilename)}>
          <TrblIfcIcon width="14" height="14" />
          {spaceExtractionTask.inputModelFilename}
        </Link>
      ) : (
        <p className={classes.simulation_name}>
          <TrblIfcIcon width="14" height="14" />
          {spaceExtractionTask.inputModelFilename}
        </p>
      )}

      <RecentUploadStatusDisplay
        uploadStatus={spaceExtractionTask.task.status}
        task={spaceExtractionTask.task}
        hasBeenImported={spaceExtractionTask.hasImportedModel}
        goNext={() => onClick(spaceExtractionTask.id, spaceExtractionTask.inputModelFilename)}
      />
    </div>
  );
};
