import { useEffect, useRef, useState } from 'react';

import { useAppContext } from '@/context/AppContext';
import { ActionType as EditorActionType, useEditorContext } from '@/context/EditorContext';

import { ClickAwayListener, IconButton } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { TrblCaretDownIcon, TrblCaretRightIcon, TrblIcon } from '@/components/Icons';
import { TrblActionsMenu } from '@/components/TrblActionsMenu';
import { useFeatureFlags } from '../FeatureToggles';
import { ActionType, useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';
import { TrblTooltip } from '../Shared';
import { SourceRow } from './SourceRow';
import { SourceSettingsPopup } from './SourceSettings';

import { useGetDisplaySources } from './hooks/useGetDisplaySources';
import { usePoints } from './hooks/usePoints';
import { useSources } from './hooks/useSources';

import { MAX_SOURCES, MAX_SOURCES_FOR_FEATURE_FLAG } from './constants';

import { Source } from '@/context/EditorContext/types';

import styles from './styles.module.scss';

export const Sources = ({
  sources,
  selectedSourceSettings,
  sourceIndex,
  isDisabled,
  readonly,
  setSelectedSourceId,
}: {
  sources: Source[];
  selectedSourceSettings: Source | undefined;
  sourceIndex: number;
  isDisabled: boolean;
  readonly: boolean;
  setSelectedSourceId: (id: string | undefined) => void;
}) => {
  const {
    selectedPointId,
    newPointId,
    handleSourceCoordineChange,
    handleSourceParamChange,
    handleSourceLabelChange,
    handleAddSource,
    handleRemoveSource,
    handleRemoveAllSources,
    handleLoadSourcesFromFile,
  } = useSources();

  const { handleSelectionChange } = usePoints();
  const { dispatch, selectedSource, isSourceDefinitionLibraryOpen } = useLibraryPanelContext();
  const { dispatch: editorDispatch, isPopupOpen, popupName, selected } = useEditorContext();
  const [isOpen, setIsOpen] = useState(true);

  const { allow500SourcesFeature } = useFeatureFlags();
  const maxSources = allow500SourcesFeature ? MAX_SOURCES_FOR_FEATURE_FLAG : MAX_SOURCES;

  const {
    appState: { filteredSourceDefinitions },
  } = useAppContext();

  const displaySources = useGetDisplaySources(sources, filteredSourceDefinitions);

  const fileUploadRef = useRef<HTMLInputElement>(null);

  const openSourceDefinitionLibrary = (clickedSourceId: string) => {
    if (!isDisabled) {
      let isOpen = !isSourceDefinitionLibraryOpen;

      if (selectedSource && selectedSource.id !== clickedSourceId) isOpen = true;

      dispatch({
        type: ActionType.SET_SOURCE_DEFINITION_PANEL_OPEN,
        isOpen: isOpen,
      });
    }
  };

  const handleOverallGainChange = (value: number) => {
    handleSourceParamChange(sourceIndex, 'overallGain', value);
  };

  const handleEQChange = (values: number[]) => {
    handleSourceParamChange(sourceIndex, 'eq', values);
  };

  const handleCorrectIrChange = (value: boolean) => {
    handleSourceParamChange(sourceIndex, 'correctIrByOnAxisSpl', value);
  };

  const handleClosePopup = () => {
    setSelectedSourceId(undefined);
    editorDispatch({
      type: EditorActionType.SET_IS_POPUP_OPEN,
      isOpen: false,
      popupName: '',
    });
  };

  const handleViewSourceSettings = (sourceId: string) => {
    editorDispatch({
      type: EditorActionType.SET_IS_POPUP_OPEN,
      isOpen: true,
      popupName: 'Source settings',
    });
    setSelectedSourceId(sourceId);
  };

  useEffect(() => {
    // check is isPopupOpen has been set as true and there is no selectedSourceSettings
    // then it means that Source settings popup should be opened, which is done by setSelectedSourceId
    if (isPopupOpen && !selectedSourceSettings && popupName === 'Source settings' && selected) {
      setSelectedSourceId(selected.id);
    }
  }, [isPopupOpen]);

  return (
    <>
      <div className={styles.sources_container}>
        <div className={styles.sources_container__header}>
          <span>
            <IconButton
              className={styles.sources__toggle_button}
              title={`Toggle ${isOpen ? 'close' : 'open'} sources`}
              aria-label={`Toggle ${isOpen ? 'close' : 'open'} sources`}
              onClick={() => setIsOpen((isOpen) => !isOpen)}>
              {isOpen ? <TrblCaretDownIcon fill="#999999" /> : <TrblCaretRightIcon fill="#999999" />}
            </IconButton>
            <Text type={'bold-11px'}>Sources</Text>
          </span>

          <div className={`${styles.add_sources} ${readonly ? 'hidden' : ''}`}>
            <TrblTooltip title="Add new source">
              <IconButton
                disabled={displaySources.length >= maxSources || isDisabled}
                onClick={handleAddSource}
                aria-label="Add new source">
                <TrblIcon
                  icon="add"
                  color={displaySources.length >= maxSources ? '#474747' : undefined}
                  hoverColor="#f5f5f5"
                />
              </IconButton>
            </TrblTooltip>
            <TrblActionsMenu
              disabled={isDisabled || readonly}
              id={'sources-action-menu'}
              actions={[
                {
                  key: 'load',
                  value: 'Load from file',
                  onClick: () => {
                    if (fileUploadRef.current) {
                      fileUploadRef.current.click();
                    }
                  },
                },
                { key: 'delete', value: 'Delete all', onClick: handleRemoveAllSources },
              ]}
            />

            <input
              id="upload-recievers-file"
              ref={fileUploadRef}
              type="file"
              style={{ display: 'none' }}
              accept=".txt, .csv"
              onChange={(e) => {
                if (e.target.files && fileUploadRef.current) {
                  const file = e.target.files[0];
                  handleLoadSourcesFromFile(file, maxSources);
                  fileUploadRef.current.value = '';
                }
              }}
            />
          </div>
        </div>
        {isOpen && (
          <>
            {displaySources.length > 0 ? (
              <div className={styles.sources_container__content}>
                {displaySources.map((source, index) => (
                  <ClickAwayListener
                    key={source.id}
                    mouseEvent={selectedPointId === source.id ? 'onClick' : false}
                    onClickAway={(e) => {
                      if (
                        !selectedSource &&
                        !selectedSourceSettings &&
                        !(e.target instanceof Element && e.target.tagName === 'CANVAS')
                      ) {
                        handleSelectionChange(undefined);
                      }
                    }}>
                    <SourceRow
                      source={source}
                      sourceDefinition={source.sourceDefinition}
                      index={index}
                      isSelected={selectedPointId === source.id}
                      isDisabled={isDisabled}
                      readonly={readonly}
                      isNewPoint={newPointId === source.id}
                      onSelect={() => handleSelectionChange(source.id, 'SourcePoint')}
                      onViewSourceSettings={() => handleViewSourceSettings(source.id)}
                      onOpenSourceDefinitionLibrary={() => openSourceDefinitionLibrary(source.id)}
                      onDelete={() => handleRemoveSource(source.id)}
                      onChangeAxis={(axis, value) =>
                        handleSourceCoordineChange(
                          index,
                          axis === 'x' ? value : source.x,
                          axis === 'y' ? value : source.y,
                          axis === 'z' ? value : source.z
                        )
                      }
                      onChangeParam={(param, value) => handleSourceParamChange(index, param, value)}
                      onChangeLabel={(value) => handleSourceLabelChange(index, value)}
                    />
                  </ClickAwayListener>
                ))}
              </div>
            ) : (
              <p className={styles.no_sources__message}> You have no sources</p>
            )}
          </>
        )}
      </div>
      {selectedSourceSettings && (
        <SourceSettingsPopup
          source={selectedSourceSettings}
          index={sourceIndex}
          sourceDefinitions={filteredSourceDefinitions}
          onOverallGainChange={handleOverallGainChange}
          onEQChange={handleEQChange}
          onCorrectIrChange={handleCorrectIrChange}
          onClose={handleClosePopup}
          readonly={readonly}
        />
      )}
    </>
  );
};
