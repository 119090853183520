/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { OrganizationFormState } from '../Organizations/OrganizationForm';
import { AddTokenPackageRequest, useAddTokenPackage } from './useAddTokenPackage';

import { config } from '@/__config__/config';

import { Organization } from './types';

import axios from '@/axios';
const { adminApiUrl } = config;

export type UpdateOrganizationRequest = {
  organizationFormState: OrganizationFormState;
};

type UpdateOrganizationResponnse = Organization;

const updateOrganization = async (
  request: UpdateOrganizationRequest,
  addTokenPackage: UseMutateFunction<void, any, AddTokenPackageRequest, unknown>
) => {
  const { organizationFormState } = request;
  const { data: organization } = await axios.put<UpdateOrganizationResponnse>(`/api/Organization/Update`, {
    id: organizationFormState.id,
    name: organizationFormState.name,
    description: organizationFormState.description,
    chargebeeCustomerId: organizationFormState.chargebeeCustomerId,
  });

  // existing products can be updated and new ones added
  const existingProducts = organizationFormState.products.filter((p) => !!p.id);
  const newProducts = organizationFormState.products.filter((p) => !p.id);

  for (const product of existingProducts) {
    await axios.post(
      `/api/Subscription/UpdateProduct`,
      {
        id: product.id,
        productKey: product.productKey,
        billingType: product.billingType,
        concurrency: product.concurrency,
        maxSeats: product.maxSeats,
        sdkMaxCpuConcurrency: product?.sdkMaxCpuConcurrency,
        sdkMaxGpuConcurrency: product?.sdkMaxGpuConcurrency,
        sdkMaxGpuCountPerTask: product?.sdkMaxGpuCountPerTask,
        expiresAt: product.expiresAt != null ? dayjs(product.expiresAt).utc().format() : null,
        chargebeeSubscriptionId: product?.chargebeeSubscriptionId,
      },
      { baseURL: adminApiUrl }
    );
  }

  for (const product of newProducts) {
    await axios.post(
      `/api/Subscription/CreateProductForExistingSubscription?organizationId=${organization.id}`,
      {
        chargebeeSubscriptionId: product?.chargebeeSubscriptionId,
        productKey: product.productKey,
        billingType: product.billingType,
        concurrency: product.concurrency,
        maxSeats: product.maxSeats,
        sdkMaxCpuConcurrency: product?.sdkMaxCpuConcurrency,
        sdkMaxGpuConcurrency: product?.sdkMaxGpuConcurrency,
        sdkMaxGpuCountPerTask: product?.sdkMaxGpuCountPerTask,
        expiresAt: product.expiresAt != null ? dayjs(product.expiresAt).utc().format() : null,
      },
      {
        baseURL: adminApiUrl,
      }
    );
  }

  if (organizationFormState.tokens) {
    addTokenPackage({
      organizationId: organization.id,
      tokens: organizationFormState.tokens,
      packageType: organizationFormState.packageType,
    });
  }

  return organization.id;
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();
  const { mutate: addTokenPackage } = useAddTokenPackage();

  return useMutation(async (data: UpdateOrganizationRequest) => await updateOrganization(data, addTokenPackage), {
    onSuccess: (organizationId) => {
      queryClient.invalidateQueries(['organization', organizationId]);
      queryClient.invalidateQueries(['organizations']);
      queryClient.invalidateQueries(['subscription', organizationId]);
      queryClient.invalidateQueries(['token-status', organizationId]);
    },
    onError: () => toast.error('An error occurred while updating the organization!'),
  });
};
