import { useEffect, useState } from 'react';

import { useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { TrblTooltip } from '@/components/Shared';
import { useCreateSimulationCopy } from '@/components/EditSimulation/hooks/useCreateSimulationCopy';
import { useDuplicateSimulation } from '@/components/EditSimulation/hooks/useDuplicateSimulation';
import {
  TrblDeleteIcon,
  TrblDuplicateIcon,
  TrblEditIcon,
  TrblInfoIcon,
  TrblRevertSimIcon,
  TrblSimulationIcon,
} from '../Icons';
import { TrblIconButton } from '../Shared/Buttons';
import { TrblActionsMenu } from '../TrblActionsMenu';
import { DeleteSimulationPopup } from './DeleteSimulationPopup';
import { EditorPicker } from './EditorPicker';
import { EditSimAttrPopup } from './EditSimAttrPopup';
import { InfoSimulationPopup } from './InfoSimulationPopup';
import { RevertSimulationPopup } from './RevertSimulationPopup';
import { RunSimulationBadge } from './RunSimulationBadge';
import { SimulationDropdown } from './SimulationDropdown';

import { IActions, Info, SimulationRunDto } from '@/types';

import './styles.scss';

export const SidePanelTopRow = ({ isNewEditor }: { isNewEditor?: boolean }) => {
  const [simActions, setSimActions] = useState<IActions[]>([]);

  const [isTestSimulation, setIsTestSimulation] = useState(false);
  const {
    simulationState: { selectedSimulation, availableSimulations, userTouched },
  } = useSimulationContext();

  const { isAuralizerOpen, isInResultsMode } = useEditorContext();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showRevertConfirmation, setShowRevertConfirmation] = useState(false);

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [selectedSimulationInfo, setSelectedSimulationInfo] = useState<Info>({} as Info);
  const [selectedSimulationRun, setSelectedSimulationRun] = useState<SimulationRunDto | null>(null);

  const [simStatus, setSimStatus] = useState<number | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const duplicateSimulation = useDuplicateSimulation();
  const createSimulationCopy = useCreateSimulationCopy();

  useEffect(() => {
    if (selectedSimulation && selectedSimulation?.id === '5318008') {
      setIsTestSimulation(true);
    }
    setIsDisabled(false);
    const editSimulationActions: IActions[] = [
      {
        value: 'Duplicate',
        key: 'duplicate',
        icon: <TrblDuplicateIcon />,
        onClick: () => {
          makeDuplicateSimulation();
        },
      },
      {
        value: 'Edit',
        key: 'change-simulation',
        icon: <TrblEditIcon />,
        disabled: selectedSimulation?.extra.status === 1,
        onClick: () => {
          setShowEditPopup(true);
        },
      },
      {
        value: 'Details',
        key: 'details-simulation',
        icon: <TrblInfoIcon />,
        onClick: () => {
          if (selectedSimulation) {
            setSelectedSimulationInfo({
              id: selectedSimulation.id,
              name: selectedSimulation.name,
              description: selectedSimulation.description,
              createdAt: selectedSimulation.createdAt,
              createdBy: selectedSimulation.createdByUserEmail,
            });
            setSelectedSimulationRun(selectedSimulation.lastSimulationRun || null);
          }
          setShowInfoPopup(true);
        },
      },
      {
        value: 'Delete',
        key: 'delete-simulations',
        icon: <TrblDeleteIcon />,
        disabled: selectedSimulation?.extra.status === 1,
        onClick: () => {
          setShowDeleteConfirmation(true);
        },
      },
    ];
    setSimActions(editSimulationActions);
  }, [selectedSimulation]);

  useEffect(() => {
    setSimStatus(selectedSimulation?.extra.status);
  }, [selectedSimulation?.extra.status]);

  const makeDuplicateSimulation = async () => {
    setIsDisabled(true);
    const duplicate = await duplicateSimulation(selectedSimulation);
    if (duplicate && availableSimulations) {
      const newName = `${duplicate.name} - Copy`;

      // create a new simulation that is a duplicate of the current simulation
      await createSimulationCopy(duplicate, duplicate.modelId, newName, duplicate.description, availableSimulations);
    }
  };

  return (
    <div className={`row-sim-selector ${isDisabled ? 'disabled' : ''}`}>
      <div className="left-content">
        {!isTestSimulation && !isInResultsMode ? (
          <>
            {isNewEditor ? (
              <EditorPicker />
            ) : (
              <>
                <TrblSimulationIcon width="14" height="14" fill="#dadada" />
                <SimulationDropdown simulations={availableSimulations || []} defaultSimulation={selectedSimulation} />
              </>
            )}

            <RunSimulationBadge status={selectedSimulation?.extra.status ?? 0} />
          </>
        ) : null}
      </div>
      {!isInResultsMode &&
        selectedSimulation &&
        (!isTestSimulation && !isAuralizerOpen && availableSimulations && availableSimulations.length > 0 ? (
          <div className="right-content">
            {(simStatus === 2 || simStatus === 5) && userTouched && (
              <TrblTooltip title="Revert simulation to last run">
                <span>
                  <TrblIconButton
                    className="revert-btn"
                    onClick={() => {
                      setShowRevertConfirmation(true);
                    }}
                    label="Revert simulation to last run"
                    icon={<TrblRevertSimIcon />}></TrblIconButton>
                </span>
              </TrblTooltip>
            )}
            <TrblActionsMenu
              classNames={`edit-btn ${isAuralizerOpen ? 'hidden' : ''}`}
              actions={simActions}
              id={selectedSimulation?.id || ''}
            />
          </div>
        ) : null)}
      <DeleteSimulationPopup
        showDeleteConfirmation={showDeleteConfirmation}
        setShowDeleteConfirmation={setShowDeleteConfirmation}
        setIsDisabled={setIsDisabled}
      />
      <RevertSimulationPopup
        showRevertConfirmation={showRevertConfirmation}
        setShowRevertConfirmation={setShowRevertConfirmation}
      />
      {showEditPopup && <EditSimAttrPopup showPopup={showEditPopup} setShowPopup={setShowEditPopup} />}
      {showInfoPopup && (
        <InfoSimulationPopup
          info={selectedSimulationInfo}
          simulationRun={selectedSimulationRun}
          onClose={() => setShowInfoPopup(false)}
        />
      )}
    </div>
  );
};
