import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SimulationsInSpace } from '@/context/AppContext';
import { useModelContext } from '@/context/ModelContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { Box } from '@mui/material';

import { SecondaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { PopupContent } from './PopupContent';

import { useGetModelBasesBySpaceId } from './hooks';
import { useCreateNewSimulation } from './hooks/useCreateNewSimulation';
import {
  useGetModelInformation,
  useGetSimulationsBySpaceId,
  useGetThumbnailsForModelBases,
  useLoadAndExtractFileFromUrl,
  useSubmitEvent,
} from '@/hooks';

import { NewSimulationTypes } from './constants';

import { createSelectMenu } from './utils';

import { ModelSimulationsDto } from '@/types';

const POPUP_TITLE = 'Create new simulation';

export const CreateNewSimulationPopup = ({ setShowPopup }: { setShowPopup: (show: boolean) => void }) => {
  const {
    simulationState: { availableSimulations },
    dispatch,
  } = useSimulationContext();

  const [isFormValid, setIsFormValid] = useState(false);
  const [, setSearchParams] = useSearchParams();

  // Get the information from context about the model we currently have selected (which will be selected by default)
  const { modelInformation: editorModelInformation, modelVolume: editorModelVolume, rhino3dmFiles } = useModelContext();

  // Fetch all available models for the space we are in (could be refactored so that we don't need the model to determine the space)
  const { data: modelBases } = useGetModelBasesBySpaceId(editorModelInformation?.spaceId);
  const { data: thumbnails } = useGetThumbnailsForModelBases(modelBases?.map((modelBase) => modelBase.id) || []);

  // Fetch all available simulations for the space we are in
  const { data: simulations } = useGetSimulationsBySpaceId(editorModelInformation?.spaceId);

  const modelMenuItems = useMemo(() => {
    return modelBases
      ?.filter((modelBase) => modelBase.revisions > 0)
      .sort((a, b) => (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1))
      .map((modelBase) => ({
        id: modelBase.model.id,
        name: modelBase.name,
        thumbnailUrl: thumbnails?.find((thumbnail) => thumbnail.key === modelBase.id)?.value ?? '',
        createdAt: modelBase.createdAt,
      }));
  }, [modelBases, thumbnails]);

  // Form values
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedModelId, setSelectedModelId] = useState(editorModelInformation?.id);
  const [selectedRadio, setSelectedRadio] = useState(NewSimulationTypes.New);
  const [selectedSimulationToCopyId, setSelectedSimulationToCopyId] = useState('');

  const isNewModelSelected = selectedModelId ? selectedModelId !== editorModelInformation?.id : false;
  const isRhino3dmFileLoaded = selectedModelId! in rhino3dmFiles;

  // Load info about the newly selected model (not the same as being shown in the editor)
  const { data: newModelInformation } = useGetModelInformation(selectedModelId ?? null, isNewModelSelected);

  // Load the model file of the newly selected model (if it is not already loaded)
  const { data: newModelFile } = useLoadAndExtractFileFromUrl(
    newModelInformation?.modelUrl && !isRhino3dmFileLoaded ? newModelInformation?.modelUrl : null,
    selectedModelId ?? null
  );

  const simulationsInSpace: SimulationsInSpace[] = useMemo(() => {
    return (
      simulations?.map((space: ModelSimulationsDto) => {
        return {
          name: space.modelBaseName,
          id: space.modelId,
          createdAt: space.modelCreatedAt,
          simulations: space.simulations,
        };
      }) ?? []
    );
  }, [simulations]);

  const simulationMenuItems = useMemo(() => {
    return createSelectMenu(simulationsInSpace, selectedModelId);
  }, [simulationsInSpace, selectedModelId]);

  const createNewSimulation = useCreateNewSimulation();
  useSubmitEvent(isFormValid, [name, description, selectedRadio, selectedSimulationToCopyId]);

  useEffect(() => {
    // reset the simulation select when toggling away from "copy"
    if (selectedRadio === NewSimulationTypes.New) {
      setSelectedSimulationToCopyId('');
    }
  }, [selectedRadio]);

  // Form validation
  useEffect(() => {
    if (selectedRadio === NewSimulationTypes.Copy) {
      setIsFormValid(false);
      if (selectedSimulationToCopyId.length > 0 && name.length > 0) {
        setIsFormValid(true);
      }
    } else if (name.length > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name, selectedRadio, selectedSimulationToCopyId]);

  const createSimulation = async () => {
    let selectedModelData;

    if (isNewModelSelected) {
      selectedModelData = {
        modelId: selectedModelId!,
        newModelSelected: true,
        isNotWatertight: newModelInformation?.nonWatertight ?? false,
        isRhino3dmFileLoaded: isRhino3dmFileLoaded,
        modelFile: newModelFile ?? null,
        modelVolume: null,
      };
    } else {
      selectedModelData = {
        modelId: selectedModelId!,
        newModelSelected: false,
        isNotWatertight: editorModelInformation?.nonWatertight ?? false,
        isRhino3dmFileLoaded: true,
        modelFile: null,
        modelVolume: editorModelVolume,
      };
    }

    const newSim = await createNewSimulation(
      selectedRadio,
      name,
      description,
      selectedModelData,
      selectedSimulationToCopyId,
      simulationsInSpace
    );
    if (newSim) {
      if (availableSimulations) {
        // Add the new simulation to the current model
        // simulations array to update the dropdown etc.
        const newSimulations = [...availableSimulations, newSim];

        // Add the new simulation to the model's list of simulations
        dispatch({
          type: ActionType.SET_MODEL_SIMULATIONS,
          simulations: newSimulations,
        });

        // the dispatch above does not fire "quickly enough", so as an hack we set the change
        // param functionality into a timeout so that when a new simulation is created, the
        // newSimulations (availableSimulations) array will be part of the next "React state update"
        // otherwise the route changes but availableSimulations do not update in time
        setTimeout(() => {
          setSearchParams({ mid: newSim.modelId, sid: newSim.id });
        });

        toast.success(`New simulation '${newSim.name}' created!`, { className: 'editor-toast' });
      }

      setShowPopup(false);
    } else {
      toast.error('Creating a new simulation failed. Please refresh the browser.', { className: 'editor-toast' });
    }
  };

  return (
    <TrblPopup width="400px" hideBackdrop={false} aria-labelledby={POPUP_TITLE} sx={{ fontSize: '12px' }} open={true}>
      <form>
        <TrblPopupTitle onClose={() => setShowPopup(false)}>{POPUP_TITLE}</TrblPopupTitle>
        <TrblPopupContent>
          <PopupContent
            modelOptions={modelMenuItems ?? []}
            simulationOptions={simulationMenuItems}
            name={name}
            setName={setName}
            description={description}
            setDescription={setDescription}
            selectedRadio={selectedRadio}
            setSelectedRadio={setSelectedRadio}
            selectedSimulationToCopyId={selectedSimulationToCopyId}
            setSelectedSimulationToCopyId={setSelectedSimulationToCopyId}
            modelId={selectedModelId}
            setModelId={setSelectedModelId}
          />
        </TrblPopupContent>
        <TrblPopupActions>
          <Box component={'div'}>
            <SecondaryButton
              type="submit"
              disabled={!isFormValid}
              width={'fit-content'}
              label="Add"
              onClick={createSimulation}
            />
          </Box>
        </TrblPopupActions>
      </form>
    </TrblPopup>
  );
};
