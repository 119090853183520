import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblFilterListIcon: FC<IconProps> = ({ fill = '#DADADA', width = '15', height = '16' }) => (
  <svg width={width} height={height} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2643 3.28694C10.4675 3.76462 10.3901 4.32516 10.0664 4.71981H10.0614L6.46783 8.22827L6.46783 14.2175C6.46783 14.4561 6.20283 14.5992 6.00332 14.4683L4.02124 13.1686C3.93669 13.1131 3.88575 13.0188 3.88575 12.9177L3.88575 8.22827L0.309928 4.71981C-0.0136087 4.32534 -0.0911018 3.76511 0.111811 3.28755C0.314725 2.80998 0.76049 2.50346 1.25144 2.50391H9.12363C9.61481 2.50293 10.061 2.80926 10.2643 3.28694Z"
      fill={fill}
    />
    <path
      d="M11.7944 5.27683C11.7944 4.84672 12.1431 4.49805 12.5732 4.49805H14.2212C14.6514 4.49805 15 4.84672 15 5.27683C15 5.70694 14.6514 6.05562 14.2212 6.05562H12.5732C12.1431 6.05562 11.7944 5.70694 11.7944 5.27683Z"
      fill={fill}
    />
    <path
      d="M9.81714 8.73871C9.81714 8.3086 10.1658 7.95993 10.5959 7.95993H14.2212C14.6514 7.95993 15 8.3086 15 8.73871C15 9.16882 14.6514 9.5175 14.2212 9.5175H10.5959C10.1658 9.5175 9.81714 9.16882 9.81714 8.73871Z"
      fill={fill}
    />
    <path
      d="M9.81714 12.2251C9.81714 11.795 10.1658 11.4463 10.5959 11.4463H14.2212C14.6514 11.4463 15 11.795 15 12.2251C15 12.6552 14.6514 13.0039 14.2212 13.0039H10.5959C10.1658 13.0039 9.81714 12.6552 9.81714 12.2251Z"
      fill={fill}
    />
  </svg>
);
