import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblAddMemberIcon: FC<IconProps> = ({ fill = '#1f1f1f', width = '14', height = '14' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ overflow: 'visible' }}>
    <path
      d="M6.55035 7.22231C6.60802 7.15631 6.63295 7.06787 6.61825 6.98147C6.60355 6.89507 6.55077 6.81985 6.47451 6.77664C5.82748 6.40581 5.0952 6.20942 4.34943 6.20673C2.4179 6.21551 0.717936 7.48297 0.158182 9.33164C0.131837 9.41991 0.148737 9.51543 0.203766 9.5893C0.258794 9.66317 0.345485 9.7067 0.437598 9.70673H5.18593C5.32699 9.70672 5.44782 9.60578 5.47293 9.46698C5.62156 8.6348 5.99403 7.85881 6.55035 7.22231V7.22231Z"
      fill={fill}
    />
    <path
      d="M4.22933 5.62339C5.75962 5.62339 7.00016 4.38285 7.00016 2.85256C7.00016 1.32227 5.75962 0.0817261 4.22933 0.0817261C2.69904 0.0817261 1.4585 1.32227 1.4585 2.85256C1.4585 4.38285 2.69904 5.62339 4.22933 5.62339Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.271 10.2901C6.271 8.19598 7.96858 6.4984 10.0627 6.4984C12.1557 6.50097 13.8518 8.19705 13.8543 10.2901C13.8543 12.3841 12.1567 14.0817 10.0627 14.0817C7.96858 14.0817 6.271 12.3841 6.271 10.2901ZM10.9377 10.8734H12.1043C12.4265 10.8734 12.6877 10.6122 12.6877 10.2901C12.6877 9.9679 12.4265 9.70673 12.1043 9.70673H10.9377C10.7766 9.70673 10.646 9.57615 10.646 9.41506V8.2484C10.646 7.92623 10.3848 7.66506 10.0627 7.66506C9.7405 7.66506 9.47933 7.92623 9.47933 8.2484V9.41506C9.47933 9.57615 9.34875 9.70673 9.18766 9.70673H8.021C7.69883 9.70673 7.43766 9.9679 7.43766 10.2901C7.43766 10.6122 7.69883 10.8734 8.021 10.8734H9.18766C9.34875 10.8734 9.47933 11.004 9.47933 11.1651V12.3317C9.47933 12.6539 9.7405 12.9151 10.0627 12.9151C10.3848 12.9151 10.646 12.6539 10.646 12.3317V11.1651C10.646 11.004 10.7766 10.8734 10.9377 10.8734Z"
      fill={fill}
    />
  </svg>
);
