import { SyntheticEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { ClickAwayListener, Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { PrimaryButton } from '@/components/Shared/Buttons';
import { Divider } from '@/components/Shared/Divider';
import { Trbl3DBoxIcon } from '@/components/Icons';
import { GeometryCard } from '../GeometryPicker/GeometryCard';
import { TrblCustomSelect } from '../TrblCustomSelect';
import { GeometryActionsMenu } from './GeometryActionsMenu';
import { SimulationList } from './SimulationList';

import { useGetSimulationsBySpaceId, useGetSpaceById, useGetThumbnailsForModelBases } from '@/hooks';

import { ModelBaseDto, Simulation, SpaceDashboardDto } from '@/types';

import classes from './styles.module.scss';

export const SpaceInfoSidepanel = ({ spaceDetails }: { spaceDetails: SpaceDashboardDto }) => {
  const navigate = useNavigate();

  const { data: space } = useGetSpaceById(spaceDetails.id);
  // fetch all simulations in a space
  const { data: modelSimulations } = useGetSimulationsBySpaceId(spaceDetails.id);

  const simulations = modelSimulations?.flatMap((simsByModels) => simsByModels.simulations) ?? [];

  const [modelBases, setModelBases] = useState(space?.modelBases);
  const { data: thumbnails } = useGetThumbnailsForModelBases(modelBases?.map((modelBase) => modelBase.id) || []);

  const [selectedGeometry, setSelectedGeometry] = useState<ModelBaseDto | null>(null);
  const [geometryMenuClicked, setGeometryMenuClicked] = useState('');

  const [selectedSimulations, setSelectedSimulations] = useState<Simulation[]>([]);

  const filteredModelBases = modelBases?.filter((modelBase) => modelBase.revisions > 0) || [];

  useEffect(() => {
    if (space) {
      setSelectedGeometry(null);
      setModelBases(
        space.modelBases.sort((a: ModelBaseDto, b: ModelBaseDto) =>
          new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1
        )
      );
    }
  }, [space]);

  useEffect(() => {
    if (modelSimulations) {
      setSelectedSimulations(simulations);
    }
  }, [modelSimulations]);

  const getEditorUrl = () => {
    if (selectedGeometry) {
      return `/editor?mid=${selectedGeometry?.model.id}`;
    } else if (spaceDetails.latestSimulation.simulationId) {
      return `/editor?mid=${spaceDetails.latestSimulation.modelId}&sid=${spaceDetails.latestSimulation.simulationId}`;
    } else {
      return `/editor?mid=${space?.modelBases[0]?.model?.id}`;
    }
  };

  const getGeometryThumbnailUrl = () => {
    if (selectedGeometry) {
      return thumbnails?.find(({ key }) => key === selectedGeometry?.id)?.value as string;
    } else if (spaceDetails.latestSimulation.modelThumbnailUrl) {
      return spaceDetails.latestSimulation.modelThumbnailUrl;
    } else {
      return thumbnails?.find(({ key }) => key === space?.modelBases[0].id)?.value as string;
    }
  };
  const clickGeometryThumbnail = (e: SyntheticEvent, modelBase: ModelBaseDto) => {
    const target = e.target as HTMLElement;

    //hack to not select geometry if clicked target is popup window
    if (document.querySelector('.MuiModal-root')?.contains(target)) {
      return;
    }

    if (selectedGeometry?.id == modelBase.id) {
      setSelectedGeometry(null);
      setSelectedSimulations(simulations);
    } else {
      setSelectedGeometry(modelBase);
      setSelectedSimulations(simulations.filter((simulation) => simulation.modelId == modelBase.model.id));
    }
  };

  const handleOpenGeometryInEditor = (modelId: string) => {
    navigate(`/editor?mid=${modelId}`);
  };

  const onOutsideClick = (e: Event) => {
    const target = e.target as HTMLElement;

    //hack to not de-select model if clicked target is a popup window, a button or a link
    if (document.querySelector('.MuiModal-root')?.contains(target) || target.closest('button') || target.closest('a')) {
      return;
    }

    setSelectedGeometry(null);
    setSelectedSimulations(simulations);
  };

  useEffect(() => {
    if (selectedGeometry) {
      document.addEventListener('mousedown', onOutsideClick);
    }
    return () => document.removeEventListener('mousedown', onOutsideClick);
  }, [selectedGeometry]);

  return (
    <div className={classes.space_info_sidepanel}>
      <div className={classes.space_info_row}>
        <Stack flexDirection="row" justifyContent="space-between" gap="6px">
          <div className={classes.space_info_name}>{spaceDetails.name}</div>
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" margin="0 0 8px">
          <span style={{ fontWeight: '400' }}>Created: {dayjs(spaceDetails.createdAt).format('DD MMM YYYY')}</span>
          <Link to={getEditorUrl()}>
            <PrimaryButton label="Open" width="107px" />
          </Link>
        </Stack>
        <img className={classes.space_info_thumbnail} src={getGeometryThumbnailUrl()} alt="model image" />

        {space?.description ? (
          <Stack gap="6px">
            Description
            <p style={{ fontWeight: '400', color: '#bdbdbd' }}>{space.description}</p>
          </Stack>
        ) : (
          <span style={{ fontWeight: '400', color: '#bdbdbd' }}>No description</span>
        )}
      </div>

      <Divider />
      <div className={classes.geometry_iterations_info}>
        <div className={classes.geometry_iterations_info_header}>
          <div className={classes.icon_and_title}>
            <Trbl3DBoxIcon />
            {selectedGeometry ? (
              <span style={{ fontWeight: '500' }}>{selectedGeometry.name}</span>
            ) : (
              <>Geometry iterations</>
            )}
          </div>
          <TrblCustomSelect selectedItem={'View list'}>
            <div className={`${classes.models_container}  `}>
              {filteredModelBases?.map(({ id, name, model }) => (
                <GeometryCard
                  key={id}
                  id={id}
                  geometry={model}
                  thumbnail={thumbnails?.find((t) => t.key === id)?.value as string}
                  name={name}
                  selectedGeometryId={selectedGeometry?.model.id}
                />
              ))}
            </div>
          </TrblCustomSelect>
        </div>
        <div
          className={`${classes.space_geometry_thumbnails} ${
            filteredModelBases.length > 6 ? classes.with_scrollbar : ''
          }`}>
          {filteredModelBases.map((modelBase) => (
            <div
              key={modelBase.id}
              className={`${classes.geometry_thumbnail} ${
                selectedGeometry?.id == modelBase.id ? classes.selected : ''
              } ${geometryMenuClicked == modelBase.id ? classes.clicked : ''}`}>
              <TrblTooltip
                title={
                  <div>
                    <p>{modelBase.name}</p>
                    <p>
                      {simulations.filter((simulation) => simulation.modelId == modelBase.model.id).length +
                        ' simulations'}
                    </p>
                  </div>
                }>
                <button
                  onClick={(e) => clickGeometryThumbnail(e, modelBase)}
                  onDoubleClick={() => handleOpenGeometryInEditor(modelBase.model.id)}>
                  <img
                    src={thumbnails?.find(({ key }) => key === modelBase.id)?.value as string}
                    alt="Geometry iteration thumbnail"
                  />
                </button>
              </TrblTooltip>
              <ClickAwayListener disableReactTree onClickAway={() => setGeometryMenuClicked('')}>
                <div onMouseDown={() => setGeometryMenuClicked(modelBase.id)}>
                  <GeometryActionsMenu
                    geometry={modelBase}
                    projectId={space?.projectId}
                    classNames={classes.geometry_menu}
                    setSelected={() => setGeometryMenuClicked('')}
                  />
                </div>
              </ClickAwayListener>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.space_simulations_row}>
        <SimulationList
          selectedSimulations={selectedSimulations}
          selectedGeometry={selectedGeometry}
          modelSimulations={modelSimulations}
        />
      </div>
    </div>
  );
};
