import { FC, useState } from 'react';

import { TrblCancelledIcon } from '@/components/Icons/TrblCancelledIcon';
import { TrblCompletedIcon } from '@/components/Icons/TrblCompletedIcon';
import { TrblEditIcon } from '@/components/Icons/TrblEditIcon';
import { TrblIconButton } from '../Buttons/TrblIconButton';
import { TrblTooltip } from '../TrblTooltip';
import { TextField, TextFieldProps } from './TextField';

interface EditableTextFieldProps extends TextFieldProps {
  onSave: (value: string) => void;
  editTitle: string;
}

export const EditableTextField: FC<EditableTextFieldProps> = ({ value: initialValue, onSave, editTitle, ...props }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [originalValue, setOriginalValue] = useState(initialValue);
  const [isHovering, setIsHovering] = useState(false);

  const hasChanges = value !== originalValue;

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setValue(originalValue);
    setIsEditing(false);
  };

  const handleConfirm = () => {
    setOriginalValue(value);
    onSave(value);
    setIsEditing(false);
  };

  const renderAdornment = () => {
    if (!isEditing) {
      return isHovering ? (
        <TrblTooltip title={editTitle}>
          <span>
            <TrblIconButton
              size="small"
              icon={<TrblEditIcon fill="#cccccc" height="10px" width="10px" />}
              onClick={handleEdit}
            />
          </span>
        </TrblTooltip>
      ) : null;
    }
    return (
      <>
        <TrblTooltip title={!hasChanges ? 'No changes' : 'Confirm'}>
          <span>
            <TrblIconButton
              size="small"
              icon={<TrblCompletedIcon fill={!hasChanges ? '#616161' : '#00F5BA'} height="14px" width="14px" />}
              disabled={!hasChanges}
              onClick={handleConfirm}
            />
          </span>
        </TrblTooltip>
        <TrblTooltip title="Cancel">
          <span>
            <TrblIconButton
              size="small"
              icon={<TrblCancelledIcon fill="#fd5b5b" height="14px" width="14px" />}
              onClick={handleCancel}
            />
          </span>
        </TrblTooltip>
      </>
    );
  };

  return (
    <TextField
      {...props}
      key={isEditing ? 'editable-text-field' : ''}
      autoFocus={isEditing}
      value={value}
      onChange={isEditing ? setValue : undefined}
      disabled={!isEditing}
      endAdornment={renderAdornment()}
      onInteractionStart={() => setIsHovering(true)}
      onInteractionEnd={() => setIsHovering(false)}
    />
  );
};
