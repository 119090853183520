import { Stack } from '@mui/material';

import styles from '../../styles.module.scss';

export const ThumbnailandWarning = ({
  thumbnailSrc,

  imgLoaded,
  setImgLoaded,
}: {
  thumbnailSrc: string | undefined;
  warningCode: number;
  imgLoaded: boolean;
  setImgLoaded: (value: boolean) => void;
}) => {
  return (
    <Stack position="relative">
      <img
        className={styles['space-card-thumbnail']}
        src={thumbnailSrc ?? ''}
        style={{ opacity: imgLoaded ? '1' : '0' }}
        onLoad={() => setImgLoaded(true)}
        alt="space thumbnail"
      />
    </Stack>
  );
};
