import { useEffect, useMemo, useState } from 'react';
import { Rnd } from 'react-rnd';

import { ActionType, useResultsContext } from '@/components/Results/context/ResultsContext';

import { TrblDraggableIcon, TrblMinimizeIcon } from '@/components/Icons';
import { FreqResponsePlot } from '../Viewport/GridReceiverResults/FreqResponsePlot';

import { useModalReceiverData } from '../Viewport/GridReceiverResults/hooks/useModalReceiverData';

import styles from './styles.module.scss';

export const FrequencyResponsePopup = () => {
  const [yData, setYData] = useState<number[]>([]);
  const [xData, setXData] = useState<number[]>([]);

  const {
    dispatch,
    availableComparisons,
    selectedComparisonIndex,
    receiverSquare,
    modalAnalysisFrequencyValues,
    modalFrequencySelected,
    h5Files,
  } = useResultsContext();

  const selectedComparison = availableComparisons.length > 0 ? availableComparisons[selectedComparisonIndex] : null;
  const gridReceiverResultsToDisplay = selectedComparison?.formState?.simulationData?.selectedGridReceiverObjects ?? [];
  const selectedSourceObject = selectedComparison?.formState?.simulationData?.selectedSourceObject;
  const selectedSimulationId = selectedComparison?.formState?.simulationId;
  const selectedSourceSummingObject = selectedComparison?.formState?.simulationData?.selectedSourceSummingObject;

  const memoizedIds = useMemo(
    () => (gridReceiverResultsToDisplay ? gridReceiverResultsToDisplay.map((grid) => grid.pointId) : []),
    [gridReceiverResultsToDisplay]
  );

  const getFetchedH5File = () => {
    if (!h5Files || !selectedSimulationId) return null;

    const sourcePointId = selectedSourceObject?.sourcePointId || selectedSourceSummingObject?.id;
    return (sourcePointId && h5Files[selectedSimulationId]?.[sourcePointId]) || null;
  };

  const selectedH5File = getFetchedH5File();

  const { receiverData: modalAnalysisReceiverData } = useModalReceiverData(
    selectedH5File,
    memoizedIds,
    receiverSquare?.gridIndex,
    receiverSquare?.pointId
  );

  useEffect(() => {
    if (modalAnalysisReceiverData && receiverSquare && modalAnalysisFrequencyValues) {
      const receiverData = modalAnalysisReceiverData[receiverSquare.pointId];
      if (receiverData) {
        const selectedData = receiverData[`Receiver_${receiverSquare.gridIndex}`];
        if (selectedData) {
          setYData(selectedData);
          setXData(modalAnalysisFrequencyValues[receiverSquare.pointId]);
        }
      }
    } else if (receiverSquare === null || modalAnalysisReceiverData === null) {
      setYData([]);
      setXData([]);
    }
  }, [modalAnalysisReceiverData, receiverSquare, modalAnalysisFrequencyValues]);

  const closePopup = () => {
    dispatch({
      type: ActionType.SHOW_FREQ_RESPONSE_POPUP,
      showFreqResponsePopup: false,
    });
  };

  return (
    <Rnd
      className={styles['freq-popup']}
      default={{
        x: 20,
        y: 50,
        width: 500,
        height: 350,
      }}
      bounds="#viewport"
      dragHandleClassName="drag-area">
      <div style={{ width: `100%`, height: `100%` }}>
        <FreqResponsePlot yData={yData} xData={xData} selectedFrequency={modalFrequencySelected} />
        <button className={styles['minimize-btn']} onClick={closePopup}>
          <TrblMinimizeIcon />
        </button>
        <button className={`${styles['drag-btn']} drag-area`}>
          <TrblDraggableIcon />
        </button>
      </div>
    </Rnd>
  );
};
