/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetTokenSilentlyOptions, LogoutOptions } from '@auth0/auth0-react';
import { AxiosError } from 'axios';

import client from '../../axios';

import { config } from '@/__config__/config';

const { viteAuth0LogoutUri } = config;

const handleNavigation = (to: string) => {
  if (!window.location.href.includes(to)) {
    window.location.replace(`${window.location.origin}${to}`);
  }
};

export const configureAxiosResponseInterceptor = () => {
  client.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response && error.response.status === 403) {
        const errorMessage = (error.response.data as any)?.message;

        if (errorMessage === 'Trial expired') {
          handleNavigation('/subscription');
        } else if (errorMessage === 'Subscription expired') {
          handleNavigation('/subscription-expired');
        } else if (errorMessage === 'Product access disabled' || errorMessage === 'User has no TASS subscription') {
          handleNavigation('/access-disabled');
        }
      }

      return Promise.reject(error);
    }
  );
};

export const configureAxiosRequestInterceptor = (
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
  logout: (options?: LogoutOptions | undefined) => void
) => {
  client.interceptors.request.use(async (config) => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (config.headers && accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    } catch (e: any) {
      if (e.error === 'login_required' || e.error === 'invalid_grant' || e.error === 'missing_refresh_token') {
        logout({ returnTo: viteAuth0LogoutUri });
      }
    }
    return config;
  });
};
