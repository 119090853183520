import { keyframes, LinearProgress, linearProgressClasses, styled } from '@mui/material';

const slowStripes = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: 100px 0; } /* Moves the stripes horizontally */
`;

export const ProgressBar = styled(LinearProgress)(() => ({
  height: '8px',
  border: '0.5px solid #171717',
  borderRadius: '50px',
  backgroundColor: '#171717',
  margin: '10px 0',

  [`& .${linearProgressClasses.barColorPrimary}`]: {
    borderRadius: '50px',
    backgroundColor: '#00F5BA',
  },

  [`& .${linearProgressClasses.barColorSecondary}`]: {
    borderRadius: '50px',
    backgroundColor: '#00D1FF',
  },

  [`& .${linearProgressClasses.bar2Buffer}`]: {
    backgroundColor: 'transparent !important',
  },

  [`& .${linearProgressClasses.dashed}`]: {
    backgroundColor: 'transparent !important',
    backgroundImage: 'none',
  },

  [`& .${linearProgressClasses.bar1Buffer}`]: {
    backgroundImage: 'repeating-linear-gradient(-45deg, #333333 0px, #333333 5px, #999999 5px, #999999 10px)',
    animation: `${slowStripes} 20s linear infinite`,
  },
}));
