import { useState } from 'react';

import { SummedSourceOption } from '../../../types';

export const useSummedSourceState = () => {
  const [sourceSummingPopupOpen, setSourceSummingPopupOpen] = useState(false);
  const [summedSourceToEdit, setSummedSourceToEdit] = useState<null | string>(null);
  const [summedSourceToDelete, setSummedSourceToDelete] = useState<null | SummedSourceOption>(null);
  const [summedSourceInEditMode, setSummedSourceInEditMode] = useState<null | string>(null);
  const [summedSourceInEditModeLabel, setSummedSourceInEditModeLabel] = useState<string>('');

  const handleCancelLabelChange = () => {
    setSummedSourceInEditMode(null);
    setSummedSourceInEditModeLabel('');
  };

  return {
    sourceSummingPopupOpen,
    setSourceSummingPopupOpen,
    summedSourceToEdit,
    setSummedSourceToEdit,
    summedSourceToDelete,
    setSummedSourceToDelete,
    summedSourceInEditMode,
    setSummedSourceInEditMode,
    summedSourceInEditModeLabel,
    setSummedSourceInEditModeLabel,
    handleCancelLabelChange,
  };
};
