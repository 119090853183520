import { useResultsContext } from '@/components/Results/context/ResultsContext';
import { ActionType, useEditorContext } from '@/context/EditorContext';

import { TrblSelect } from '@/components/Shared/TrblSelect';

import { VIEW_3D_OPTIONS } from './constants';

import { ResultsView, View3DType } from '@/context/EditorContext/types';

import styles from './styles.module.scss';

export const ModelViewOptions = ({
  showResults,
  position,
}: {
  showResults: boolean;
  position?: { top?: string; right: string; left?: string };
}) => {
  const { resultsView, view3D, isCameraInsideModel, dispatch } = useEditorContext();
  const { emptyResults } = useResultsContext();

  const handle3DViewChanged = (selectedTab: string) => {
    dispatch({
      type: ActionType.USER_SET_3D_VIEW,
      userSelectedView3D: selectedTab as View3DType,
    });
  };

  return (
    <div
      className={`${styles['change-3d-tabs']} ${
        showResults && resultsView === ResultsView.ResultsGridReceiversView ? styles['grid-receivers'] : ''
      }`}
      style={{ top: position?.top, right: position?.right, left: position?.left }}>
      {!isCameraInsideModel && !emptyResults && (
        <TrblSelect
          menuItems={VIEW_3D_OPTIONS}
          value={view3D}
          setValue={handle3DViewChanged}
          minimal
          style={{
            height: '24px',
            fontSize: '12px',
            backgroundColor: 'transparent',
          }}
        />
      )}
    </div>
  );
};
