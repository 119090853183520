import { FC, useMemo, useState } from 'react';

import { TrblPopup, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { useResultComparisonContext } from '../ResultComparison';
import { PopupActions } from './PopupActions';
import { SourceList } from './SourceList/SourceList';
import { SourceNameField } from './SourceNameField';

import { useSourceSearch } from './hooks/useSourceSearch';
import { useSourceSelection } from './hooks/useSourceSelection';
import { useSourceValidation } from './hooks/useSourceValidation';

import { SummedSourceOption } from '../../types';
import { BaseType } from '@/types';

type SourceSummingPopupProps = {
  sources: BaseType[];
  summedSourceId: string | null;
  availableSummedSources: SummedSourceOption[];
  onClose: () => void;
  onSumSources: (name: string, sources: string[], delays: Record<string, number>) => void;
  onUpdateSummedSourceLabel: (summedSourceId: string, label: string) => void;
};

const POPUP_TITLE = 'Sum multiple sources';
const POPUP_EDIT_TITLE = 'Edit summed source';

export const SourceSummingPopup: FC<SourceSummingPopupProps> = ({
  sources,
  summedSourceId,
  availableSummedSources,
  onClose,
  onSumSources,
  onUpdateSummedSourceLabel,
}) => {
  const { dispatch } = useResultComparisonContext();

  const sourceBeingEdited = useMemo(() => {
    if (summedSourceId) {
      return availableSummedSources.find((item) => item.id === summedSourceId) ?? null;
    }
    return null;
  }, [summedSourceId, availableSummedSources]);

  const [name, setName] = useState(
    sourceBeingEdited ? sourceBeingEdited.name : `Summed source ${availableSummedSources.length + 1}`
  );

  const { searchQuery, setSearchQuery, filteredSourceIds } = useSourceSearch(sources);

  const { selectedSourceIds, handleSelectAll, handleToggleSource } = useSourceSelection({
    sourceBeingEdited,
    dispatch,
  });

  const validationError = useSourceValidation({
    name,
    selectedSourceIds,
    sourceBeingEdited,
    availableSummedSources,
  });

  const handleSumSources = () => {
    onSumSources(name, selectedSourceIds, {});
    onClose();
  };

  const handleSaveName = (newName: string) => {
    setName(newName);
    if (summedSourceId) {
      onUpdateSummedSourceLabel(summedSourceId, newName);
    }
  };

  return (
    <TrblPopup
      width="420px"
      hideBackdrop={true}
      draggable={true}
      aria-labelledby={POPUP_TITLE}
      sx={{ fontSize: '12px' }}
      open={true}>
      <form>
        <TrblPopupTitle onClose={onClose}>{sourceBeingEdited ? POPUP_EDIT_TITLE : POPUP_TITLE}</TrblPopupTitle>

        <TrblPopupContent>
          <SourceNameField
            name={name}
            sourceBeingEdited={!!sourceBeingEdited}
            onNameChange={setName}
            onNameSave={handleSaveName}
          />

          <div style={{ marginTop: '20px', height: '450px', display: 'flex', flexDirection: 'column' }}>
            <SourceList
              sources={sources}
              selectedSourceIds={selectedSourceIds}
              filteredSourceItemIds={filteredSourceIds}
              sourceSearchQuery={searchQuery}
              onSearchChange={setSearchQuery}
              onSourceToggle={handleToggleSource}
              onSelectAll={(isChecked) => handleSelectAll(isChecked ? sources.map((item) => item.id) : [])}
            />
          </div>
        </TrblPopupContent>

        <PopupActions
          validationError={validationError}
          sourceBeingEdited={!!sourceBeingEdited}
          onSubmit={handleSumSources}
        />
      </form>
    </TrblPopup>
  );
};
