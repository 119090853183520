import { useQuery } from '@tanstack/react-query';

import axios from '@/axios';

let refetchInterval = 0;

const getEstimatedQueueTimesForQueuedSimulations = async () => {
  const { data } = await axios.get<{ simulationRunId: string; estimatedQueueTimeSeconds: number }[]>(
    `/api/SimulationRun/GetEstimatedQueueTimesForQueuedSimulations`
  );

  return data;
};

export const useGetEstimatedQueueTimesForQueuedSimulations = (enabled = true) =>
  useQuery(['estimated-queue-times-for-queued-simulations'], () => getEstimatedQueueTimesForQueuedSimulations(), {
    enabled,
    refetchOnWindowFocus: false,
    refetchInterval,
    onSuccess: (data) => {
      if (data.length > 0) {
        refetchInterval = 10000;
      } else {
        refetchInterval = 0;
      }
    },
  });
