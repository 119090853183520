import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { SxProps, Theme } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { hasTASSSeats, hasTSDKSeats } from '../Organization';
import { RowActions } from './RowActions';
import { SelectCell } from './SelectCell';
import { getUserTASSStatus, getUserTSDKAccess } from './UsersGrid';

import { SubscriptionDetails, UserAccess } from '../types';

import styles from './styles.module.scss';

export const gridStyleOverrides: SxProps<Theme> = {
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
};

export const getColumns = (
  currentUserId: string,
  onEditUser: (user: UserAccess) => void,
  onDeleteUser: (userId: string) => void,
  onToggleIsAdmin: (userId: string, isAdmin: boolean) => void,
  onToggleIsEnabled: (userId: string, isEnabled: boolean) => void,
  onToggleHasSDKAccess: (userId: string, isEnabled: boolean) => void,
  isUsingSso: boolean,
  subscription: SubscriptionDetails
): GridColDef<UserAccess>[] => {
  const columns: GridColDef<UserAccess>[] = [
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
      editable: false,
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 200,
      editable: false,
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 220,
      editable: false,
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      type: 'date',
      width: 150,
      editable: false,
      renderCell: (params) => (params.value ? dayjs(params.value).format('DD/MM/YYYY') : ''),
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'tsdkAccess',
      headerName: 'SDK access',
      width: 130,
      disableColumnMenu: true,
      valueGetter: (params) => {
        return getUserTSDKAccess(params.row.productAccesses);
      },
      renderCell: (params) => (
        <TrblTooltip
          title={
            !params.value && !hasTSDKSeats(subscription)
              ? 'You can not enable this user - please add more seats'
              : params.value && currentUserId === params.row.id
              ? 'You can not disable your own account'
              : ''
          }>
          <span>
            <SelectCell
              menuItems={[
                { name: 'Yes', id: 'yes' },
                { name: 'No', id: 'no' },
              ]}
              value={params.value ? 'yes' : 'no'}
              onSelect={(value) => {
                onToggleHasSDKAccess(params.row.id, value == 'yes');
              }}
              confirmationText={
                !params.value
                  ? `Are you sure you want to grant SDK access to this user?`
                  : `Are you sure you want to revoke SDK access for this user?`
              }
              // If the user is not enabled and there are no available seats, the switch should be disabled
              // Also you should not be able to disable yourself (the logged in user)
              disabled={!params.value && !hasTSDKSeats(subscription)}
            />
          </span>
        </TrblTooltip>
      ),
      editable: false,
      headerClassName: styles['data-grid-header'],
      hide: !subscription.sdkProduct,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      renderCell: (params) => (
        <RowActions params={params} onEditUser={onEditUser} onDeleteUser={onDeleteUser} isUsingSso={isUsingSso} />
      ),
      headerClassName: styles['data-grid-header'],
    },
  ];

  if (subscription.productKey == 'TASS') {
    columns.splice(4, 0, {
      field: 'tassEnabled',
      headerName: subscription.sdkProduct ? 'Web app status' : 'Status',
      width: 130,
      disableColumnMenu: true,
      valueGetter: (params) => {
        return getUserTASSStatus(params.row.productAccesses);
      },
      renderCell: (params) =>
        params.value && currentUserId === params.row.id ? (
          <TrblTooltip title="You can not disable your own account">
            <div>Active</div>
          </TrblTooltip>
        ) : (
          <TrblTooltip
            title={
              !params.value && !hasTASSSeats(subscription) ? 'You can not enable this user - please add more seats' : ''
            }>
            <span>
              <SelectCell
                menuItems={[
                  { name: 'Active', id: 'active' },
                  { name: 'Disabled', id: 'disabled' },
                ]}
                value={params.value ? 'active' : 'disabled'}
                onSelect={(value) => {
                  onToggleIsEnabled(params.row.id, value == 'active');
                }}
                confirmationText={
                  !params.value
                    ? subscription.sdkProduct
                      ? 'Are you sure you want to grant web app access to this user?'
                      : 'Are you sure you want to activate this user?'
                    : subscription.sdkProduct
                    ? 'Are you sure you want to revoke web app access for this user?'
                    : 'Are you sure you want to disable this user?'
                }
                // If the user is not enabled and there are no available seats, the switch should be disabled
                // Also you should not be able to disable yourself (the logged in user)
                disabled={
                  (!params.value && !hasTASSSeats(subscription)) || (params.value && currentUserId === params.row.id)
                }
              />{' '}
            </span>
          </TrblTooltip>
        ),
      headerClassName: styles['data-grid-header'],
    });
  }

  columns.splice(3, 0, {
    field: 'roles',
    headerName: 'Role',
    width: 100,
    disableColumnMenu: true,
    renderCell: (params) => {
      const role = params.value ? (params.value.includes('Admin') ? 'Admin' : 'User') : 'User';
      return role == 'Admin' && currentUserId === params.row.id ? (
        <TrblTooltip title="You can not revoke your own admin permissions">
          <div>Admin</div>
        </TrblTooltip>
      ) : (
        <SelectCell
          menuItems={[
            { name: 'User', id: 'User' },
            { name: 'Admin', id: 'Admin' },
          ]}
          value={role}
          onSelect={(value) => {
            onToggleIsAdmin(params.row.id, value == 'Admin');
          }}
          confirmationText={
            role == 'User'
              ? `Are you sure you want to grant Admin permissions to this user?`
              : `Are you sure you want to revoke Admin permissions for this user?`
          }
          // You should not be able to revoke you own admin rights (the logged in user)
          disabled={role && currentUserId === params.row.id}
        />
      );
    },
    headerClassName: styles['data-grid-header'],
  });

  return columns;
};
