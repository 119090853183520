import { useEffect, useState } from 'react';

import { Stack } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { TrblToggle, TrblTooltip } from '../Shared';

import { CORRECT_IR_SPL_TEXT } from './constants';

import { Source } from '@/context/EditorContext/types';
import { SourceDefinition } from '@/types';

type SourceDefinitionFooterProps = {
  source?: Source;
  sourceDefinition?: SourceDefinition;
  onCorrectIrChange?: (value: boolean) => void;
  readonly?: boolean;
};

export const SourceDefinitionFooter = ({
  source,
  sourceDefinition,
  onCorrectIrChange,
  readonly = false,
}: SourceDefinitionFooterProps) => {
  const [correctIr, setCorrectIr] = useState(
    source?.params.correctIrByOnAxisSpl ?? sourceDefinition?.correctIrByOnAxisSplDefault ?? false
  );

  useEffect(() => {
    setCorrectIr(source?.params.correctIrByOnAxisSpl ?? sourceDefinition?.correctIrByOnAxisSplDefault ?? false);
  }, [source, sourceDefinition]);

  useEffect(() => {
    if (correctIr !== source?.params.correctIrByOnAxisSpl && onCorrectIrChange) {
      onCorrectIrChange(correctIr);
    }
  }, [correctIr]);

  return (
    <Stack gap="30px" alignItems={'center'} justifyContent={'flex-end'} direction="row" margin={'16px 0 0'}>
      <TrblTooltip title={<div style={{ whiteSpace: 'pre-line' }}>{CORRECT_IR_SPL_TEXT}</div>}>
        <Stack gap="8px" alignItems={'center'} direction="row">
          <Text type="regular-11px">Correct IR with on-axis SPL</Text>
          <TrblToggle
            id="irToggle"
            ariaLabel="Set correct IR with on-axis SPL toggle"
            checked={correctIr}
            disabled={!source || readonly}
            onChangeToggle={() => setCorrectIr(!correctIr)}
          />
        </Stack>
      </TrblTooltip>
    </Stack>
  );
};
