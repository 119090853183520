import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { StartTutorialDto } from '../types';

import axios from '@/axios';

const startTutorial = async (tutorialId: string): Promise<StartTutorialDto> => {
  const { data } = await axios.post(`/api/Space/StartTutorial`, undefined, { params: { tutorialId } });
  return data;
};

export const useStartTutorial = () => {
  return useMutation(async (tutorialId: string) => await startTutorial(tutorialId), {
    onError: () => {
      toast.error('An error occurred while starting the tutorial. Please refresh the browser.');
    },
  });
};
