import { FC } from 'react';

import { ReflectogramResultsProvider } from '@/components/Results/context/ReflectogramResultsContext';
import { ResultsProvider } from '@/components/Results/context/ResultsContext';
import { EditorProvider } from '@/context/EditorContext';
import { MeshProvider } from '@/context/MeshContext';
import { ModelProvider } from '@/context/ModelContext';
import { SimulationProvider } from '@/context/SimulationContext';

import { EditorNew } from '@/components/Editor/EditorNew';
import { LibraryPanelProvider } from '@/components/LibraryPanel/LibraryPanelContext';

type EditorPageProps = {
  showResults?: boolean;
  showAuralizer?: boolean;
};

export const EditorPageNew: FC<EditorPageProps> = ({ showResults = false, showAuralizer = false }) => {
  return (
    <EditorProvider>
      <ModelProvider>
        <SimulationProvider>
          <LibraryPanelProvider>
            <MeshProvider>
              <ResultsProvider isInResultsMode={showResults}>
                <ReflectogramResultsProvider>
                  <EditorNew showResults={showResults} showAuralizer={showAuralizer} />
                </ReflectogramResultsProvider>
              </ResultsProvider>
            </MeshProvider>
          </LibraryPanelProvider>
        </SimulationProvider>
      </ModelProvider>
    </EditorProvider>
  );
};
