import { FC, ReactNode } from 'react';

import { TrblTooltip } from '@/components/Shared';

import styles from './styles.module.scss';

export type TabButton = {
  key: string;
  label?: string | ReactNode;
  tooltip?: string;
  disabled?: boolean;
};

type TabButtonsProps = {
  options: TabButton[];
  selectedValue: string | null;
  onChange: (selectedValue: string) => void;
  height?: number;
  color?: string;
};

export const TabButtonsOutlined: FC<TabButtonsProps> = ({
  options,
  selectedValue,
  onChange,
  height,
  color = '#1c1c1c',
}) => {
  const back =
    'linear-gradient(' + color + ' 0 0) padding-box, linear-gradient(to bottom, #3c3c3c 0%, #3c3c3c00 100%) border-box';
  return (
    <div className={styles['tabs-container']}>
      {options.map(({ key, label, tooltip, disabled = false }) => (
        <TrblTooltip title={tooltip} key={key}>
          <button
            type="button"
            tabIndex={0}
            className={`${styles['tab']} ${selectedValue === key ? styles['selected'] : ''} ${
              disabled ? styles['disabled'] : ''
            }`}
            onClick={() => {
              if (!disabled) {
                onChange(key);
              }
            }}
            style={{ height, background: back }}>
            {label && <span>{label}</span>}
          </button>
        </TrblTooltip>
      ))}
    </div>
  );
};
