export const VolumeSliderTicks = () => {
  return (
    <div className="slider-ticks-container">
      <span className="slider-tick-big" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick-big" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick-big zero-tick" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick-big" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick-big" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick-big" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick-big" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick" />
      <span className="slider-tick-big" />
    </div>
  );
};
