/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { datadogRum } from '@datadog/browser-rum';
import {
  Box3,
  BufferGeometry,
  DoubleSide,
  Group,
  Material,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  Vector3,
} from 'three';
import { Rhino3dmLoader } from 'three/examples/jsm/loaders/3DMLoader';

import { LayerUserAttribute, ModelFile, ModelLayerGroup } from './types';

export const parseModelAsObject3D = async (modelFile: ModelFile): Promise<Object3D> => {
  const loader = new Rhino3dmLoader();
  return new Promise((resolve, reject) => {
    loader.setLibraryPath('/scripts/rhino3dm@7.15.0/');
    loader.parse(
      modelFile.fileData,
      (model) => {
        resolve(model);
      },
      (error) => {
        reject(new Error('Could not parse 3D model: ' + error.message));
        datadogRum.addError('Could not parse 3D model: ' + error.message);
      }
    );
  });
};

export const parseModelAs3dmFile = async (fileData: ArrayBufferLike): Promise<any> => {
  const rhino = await (window as any).rhino3dm();
  const arrayBuffer = new Uint8Array(fileData);
  const doc = rhino.File3dm.fromByteArray(arrayBuffer);
  doc.settings().pageUnitSystem = rhino.UnitSystem.Meters;
  return doc;
};

export const createLayerGroupsFromModel = (model3d: Object3D): Array<ModelLayerGroup> => {
  const layers = model3d.userData['layers'] as Array<LayerUserAttribute>;
  const modelLayerGroups: Array<ModelLayerGroup> = [];

  layers
    .filter((layer: any) => layer.fullPath !== 'Treble' && layer.fullPath !== 'Treble::Geometry')
    .forEach((layer) => {
      const surfaces: Array<Mesh<BufferGeometry, MeshStandardMaterial>> = (model3d.children.filter(
        (x) => x.type === 'Mesh' && layers[x.userData.attributes['layerIndex']].id === layer.id
      ) || []) as Array<Mesh<BufferGeometry, MeshStandardMaterial>>;

      // Give the meshes a name so we can destinguish from other meshes
      surfaces.forEach((s) => (s.name = 'Layer'));

      if (surfaces.length) {
        modelLayerGroups.push({
          id: layer.id,
          name: layer.name,
          children: surfaces,
        });
      }
    });

  return modelLayerGroups;
};

export const setupAreaAndGroupIntByObjectId = (file3dm: any) => {
  const rhinoObjects = file3dm.objects();

  const geometryGroupByGroupId = getRhinoGeometryGroupDict(file3dm);

  const areaByObjectId: Record<string, number> = {};
  const groupIntegerByObjectId: Record<string, number> = {};

  for (let i = 0; i < rhinoObjects.count; i++) {
    const rhinoObject = rhinoObjects.get(i);
    const rhonoObjectGeometry = rhinoObject.geometry();

    if (rhonoObjectGeometry.$$.ptrType.name === 'Mesh*') {
      const objectId = rhinoObject.attributes().id;
      const userStrings = rhonoObjectGeometry.getUserStrings();

      if (userStrings.length > 0) {
        areaByObjectId[objectId] = +userStrings.find((key: any) => key[0] === 'tech.treble.surface_area')[1];
        const geometryGroupId = userStrings.find((key: any) => key[0] === 'tech.treble.geometry_group_id')[1];
        groupIntegerByObjectId[objectId] = +geometryGroupByGroupId[geometryGroupId];
      }
    }
  }

  return {
    areaByObjectId,
    groupIntegerByObjectId,
  };
};

export const getInnerAndOuterMeshes = (meshes: Mesh[], groupIntegerByObjectId: Record<string, number>) => {
  const outerMeshes: Object3D[] = [];
  const innerMeshes: Object3D[] = [];

  meshes.forEach((mesh) => {
    (mesh.material as Material).side = DoubleSide;
    const meshId = mesh.userData.attributes.id;
    const groupInteger = groupIntegerByObjectId[meshId];

    if (groupInteger == 1) {
      outerMeshes.push(mesh);
    } else if (groupInteger == 2) {
      innerMeshes.push(mesh);
    }
  });

  return {
    innerMeshes,
    outerMeshes,
  };
};

const getRhinoGeometryGroupDict = (file3dm: any) => {
  const rhinoLayerTable = file3dm.layers();
  let geometryLayerIndex = null;

  for (let i = 0; i < rhinoLayerTable.count(); i++) {
    if (rhinoLayerTable.get(i).name === 'Geometry') {
      geometryLayerIndex = i;
      break;
    }
  }

  if (geometryLayerIndex === null) return;

  const geometryGroupByGroupIdStrings = rhinoLayerTable
    .get(geometryLayerIndex)
    .getUserStrings()
    .find((key: any) => key[0] === 'tech.treble.geometry_group_type_by_group_id');

  if (geometryGroupByGroupIdStrings === null) return;

  return JSON.parse(geometryGroupByGroupIdStrings[1]);
};

export const getModelVolume = (file3dm: any) => {
  const layers = file3dm.layers();
  let layer;

  let modelVolumes: any[] = [];

  for (let i = 0; i < layers.count(); i++) {
    layer = layers.findIndex(i);

    if (layer.name == 'Geometry' && layer.userStringCount > 0) {
      const volumeUserString = JSON.parse(layer.getUserString('tech.treble.volume_by_group_id'));
      modelVolumes = Object.keys(volumeUserString).map((key: any) => [key, volumeUserString[key]]);
      break;
    }
  }

  let totalVolume = 0;

  if (modelVolumes.length < 1) return totalVolume;

  if (modelVolumes.length > 1) {
    let volume;
    for (const modelVolume of modelVolumes) {
      volume = modelVolume[1];
      if (volume > totalVolume) totalVolume = volume;
    }

    for (const modelVolume of modelVolumes) {
      volume = modelVolume[1];
      if (volume < totalVolume) totalVolume -= volume;
    }
  } else {
    totalVolume = modelVolumes[0][1];
  }

  if (totalVolume <= 0.01) {
    toast.warning('This model has too little volume');
  }

  return totalVolume;
};

export const getModelBoundingBox = (meshes: Mesh[]) => {
  const modelGroup = new Group();

  modelGroup.children = meshes;

  const modelBoundingBox = new Box3().setFromObject(modelGroup);
  const modelCenter = new Vector3();
  modelBoundingBox.getCenter(modelCenter);

  return { modelBoundingBox, modelCenter };
};

export const updateLoadingScreen = (statement: boolean) => {
  if (statement) {
    setTimeout(() => {
      return false;
    }, 300);
  } else {
    return true;
  }
};
