import { FC } from 'react';

import { TabButtons } from '@/components/Shared';
import { ExpandableSearchInput } from '../ExpandableSearchInput';

import { VIEW_TYPES, VIEW_TYPES_OPTIONS } from './constants';

import styles from './styles.module.scss';

type PageHeaderProps = {
  selectedView?: VIEW_TYPES;
  showSearch?: boolean;
  showViewToggle?: boolean;
  searchValue?: string;
  searchPlaceholder?: string;
  pageTitle?: string;
  setSearchValue?: (search: string) => void;
  onChangeView?: (view: VIEW_TYPES) => void;
};

export const PageHeader: FC<PageHeaderProps> = ({
  selectedView = VIEW_TYPES.CARDS,
  showSearch = true,
  showViewToggle = true,
  searchValue,
  searchPlaceholder,
  pageTitle,
  setSearchValue,
  onChangeView,
}) => {
  return (
    <div className={styles['page-header']}>
      {pageTitle && (
        <div className={styles['page-header-title']}>
          <h3 className={styles['page-title']}>{pageTitle}</h3>
        </div>
      )}
      <div className={styles['page-header-controls']}>
        {showSearch && setSearchValue && (
          <ExpandableSearchInput value={searchValue ?? ''} onChange={setSearchValue} placeholder={searchPlaceholder} />
        )}
        {showViewToggle && onChangeView && (
          <div className={styles['view-toggle']}>
            <TabButtons
              options={VIEW_TYPES_OPTIONS}
              selectedValue={selectedView}
              onChange={(view) => onChangeView(view as VIEW_TYPES)}
              height={30}
              dark
              small
            />
          </div>
        )}
      </div>
    </div>
  );
};
