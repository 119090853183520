import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSimulationContext } from '@/context/SimulationContext';
import { ActionType } from '@/context/SimulationContext';

import { useCreateSimulation } from '@/hooks';

import { Simulation } from '@/types';

export const useCreateSimulationCopy = () => {
  const { mutate: createSimulation } = useCreateSimulation();
  const [, setSearchParams] = useSearchParams();
  const { dispatch } = useSimulationContext();
  const createSimulationCopy = async (
    simObject: Simulation,
    modelId: string,
    name: string,
    description: string,
    simulations: Simulation[]
  ) => {
    try {
      const newSimulationObject: Simulation = {
        ...simObject,
        modelId,
        name,
        description,
      };

      createSimulation(newSimulationObject, {
        onSuccess: (data) => {
          const savedSim: Simulation = data;
          if (savedSim === null || savedSim === undefined) {
            toast.error('Error duplicating simulation');
          }

          // used for storing temporary data like meshTaskId
          savedSim.extra = {};

          if (simulations) {
            // Add the new simulation to the current model
            // simulations array to update the dropdown etc.
            const newSimulations = [...simulations, savedSim];

            // Add the new simulation to the model's list of simulations
            dispatch({
              type: ActionType.SET_MODEL_SIMULATIONS,
              simulations: newSimulations,
            });

            toast.success(`New simulation '${data.name}' created!`);

            // the dispatch above does not fire "quickly enough", so as an hack we set the change
            // param functionality into a timeout so that when a new simulation copy is created, the
            // newSimulations (availableSimulations) array will be part of the next "React state update"
            // otherwise the route changes but availableSimulations do not update in time
            setTimeout(() => {
              setSearchParams({ mid: savedSim.modelId, sid: savedSim.id });
            });
          }
        },
      });
    } catch (error) {
      toast.error('Creating a new simulation failed. Please refresh the browser.');
    }
  };
  return createSimulationCopy;
};
