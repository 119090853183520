import { FC } from 'react';
import { extend, ReactThreeFiber, useThree } from '@react-three/fiber';
import { BufferGeometry, Line, Vector2, Vector3 } from 'three';

/** Avoid type conflict by defining our own _line element */
extend({ Line_: Line });

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      line_: ReactThreeFiber.Object3DNode<THREE.Line, typeof Line>;
    }
  }
}

const AxesHelper = () => {
  const setXAxesPoints = (geometry: BufferGeometry) => {
    geometry.setFromPoints([new Vector2(0, 0), new Vector2(180, 0)]);
  };

  const setYAxesPoints = (geometry: BufferGeometry) => {
    geometry.setFromPoints([new Vector2(0, 0), new Vector2(0, 180)]);
  };

  // Position offset to reduce the flickering of the axesHelper when it intersect with a line in the model
  const axesHelperPositionOffset = new Vector3(-0.003, -0.003, -0.002);

  return (
    <group position={axesHelperPositionOffset}>
      <line_>
        <bufferGeometry attach="geometry" onUpdate={setXAxesPoints} />
        <lineBasicMaterial attach="material" color={'#fd5b5b'} />
      </line_>
      <line_>
        <bufferGeometry attach="geometry" onUpdate={setYAxesPoints} />
        <lineBasicMaterial attach="material" color={'#22bb33'} />
      </line_>
    </group>
  );
};

type GridProps = {
  showAxes: boolean;
};

export const Grid: FC<GridProps> = ({ showAxes }) => {
  useThree(({ gl }) => {
    gl.setClearColor(0x67686d);
    const g = gl.getContext();
    g.enable(g.BLEND);
    g.blendFunc(g.SRC_ALPHA, g.ONE_MINUS_SRC_ALPHA);
  });

  return (
    <group>
      <gridHelper
        args={[360, 360, '#616363', '#616363']}
        material-renderOrder={-1}
        material-depthTest={false}
        rotation-x={Math.PI / 2}
      />
      <gridHelper
        args={[360, 72, '#5B5E5E', '#5B5E5E']}
        material-renderOrder={-1}
        material-depthTest={false}
        rotation-x={Math.PI / 2}
      />
      {showAxes && <AxesHelper />}
    </group>
  );
};
