import { useMemo } from 'react';

import { Text } from '@/components/Shared/Text';
import { TrblInfoIcon } from '@/components/Icons';
import WarningTable from './WarningTable';

import { Warnings, WarningsInfo } from '../constants';

import { GeometryInfo, WarningTableGeometryInfo } from '../types';

import styles from './styles.module.scss';

export const MultiSpaceGeometryFeedbackBox = ({
  geometryInfo,
  modelId,
}: {
  geometryInfo: GeometryInfo;
  modelId: string;
}) => {
  const geometryWarningsData = useMemo(() => {
    if (modelId && (geometryInfo?.warnings?.length || geometryInfo?.errors?.length)) {
      const tableData: WarningTableGeometryInfo[] = [];
      const warningTypes = Object.values(Warnings);

      warningTypes.forEach((warningType) => {
        if (geometryInfo[warningType]?.length) {
          tableData.push({
            id: warningType,
            name: WarningsInfo[warningType][0],
            info: WarningsInfo[warningType][1],
            length: geometryInfo[warningType].length,
            // checking if cappedElementLists is undefined for backwards compatiablity, this check can be removed in the future
            actualLength: geometryInfo.cappedElementLists ? geometryInfo.cappedElementLists[warningType] : 0,
          });
        }
      });

      return tableData;
    }

    return null;
  }, [geometryInfo]);

  return (
    <div className={styles['warnings-container']}>
      <div className={styles['header']}>
        <TrblInfoIcon />
        <span className={styles['title']}>
          <Text type="semibold-12px">Geometry info</Text>
        </span>
      </div>
      {geometryWarningsData?.length ? (
        <WarningTable data={geometryWarningsData} modelId={modelId} />
      ) : (
        <div className={styles['no-warnings']}>
          <Text type="regular-11px">The geometry of this space has no warnings</Text>
        </div>
      )}
    </div>
  );
};
