import { FC, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { AccountProfile } from '@/components/AccountProfile';
import { CreateButton } from '../CreateButton';
import { RecentActivityNav } from '../RecentActivity/RecentActivityNav';

import styles from './styles.module.scss';

import trebleLogoUrl from '@/images/treble-logo.png';

type HeaderProps = {
  mainContent?: ReactNode;
  sideContent?: ReactNode;
  mainContentMinWidth?: string;
};

export const Header: FC<HeaderProps> = ({ mainContent, sideContent, mainContentMinWidth }) => {
  const location = useLocation();

  const extra =
    location.pathname.includes('/editor') ||
    location.pathname.includes('/results') ||
    location.pathname.includes('/auralizer')
      ? styles['editor']
      : location.pathname.includes('/project')
      ? styles['project']
      : '';

  return (
    <header className={` ${styles['header-container']} ${extra} `}>
      {location.pathname === '/' ? (
        <a href="/">
          <img src={trebleLogoUrl} alt="Logo" />
        </a>
      ) : (
        <Link to="/">
          <img src={trebleLogoUrl} alt="Logo" />
        </Link>
      )}
      <div
        style={{
          minWidth: mainContentMinWidth,
        }}
        className={styles['header-content-main']}>
        {mainContent}
      </div>
      <div className={styles['header-content-side']}>
        <Box component="div" alignItems={'center'} flex="1 1 0">
          {sideContent}
          <div
            style={{
              display: 'flex',
              width: '100%',

              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <CreateButton />
            <RecentActivityNav />
          </div>
        </Box>

        <AccountProfile />
      </div>
    </header>
  );
};
