import { FC } from 'react';

import { Box } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';

import classes from './styles.module.scss';

type SelectItemProps = {
  label: string;
  icon?: React.ReactNode;
  className?: string;
};

export const SelectItem: FC<SelectItemProps> = ({ label, icon, className }) => {
  return (
    <div className={className}>
      <TrblTooltip enterDelay={600} title={<div style={{ whiteSpace: 'pre-line' }}>{label?.replace(', ', '\n')}</div>}>
        <Box component="div" display="flex" alignItems="center" gap="7px">
          {icon}
          <span className={classes.label}>{label}</span>
        </Box>
      </TrblTooltip>
    </div>
  );
};
