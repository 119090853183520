import { useMutation } from '@tanstack/react-query';

import { ProjectDto } from '@/types';

import axios from '@/axios';

type CreateProjectDto = {
  name: string;
  description: string;
};

const createProject = async (request: CreateProjectDto) => {
  const { data } = await axios.post<ProjectDto>(`/api/v2/Project/CreateProject`, request);
  return data;
};

export const useCreateProject = () => {
  return useMutation(async (data: CreateProjectDto) => await createProject(data));
};
