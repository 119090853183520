import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';
import { OVERWRITE_TEXT } from '../const';

type ConfirmNewRunPopup = {
  showRunConfirmation: boolean;
  setShowRunConfirmation: (show: boolean) => void;
  runSimulation: () => void;
};

export const ConfirmNewRunPopup = ({
  showRunConfirmation,
  setShowRunConfirmation,
  runSimulation,
}: ConfirmNewRunPopup) => {
  return (
    <>
      {showRunConfirmation && (
        <ConfirmationDialog
          title="Confirm overwrite"
          message={() => <span> {OVERWRITE_TEXT} </span>}
          onConfirm={() => {
            runSimulation();
            setShowRunConfirmation(false);
          }}
          onCancel={() => setShowRunConfirmation(false)}
        />
      )}
    </>
  );
};
