import { useEffect, useState } from 'react';

import { ActionType, useEditorContext } from '@/context/EditorContext';

import './style.scss';

export const AutoSaveText = () => {
  const { autoSaveText, dispatch } = useEditorContext();
  const [text, setText] = useState<string | undefined>();

  useEffect(() => {
    setText(autoSaveText);
    setTimeout(() => {
      dispatch({ type: ActionType.SET_AUTO_SAVE_TEXT, text: '' });
    }, 1600);
  }, [autoSaveText]);

  return <div className={`autosave-text ${text && text.length > 0 ? 'show' : ''}`}>{text}</div>;
};
