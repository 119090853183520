import { FC } from 'react';

import { Box } from '@mui/material';

import { Text } from '@/components/Shared/Text';

import { Simulation } from '@/types';

type SimulationDetailsContentProps = {
  selectedSimulation: Simulation;
  isGAOnly: boolean;
};

export const SimulationDetailsContent: FC<SimulationDetailsContentProps> = ({ selectedSimulation, isGAOnly }) => {
  return (
    <Box component="div" display="flex" mt="16px" mb="16px" flexGrow={1}>
      {!isGAOnly && (
        <Box component="div" display="flex" flexDirection="column" gap="7px" width="100%">
          <Text type="semibold-11px">Transition frequency </Text>
          <Text type="regular-11px" numberFontStyleEnabled>
            {selectedSimulation.solverSettings.dgSettings.crossoverFrequency} Hz
          </Text>
        </Box>
      )}
      <Box component="div" display="flex" flexDirection="column" gap="7px" alignItems="flex-end" width="214px">
        <Text type="semibold-11px" style={{ width: `${isGAOnly ? '214px' : '137px'}` }}>
          Impulse response length
        </Text>
        <Text type="regular-11px" numberFontStyleEnabled style={{ width: '100%' }}>
          {selectedSimulation.solverSettings.dgSettings.impulseLengthSeconds} s
          {selectedSimulation.solverSettings.dgSettings.energyDecayThreshold !== null && (
            <span style={{ color: '#999999' }}> (Estimated)</span>
          )}
        </Text>
      </Box>
    </Box>
  );
};
