import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Skeleton } from '@mui/material';

import { HighlightedText } from '@/components/Shared/HighlightedText';
import { TrblSimulationIcon } from '@/components/Icons';
import defaultPlaceholderImageUrl from './images/default-placeholder.png';

import { useGetSimulationsByModelId } from '@/hooks';

import { Model } from '@/types';

import classes from './styles.module.scss';

export const GeometryCard = ({
  name,
  thumbnail = '',
  geometry: model,
  selectedGeometryId,
  searchValue,
}: {
  name: string;
  id: string;
  thumbnail: string;
  geometry: Model;
  searchValue?: string;
  selectedGeometryId?: string;
}) => {
  const navigate = useNavigate();
  const [imgLoaded, setImgLoaded] = useState(false);

  // fetch all simulations for a given model
  const { data: simulations = [], isFetching, isLoading } = useGetSimulationsByModelId(model.id || '');

  const handleSelected = () => {
    onOpen();
  };

  const onOpen = () => {
    navigate(`/editor?mid=${model.id}`);
  };

  return (
    <div
      onDoubleClick={onOpen}
      className={`${classes.model_card__container} base-model-card ${
        selectedGeometryId === model.id ? classes.selected : ''
      } ${isLoading || isFetching ? classes.disabled : ''} `}
      onClick={handleSelected}>
      <img
        className={classes.model_thumbnail}
        src={thumbnail || defaultPlaceholderImageUrl}
        style={{ opacity: imgLoaded ? '1' : '0' }}
        onLoad={() => {
          setImgLoaded(true);
        }}
        alt="model image"
      />
      {isLoading || isFetching || !imgLoaded ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ width: '100%', height: '60px', margin: '10px 0 10px 15px', opacity: 0.15 }}
        />
      ) : (
        <>
          <span className={classes.model_name}>
            {searchValue ? <HighlightedText text={name} highlight={searchValue} /> : <span> {name} </span>}{' '}
          </span>
          <div className={classes.card_model_data}>
            <div className={classes.card_model_data__content}>
              <span className={classes.card_model_data__content_icon}>
                <TrblSimulationIcon fill="#ADADAD" />
              </span>
              {!isFetching && `${simulations.length}`}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
