import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';
import { EditModelPopup, InfoModelPopup } from '@/components/ModelCard';
import { TrblActionsMenu } from '@/components/TrblActionsMenu';

import { useDeleteModelBase, useGetDownloadUrl } from '@/hooks';

import { IActions, Info, Model, ModelBaseDto } from '@/types';

export const GeometryActionsMenu = ({
  geometry,
  projectId,
  setSelected,
  classNames,
  onUpdate,
}: {
  geometry: ModelBaseDto;
  projectId?: string;
  setSelected?: (value: boolean) => void;
  classNames?: string;
  onUpdate?: () => void;
}) => {
  const queryClient = useQueryClient();

  const deleteModelBase = useDeleteModelBase();

  const [sourceGeometryUploadId, setSourceGeometryUploadId] = useState('');
  const { data: downloadSourceGeometryUrl } = useGetDownloadUrl(sourceGeometryUploadId);

  const [geometryInfo, setGeometryInfo] = useState<Info>({} as Info);

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const getGeometryActions = (modelBase: {
    id: string;
    name: string;
    application: string;
    sourceGeometryFileUploadId?: string;
    model: Model;
  }): IActions[] => [
    {
      value: 'Edit',
      onClick: () => {
        setShowEditPopup(true);
      },
      key: 'edit-geometry',
    },
    {
      value: 'Details',
      onClick: () => {
        setGeometryInfo({
          name: modelBase.name,
          id: modelBase.model.id,
          createdAt: modelBase.model.creationTime,
          createdBy: modelBase.model.createdByUserEmail,
        });
        setShowDetailsPopup(true);
      },
      key: 'details-geometry',
    },
    {
      value: 'Delete',
      onClick: () => {
        setShowDeleteDialog(true);
      },
      key: 'delete-geometry',
    },
    {
      value: 'Download source geometry',
      onClick: () => {
        if (modelBase.sourceGeometryFileUploadId && modelBase.application !== 'sketchup') {
          setSourceGeometryUploadId(modelBase.sourceGeometryFileUploadId);
        }
      },
      disabled: modelBase.application == 'sketchup',
      title: modelBase.application == 'sketchup' ? 'Not available for SketchUp geometries' : '',
      key: 'download-source-geometry',
    },
  ];

  useEffect(() => {
    if (downloadSourceGeometryUrl) {
      const link = document.createElement('a');
      link.download = 'source_geometry';
      link.href = downloadSourceGeometryUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [downloadSourceGeometryUrl]);

  function deleteModelBaseFunc(id: string, modelBase: { id: string; name: string }) {
    deleteModelBase.mutate(
      { modelBaseId: id },
      {
        onSuccess: () => {
          if (onUpdate) {
            onUpdate();
          } else {
            queryClient.invalidateQueries(['spaceById', geometry.spaceId]);
            queryClient.invalidateQueries(['sims-by-space-id', geometry.spaceId]);
            if (projectId) queryClient.invalidateQueries(['projectById', projectId]);
          }

          toast.info("'" + modelBase.name + "' deleted");
        },
        onError: () => {
          toast.error('An error occurred while deleting geometry');
        },
      }
    );
  }
  function updateCard() {
    setShowEditPopup(false);

    if (onUpdate) {
      onUpdate();
    } else {
      queryClient.invalidateQueries(['spaceById', geometry.spaceId]);
    }
  }
  const handleClosePopup = () => {
    setShowEditPopup(false);
    setShowDetailsPopup(false);
    setShowDeleteDialog(false);
    if (setSelected) setSelected(false);
  };

  return (
    <>
      <TrblActionsMenu
        actions={getGeometryActions({
          id: geometry.model.modelBaseId,
          name: geometry.name,
          application: geometry.model.application,
          sourceGeometryFileUploadId: geometry.model.sourceModelFileUploadId,
          model: geometry.model,
        })}
        id={geometry.id}
        title="View geometry actions"
        size="small"
        classNames={classNames}
      />
      {showEditPopup && (
        <EditModelPopup id={geometry.id} name={geometry.name} onClose={handleClosePopup} onUpdate={updateCard} />
      )}
      {showDetailsPopup && <InfoModelPopup info={geometryInfo} onClose={handleClosePopup} />}
      {showDeleteDialog && (
        <ConfirmationDialog
          title="Delete geometry"
          message={() => (
            <span>
              Are you sure you want to delete <b>{geometry.name}</b> ?
            </span>
          )}
          onConfirm={() => {
            deleteModelBaseFunc(geometry.id, geometry);
            setShowDeleteDialog(false);
          }}
          onCancel={handleClosePopup}
        />
      )}
    </>
  );
};
