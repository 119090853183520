import { FC, RefObject } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { HighlightedText } from '@/components/Shared/HighlightedText';
import { TrblModelsIcon } from '@/components/Icons';
import { SimulationLightDto } from '@/components/Results/types';

import { SpaceAndSimulations } from './types';

import styles from './styles.module.scss';

type SimulationsListProps = {
  space: SpaceAndSimulations;
  searchTerm: string;
  defaultSimulationId: string | null;
  defaultSimulationRef: RefObject<HTMLButtonElement>;
  onSimulationClick: (simulation: SimulationLightDto, modelName: string, spaceName: string) => void;
};

export const SimulationsList: FC<SimulationsListProps> = ({
  space,
  searchTerm,
  defaultSimulationId,
  defaultSimulationRef,
  onSimulationClick,
}) => (
  <div className={styles['simulations-list']}>
    {space.simulations.map((simulation) => (
      <button
        key={simulation.simulationDetails.id}
        ref={simulation.simulationDetails.id === defaultSimulationId ? defaultSimulationRef : undefined}
        className={`${styles['simulation-row']} ${
          simulation.simulationDetails.id === defaultSimulationId ? styles['highlight-default'] : ''
        }`}
        onClick={() => onSimulationClick(simulation.simulationDetails, simulation.modelName, space.spaceName)}>
        <TrblTooltip title={simulation.simulationDetails.name.length > 50 ? simulation.simulationDetails.name : ''}>
          <span className={styles['simulation-name']}>
            <HighlightedText text={simulation.simulationDetails.name} highlight={searchTerm} />
          </span>
        </TrblTooltip>
        <span className={styles['geometry-iteration-name']}>
          <span className={styles['geometry-iteration-icon']}>
            <TrblModelsIcon width="12" height="12" fill="#999999" />
          </span>
          <span className={styles['geometry-iteration-name-text']}>{simulation.modelName}</span>
        </span>
      </button>
    ))}
  </div>
);
