import { useRef, useState } from 'react';

import { useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { Popper, Stack } from '@mui/material';

import { TrblChevronDownIcon, TrblSimulationIcon } from '../Icons';
import { GeometryPicker } from '../New/GeometryPicker/GeometryPicker';
import { SearchInput } from '../Shared/SearchInput';
import { SimulationFilterMenu } from './SimulationFilterMenu';
import { SimulationPicker } from './SimulationPicker';

import { FilterType, ListType, SortType } from './constants';

import './styles2.scss';

export const EditorPicker = () => {
  const dropdownBtn = useRef<HTMLButtonElement>(null);
  const menuContent = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const {
    simulationState: { selectedSimulation, availableSimulations },
  } = useSimulationContext();
  const [filterType, setFilterType] = useState(FilterType.SPACE);
  const [listType, setListType] = useState(ListType.SIMULATIONS);
  const [sortSimListBy, setSortSimListBy] = useState(SortType.MODIFIED);
  const [sortGeoListBy, setSortGeoListBy] = useState(SortType.NAME);

  const [searchSimValue, setSearchSimValue] = useState('');
  const [searchGeoValue, setSearchGeoValue] = useState('');

  const { isAuralizerOpen } = useEditorContext();

  const onSearch = (value: string) => {
    if (listType === ListType.SIMULATIONS) {
      setSearchSimValue(value);
    } else {
      setSearchGeoValue(value);
    }
  };

  return (
    <div className="sim-picker-container">
      {isAuralizerOpen ? (
        <p className="sim-name-aur">
          <TrblSimulationIcon width="14" height="14" fill="#dadada" /> <span>{selectedSimulation?.name}</span>
        </p>
      ) : (
        <button
          ref={dropdownBtn}
          className={`dropdown-btn ${showMenu ? 'selected' : ''} ${
            availableSimulations?.length == 0 ? 'disabled' : ''
          }`}
          onClick={() => setShowMenu(true)}>
          <TrblSimulationIcon width="14" height="14" fill="#dadada" />
          <div className="simulation-name">
            {selectedSimulation?.name ?? (availableSimulations?.length == 0 ? 'No simulations' : 'Select simulation')}
          </div>
          <TrblChevronDownIcon width="8" height="6" fill="#adadad" />
        </button>
      )}
      {showMenu && (
        <Popper
          anchorEl={dropdownBtn.current}
          sx={{ zIndex: 1300 }}
          open={showMenu}
          placement="bottom-end"
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}>
          <div ref={menuContent} className="dropdown-content">
            <div>
              <ul className="row-tabs">
                <li className={`tab ${listType === ListType.SIMULATIONS ? 'active' : ''}`}>
                  <button onClick={() => setListType(ListType.SIMULATIONS)}>Simulations</button>
                </li>
                <li className={`tab ${listType === ListType.GEOMETRIES ? 'active' : ''}`}>
                  <button onClick={() => setListType(ListType.GEOMETRIES)}>Geometry iterations</button>
                </li>
              </ul>
              <div style={{ margin: '5px 12px' }}>
                <Stack flexDirection="row" gap="8px" alignItems="center" justifyContent="space-between">
                  <SearchInput
                    className="search-sim"
                    placeholder="Search"
                    value={listType === ListType.SIMULATIONS ? searchSimValue : searchGeoValue}
                    onChange={onSearch}
                  />
                  <SimulationFilterMenu
                    sortListBy={listType === ListType.SIMULATIONS ? sortSimListBy : sortGeoListBy}
                    setSortListBy={listType === ListType.SIMULATIONS ? setSortSimListBy : setSortGeoListBy}
                    filterType={filterType}
                    setFilterType={setFilterType}
                    listType={listType}
                  />
                </Stack>
              </div>
            </div>
            <SimulationPicker
              availableSimulations={availableSimulations}
              selectedSimulation={selectedSimulation}
              listType={listType}
              sortListBy={sortSimListBy}
              showMenu={showMenu}
              filterType={filterType}
              menuContent={menuContent}
              setShowMenu={setShowMenu}
              searchValue={searchSimValue}
            />
            <div
              className="geometries-list dropdown-content-list"
              style={{ display: listType == 'geometries' ? 'block' : 'none' }}>
              <GeometryPicker sortListBy={sortGeoListBy} searchValue={searchGeoValue} />
            </div>
          </div>
        </Popper>
      )}
    </div>
  );
};
