import { useMemo } from 'react';

import { ResultPresetDto } from '@/components/ResultPresets/types';

export const useTransformData = (allResultPresets: ResultPresetDto[], isLoadingPresets: boolean) => {
  return useMemo(() => {
    if (allResultPresets.length === 0 || isLoadingPresets) {
      return [];
    }

    return allResultPresets.map((row) => {
      const allSimulations = row.resultSimulations.map((sim) => sim.simulationName);
      const uniqueSimulations = [...new Set(allSimulations)].sort((a, b) => a.localeCompare(b)).join(', ');

      const allProjects = row.resultSimulations.map((sim) => sim.projectName);
      const uniqueProjects = [...new Set(allProjects)].sort((a, b) => a.localeCompare(b)).join(', ');

      const allSpaces = row.resultSimulations.map((sim) => sim.spaceName);
      const uniqueSpaces = [...new Set(allSpaces)].sort((a, b) => a.localeCompare(b)).join(', ');

      return {
        ...row,
        allSimulations: uniqueSimulations,
        allProjects: uniqueProjects,
        allSpaces: uniqueSpaces,
      };
    });
  }, [allResultPresets, isLoadingPresets]);
};
