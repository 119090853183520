import { FC, useMemo } from 'react';

import { PrimaryButton } from '@/components/Shared/Buttons';
import {
  TrblAuralizeIcon,
  TrblFolderIcon,
  TrblFolderSharedIcon,
  TrblOpenLibraryIcon,
  TrblResultsIcon,
  TrblSpaceIcon,
} from '@/components';
import { BreadcrumbTab } from '@/components/New/BreadcrumbTab/BreadcrumbTab';
import { useGetTutorialById } from '@/components/New/TutorialsPage/hooks';
import { handleOpenTutorialGuide } from '@/components/New/TutorialsPage/utils';
import { ResultPresetDto } from '@/components/ResultPresets/types';

import { ModelInformationDto, ProjectAndSpacesDto } from '@/types';

type EditorHeaderProps = {
  modelInformation: ModelInformationDto | null;
  project: ProjectAndSpacesDto | null;
  userInfo: { id: string } | null;
  selectedPreset: ResultPresetDto | null;
  showResults: boolean;
  showAuralizer: boolean;
  simulationId: string | null;
};

export const EditorHeader: FC<EditorHeaderProps> = ({
  modelInformation,
  project,
  userInfo,
  selectedPreset,
  showResults,
  showAuralizer,
  simulationId,
}) => {
  const breadcrumbItems = useMemo(() => {
    let items: BreadcrumbTab[] = [];

    if (!modelInformation || !project || !userInfo) {
      return items;
    }

    if (showResults || showAuralizer) {
      items = [
        {
          text: modelInformation.projectName,
          to: '/project/' + modelInformation.projectId,
          icon:
            project.createdBy !== userInfo?.id ? (
              <TrblFolderSharedIcon fill="#c0c0c0" />
            ) : (
              <TrblFolderIcon fill="#c0c0c0" />
            ),
        },
        {
          text: modelInformation.spaceName,
          to: '/editor?mid=' + modelInformation.id + (simulationId && '&sid=' + simulationId),
          icon: <TrblSpaceIcon width="15" height="15" />,
          hidden: !!selectedPreset,
        },
        {
          text: '',
          icon: showResults ? <TrblResultsIcon fill="#eaeaea" /> : <TrblAuralizeIcon fill="#eaeaea" />,
        },
      ];
    } else {
      items = [
        {
          text: modelInformation.projectName,
          to: '/project/' + modelInformation.projectId,
          icon:
            project.createdBy !== userInfo?.id ? (
              <TrblFolderSharedIcon fill="#c0c0c0" />
            ) : (
              <TrblFolderIcon fill="#c0c0c0" />
            ),
        },
        {
          text: modelInformation.spaceName,
          icon: <TrblSpaceIcon width="15" height="15" />,
          hidden: !!selectedPreset,
        },
      ];
    }

    return items;
  }, [modelInformation, project, userInfo, selectedPreset, showResults, showAuralizer]);

  const isTutorial = project?.isTutorial;
  const tutorialId = project?.spaces.find((space) => space.id === modelInformation?.spaceId)?.tutorialId;

  const { data: tutorial } = useGetTutorialById(tutorialId);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
      <div style={{ display: 'flex', gap: '10px' }}>
        <BreadcrumbTab items={breadcrumbItems} />
        {isTutorial && (
          <div style={{ display: 'flex', alignItems: 'center', padding: '0 20px' }}>
            <PrimaryButton
              disabled={!tutorial}
              onClick={() => handleOpenTutorialGuide(tutorial!.documentationUrl)}
              label="Open guide"
              icon={<TrblOpenLibraryIcon width="12" height="12" fill="#1F1F1F" />}
            />
          </div>
        )}
      </div>
    </div>
  );
};
