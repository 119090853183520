import { useQuery } from '@tanstack/react-query';

import { ModelSimulationsDto } from '@/types';

import axios from '@/axios';

export const getSimulationsBySpaceId = async (spaceId: string): Promise<ModelSimulationsDto[]> => {
  const { data } = await axios.get(`/api/Simulation/GetBySpaceId?spaceId=${spaceId}`);
  return data;
};

export const useGetSimulationsBySpaceId = (spaceId?: string) => {
  return useQuery<ModelSimulationsDto[], boolean>(
    ['sims-by-space-id', spaceId],
    () => getSimulationsBySpaceId(spaceId!),
    {
      enabled: !!spaceId,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );
};
