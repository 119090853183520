import { Text } from '@/components/Shared/Text';

export const StiResultCategories = () => {
  return (
    <>
      <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Text type="medium-11px" color="#000000">
          Bad
        </Text>
      </div>
      <div style={{ width: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Text type="medium-11px" color="#000000">
          Poor
        </Text>
      </div>
      <div style={{ width: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Text type="medium-11px" color="#000000">
          Fair
        </Text>
      </div>
      <div style={{ width: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Text type="medium-11px" color="#000000">
          Good
        </Text>
      </div>
      <div style={{ width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Text type="medium-11px" color="#000000">
          Excellent
        </Text>
      </div>
    </>
  );
};
