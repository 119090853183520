import { useMemo } from 'react';

import { ProjectAndSimulationData } from '../types';

export const useFilteredProject = (
  projectAndSimulationData: ProjectAndSimulationData[],
  selectedProjectId: string | null,
  searchTerm: string
) => {
  return useMemo(() => {
    const project = projectAndSimulationData.find((p) => p.projectId === selectedProjectId);
    if (!project || !searchTerm.trim()) {
      return project;
    }

    const searchTermLowerCase = searchTerm.toLowerCase();
    return {
      ...project,
      spaces: project.spaces
        .map((space) => ({
          ...space,
          simulations: space.simulations.filter((simulation) =>
            simulation.simulationDetails.name?.toLowerCase().includes(searchTermLowerCase)
          ),
        }))
        .filter((space) => space.simulations.length > 0),
    };
  }, [projectAndSimulationData, selectedProjectId, searchTerm]);
};
