import { toast } from 'react-toastify';

import { useEditorContext } from '@/context/EditorContext';
import { ActionType, useMeshContext } from '@/context/MeshContext';
import { ActionType as SimActionType, useSimulationContext } from '@/context/SimulationContext';

import { useFeatureFlags } from '@/components/FeatureToggles';
import { getMeshResult } from './useGetMeshResult';
import { getMeshTask } from './useGetMeshTask';

import { useCalculateSimulationRunEstimatedTime } from '@/hooks/SolveTask/useCalculateSimulationRunEstimatedTime';

import {
  maxMeshElements,
  maxMeshElementsId,
  maxMeshElementsMessage,
  meshElementSizeFactor,
  noMeshElementsMessage,
  smallMeshElements,
  smallMeshElementsId,
  smallMeshElementsMessage,
} from '@/utils/constants';

import { CompletedMeshTask } from '@/context/MeshContext/types';
import { Simulation } from '@/types';

export const useGetEstimatedSimulationTime = () => {
  const { dispatch, completedMeshResults, completedMeshTasks, meshWarnings } = useMeshContext();
  const { dispatch: simDispatch } = useSimulationContext();
  const { isInResultsMode } = useEditorContext();
  const { mutate: calculateSimulationRunEstimatedTime } = useCalculateSimulationRunEstimatedTime();
  const { removeMaxElementBlock } = useFeatureFlags();

  const getEstimatedSimulationTime = async (transitionFrequency: number, selectedSimulation: Simulation) => {
    if (selectedSimulation.taskType === 'GA') {
      // if GA only, don't send mesh results to calculation
      calculateSimulationRunEstimatedTime(
        {
          simulation: {
            simulationId: selectedSimulation.id,
            taskType: selectedSimulation.taskType,
            sources: selectedSimulation.sources,
            sourceParameters: selectedSimulation.sourceParameters,
            receivers: selectedSimulation.receivers,
            gridReceivers: selectedSimulation.gridReceivers,
            modelSettings: selectedSimulation.modelSettings,
            solverSettings: selectedSimulation.solverSettings,
            settingsPreset: selectedSimulation.settingsPreset,
            // Do not use estimation service that used ML model to create new estimation
            useEstimationService: false,
          },
          meshTaskId: null,
        },
        {
          onSuccess: (data) => {
            dispatch({
              type: ActionType.SET_CALCULATION_HAS_ERROR,
              error: null,
            });
            dispatch({ type: ActionType.SET_CURRENT_SIM_ESTIMATE, estimate: data });
            dispatch({ type: ActionType.SET_CURRENT_MESH_RESULT, currentMeshResult: null });
            dispatch({ type: ActionType.SET_IS_ESTIMATING, isEstimating: false });
          },
          onError: (error) => {
            dispatch({ type: ActionType.SET_IS_ESTIMATING, isEstimating: false });
            // @ts-expect-error error: unknown
            toast.error(`Error calculating simulation estimated time. ${error.response?.data}.`, {
              toastId: 'ga-estimation',
            });
          },
        }
      );
    }

    if (selectedSimulation.taskType === 'DG' || selectedSimulation.taskType === 'Hybrid') {
      // if DG only or Hybrid, then look for mesh results and send to calculation

      if (completedMeshTasks?.length) {
        let meshResult = null;

        const meshTask = completedMeshTasks.find(
          (completedMeshTask: CompletedMeshTask) => completedMeshTask.crossoverFrequency === transitionFrequency
        );

        if (meshTask) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          meshResult = completedMeshResults.find((x: any) => x.meshTaskId === meshTask.meshTaskId);
          if (!meshResult) {
            meshResult = await getNewMeshResults(meshTask.meshTaskId, !isInResultsMode);
          }
        }
        if (meshResult && meshTask) {
          const newCurrentMeshResult = {
            ...meshResult,
            meshTaskId: meshTask.meshTaskId, // yes this is supposed to be here
          };
          const newCompletedMeshResults = [...completedMeshResults, newCurrentMeshResult];
          dispatch({ type: ActionType.SET_COMPLETED_MESH_RESULTS, completedMeshResults: newCompletedMeshResults });
          dispatch({ type: ActionType.SET_CURRENT_MESH_RESULT, currentMeshResult: newCurrentMeshResult });
          dispatch({ type: ActionType.SET_MESH_ERROR, meshHasError: false });
          simDispatch({
            type: SimActionType.UPDATE_MESH_TASK_ID,
            meshTaskId: newCurrentMeshResult.meshTaskId,
          });

          // a catch for edge cases where the mesh results are returned as completed, but have no elements.
          if (!meshResult.elementCount) {
            toast.error(noMeshElementsMessage);
          } else {
            // if status 1 or 2 (in progress or completed) then don't show mesh warnings
            // if status 2 (completed) then only show mesh warning if simulation has been edited
            if (
              (selectedSimulation.extra?.status !== 1 && selectedSimulation.extra?.status !== 2) ||
              (selectedSimulation.extra?.status == 2 && selectedSimulation.hasBeenEdited === true)
            ) {
              // Warning for too many elements in mesh
              if (meshResult.elementCount > maxMeshElements && !removeMaxElementBlock) {
                const maxMeshWarningId = maxMeshElementsId + transitionFrequency.toString();
                if (meshWarnings.indexOf(maxMeshWarningId) === -1) {
                  toast.warning(maxMeshElementsMessage, { toastId: maxMeshWarningId });
                  dispatch({
                    type: ActionType.ADD_MESH_WARNING,
                    meshWarning: maxMeshWarningId,
                  });
                }
              }

              // Warning for too small elements in the mesh
              if (meshResult.elementMinLength * meshElementSizeFactor < smallMeshElements) {
                const smallMeshWarningId = smallMeshElementsId + transitionFrequency.toString();
                if (meshWarnings.indexOf(smallMeshWarningId) === -1) {
                  toast.warning(smallMeshElementsMessage, { toastId: smallMeshWarningId });
                  dispatch({
                    type: ActionType.ADD_MESH_WARNING,
                    meshWarning: smallMeshWarningId,
                  });
                }
              }
            }
            calculateSimulationRunEstimatedTime(
              {
                simulation: {
                  simulationId: selectedSimulation.id,
                  taskType: selectedSimulation.taskType,
                  sources: selectedSimulation.sources,
                  sourceParameters: selectedSimulation.sourceParameters,
                  receivers: selectedSimulation.receivers,
                  gridReceivers: selectedSimulation.gridReceivers,
                  modelSettings: selectedSimulation.modelSettings,
                  solverSettings: selectedSimulation.solverSettings,
                  settingsPreset: selectedSimulation.settingsPreset,
                  // Do not use estimation service that used ML model to create new estimation
                  useEstimationService: false,
                },
                meshTaskId: newCurrentMeshResult.meshTaskId,
              },
              {
                onSuccess: (data) => {
                  dispatch({
                    type: ActionType.SET_CALCULATION_HAS_ERROR,
                    error: null,
                  });
                  dispatch({ type: ActionType.SET_CURRENT_SIM_ESTIMATE, estimate: data });
                  dispatch({ type: ActionType.SET_IS_ESTIMATING, isEstimating: false });
                },
                onError: (error) => {
                  dispatch({ type: ActionType.SET_IS_ESTIMATING, isEstimating: false });
                  dispatch({
                    type: ActionType.SET_CALCULATION_HAS_ERROR,
                    // @ts-expect-error error: unknown
                    error: `Error calculating simulation estimated time. ${error.response?.data}.`,
                  });
                  // @ts-expect-error error: unknown
                  toast.error(`Error calculating simulation estimated time. ${error.response?.data}.`, {
                    toastId: meshTask.meshTaskId,
                  });
                },
              }
            );
          }
        } else {
          const filteredCompletedMeshTasks = completedMeshTasks.filter((completedMeshTask: CompletedMeshTask) => {
            return completedMeshTask.meshTaskId !== meshTask?.meshTaskId;
          });
          dispatch({ type: ActionType.SET_COMPLETED_MESH_TASKS, completedMeshTasks: filteredCompletedMeshTasks });
          dispatch({ type: ActionType.SET_CURRENT_MESH_RESULT, currentMeshResult: null });
        }
      } else {
        dispatch({ type: ActionType.SET_CURRENT_SIM_ESTIMATE, estimate: null });
      }
    }
  };

  return getEstimatedSimulationTime;
};

const getNewMeshResults = async (meshTaskId: string, showMessage: boolean = true) => {
  const meshTask = await getMeshTask(meshTaskId);
  const meshMade = new Date(meshTask.task.completedAt);
  const newMesherMade = new Date('2023-04-17');

  if (meshMade < newMesherMade) {
    if (showMessage) {
      toast.warning(
        'We have improved our geometry preprocessing so your previous estimate at ' +
          meshTask.crossoverFrequency +
          ' Hz is no longer available, please run the estimation again to proceed',
        { className: 'editor-toast', autoClose: false, toastId: 'mesh-' + meshTask.crossoverFrequency }
      );
    }

    return null;
  } else {
    const meshResult = await getMeshResult(meshTask.task.id);
    return meshResult;
  }
};
