import { useEffect, useState } from 'react';

import { WarningMessage } from '@/components/Shared/Popup';
import { TrblMemberSearch } from '@/components/Shared/TrblMemberSearch';
import { ProjectMembers } from './ProjectMembers';

import { useAddMemberToProject, useRemoveMemberFromProject } from '@/hooks';

import { UserBasicDto } from '@/types';

import classes from './styles.module.scss';

let projectUsersUpdated: UserBasicDto[] = [];

export const ManageAccessContent = ({
  projectId,
  creator,
  projectUsers,
  organizationUsers,
  setProjectUpdated,
}: {
  projectId: string;
  creator?: UserBasicDto;
  projectUsers: UserBasicDto[];
  organizationUsers: UserBasicDto[];
  setProjectUpdated: (value: boolean) => void;
}) => {
  const [dropdownList, setDropdownList] = useState<UserBasicDto[]>(organizationUsers);
  const [projectMembers, setProjectMembers] = useState<UserBasicDto[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [newMemberId, setNewMemberId] = useState('');

  const { mutate: addMemberToProject } = useAddMemberToProject();
  const { mutate: removeMemberFromProject } = useRemoveMemberFromProject();

  useEffect(() => {
    if (projectUsers && organizationUsers) {
      // filter out projectUsers for the dropdown list so the user won't select the same person twice
      const filteredOrganizatinUsers = filterListByUniqueId(organizationUsers, projectUsers);

      setDropdownList(filteredOrganizatinUsers);
      setProjectMembers(projectUsers);
      projectUsersUpdated = projectUsers;
    }
  }, [projectUsers, organizationUsers]);

  useEffect(() => {
    if (projectMembers.length > 0) {
      // filter out projectMembers for the dropdown list so the user won't select the same person twice
      const filteredOrganizatinUsers = filterListByUniqueId(organizationUsers, projectMembers);
      setDropdownList(filteredOrganizatinUsers);
    }
  }, [projectMembers]);

  const filterListByUniqueId = (listA: UserBasicDto[], listB: UserBasicDto[]) => {
    return listA.filter((objA) => !listB.map((objB) => objB.id).includes(objA.id));
  };

  const onAddMember = (member: UserBasicDto) => {
    const newProjectMembers = [...projectUsersUpdated, member];
    updateProjectMembers(newProjectMembers);
    addMemberToProject({ projectId, userId: member.id });
    setNewMemberId(member.id);
  };

  const onRemoveMember = (member: UserBasicDto) => {
    const newProjectMembers = projectUsersUpdated.filter((mem) => mem.id !== member.id);
    updateProjectMembers(newProjectMembers);
    removeMemberFromProject({ projectId, userId: member.id });
    setNewMemberId('');
  };

  const updateProjectMembers = (newProjectMembers: UserBasicDto[]) => {
    setProjectMembers(newProjectMembers);
    projectUsersUpdated = newProjectMembers;
    setProjectUpdated(true);
  };

  return (
    <>
      <TrblMemberSearch
        dropdownList={dropdownList}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectMember={onAddMember}
      />
      <div className={classes.project_members__container}>
        <ul className={classes.project_members__list}>
          <ProjectMembers
            creator={creator}
            projectMembers={projectMembers}
            removeMember={onRemoveMember}
            newMemberId={newMemberId}
          />
        </ul>

        <WarningMessage
          isClosed={projectMembers.length > 0}
          text="Anyone with access to the project has full editing rights, including using shared resources present in the
          project."
        />
      </div>
    </>
  );
};
