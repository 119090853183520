import { useQuery } from '@tanstack/react-query';

import { SpaceDashboardDto } from '@/types';

import axios from '@/axios';

const getSpacesDashboardByProjectId = async (projectId: string) => {
  const { data } = await axios.get<SpaceDashboardDto[]>(`/api/Space/GetSpacesDashboardByProjectId`, {
    params: {
      projectId,
    },
  });

  return data.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

export const useGetSpacesDashboardByProjectId = (projectId: string) => {
  const query = useQuery<SpaceDashboardDto[], boolean>(
    ['spaces-for-project', projectId],
    () => getSpacesDashboardByProjectId(projectId),
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
