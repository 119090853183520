export const getEstimatedTransitionFrequency = (modelVolume: number) => {
  const maxT = 1.0;
  const defaultFactor = 4;
  const thirdOctaveBands = [177, 224, 262, 355, 448, 562, 710];
  let volume = 1;
  if (modelVolume > 1) {
    volume = modelVolume;
  }
  const transitionFrequency = 2000 * Math.sqrt(maxT / volume) * defaultFactor;

  // Snaps the calculated frequency to one of the predefined third ocave band upper frequencies
  return thirdOctaveBands.reduce((prev: number, curr: number) =>
    Math.abs(curr - transitionFrequency) < Math.abs(prev - transitionFrequency) ? curr : prev
  );
};

export const computeMeshElementCount = (volume: number, frequency: number) => {
  // Constant that came from testing multiple meshes, taken from Jesper and the application team, then adjusted by Úlfur
  const element_ratio_355hz = 37;
  return Math.ceil(volume * Math.pow(frequency / 355, 3) * element_ratio_355hz);
};
