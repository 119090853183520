import { FC } from 'react';

import { TrblPopup, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { AddComparisonContent } from './AddComparisonContent';

const POPUP_TITLE = 'Add simulation';

type AddComparisonPopupProps = {
  showPopup: boolean;
  setShowPopup: (showPopup: boolean) => void;
};

export const AddComparisonPopup: FC<AddComparisonPopupProps> = ({ showPopup, setShowPopup }) => {
  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <TrblPopup
      width="820px"
      height="500px"
      hideBackdrop={false}
      aria-labelledby={POPUP_TITLE}
      sx={{ fontSize: '12px' }}
      onClose={handleClose}
      open={showPopup}>
      <TrblPopupTitle onClose={handleClose}>{POPUP_TITLE}</TrblPopupTitle>
      <TrblPopupContent style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
        <AddComparisonContent setShowPopup={setShowPopup} />
      </TrblPopupContent>
    </TrblPopup>
  );
};
