import { toast } from 'react-toastify';

import { ActionType as AppActionType, useAppContext } from '@/context/AppContext';
import { useCreateSurfaceLayers } from '@/context/hooks/useCreateSurfaceLayers';
import { useModelContext } from '@/context/ModelContext';
import { ActionType as SimActionType, useSimulationContext } from '@/context/SimulationContext';

import { ActionType as LibraryActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';

import { useDeleteMaterial } from '@/hooks';

export const useOnDeleteMaterial = () => {
  const { mutate: deleteMaterial } = useDeleteMaterial();
  const {
    appState: { filteredMaterials },
    dispatch: appDispatch,
  } = useAppContext();
  const { currentModel3dLayerGroups } = useModelContext();
  const {
    dispatch: simulationDispatch,
    simulationState: { missingMaterials, selectedSimulation },
  } = useSimulationContext();
  const { dispatch: libraryDispatch } = useLibraryPanelContext();
  const createSurfaceLayers = useCreateSurfaceLayers();

  const onDeleteMaterial = async (materialId: string, materialName: string) => {
    if (!materialId) return;

    // We need to return a promise so we can await for this to finish before e.g. popups close
    // because otherwise the re-rendering won't happen (the material list won't update)
    return new Promise((resolve) => {
      deleteMaterial(materialId, {
        onSuccess: () => {
          const newMaterials = filteredMaterials.filter((filteredMaterial) => filteredMaterial.id !== materialId);
          const newMissingMaterials = [
            ...missingMaterials,
            { materialId, materialName, isDeleted: true, isSharedWithOrganization: true },
          ];
          appDispatch({
            type: AppActionType.SET_MATERIALS,
            payload: newMaterials,
          });
          libraryDispatch({
            type: LibraryActionType.SET_HIGHLIGHTED_ITEM,
            highlightedItemId: null,
          });

          const newLayers = createSurfaceLayers(
            currentModel3dLayerGroups,
            selectedSimulation,
            newMaterials,
            newMissingMaterials
          );
          simulationDispatch({
            type: SimActionType.SET_SURFACE_LAYERS,
            surfaceLayers: newLayers,
          });
          simulationDispatch({
            type: SimActionType.SET_MISSING_MATERIALS,
            missingMaterials: newMissingMaterials,
          });

          toast.info("'" + materialName + "' deleted");
          resolve(true);
        },
        onError: () => {
          toast.error('An error occurred while deleting material');
        },
      });
    });
  };

  return onDeleteMaterial;
};
