import { FC } from 'react';

import { Text } from '@/components/Shared/Text';
import { IconTypes, TrblIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type LinkButtonProps = {
  text: string;
  url: string;
  icon: IconTypes;
  onClick?: (url: string) => void;
};

export const LinkButton: FC<LinkButtonProps> = ({ text, url, icon, onClick }) => {
  const handleClick = () => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <button className={styles['link-button']} onClick={onClick ? () => onClick(url) : handleClick}>
      <div className={styles['content']}>
        <div className={styles['left']}>
          <TrblIcon icon={icon} />
          <Text type="semibold-12px">{text}</Text>
        </div>
        <div className={styles['right']}>
          <TrblIcon icon={'arrowThinRight'} color={'#00F5BA'} />
        </div>
      </div>
      <div className={styles['devider']} />
    </button>
  );
};
