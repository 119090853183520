import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { useBaseContext } from '@/context/BaseContext';
import { useEditorContext } from '@/context/EditorContext';

import { Drawer, IconButton } from '@mui/material';

import { TrblCloseIcon, TrblRecentSimulation, TrblRecentSimulations } from '@/components/Icons';
import { useGetAllSimulationsForSpaces } from '@/components/Results/hooks/useGetAllSimulationsForSpaces';
import { RecentActivity } from './RecentActivity';
import { RecentSimulationStatusDisplay } from './RecentSimulationStatusDisplay';

import { getSimulationRunById, useGetSimulationRunStatusesByUser } from '@/hooks';
import { useGetEstimatedQueueTimesForQueuedSimulations } from '@/hooks/SimulationRun/useGetEstimatedQueueTimesForQueuedSimulations';

import { RunStatus, SimulationRunDto, SimulationRunStatusDto, SourceStatus, TaskStatus } from '@/types';

import classes from './styles.module.scss';

let hasLoadedOnce = false;

const isRunningStatus = (status: RunStatus) => {
  return [RunStatus.InProgress, RunStatus.Created, RunStatus.ProcessingResults, RunStatus.Queued].includes(status);
};

export const RecentActivityNav = () => {
  const location = useLocation();

  // TODO: Get rid of this, since we do not always render this component within the EditorProvider
  const { isAuralizerOpen } = useEditorContext();
  const {
    state: { subscriptionInfo },
  } = useBaseContext();

  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  function openSidebar() {
    setIsSidebarActive(true);
  }

  function closeSidebar() {
    setIsSidebarActive(false);
  }

  const { data: recentSimulations, isFetching: isFetchingTaskStatuses } = useGetSimulationRunStatusesByUser(8);
  const { data: estimatedQueueTimes = [] } = useGetEstimatedQueueTimesForQueuedSimulations(
    recentSimulations?.some((sim) => sim.status === RunStatus.Queued)
  );

  const { refetch: refetchAllSimulationsForSpaces } = useGetAllSimulationsForSpaces(false);

  const [runningSimulations, setRunningSimulations] = useState<Array<SimulationRunStatusDto>>([]);
  const [latestSimRunStatus, setLatestSimRunStatus] = useState<SimulationRunStatusDto | null>(null);
  const [latestTaskRun, setLatestTaskRun] = useState<TaskStatus | null>(null);

  const [percentage, setPercentage] = useState<number>(0);

  if (!isFetchingTaskStatuses && recentSimulations) {
    if (recentSimulations.length) {
      hasLoadedOnce = true;
    }
  }

  useEffect(() => {
    // every time route params change we close the recent simulation sidebar so it doesn't overlap the content
    setIsSidebarActive(false);
  }, [location.search]);

  const currentModelId = useMemo(() => {
    if (searchParams.size === 0) {
      return null;
    }

    return searchParams.get('mid');
  }, [searchParams]);

  // Map through all recent simulations that were previously running but are have now stopped running
  // Load the simulation run object for each of the completed ones to update in simulation context
  const completedSimulationRuns = useQueries({
    queries: recentSimulations?.length
      ? recentSimulations
          .filter(
            (simRunStatus) =>
              simRunStatus.status != null &&
              !isRunningStatus(simRunStatus.status) &&
              runningSimulations.findIndex((s) => s.id === simRunStatus.id) > -1
          )
          .map((simRunStatus) => {
            return {
              queryKey: ['simulationRun', simRunStatus.id],
              queryFn: () => getSimulationRunById(simRunStatus.id),

              onSuccess: (simRun: SimulationRunDto) => {
                // Clear cache for simulations in space to get updated list of simulations and updated status in sidepanel (only applies when in editor)
                if (currentModelId === simRunStatus.modelId) {
                  queryClient.invalidateQueries(['sims-by-space-id']);
                }

                if (simRun.status === RunStatus.Completed) {
                  if (location.pathname.includes('/results')) {
                    refetchAllSimulationsForSpaces();
                  }

                  toast.success(
                    <Link to={`/results?mid=${simRunStatus.modelId}&sid=${simRunStatus.simulationId}`}>
                      <p>Simulation completed</p>
                      <span> '{simRunStatus.simulationName}'</span>
                    </Link>,
                    { autoClose: 10000 }
                  );
                }
              },
            };
          })
      : [],
  });
  const isFetchingCompletedSimulationRun = completedSimulationRuns.some((r) => r.isFetching);

  const getLatestTaskRun = (sources: SourceStatus[]): TaskStatus | null => {
    let latestTaskStatus: null | TaskStatus = null;

    sources.forEach((item) => {
      if (item.taskStatuses?.length) {
        // Sort the tasks for each source by completedAt
        item.taskStatuses?.sort(
          (taskA: TaskStatus, taskB: TaskStatus) => dayjs(taskB.completedAt).unix() - dayjs(taskA.completedAt).unix()
        );

        // If the latestTaskStatus is not set or this source contains a later completedAt date we assign it
        if (
          latestTaskStatus === null ||
          dayjs(item.taskStatuses[0].completedAt).unix() > dayjs(latestTaskStatus.completedAt).unix()
        ) {
          latestTaskStatus = item.taskStatuses[0];
        }
      }
    });

    return latestTaskStatus;
  };

  useEffect(() => {
    // We need to wait for the simulation run fetch to complete so we don't mess up the useQueries above
    if (!isFetchingCompletedSimulationRun) {
      const orderedSimulationsInProgress =
        recentSimulations
          ?.filter((s) => s.status != null && s.sources?.length && isRunningStatus(s.status))
          .sort((a, b) => +b.createdAt - +a.createdAt) || [];
      setRunningSimulations(orderedSimulationsInProgress);

      const latestSimRunStatus = orderedSimulationsInProgress.length ? orderedSimulationsInProgress[0] : null;
      setLatestSimRunStatus(latestSimRunStatus);

      // show 99% done before showing 100% processing results
      if (
        latestSimRunStatus &&
        latestSimRunStatus.status !== RunStatus.ProcessingResults &&
        latestSimRunStatus.progressPercentage == 100
      ) {
        setPercentage(99);
      } else {
        setPercentage(latestSimRunStatus?.progressPercentage || 0);
      }

      const latestTaskRun = latestSimRunStatus ? getLatestTaskRun(latestSimRunStatus.sources!) : null;
      setLatestTaskRun(latestTaskRun);
    }
  }, [recentSimulations, isFetchingCompletedSimulationRun]);

  const handleChildClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {hasLoadedOnce ? (
        <>
          <div className={classes.recent_simulation_nav_container}>
            <button disabled={isAuralizerOpen} onClick={openSidebar} className={classes.recent_simulations_button}>
              {latestSimRunStatus ? (
                <>
                  <TrblRecentSimulation></TrblRecentSimulation>
                  <div
                    className={classes.recent_simulation_count}
                    title={runningSimulations.length.toString() + ' simulations running'}>
                    {runningSimulations.length.toString()}
                  </div>
                  <div
                    className={classes.recent_simulation_nav}
                    title={latestSimRunStatus.spaceName + ' › ' + latestSimRunStatus.modelBaseName}>
                    {currentModelId && latestSimRunStatus.modelId === currentModelId ? (
                      <p className={classes.simulation_name}>{latestSimRunStatus.simulationName}</p>
                    ) : (
                      <a
                        href={`/editor?mid=${latestSimRunStatus.modelId}`}
                        onClick={handleChildClick}
                        title={'Go to ' + latestSimRunStatus.spaceName + ' › ' + latestSimRunStatus.modelBaseName}>
                        <p className={classes.simulation_name}>{latestSimRunStatus.simulationName}</p>
                      </a>
                    )}

                    {(latestTaskRun || latestSimRunStatus.status === RunStatus.Created) && (
                      <RecentSimulationStatusDisplay
                        simulationStatus={latestSimRunStatus.status || RunStatus.Completed}
                        taskStatus={latestTaskRun}
                        percentage={percentage}
                        timeEstimate={latestSimRunStatus.timeEstimate || null}
                        startedAt={latestSimRunStatus.createdAt}
                        completedAt={latestSimRunStatus.completedAt}
                        parent="Nav"
                        queueTime={
                          estimatedQueueTimes.find((task) => task.simulationRunId === latestSimRunStatus.id)
                            ?.estimatedQueueTimeSeconds ?? null
                        }
                      />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <TrblRecentSimulations width="17" height="17"></TrblRecentSimulations>
                  <div className={classes.recent_simulation_nav}>
                    <h4>Recent tasks</h4>
                  </div>
                </>
              )}
            </button>
          </div>

          <Drawer
            open={isSidebarActive}
            anchor="right"
            onClose={closeSidebar}
            PaperProps={{
              style: {
                position: 'absolute',
                width: '400px',
                background: '#272727',
                boxShadow: '0px 6px 20px rgb(0 0 0 / 50%)',
                top: '0',
              },
            }}
            BackdropProps={{ style: { position: 'absolute', backdropFilter: 'blur(5px)' } }}
            ModalProps={{
              container: document.getElementsByClassName('page-layout')[0],
              keepMounted: true,
            }}
            variant="temporary">
            <h4 className={classes.recent_activity_header}>
              <TrblRecentSimulations /> Recent tasks
            </h4>
            <div className={classes.recent_activity_sidebar}>
              {isSidebarActive && (
                <RecentActivity color="#272727" hasAccessToShare={subscriptionInfo.hasAccessToShare} />
              )}
            </div>
            <IconButton className={classes.close_btn} aria-label="close" onClick={closeSidebar}>
              <TrblCloseIcon />
            </IconButton>
          </Drawer>
        </>
      ) : (
        <div className={classes.recent_simulation_nav_container}>
          <TrblRecentSimulations width="17" height="17" fill="#999999" />
          <div className={classes.recent_simulation_nav}>
            <h4 style={{ color: '#999999' }}>Recent tasks</h4>
          </div>
        </div>
      )}
    </>
  );
};
