// This is no longer used for some reason, see EMPTY_SIM for the new simulation object
export const emptySim = {
  modelId: '',
  name: '',
  userId: '',
  hasBeenEdited: false,
  id: '',
  extra: {},
  createdAt: '',
  description: '',
  modelSettings: {
    materialIdByObjectId: {},
    scatteringByObjectId: {},
  },
  solverSettings: {
    dgSettings: {
      crossoverFrequency: 710,
      impulseLengthSeconds: 1.0,
      sourceWidth: null,
      basisOrder: 4,
      cfl: 1.0,
      precision: 0,
      gpuCount: 1,
    },
    gaSettings: {
      crossoverFrequency: 710,
      impulseLengthSeconds: 1.0,
      frequencyBands: [125, 250, 500, 1000, 2000, 4000, 8000],
      generateIR: true,
      maxReflections: 100,
      reflectionOrder: 2,
      methodType: 0,
      rayCount: 10000,
      receiverDiameter: 0.3,
    },
  },
  sources: [],
  receivers: [],
  sourceParameters: [],
  taskType: null,
  settingsPreset: 1,
  lastSimulationRun: null,
};

export enum SortType {
  NAME = 'name',
  MODIFIED = 'date modified',
  CREATED = 'created by',
}

export enum FilterType {
  SPACE = 'space',
  GEOMETRY = 'geometry',
}

export enum ListType {
  SIMULATIONS = 'simulations',
  GEOMETRIES = 'geometries',
}
