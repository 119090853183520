import { useEffect, useState } from 'react';

import { SpaceExtractionTask } from '@/components/MultiSpaceImport/types';

import { FilterType } from '../utils';

import { SimulationRunStatusDto } from '@/types';

type Task = SimulationRunStatusDto | SpaceExtractionTask;

const getTaskDate = (task: Task) => {
  if ('sources' in task) {
    return new Date(task.completedAt || task.createdAt);
  }
  return new Date(task.task.completedAt || task.task.createdAt);
};

export const useRecentTasks = (
  recentSimulations: SimulationRunStatusDto[] | undefined,
  ifcImportTasks: SpaceExtractionTask[] | undefined,
  recentSharedSimulations: SimulationRunStatusDto[] | undefined,
  filterType: FilterType,
  shared: boolean | undefined,
  showNumber: number
) => {
  const [recentTasks, setRecentTasks] = useState<Task[] | undefined>(undefined);

  useEffect(() => {
    if (!shared) {
      let tasks: Task[] = [];
      switch (filterType) {
        case FilterType.IFCImportsOnly:
          tasks = [...(ifcImportTasks ?? [])];
          break;
        case FilterType.SimulationsOnly:
          tasks = [...(recentSimulations ?? [])];
          break;
        default:
          tasks = [...(recentSimulations ?? []), ...(ifcImportTasks ?? [])];
      }
      const sortedTasks = [...tasks].sort((a, b) => (getTaskDate(b) > getTaskDate(a) ? 1 : -1));
      setRecentTasks(sortedTasks.slice(0, showNumber));
    } else if (shared && recentSharedSimulations) {
      setRecentTasks(recentSharedSimulations.slice(0, showNumber));
    }
  }, [recentSimulations, ifcImportTasks, recentSharedSimulations, filterType, shared, showNumber]);

  return recentTasks;
};
