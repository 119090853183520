import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, Stack } from '@mui/material';

import { SecondaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { TextArea } from '@/components/Shared/TextArea';
import { TextField } from '@/components/Shared/TextField';

import { useCreateProject, useSubmitEvent } from '@/hooks';

interface CreateProjectPopupProps {
  onClose?: () => void;
}
export const CreateProject = ({ onClose }: CreateProjectPopupProps) => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState(true);

  const { mutate: createProject } = useCreateProject();

  useSubmitEvent(isFormValid, [name]);

  // Form validation
  useEffect(() => {
    if (name.length > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name]);

  const handleSubmit = () => {
    createProject(
      {
        name,
        description,
      },
      {
        onSuccess: (newProject) => {
          toast.info("New project '" + name + "' created!");
          navigate('/project/' + newProject.id);
        },
        onError: () => {
          toast.error('An error occurred while creating project');
        },
      }
    );
  };

  return (
    <TrblPopup aria-labelledby={'Create new project'} open={true}>
      <form>
        <TrblPopupTitle onClose={onClose}>{'Create new project'}</TrblPopupTitle>
        <TrblPopupContent>
          <Stack gap={1}>
            <TextField autoFocus maxLength={128} placeholder="Name" value={name} onChange={setName} />
            <TextArea placeholder="Description" value={description} onChange={setDescription} />
          </Stack>
        </TrblPopupContent>
        <TrblPopupActions>
          <Box component={'div'} display="flex" justifyContent="flex-end" width={'100%'}>
            <SecondaryButton
              type="submit"
              disabled={!isFormValid}
              width={'auto'}
              label="Create"
              onClick={handleSubmit}
            />
          </Box>
        </TrblPopupActions>
      </form>
    </TrblPopup>
  );
};
