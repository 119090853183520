import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { useBaseContext } from '@/context/BaseContext';

import { Stack } from '@mui/material';

import { Text } from '@/components/Shared/Text';
import { TrblProjectIcon, TrblSpaceIcon } from '@/components/Icons';
import { PageLayout } from '@/components/New/PageLayout';
import { InfoPanel } from '../InfoPanel';
import { ProjectCard } from '../ProjectsPage/ProjectCard';
import { RecentActivity } from '../RecentActivity';
import { SpaceCard } from '../SpaceCard/SpaceCard';

import { useGetRecentProjects } from '@/hooks/Project/useGetRecentProjects';
import { useGetRecentSpaces } from '@/hooks/Spaces/useGetRecentSpaces';

import { INFO_PANEL_KEY } from '../InfoPanel/constants';

import classes from './styles.module.scss';

export const DashboardNew = () => {
  const queryClient = useQueryClient();
  const {
    state: { userInfo, subscriptionInfo },
  } = useBaseContext();

  const { data: recentSpaces, isLoading: isLoadingSpaces } = useGetRecentSpaces('Owned');
  const { data: recentProjects, isLoading: isLoadingProjects } = useGetRecentProjects('Owned');

  const [projectSearchString] = useState('');

  const [showInfoPanel, setShowInfoPanel] = useState(localStorage.getItem(INFO_PANEL_KEY) ?? 'open');
  const closeInfoPanel = () => {
    localStorage.setItem(INFO_PANEL_KEY, 'closed');
    setShowInfoPanel('closed');
  };

  const onSpaceUpdate = () => {
    queryClient.invalidateQueries(['recent_spaces', 'Owned']);
  };
  const onProjectUpdate = () => {
    queryClient.invalidateQueries(['recent_projects', 'Owned']);
  };

  return (
    <PageLayout isFetching={isLoadingSpaces || isLoadingProjects} navpanel={true}>
      <div className={classes.homepage_container}>
        <InfoPanel show={showInfoPanel} subscriptionInfo={subscriptionInfo} closeInfoPanel={closeInfoPanel} />
        <div className={`${classes.homepage_content} ${showInfoPanel == 'open' ? classes.info_open : ''}`}>
          <div className={classes.homepage_left_content}>
            <div className={classes.homepage_header}>My recent spaces</div>
            <div className={classes.homepage_spaces_container}>
              {recentSpaces && recentSpaces.length > 0 ? (
                recentSpaces.map((space) => (
                  <SpaceCard
                    key={space.id}
                    space={space}
                    modified={`Modifed ${dayjs(space.updatedAt).format('DD MMM, HH:mm')}`}
                    onUpdate={onSpaceUpdate}
                  />
                ))
              ) : (
                <div className={classes.homepage_empty}>
                  <Stack gap="10px" alignItems="center">
                    <TrblSpaceIcon width="30px" height="30px" fill="#616161" />
                    <Text type="semibold-14px">You have no spaces</Text>
                  </Stack>
                </div>
              )}
            </div>
            <div className={classes.homepage_header}>My recent projects</div>
            <div className={classes.homepage_projects_container}>
              {userInfo && recentProjects && recentProjects.length > 0 ? (
                recentProjects?.map((project) => (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    loggedInUserId={userInfo.id}
                    projectSearchString={projectSearchString}
                    onUpdate={onProjectUpdate}
                  />
                ))
              ) : (
                <div className={classes.homepage_empty}>
                  <Stack gap="10px" alignItems="center">
                    <TrblProjectIcon width="30px" height="30px" fill="#616161" />
                    <Text type="semibold-14px">You have no projects</Text>
                  </Stack>
                </div>
              )}
            </div>
          </div>
          <div className={classes.homepage_right_content}>
            <RecentActivity hasAccessToShare={subscriptionInfo.hasAccessToShare} />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
