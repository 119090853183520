import { FC } from 'react';

import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';

import { SummedSourceOption } from '../../types';

interface DeleteMessageProps {
  name: string;
}

const DeleteMessage: FC<DeleteMessageProps> = ({ name }) => (
  <span style={{ width: '100%', overflowWrap: 'break-word' }}>
    Are you sure you want to delete the summed source <b>{name}</b>?
  </span>
);

interface DeleteSummedSourceDialogProps {
  summedSource: SummedSourceOption | null;
  onConfirm: (summedSourceId: string, taskIds: string[]) => void;
  onCancel: () => void;
}

export const DeleteSummedSourceDialog: FC<DeleteSummedSourceDialogProps> = ({ summedSource, onConfirm, onCancel }) => {
  if (!summedSource) return null;

  return (
    <ConfirmationDialog
      title="Delete summed source"
      message={() => <DeleteMessage name={summedSource.name} />}
      onConfirm={() => {
        onConfirm(
          summedSource.id,
          summedSource.completedTasks.map((t) => t.taskId)
        );
      }}
      onCancel={onCancel}
    />
  );
};
