import { ReactNode, useState } from 'react';
import { defineStyleConfig, PlacementWithLogical, Tooltip } from '@chakra-ui/react';

export const tooltipTheme = defineStyleConfig({
  baseStyle: {
    zIndex: 9999,
  },
});

export const TrblTooltip = ({
  children,
  title,
  placement,
  hidden,
  disableInteractive,
  enterDelay,
  arrow,
  defaultIsOpen = false,
  dark = false,
}: {
  children: ReactNode;
  title: string | ReactNode;
  placement?: PlacementWithLogical;
  hidden?: boolean;
  disableInteractive?: boolean;
  enterDelay?: number;
  arrow?: boolean;
  defaultIsOpen?: boolean;
  dark?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen ? undefined : false);
  const [isHover, setIsHover] = useState(false);

  return (
    <Tooltip
      styleConfig={tooltipTheme}
      openDelay={enterDelay ?? 300}
      closeDelay={100}
      background={dark ? 'rgba(38, 38, 38, 0.98)' : '#c0c0c0'}
      color={dark ? '#adadad' : '#0a0a0a'}
      padding={'3px 6px'}
      borderRadius={'4px'}
      fontSize={'11px'}
      fontWeight={'600'}
      fontFamily={'"Inter", arial'}
      letterSpacing={0.4}
      label={title}
      maxWidth={'296px'}
      overflowWrap={'break-word'}
      hasArrow={arrow}
      placement={placement}
      hidden={hidden}
      isOpen={isOpen}
      defaultIsOpen={defaultIsOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => {
        if (isHover) setIsOpen(true);
        else setIsOpen(false);
      }}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
        setIsHover(false);
      }}
      pointerEvents={disableInteractive ? 'none' : 'auto'}>
      {children}
    </Tooltip>
  );
};
