import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { SimulationUsageInfoDto } from './hooks/useGetTokenUsageReport';

import styles from './styles.module.scss';

export const getProjectUsageColumns = (): GridColDef<SimulationUsageInfoDto>[] => [
  {
    field: 'projectName',
    headerName: 'Project',
    width: 200,
    editable: false,
    headerClassName: styles['data-grid-header'],
  },
  {
    field: 'totalUsage',
    headerName: 'Total Usage',
    width: 150,
    editable: false,
    valueFormatter: (params) => `${params.value.toFixed(2)} tokens`,
    headerClassName: styles['data-grid-header'],
  },
  {
    field: 'simulationCount',
    headerName: 'Simulations',
    width: 120,
    editable: false,
    valueGetter: (params) => params.row.simulationUsages?.length || 0,
    headerClassName: styles['data-grid-header'],
  },
  {
    field: 'lastCompleted',
    headerName: 'Last Completed',
    width: 180,
    editable: false,
    valueGetter: (params) => {
      const completedDates = params.row.simulationUsages
        ?.map((sim) => sim.completedAt)
        .filter((date) => date) as string[];
      return completedDates.length > 0 ? Math.max(...completedDates.map((date) => new Date(date).getTime())) : null;
    },
    valueFormatter: (params) => (params.value ? dayjs(params.value).format('DD/MM/YYYY HH:mm') : '-'),
    headerClassName: styles['data-grid-header'],
  },
];

export const getSimulationUsageColumns = (): GridColDef[] => [
  {
    field: 'simulationName',
    headerName: 'Simulation Name',
    width: 200,
    editable: false,
    headerClassName: styles['data-grid-header'],
  },
  {
    field: 'usage',
    headerName: 'Usage',
    width: 150,
    editable: false,
    valueFormatter: (params) => `${params.value.toFixed(2)} tokens`,
    headerClassName: styles['data-grid-header'],
  },
  {
    field: 'runtime',
    headerName: 'Runtime',
    width: 120,
    editable: false,
    valueFormatter: (params) => {
      if (!params.value) return '-';

      const seconds = Math.floor(params.value);
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      if (hours > 0) {
        return `${hours}h ${minutes}m ${remainingSeconds}s`;
      } else if (minutes > 0) {
        return `${minutes}m ${remainingSeconds}s`;
      } else {
        return `${remainingSeconds}s`;
      }
    },
    headerClassName: styles['data-grid-header'],
  },
  {
    field: 'maxGPUCount',
    headerName: 'GPU Count',
    width: 120,
    editable: false,
    valueFormatter: (params) => params.value || '-',
    headerClassName: styles['data-grid-header'],
  },
  {
    field: 'completedAt',
    headerName: 'Completed',
    width: 180,
    editable: false,
    valueFormatter: (params) => (params.value ? dayjs(params.value).format('DD/MM/YYYY HH:mm') : '-'),
    headerClassName: styles['data-grid-header'],
  },
  {
    field: 'email',
    headerName: 'Run by',
    width: 200,
    editable: false,
    valueFormatter: (params) => params.value || '-',
    headerClassName: styles['data-grid-header'],
  },
];
