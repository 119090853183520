import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import axios from '@/axios';

export type ModalResultDtoModalResultDto = {
  modalResultDownloadUrl: string | null;
  sourcePointId: string;
  sourceSummingName: string;
  solveResultId: string;
};

const getGetLastModalAnalysisResult = async (simulationId: string | undefined) => {
  const { data } = await axios.get(`/api/SolveTask/GetLastModalAnalysisResult?simulationId=${simulationId}`);

  return await data;
};

export const useGetLastModalAnalysisResult = (simulationId: string | undefined, enabled = true) => {
  return useQuery<ModalResultDtoModalResultDto[], boolean>(
    ['lastModalAnalysisResultBySimulationId', simulationId],
    () => getGetLastModalAnalysisResult(simulationId),
    {
      refetchOnWindowFocus: false,
      enabled: !!simulationId && enabled,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.status === 404) {
          toast.error(
            'Forced modal results not found. Modal analysis is only applicable for wave based simulations and simulations run on or after 6th of February 2025.'
          );
        } else {
          toast.error('An error occurred while fetching data');
        }
      },
    }
  );
};
