import { TrblCheckIcon } from '@/components/Icons/TrblCheckIcon';

import { SortType } from '../constants';

import classes from './styles.module.scss';

type SortButtonsProps = {
  sortListBy: SortType;
  setSortListBy: (value: SortType) => void;
  sortOptions: {
    label: string;
    type: SortType;
    disabled?: boolean;
  }[];
};

export const SortButtons = ({ sortListBy, setSortListBy, sortOptions }: SortButtonsProps) => {
  return (
    <div>
      {sortOptions.map(({ label, type, disabled }) => (
        <button key={type} disabled={disabled} className={classes.filter_row} onClick={() => setSortListBy(type)}>
          {sortListBy === type ? (
            <span className={classes.filter_row_icon}>
              <TrblCheckIcon />
            </span>
          ) : null}
          {label}
        </button>
      ))}
    </div>
  );
};
