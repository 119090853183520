import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { MoreInformationLink, pricingUrl } from '@/utils/constants';

import { getTimeStringByDuration, roundFloat, toSorted } from '@/utils/trebleFunctions';

import { SimulationRunDetails, SimulationStatusText } from './types';
import { RunStatus, SimulationRunDto, SimulationRunStatusDto, TaskRunStatus } from '@/types';

export const getIsInProgressByStatus = (runStatus: RunStatus | null) => {
  switch (runStatus) {
    case RunStatus.Created:
    case RunStatus.InProgress:
    case RunStatus.Queued:
    case RunStatus.ProcessingResults:
      return true;
    default:
      return false;
  }
};

export const getSimulationStatusTextByRunStatus = (runStatus: RunStatus | null): SimulationStatusText => {
  switch (runStatus) {
    case RunStatus.Queued:
      return SimulationStatusText.Queued;
    case RunStatus.Created:
    case RunStatus.InProgress:
      return SimulationStatusText.Running;
    case RunStatus.Completed:
      return SimulationStatusText.Completed;
    case RunStatus.ProcessingResults:
      return SimulationStatusText.ProcessingResults;
    case RunStatus.Cancelled:
      return SimulationStatusText.Cancelled;
    case RunStatus.InsufficientTokens:
      return SimulationStatusText.InsufficientTokens;
    case RunStatus.Error:
    case RunStatus.TaskError:
    default:
      return SimulationStatusText.Error;
  }
};

export const getSourceStatusTextByTaskRunStatus = (taskRunStatuses: TaskRunStatus[]) => {
  if (taskRunStatuses.length) {
    if (taskRunStatuses.some((s) => s === 'Error' || s === 'Invalid')) {
      return 'Error';
    } else if (taskRunStatuses.some((s) => s === 'Cancelling' || s === 'Cancelled')) {
      return 'Cancelled';
    } else if (taskRunStatuses.some((s) => s === 'Queued')) {
      return 'Queued';
    } else if (taskRunStatuses.every((s) => s === 'Completed')) {
      return 'Completed';
    }
  }

  return '';
};

export const getIsInProgressByTaskRunStatus = (taskRunStatuses: TaskRunStatus[]) => {
  if (taskRunStatuses.some((s) => ['Created', 'Pending', 'InProgress', 'ProcessingResults'].includes(s))) {
    return true;
  }

  return false;
};

const getProgressBarColorByStatusText = (statusText: string) => {
  if (statusText === 'Cancelled') return 'warning';
  if (statusText === 'Error') return 'error';
  return 'secondary';
};

export const getProgressBarColorByStatus = (runStatus: RunStatus | null) => {
  switch (runStatus) {
    case RunStatus.Cancelled:
    case RunStatus.InsufficientTokens:
      return 'warning';
    case RunStatus.Error:
    case RunStatus.TaskError:
      return 'error';
    default:
      return 'primary';
  }
};

export const getProgressBarColorByTaskStatus = (runStatus: TaskRunStatus | null) => {
  switch (runStatus) {
    case 'Cancelled':
    case 'Cancelling':
      return 'warning';
    case 'Error':
    case 'Invalid':
      return 'error';
    default:
      return 'secondary';
  }
};

export const getSimulationRemainingEstimate = (percentage: number | null, timeEst: number | null) => {
  if (percentage && timeEst) {
    dayjs.extend(duration);

    // we use the percentage to adjust remaining time, since timeEst can sometimes a bit wrong
    const difference = timeEst * (1 - percentage / 100) * 1000;
    const differenceDuration = dayjs.duration(difference);

    if (difference > 0) {
      return getTimeStringByDuration(differenceDuration);
    } else {
      return null;
    }
  }

  return null;
};

export const mapSimulationRunToSimulationRunDetails = (simulationRun: SimulationRunDto): SimulationRunDetails => {
  const inProgress = getIsInProgressByStatus(simulationRun?.status);

  const runDetails: SimulationRunDetails = {
    id: simulationRun.id,
    inProgress,
    statusText: getSimulationStatusTextByRunStatus(simulationRun.status),
    showEllipsis: simulationRun.status === RunStatus.ProcessingResults,
    createdAt: simulationRun.createdAt || null,
    completedAt: null,
    percentage: simulationRun.progressPercentage || null,
    progressBarColor: getProgressBarColorByStatus(simulationRun.status),
    timeRemainingText: null,
    showRemaining:
      inProgress && simulationRun.status !== RunStatus.ProcessingResults && simulationRun.status !== RunStatus.Queued,
    sourceDetails: toSorted(simulationRun.sources, (a, b) => a.orderNumber - b.orderNumber).map((s, index) => ({
      label: s.label || `Source ${index + 1}`,
      progressBarColor: 'secondary',
      inProgress: false,
      statusText: '',
      percentage: null,
      orderNumber: s.orderNumber,
    })),
  };

  if (runDetails.statusText === SimulationStatusText.Running && !runDetails.percentage) {
    runDetails.statusText = SimulationStatusText.InitializingSolver;
    runDetails.showEllipsis = true;
    runDetails.showRemaining = false;
  }

  return runDetails;
};

export const mapSimulationRunStatusToSimulationRunDetails = (
  simulationRunStatus: SimulationRunStatusDto,
  taskType: string | null
): SimulationRunDetails => {
  const inProgress = getIsInProgressByStatus(simulationRunStatus.status);

  let percentage = simulationRunStatus.progressPercentage;
  if (inProgress && percentage === 100) percentage = 99;

  const runDetails: SimulationRunDetails = {
    id: simulationRunStatus.id,
    inProgress: getIsInProgressByStatus(simulationRunStatus.status),
    statusText: getSimulationStatusTextByRunStatus(simulationRunStatus.status),
    createdAt: simulationRunStatus.createdAt,
    completedAt: simulationRunStatus.completedAt,
    percentage: percentage,
    progressBarColor: getProgressBarColorByStatus(simulationRunStatus.status),
    showEllipsis: simulationRunStatus.status === RunStatus.ProcessingResults,
    timeRemainingText: getSimulationRemainingEstimate(
      simulationRunStatus.progressPercentage,
      simulationRunStatus.timeEstimate
    ),
    showRemaining:
      inProgress &&
      simulationRunStatus.status !== RunStatus.ProcessingResults &&
      simulationRunStatus.status !== RunStatus.Queued,
    sourceDetails:
      simulationRunStatus.sources
        ?.sort((a, b) => a.orderNumber - b.orderNumber)
        .map((s, index) => {
          const statusText = getSourceStatusTextByTaskRunStatus(
            s.taskStatuses ? s.taskStatuses.map((s) => s.status) : []
          );

          return {
            label: s.label ? s.label : `Source ${index + 1}`,
            statusText: statusText,
            inProgress: getIsInProgressByTaskRunStatus(s.taskStatuses ? s.taskStatuses.map((s) => s.status) : []),
            progressBarColor: getProgressBarColorByStatusText(statusText),
            percentage: s.progressPercentage,
            orderNumber: s.orderNumber,
          };
        }) || [],
  };

  if (runDetails.statusText === 'Running' && !runDetails.percentage && taskType !== 'GA') {
    runDetails.statusText = SimulationStatusText.InitializingSolver;
    runDetails.showEllipsis = true;
    runDetails.showRemaining = false;
  }

  return runDetails;
};

export const formatEstimatedTokens = (totalEstimatedTokensSeconds: number) =>
  roundFloat(totalEstimatedTokensSeconds / 60 / 60, 2);

export const getTokenText = () => (
  <div style={{ whiteSpace: 'pre-line' }}>
    The estimated runtime and required tokens is an approximation of how much computational effort is needed to run the
    simulation. <br />
    <br />
    The estimated token cost indicates the amount deducted from the organization's token pool upon the successful
    completion of a simulation run. This is only an estimate and the actual token cost of the simulation is what will be
    registered to the account. <br />
  </div>
);

export const getTrialTokenText = () => {
  return `The free trial is restricted by cloud computing usage. This field shows what percentage of the overall trial allowance this simulation uses.`;
};

export const getConcurrencyInfoText = () => {
  return (
    <>
      This simulation is partially queued. Due to the concurrency limit of your organization some sources in this
      simulation will be queued until other finish. <MoreInformationLink href={pricingUrl} />
    </>
  );
};

export const getQueueInfoText = () => {
  return (
    <>
      Estimated wait time in queue. Due to the concurrency limit of your organization and the number of ongoing
      simulations, this simulation will be wholly queued until other simulations finish.{' '}
      <MoreInformationLink href={pricingUrl} />
    </>
  );
};

const getRemainingText = (showRemaining: boolean, percentage: number | null, timeRemainingText: string | null) => {
  if (!showRemaining) return '';
  const percentageText = `${percentage ?? 0}%`;
  return timeRemainingText ? `${percentageText} · ${timeRemainingText} remaining` : percentageText;
};

export const getStatusText = (
  statusText: string,
  showRemaining: boolean,
  percentage: number | null,
  timeRemainingText: string | null
) => {
  return `${statusText} ${getRemainingText(showRemaining, percentage, timeRemainingText)}`;
};

export const secToTimeString = (durInSec: number) => {
  if (durInSec > 0) {
    dayjs.extend(duration);
    const differenceDuration = dayjs.duration(durInSec * 1000, 'millisecond');

    return getTimeStringByDuration(differenceDuration);
  } else {
    return '';
  }
};
